import React, { useState, useEffect } from "react";
import {
    Grid,
    makeStyles,
    CircularProgress,
    TextField,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Button,
    Dialog,
    DialogContent,
    colors,
    Divider,
    DialogTitle,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Field, Form, Formik } from 'formik';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Chrono } from "react-chrono";
import moment from 'moment';
import CommentsBlock from 'simple-react-comments';
import Autocomplete from '@mui/material/Autocomplete';


import {
    getUserIncident,
    updateFromData,
    getIncidentManager,
    updateIncident,
    getIncidentAdmin,
    acceptIncident,
    rejectIncident,
    resolveIncident,
    clearSnackBar,
    assignIncident,
    convertToRequest,
    acceptIncidentAsAc
} from "../../redux/actions/incidents/incidentActions"
import { getBus, getBuses } from '../../redux/actions/buses/buses'
import CustomMessage from "../templates/CustomMessages";
import { getStatuses } from "../../redux/actions/status/statusAction"
import { getPriorities } from '../../redux/actions/priority/prioritiesActions'
import { getDamages } from '../../redux/actions/damages/damages'
import { getCategories } from '../../redux/actions/categories/categoriesActions'
import { checkLogin } from "../../redux/actions/auth/login"
import { getUsersAction } from '../../redux/actions/usersAction'
// import { removeSpace } from '../../../utils/removeSpaces';
import { removeSpace } from '../../utils/removeSpaces'
import {
    getProjects,
    getProject
} from "../../redux/actions/projects/projectsAction";
import { getDevices } from "../../redux/actions/devices/getDevices";


import Top from "../templates/TopPartOfPageWithId"
import color from "../colors"
import AdditionalInfo from '../templates/AdditionalInfo';
import AddModel from '../templates/AddModel'
import * as yup from 'yup';
import "./incidentView.scss"
import Selects from "./incidentSubmission/select"
import { createComment } from "../../redux/actions/comments/comments";

export const useStyles = makeStyles((theme) => ({
    "&.Mui-focused": {
        border: "2px solid red",
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    contain: {
        position: "relative",
        width: "100%",
        marginBottom: "2px",
        borderRadius: "3px"
    },
    btncontainer: {
        marginBottom: theme.spacing(2.5)
    },
    sectionTitle: {
        color: color.primary100,
        fontSize: '1.3em',
        fontWeight: 'bold',
    },
    btn: {
        height: theme.spacing(3.5),

    },
    submitbtn: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2)

    },
    acceptbtn: {
        backgroundColor: `${color.success}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.success}`,

        }

    },
    rejectbtn: {
        backgroundColor: `${color.yellow}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.yellow}`,

        }

    },
    requestbtn: {
        backgroundColor: `${color.mix}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.mix}`,

        }

    },
}))
const customStyles = {
    content: {
        marginTop: '4em'
    },
};
const validationSchema = yup.object({
    description: yup
        .string()
        .required('description is required')
        .max(100)

});


function Incident(props) {
    const dispatch = useDispatch();
    const incidents = useSelector(state => state.incidents)
    const priorities = useSelector(state => state.priorities)
    const damages = useSelector(state => state.damages)
    const categories = useSelector(state => state.categories)
    const auth = useSelector(state => state.login)
    const users = useSelector(state => state.users)
    const statuses = useSelector(state => state.statuses)
    const comments = useSelector(state => state.comments)
    const projects = useSelector(state => state.projects)
    const buses = useSelector(state => state.buses)
    const devices = useSelector(state => state.devices)

    const classes = useStyles();
    const [slideIndex, setSlideIndex] = useState(0);
    const [edit, setEdit] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isLightboxOpen, setLightboxOpen] = useState(false)
    const [openAssign, setOpenAssign] = useState(false)
    const [assigneeUser, setAssigneeUser] = useState()
    const [action, setAction] = useState("")
    const [projectId, setProjectId] = useState(null);
    const [replacementId, setReplacementId] = useState(null)
    const [categoryName, setCategoryName] = useState("")
    const [busId, setBusId] = useState(0)
    const [canEditId, setCanEditId] = useState(false)


    useEffect(() => {
        dispatch(checkLogin());
    }, [comments.comment])



    useEffect(() => {
        dispatch(checkLogin());
        dispatch(getStatuses());
        dispatch(getDamages())
        dispatch(getPriorities());
        dispatch(getUsersAction())
        dispatch(getProjects())
        dispatch(getCategories())
        dispatch(getProject(projectId))
        dispatch(getDevices())

        // getBuses()
        dispatch(getBus(busId))
        // dispatch(getDevices)
        // dispatch(getDevi)
    }, [])

    // console.log("here the incident we have is:", incidents.incident)

    useEffect(() => {
        const selectedProject = projects.projects ? projects.projects.filter(project => project.name == incidents.formData.project) : []
        const selectedBus = buses.buses ? buses.buses.filter(bus => bus.plateNumber == incidents.formData.bus) : []

        setProjectId(selectedProject.length !== 0 ? selectedProject[0].id : 0)
        setBusId(selectedBus.length !== 0 ? selectedBus[0].id : 0)
    }, [incidents.formData.project, incidents.formData.bus])


    useEffect(() => {
        if (auth.user) {
            if (auth.user.role.Permissions.manageIncident) {
                dispatch(getIncidentAdmin(props.match.params.id));
                setCanEditId(true)
            } else if (auth.user.role.Permissions.institutionManager) {
                dispatch(getIncidentManager(props.match.params.id));
            }
            else {
                setEdit(false)
                dispatch(getUserIncident(props.match.params.id));
            }
        }

        setEdit(false)
        if (incidents.formData.actionRequired) {
            setAction(incidents.formData.actionRequired)
        }
    }, [incidents.changed, auth.user])

    useEffect(() => {
        incidents.incident.category ? setCategoryName(incidents.incident.category.name) : setCategoryName("")
    }, [incidents.incident])

    // console.log("Teh state for incidents is:", incidents)

    const handleOpenAssign = () => setOpenAssign(true)
    const handleCloseAssign = () => setOpenAssign(false)
    const handleAssignUser = () => {
        dispatch(assignIncident(props.match.params.id, assigneeUser))
        handleCloseAssign()
    }

    let timelineActivities = []
    incidents.incident && incidents.incident.timeline ? incidents.incident.timeline.map(activity => {
        timelineActivities.push({
            title: incidents.incident.timeline.indexOf(activity) + 1,
            cardSubtitle: moment(activity.createdAt).format('MMM D, YYYY h:mm a'),
            cardTitle: activity.user.name,
            cardDetailedText: activity.activity
        })
    }) : timelineActivities.push({})

    let incidentComments = []
    incidents.incident && incidents.incident.comments ? incidents.incident.comments.map(comment => {
        incidentComments.push({
            avatarUrl: comment.createdUser.image,
            authorUrl: '/',
            fullName: comment.createdUser.name,
            createdAt: new Date(comment.createdAt),
            text: comment.comment
        })
    }) : incidentComments = []

    const userOptions = users ? users.users.map(user => {
        return {
            label: user.name,
            value: user.id
        }
    }) : []

    const deviceOptions = devices ? devices.devices.map(device => {
        return {
            label: `${device.itsDeviceType.name} - ${device.deviceImei}`,
            value: device.id
        }
    }) : []

    const actionOptions = ["Repair", "Refund", "Replace Device"]

    const plusSlides = (n) => {
        const index = slideIndex + n
        showSlides(index);
    }
    const currentSlide = (n) => {
        showSlides(n)
    }
    const showSlides = (n = 0) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length - 1) {
            n = 0
        }
        if (n < 0) {
            n = slides.length - 1
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        setSlideIndex(n)
        slides[n].style.display = "block";
        dots[n].className += " active";
    }
    const handlUpdateClick = () => {
        setEdit(!edit)
    }

    const handleAccepte = () => {
        dispatch(acceptIncident(props.match.params.id))
    }
    const handleAcceptAsAc = () => {
        dispatch(acceptIncidentAsAc(props.match.params.id))
    }
    const handleClose = () => {
        dispatch(resolveIncident(props.match.params.id))
    }
    const handleReject = () => {
        dispatch(rejectIncident(props.match.params.id))
    }
    const handleRequest = () => dispatch(convertToRequest(props.match.params.id))


    let spans = []
    let images = []
    let actionBtn = []
    if (!incidents.incident.declined && !incidents.incident.resolved) {

        if (auth.user) {
            const { Permissions } = auth.user.role
            if (auth.user.id === incidents.incident.createdBy || (Permissions.manageIncident || Permissions.institutionManager)) {

                let btn = <Grid item xs={6} sm={3} lg={2} >
                    <Button variant="contained" color="primary" onClick={handlUpdateClick} className={classes.btn}>Update</Button>
                </Grid>
                actionBtn.push(btn)
            }

            if (!incidents.incident.acceptedByInstitution && (Permissions.confirmIncidentAsInstitution)) {
                let btn = <Grid item xs={6} sm={3} lg={2} >
                    <Button variant="contained" onClick={handleAccepte} className={classes.acceptbtn}>Accept</Button>
                </Grid>


                actionBtn.push(btn)
            }
            if (!incidents.incident.acceptedByAc && (Permissions.confirmIncidentAsAc)) {
                let btn = <Grid item xs={6} sm={3} lg={2} >
                    <Button variant="contained" onClick={handleAcceptAsAc} className={classes.acceptbtn}>Accept as AC</Button>
                </Grid>


                actionBtn.push(btn)
            }
            if (!incidents.incident.declined && (Permissions.disclosureIncident || Permissions.institutionManager)) {
                let btn = <Grid item xs={6} sm={3} lg={2}  >
                    <Button variant="contained" onClick={handleReject} className={classes.rejectbtn}>Reject</Button>
                </Grid>


                actionBtn.push(btn)
            }
            if (!incidents.incident.isRequest && Permissions.manageIncident) {
                let btn = <Grid item xs={6} sm={3} lg={2}  >
                    <Button
                        variant="contained"
                        className={classes.requestbtn}
                        onClick={handleRequest}
                    >Request</Button>
                </Grid>


                actionBtn.push(btn)
            }
            if (Permissions.disclosureIncident || Permissions.manageIncident) {
                let btn = <Grid item xs={6} sm={3} lg={2}  >
                    <Button variant="contained" color="secondary" onClick={handleClose} className={classes.btn}>Resolve</Button>
                </Grid>
                actionBtn.push(btn)
            }
            if (auth.user.id === incidents.incident.createdBy || Permissions.manageIncident) {
                if (incidents.incident.assignee) {
                    let btn = <Grid item xs={6} sm={3} lg={2}  >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenAssign}
                            className={classes.btn}>
                            re-ssign
                        </Button>
                    </Grid>
                    actionBtn.push(btn)
                } else {
                    let btn = <Grid item xs={6} sm={3} lg={2}  >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenAssign}
                            className={classes.btn}>
                            Assign
                        </Button>
                    </Grid>
                    actionBtn.push(btn)
                }

            }

        }
    }
    let image
    let imagesToRender;
    if (incidents.incident.images) {
        imagesToRender = incidents.incident.images
    }


    if (incidents.incident.images) {
        incidents.incident.images.map((_, index) => {
            let span
            if (index === 0) {
                image = <div className="mySlides fade" style={{ display: "block" }} onClick={() => setLightboxOpen(true)}><img className="img" src={incidents.incident.images[index]} /></div>
            } else {

                image = <div className="mySlides fade" onClick={() => setLightboxOpen(true)} ><img className="img" src={incidents.incident.images[index]} /></div>
            }
            span = <span className="dot" key={index} onClick={() => currentSlide(index)}></span>
            images.push(image)
            spans.push(span)
        })
    }

    // console.log("Name of the category is:", categoryName)
    return (
        <div className={classes.contain}>
            <Top props={props} name="Incident Details" />
            <CustomMessage reducer={incidents} cleaner={clearSnackBar} />
            <React.Fragment>
                {incidents.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Grid
                            container
                            item xs={12} sm={9}
                            spacing={1}
                            className={classes.btncontainer}>
                            {actionBtn}
                        </Grid>
                        <Card>
                            <CardContent className={classes.cardContent} >
                                <div>
                                    {incidents.incident ?
                                        <>
                                            <div className="slideshow-container">
                                                {isLightboxOpen && (
                                                    <Grid style={{ marginTop: '3em', zIndex: '3' }}>
                                                        < Lightbox
                                                            reactModalStyle={customStyles}
                                                            mainSrc={imagesToRender[photoIndex]}
                                                            nextSrc={imagesToRender[(photoIndex + 1) % imagesToRender.length]}
                                                            prevSrc={imagesToRender[(photoIndex + imagesToRender.length - 1) % imagesToRender.length]}
                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                            onMovePrevRequest={() => setPhotoIndex((photoIndex + imagesToRender.length - 1) % imagesToRender.length)}
                                                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imagesToRender.length)}
                                                        />
                                                    </Grid>

                                                )
                                                }

                                                {images}
                                                {incidents.incident.images ? incidents.incident.images.length > 0 ?
                                                    <>
                                                        <a className="prev" onClick={() => { plusSlides(-1) }} >&#10094;</a>
                                                        <a className="next" onClick={() => { plusSlides(1) }} >&#10095;</a>
                                                    </>
                                                    : null
                                                    : null
                                                }
                                            </div>
                                            <br />
                                            <div style={{ textAlign: "center" }}>
                                                {spans}
                                            </div>
                                            <Grid
                                                container
                                            >
                                                <Grid lg={8}>
                                                    <Formik
                                                        initialValues={incidents.formData}
                                                        validationSchema={validationSchema}
                                                        onSubmit={values => {
                                                            // console.log("The values we are submiting are:", { ...values, replacementId })
                                                            dispatch(updateIncident(props.match.params.id, { ...values, replacementId }));
                                                        }}

                                                    >
                                                        {({ errors, touched, values, setFieldValue }) => (
                                                            <Form  >
                                                                <Field
                                                                    className={classes.forminput}
                                                                    name='ticket'
                                                                    label='TicketId'
                                                                    margin='normal'
                                                                    variant="outlined"
                                                                    as={TextField}
                                                                    fullWidth
                                                                    disabled={true}
                                                                    autocomplete="false"
                                                                />
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="space-between"
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={5}
                                                                        style={{ marginTop: "16px" }}
                                                                    >

                                                                        <Field
                                                                            className={classes.forminput}
                                                                            name='project'
                                                                            label='Project'
                                                                            margin='normal'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={5}
                                                                        style={{ marginTop: "16px" }}
                                                                    >

                                                                        <Field
                                                                            className={classes.forminput}
                                                                            name='institution'
                                                                            label='Institution'
                                                                            margin='normal'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={5}>
                                                                        <Field
                                                                            className={classes.forminput}
                                                                            name='category'
                                                                            label='Incident Category'
                                                                            margin='normal'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />

                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={5}
                                                                        style={{ marginTop: "16px" }}>
                                                                        <Selects
                                                                            className={classes.forminput}
                                                                            name="damageId"
                                                                            label="Issue"
                                                                            options={damages.damages ? damages.damages : []}
                                                                            disabled={true}
                                                                        // disabled={auth.user ? auth.user.id === incidents.incident.createdBy ? !edit : true : true}
                                                                        />
                                                                    </Grid>
                                                                    {incidents.incident.requestAmount && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            style={{ marginTop: "16px" }}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='requestAmount'
                                                                                label='Request Amount (Rwf)'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={!edit || !canEditId ? true : false}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {incidents.incident.busTicketId && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            style={{ marginTop: "16px" }}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='busTicketId'
                                                                                label='Bus Ticket Id'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={!edit || !canEditId ? true : false}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {incidents.incident.momoTransactionId && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            style={{ marginTop: "16px" }}>

                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='momoTransactionId'
                                                                                label='Momo Transaction Id'
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                error={touched.momoTransactionId && errors.momoTransactionId}
                                                                                helperText={touched.momoTransactionId && errors.momoTransactionId}
                                                                                fullWidth
                                                                                disabled={!edit || !canEditId ? true : false}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {incidents.incident.customerName && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            style={{ marginTop: "16px" }}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='customerName'
                                                                                label='Customer Name'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                value={incidents.incident.customerName}
                                                                                // multiline
                                                                                // aria-label="minimum height"
                                                                                variant="outlined"
                                                                                disabled={true}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>

                                                                    )}
                                                                    {incidents.incident.customerPhone && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            style={{ marginTop: "16px" }}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='customerPhone'
                                                                                label='Customer Phone'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                value={incidents.incident.customerPhone}
                                                                                inputProps={{ maxLength: 10 }}
                                                                                // aria-label="minimum height"
                                                                                variant="outlined"
                                                                                disabled={true}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {
                                                                        incidents.incident.ccdUsers && incidents.incident.ccdUsers.length !== 0 && (
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                sm={5}
                                                                                style={{ marginTop: "35px" }}>
                                                                                <Autocomplete
                                                                                    id="free-solo-demo"
                                                                                    multiple
                                                                                    options={userOptions}
                                                                                    value={values.ccdUsers}
                                                                                    defaultValue={[...incidents.incident.ccdUsers]}
                                                                                    disabled={true}
                                                                                    renderInput={(params) => <TextField
                                                                                        variant='outlined'
                                                                                        {...params}
                                                                                        label="Created Alongside"
                                                                                    />
                                                                                    }
                                                                                />
                                                                            </Grid>

                                                                        )
                                                                    }
                                                                    {incidents.incident.doneBy && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                label='Done By'
                                                                                value={incidents.incident ? incidents.incident.doneBy : ""}
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>

                                                                    )}
                                                                    {values.bus && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='bus'
                                                                                label='Bus'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {values.route && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}>

                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='route'
                                                                                label='Route'
                                                                                margin='normal'
                                                                                variant="outlined"
                                                                                as={TextField}
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}

                                                                    {values.deviceImei && (
                                                                        <Grid item xs={12} sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                name='deviceImei'
                                                                                label='Device IMEI '
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {
                                                                        incidents.incident.device && (
                                                                            <Grid item xs={12} sm={5}>
                                                                                <Field
                                                                                    className={classes.forminput}
                                                                                    // name='device'
                                                                                    // value={"Name-Type"}
                                                                                    value={`${incidents.incident.device ? incidents.incident.device.itsDeviceType.name : ""}-${incidents.incident.device && incidents.incident.device.make ? incidents.incident.device.make.name : ""}`}
                                                                                    label='Device Type-Make'
                                                                                    margin='normal'
                                                                                    as={TextField}
                                                                                    aria-label="minimum height"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    disabled={true}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    }


                                                                    {incidents.incident.incidentAgent && (
                                                                        <Grid item xs={12} sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={incidents.incident ? incidents.incident.creator.name : "Unknown"}
                                                                                label='Incident Creator'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {incidents.incident.incidentAgent || incidents.incident.agentName && (
                                                                        <Grid item xs={12} sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={`${incidents.incident.agentName ? incidents.incident.agentName : incidents.incident.incidentAgent.name} @${incidents.incident.agentUsername ? incidents.incident.agentUsername : ""}`}
                                                                                label='Agent'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {incidents.incident.incidentAgent || incidents.incident.supervisorName && (
                                                                        <Grid item xs={12} sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={incidents.incident.supervisorName ? incidents.incident.supervisorName : incidents.incident.incidentAgent.supervisorUser ? incidents.incident.incidentAgent.supervisorUser.name : "No Supervisor"}
                                                                                label='Supervisor'
                                                                                margin='normal'
                                                                                variant="outlined"
                                                                                as={TextField}
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}

                                                                    {incidents.incident.incidentAgent || incidents.incident.superAgentName && (
                                                                        <Grid item xs={12} sm={5}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={incidents.incident.superAgentName ? incidents.incident.superAgentName : incidents.incident.incidentAgent.superAgentUser ? incidents.incident.incidentAgent.superAgentUser.name : "No Superagent"}
                                                                                label='Super Agent'
                                                                                margin='normal'
                                                                                as={TextField}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}

                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                        <Field
                                                                            className={classes.forminput}
                                                                            value={moment(incidents.incident.occuranceTime ? incidents.incident.occuranceTime : incidents.incident.createdAt).format('MMM D, YYYY h:mm a')}
                                                                            label='Time of Incident'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            rowsMax={8}
                                                                            rows={4}
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>

                                                                        <Field
                                                                            className={classes.forminput}
                                                                            value={incidents.incident.assignedUser ? incidents.incident.assignedUser.name : "Unassigned"}
                                                                            label='Assignee'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                        <Field
                                                                            className={classes.forminput}
                                                                            name='location'
                                                                            label='Location'
                                                                            variant="outlined"
                                                                            as={TextField}
                                                                            fullWidth
                                                                            disabled={auth.user ? auth.user.id === incidents.incident.createdBy ? !edit : true : true}
                                                                        />

                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                        <Selects
                                                                            className={classes.forminput}
                                                                            name='priorityId'
                                                                            label='Priority'
                                                                            options={priorities.priorities ? priorities.priorities : []}
                                                                            disabled={!edit}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>

                                                                        <Field
                                                                            className={classes.forminput}
                                                                            name='description'
                                                                            label='Description'
                                                                            as={TextField}
                                                                            aria-label="minimum height"
                                                                            variant="outlined"
                                                                            error={touched.description && errors.description}
                                                                            helperText={touched.description && errors.description}
                                                                            rowsMax={8}
                                                                            rows={4}
                                                                            fullWidth
                                                                            disabled={auth.user ? auth.user.id === incidents.incident.createdBy ? !edit : true : true}
                                                                        />
                                                                    </Grid>
                                                                    {removeSpace(incidents.incident.project ? incidents.incident.project.name : "") == 'upcountry' && (
                                                                        <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={incidents.incident.acceptedByInstitution ? "Yes" : "No"}
                                                                                label="Accepted by Insitution's Finance"
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {removeSpace(incidents.incident.project ? incidents.incident.project.name : "") == 'upcountry' && (

                                                                        <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>

                                                                            <Field
                                                                                className={classes.forminput}
                                                                                value={incidents.incident.acceptedByAc ? "Yes" : "No"}
                                                                                label="Accepted by AC Group's Finance"
                                                                                as={TextField}
                                                                                aria-label="minimum height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                disabled={true}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={actionOptions}
                                                                            sx={{ width: '100%' }}
                                                                            value={incidents.incident.actionRequired}
                                                                            disabled={!edit}
                                                                            onChange={(event, newValue) => {
                                                                                setFieldValue("actionRequired", newValue)
                                                                                setAction(newValue)
                                                                            }}

                                                                            renderInput={(params) => <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                label={`Action Required`}
                                                                            />}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5} style={{ marginTop: "16px" }}>
                                                                        {removeSpace(values.actionRequired ? values.actionRequired : "") == 'repair' && (
                                                                            <Autocomplete
                                                                                id="combo-box-demo"
                                                                                options={["Yes", "No"]}
                                                                                sx={{ width: '100%' }}
                                                                                value={values.repaired ? "Yes" : "No"}

                                                                                disabled={!edit}
                                                                                onChange={(event, newValue) => {
                                                                                    setFieldValue("repaired", removeSpace(newValue) == "yes" ? true : false)
                                                                                }}

                                                                                renderInput={(params) => <TextField
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    label="Repaired?"
                                                                                />}
                                                                            />
                                                                        )}
                                                                        {removeSpace(values.actionRequired ? values.actionRequired : "") == 'replacedevice' && (

                                                                            <div>
                                                                                {
                                                                                    incidents.incident.device ?
                                                                                        <Autocomplete
                                                                                            className={classes.forminput}
                                                                                            id="combo-box-demo"
                                                                                            options={deviceOptions}
                                                                                            sx={{ width: '100%' }}
                                                                                            value={deviceOptions.value}
                                                                                            onChange={(event, newValue) => {
                                                                                                setReplacementId(newValue.value)
                                                                                                setFieldValue("replaced", true)
                                                                                            }}
                                                                                            renderInput={(params) => <TextField
                                                                                                {...params}
                                                                                                variant="outlined"
                                                                                                label="Device for replacement"
                                                                                            />}
                                                                                        /> :
                                                                                        <Autocomplete
                                                                                            className={classes.forminput}
                                                                                            id="combo-box-demo"
                                                                                            sx={{ width: '100%' }}
                                                                                            disabled={true}
                                                                                            renderInput={(params) => <TextField
                                                                                                {...params}
                                                                                                variant="outlined"
                                                                                                label="This is not a device-related incident"
                                                                                            />}
                                                                                        />
                                                                                }
                                                                            </div>

                                                                        )}
                                                                        {removeSpace(values.actionRequired ? values.actionRequired : "") === 'refund' && (
                                                                            <Autocomplete
                                                                                id="combo-box-demo"
                                                                                options={["Yes", "No"]}
                                                                                sx={{ width: '100%' }}
                                                                                value={values.refunded ? "Yes" : "No"}
                                                                                disabled={!edit}
                                                                                onChange={(event, newValue) => {
                                                                                    setFieldValue("refunded", removeSpace(newValue) == "yes" ? true : false)

                                                                                }}

                                                                                renderInput={(params) => <TextField
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    label="Refunded?"
                                                                                />}
                                                                            />

                                                                        )}

                                                                    </Grid>
                                                                </Grid>
                                                                {edit ?
                                                                    <div className={classes.submitbtn} >
                                                                        <Button color="secondary" variant="contained" onClick={() => setEdit(false)} > Close</Button>
                                                                        <Button color="primary" variant="contained" type='submit'> Submit</Button>
                                                                    </div>
                                                                    : null}

                                                            </Form>

                                                        )}
                                                    </Formik>
                                                    <Grid
                                                        style={{
                                                            marginTop: '1em',
                                                            fontFamily: `'Poppins', sans-serif`
                                                        }}>
                                                        <Typography
                                                            className={classes.sectionTitle}
                                                            style={{
                                                                marginBottom: '1em'

                                                            }}
                                                        >Comments</Typography>
                                                        <CommentsBlock
                                                            comments={incidentComments}
                                                            signinUrl={'/'}
                                                            isLoggedIn
                                                            onSubmit={text => {
                                                                if (text.length > 0) {
                                                                    dispatch(createComment(text, incidents.incident.id))
                                                                }
                                                            }}
                                                            styles={{
                                                                btn: base => ({
                                                                    ...base,
                                                                    fontSize: '0.8em'
                                                                }),

                                                            }
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    style={{
                                                        padding: '0.5em 1em',
                                                    }}
                                                    lg={4}
                                                >
                                                    <Typography
                                                        className={classes.sectionTitle}
                                                    >Timeline</Typography>
                                                    <Divider className={classes.divider} />
                                                    <Chrono
                                                        items={timelineActivities}
                                                        mode="VERTICAL"
                                                        hideControls
                                                        useReadMore={false}
                                                        cardHeight={80}

                                                    />
                                                </Grid>
                                                <AddModel
                                                    isModelOpen={openAssign}
                                                    closeAddModel={handleCloseAssign}
                                                    handleCreate={handleAssignUser}
                                                >
                                                    <DialogTitle id="form-dialog-title">Assign this incident to a user</DialogTitle>
                                                    <DialogContent>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={userOptions}
                                                            sx={{ width: '100%' }}
                                                            value={userOptions.value}
                                                            onChange={(event, newValue) => {
                                                                setAssigneeUser(newValue.value)
                                                            }}

                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                label="Assignee"
                                                            />}
                                                        />

                                                    </DialogContent>
                                                </AddModel>
                                            </Grid>

                                            {/* <AdditionalInfo
                                                creator={incidents.incident.createdByUser ? incidents.incident.createdByUser.name : "Unknown"}
                                                updator={incidents.incident.updatedByUser ? incidents.incident.updatedByUser.name : "Unknown"}
                                                creationDate={incidents.incident.createdAt}
                                                updateDate={incidents.incident.updatedAt}

                                            /> */}


                                        </>
                                        :
                                        <div> Not incident vailable</div>
                                    }

                                </div>
                            </CardContent>
                        </Card>

                    </>

                }
            </React.Fragment>
        </div>
    )
}

export default Incident
