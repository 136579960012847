import Cookie from "universal-cookie";
import http from "../../utils/axios/axios";
import formDatahttp from "../../utils/axios/formDataAxios";
import { types } from "../actions/types";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const PENDING_USERS = "PENDING_USERS";
export const USERS_ERROR = "USERS_ERROR";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const ONE_USER_SUCCESS = "ONE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const UPLOAD_USERS = "UPLOAD_USERS";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";
export const RESET_PSWD_LINK = "RESET_PSWD_LINK";
export const USER_PSWD_RESET = "USER_PSWD_RESET";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
const cookies = new Cookie();

const auth = cookies.get("x-auth-token");

// Function that gets all users
export const getUsersAction = () => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .get("/api/users/", {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: res.data.data.sort((a, b) =>
          a.id > b.id ? 1 : b.id > a.id ? -1 : 0
        ),
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

// Function that gets a single user
export const getOneUserAction = (id) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .get(`/api/users/${id}`, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: ONE_USER_SUCCESS,
        payload: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};

// Function that handles deleting a user
export const deleteUserAction = (id) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .delete(`/api/users/${id}`, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: res.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};
export const getUserDepartments = () => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .get(`/api/userdepartments`)
    .then((res) => {
      dispatch({
        type: GET_DEPARTMENTS,
        departments: res.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

// Function that is used to add a new user
export const postUsersAction = (user) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .post("/api/users/create", user, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: POST_USER_SUCCESS,
        payload: res.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

//Function that is used to get roles while adding  a new user because we will need to assign a role to a user
export const getUserRoles = () => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .get("/api/roles/", {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USER_ROLES,
        payload: res.data.roles,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

export const getAllProjects = () => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .get("/api/projects/", {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ALL_PROJECTS,
        payload: res.data.projects,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

export const updateUserAction = (id, user) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .patch(`/api/users/${id}`, user, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USERS_SUCCESS,
        payload: res.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

export const resetPasswordAction = (id, pass) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .patch(`/api/users/reset/${id}`, pass, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data.message,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const uploadUsers = (users) => (dispatch) => {
  console.log("Users  before upload are:", users);

  dispatch({
    type: PENDING_USERS,
  });
  users.forEach((data) => {
    delete data.id;
  });
  users.forEach(async (oneUser) => {
    // console.log("The one user is:", oneUser)
    oneUser.roleId = oneUser.roleid;
    oneUser.phoneNumber = oneUser.phonenumber;
    await delete oneUser.roleid;
    await delete oneUser.phonenumber;
  });

  return http
    .post(
      `/api/users/upload`,
      { users },
      {
        headers: {
          "x-auth-token": auth,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: UPLOAD_USERS,
        payload: res.data,
        message: res.data.msg,
      });
    })
    .catch((err) => {
      console.log("Errror from uploading users is:", err);

      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

export const clearSnackBar = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SNACKBAR,
  });
};

export const requestResetLink = (uid) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .post(
      `/api/users/password/reset`,
      { uid },
      {
        headers: {
          "x-auth-token": auth,
        },
      }
    )
    .then((res) => {
      console.log("The link is received", res);
      dispatch({
        type: RESET_PSWD_LINK,
        message: res.data.message,
      });
    })
    .catch((err) => {
      console.log("There was an error and the error is:", err);
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};
export const resetUserPassword = (uid, data) => (dispatch) => {
  dispatch({
    type: PENDING_USERS,
  });
  return http
    .patch(`/api/users/user/reset/${uid}`, data)
    .then((res) => {
      dispatch({
        type: USER_PSWD_RESET,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};
