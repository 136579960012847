import React from 'react';
import { Typography } from '@material-ui/core'
import './landingstyle.scss';
import color from '../colors'

function Footer() {
    return (
        <div className='footer-landing'>
            <Typography className="footer">&copy; Incident Management System by AC Group</Typography>
        </div>
    )
}

export default Footer;