import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Chip,
  Autocomplete,
  Slide,
  Snackbar,
} from "@mui/material/";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";

import colors from "../colors";
import Header from "../templates/TopPartOfPageWithId";
import AddStock from "../templates/AddModel";
import {
  addStock,
  getAllRequest,
  reportDamage,
  recordRepaired,
  clearSnackBar,
  getStockItem,
  getStockReportsByItem,
  getInventoryRequests,
} from "../../redux/actions/stock/stock";
import { checkAuthedUser } from "../../redux/actions/auth/login";
import { getDevices } from "../../redux/actions/devices/getDevices";
import { updateDevice } from "../../redux/actions/devices/updateDevice";

import DisplayAllStock from "../templates/DisplayAll";
import RecordRepair from "../templates/AddModel";
import ReportDamage from "../templates/AddModel";
import DescribeItem from "../templates/DescribeItem";
import StockActivities from "../templates/DisplayAll";

const ManageStockScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const stock = useSelector((state) => state.stock);
  // const users = useSelector((state) => state.stock);

  const loginState = useSelector((state) => state.login);
  const devices = useSelector((state) => state.devices);

  const userProfile = loginState.loggedInUser;
  const filterData = {
    applyFilters: false,
    userId: 1,
    startDate: "2023-1-10",
    endDate: "2023-12-31",
  };
  useEffect(() => {
    // dispatch(getAllRequest())
    dispatch(getInventoryRequests(props.match.params.id));
    dispatch(checkAuthedUser());
    dispatch(getStockItem(props.match.params.id));
    dispatch(getDevices());
  }, [stock.refresh]);

  useEffect(() => {
    dispatch(getStockReportsByItem(props.match.params.id, filterData));
  }, []);

  const [addStockOpen, setAddStockOpen] = useState(false);
  const [recordRepairOpen, setRecordRepairOpen] = useState(false);
  const [reportDamageOpen, setRecordDamageOpen] = useState(false);
  const [reportedFrom, setReportedFrom] = useState();

  const [quantity, setQuantity] = useState();
  const [repairedQuantity, setRepairedQuantity] = useState();
  const [damageQuantity, setDamagedQuantity] = useState();
  const [selectedDevices, setSelectedDevices] = useState();
  const [selectedRepairedDevices, setSelectdRepairedDevices] = useState();

  // console.log("The request we have here and there is:", stock);

  const closeAddStock = () => setAddStockOpen(false);
  const openAddStock = () => {
    // console.log("The stock is", stock);
    if (stock.stockItem.category === "device") {
      // console.log("here and there:", stock.stockItem.category);
      history.push(`/dashboard/devices`);
    } else {
      setAddStockOpen(true);
    }
  };

  const openRecordRepair = () => setRecordRepairOpen(true);
  const closeRecordRepair = () => setRecordRepairOpen(false);

  const openRecordDamage = () => setRecordDamageOpen(true);
  const closeRecordDamage = () => setRecordDamageOpen(false);
  const [data, setData] = React.useState("Not Found");

  const handleAddItemsToStock = () => {
    const data = {
      id: props.match.params.id,
      qtyToAdd: quantity,
    };
    dispatch(addStock(data));
    closeAddStock();
  };

  const handleQuantityChange = (e) => setQuantity(e.target.value);
  const handleQuantityRepairedChange = (e) =>
    setRepairedQuantity(e.target.value);
  const handleQuantityDamagedChange = (e) => setDamagedQuantity(e.target.value);

  const handleRecordRepair = () => {
    const data = {
      qtyRepaired:
        stock.stockItem.category === "device"
          ? selectedRepairedDevices.length
          : repairedQuantity,
    };
    dispatch(recordRepaired(props.match.params.id, data));
    selectedRepairedDevices.map((device) => {
      console.log("the device from record repaired is:", device);
      dispatch(
        updateDevice(device.id, {
          statusId: 1,
        })
      );
    });
    closeRecordRepair();
  };
  const handleReportDamage = () => {
    const data = {
      qtyDamaged:
        stock.stockItem.category === "device"
          ? selectedDevices.length
          : damageQuantity,
      reportedFrom,
    };
    dispatch(reportDamage(props.match.params.id, data));

    selectedDevices.map((device) => {
      console.log("the device from record damaage is:", device);
      dispatch(
        updateDevice(device.id, {
          statusId: 3,
          busCompanyId: null,
          busId: null,
          airtelSim: null,
          fourGSim: null,
          mtnSim: null,
        })
      );
    });
    closeRecordDamage();
  };
  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  // console.log("The devices here are:", devices.devices);

  const deployedDevices = devices.devices.filter(
    (device) => device.statusId === 2
  );
  const inStockDevices = devices.devices.filter(
    (device) => device.statusId === 1
  );
  const damagedDevices = devices.devices.filter(
    (device) => device.statusId === 3
  );
  const inStockDeviceOptions = inStockDevices
    ? inStockDevices.map((device) => {
        return {
          title: `${device.itsDeviceType.name} - ${device.deviceImei}`,
          id: device.id,
        };
      })
    : [];

  const deployedDeviceOptions = deployedDevices
    ? deployedDevices.map((device) => {
        return {
          title: `${device.itsDeviceType.name} - ${device.deviceImei}`,
          id: device.id,
        };
      })
    : [];
  const damagedDeviceOptions = damagedDevices
    ? damagedDevices.map((device) => {
        return {
          title: `${device.itsDeviceType.name} - ${device.deviceImei}`,
          id: device.id,
        };
      })
    : [];

  const reportColumns = [
    {
      field: "newId",
      title: "ID",
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        width: "5%",
      },
      headerStyle: {
        backgroundColor: "#039be5",
      },
    },
    {
      field: "date",
      title: "Date",
    },
    {
      field: "username",
      title: "User",
      render: (rowData) => (
        <div>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => history.push(`/dashboard/users/${rowData.userId}`)}
          >
            {rowData.username}
          </p>
        </div>
      ),
    },
    {
      field: "itemname",
      title: "Item",
    },
    {
      field: "stockIn",
      title: "Stock In",
    },
    {
      field: "stockOut",
      title: "Stock Out",
    },

    {
      field: "stockBalance",
      title: "Stock Balance",
    },
    {
      field: "totalInvRequests",
      title: "No. Requests Made",
    },
    {
      field: "activity",
      title: "Activity",
    },
  ];

  const columns = [
    {
      field: "newId",
      title: "ID",
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        width: "5%",
      },
      headerStyle: {
        backgroundColor: "#039be5",
      },
    },
    {
      field: "itemName",
      title: "Item",
    },
    {
      field: "requestedByUser",
      title: "Requested By",
    },
    {
      field: "totalPaid",
      title: "Total Paid",
    },
    {
      field: "amountDue",
      title: "Amount Due",
    },
    {
      field: "qtyRequested",
      title: "Quantity Requested",
    },
    {
      field: "description",
      title: "Description",
    },
    {
      field: "creationDate",
      title: "Requested At",
    },
    {
      field: "neededDate",
      title: "Items Needed By",
    },
    {
      field: "reqStatus",
      title: "Status",
      render: (rowData) => (
        <Chip
          label={rowData.reqStatus ? rowData.reqStatus.toUpperCase() : ""}
          style={{
            backgroundColor:
              rowData.reqStatus === "accepted"
                ? "green"
                : rowData.reqStatus === "pending"
                ? "yellow"
                : rowData.reqStatus === "cancelled"
                ? "#EC7063"
                : rowData.reqStatus === "resolved"
                ? "#58D68D"
                : rowData.reqStatus === "rejected"
                ? "#78281F"
                : "#ECF0F1",
            color:
              rowData.reqStatus === "accepted" ||
              rowData.reqStatus === "rejected"
                ? "white"
                : "black",
          }}
        />
      ),
    },
    {
      field: "action",
      title: "Actions",
      headerStyle: {
        textAlign: "right",
      },
      render: (rowData) => (
        <Grid
          container
          direction="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Grid item>
            <Button
              onClick={() =>
                props.history.push(`/stock/requests/${rowData.id}`)
              }
            >
              <VisibilityIcon color="primary" />
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];
  let rows = [];
  let reportRows = [];
  stock && stock.itemStockReports
    ? (reportRows = stock.itemStockReports)
    : (reportRows = []);
  stock && stock.inventoryRequests
    ? (rows = stock.inventoryRequests)
    : (rows = []);

  rows.map((row) => {
    row.newId = row.id;
    row.itemName = row.item.name;
    row.requestedByUser = row.requester ? row.requester.name : "N/A";
    row.reqStatus = row.status ? row.status.title : "No Status";
    row.amountDue = row.ledger ? row.ledger.amountDue : "No ledger";
    row.totalPaid = row.ledger ? row.ledger.paidAmount : "No ledger";
    row.creationDate = row.createdAt
      ? `${row.createdAt.split("T")[0]} at ${
          row.createdAt.split("T")[1].split(".")[0]
        } `
      : "No date";
    row.neededDate = row.itemsNeededBy
      ? `${row.itemsNeededBy.split("T")[0]} at ${
          row.itemsNeededBy.split("T")[1].split(".")[0]
        } `
      : "No date";
  });
  reportRows.map((row) => {
    // console.log("The individual row is:", row)
    row.newId = row.id;
    row.username = row.user ? row.user.name : "";
    row.userId = row.user ? row.user.id : 0;
    row.itemname = row.item ? row.item.name : "";
  });

  return (
    <div style={{ width: "90vw" }}>
      <Header name="Manage Stock" props={props} />
      <Snackbar
        open={stock.snackBarMessage.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={closeSnackBarTimer}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
      >
        <MuiAlert
          severity={stock.snackBarMessage.severity}
          variant="filled"
          elevation={6}
        >
          {stock.snackBarMessage.message}
        </MuiAlert>
      </Snackbar>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: colors.primary100,
          marginRight: 2,
        }}
        onClick={openAddStock}
      >
        Add Stock
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: colors.primary100,
          marginRight: 2,
        }}
        onClick={openRecordDamage}
      >
        Report Damages
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: colors.primary100,
          marginRight: 2,
        }}
        onClick={openRecordRepair}
      >
        Record Repairs
      </Button>
      <Grid>
        <DescribeItem title="Item Name" description={stock.stockItem.name} />
        <DescribeItem
          title="Description"
          description={stock.stockItem.description}
        />
        {/* ADd total accumulated price if the item is not a devie */}
        {stock.stockItem.category !== "device" && (
          <DescribeItem
            title="Price/Unit"
            description={`Rwf ${stock.stockItem.pricePerUnit}`}
          />
        )}
        <DescribeItem
          title="Image"
          description={
            <a href={stock.stockItem.image} target="_blank">
              View image
            </a>
          }
        />
        <DescribeItem
          title="Quantity in stock"
          description={
            stock.stockItem.quantity
              ? stock.stockItem.quantity.qtyInStock
              : "N/A"
          }
        />
        <DescribeItem
          title="Quantity deployed"
          description={
            stock.stockItem.quantity
              ? stock.stockItem.quantity.qtyDeployed
              : "N/A"
          }
        />
        <DescribeItem
          title="Quantity damaged"
          description={
            stock.stockItem.quantity
              ? stock.stockItem.quantity.qtyDamaged
              : "N/A"
          }
        />
      </Grid>

      <Typography
        variant={"h5"}
        style={{
          color: colors.primary100,
          fontSize: "1.25rem",
          fontWeight: "bolder",
          marginBottom: "-3rem",
          marginTop: "2rem",
        }}
      >
        Inventory Requests
      </Typography>
      <DisplayAllStock
        columns={columns}
        rows={rows}
        usemTable
        showSearch={false}
        showAddBtn={false}
        showImport={false}
        cTitle=""
      />
      <Typography
        variant={"h5"}
        style={{
          color: colors.primary100,
          fontSize: "1.25rem",
          fontWeight: "bolder",
          marginBottom: "-3rem",
          marginTop: "2rem",
        }}
      >
        Stock Valuation
      </Typography>
      <StockActivities
        columns={reportColumns}
        rows={reportRows}
        usemTable
        showSearch={false}
        showAddBtn={false}
        showImport={false}
        cTitle=""
      />
      <AddStock
        isModelOpen={addStockOpen}
        closeAddModel={closeAddStock}
        handleCreate={handleAddItemsToStock}
      >
        <DialogTitle id="form-dialog-title">Add Stock</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Quantity"
            onChange={handleQuantityChange}
            fullWidth
          />
        </DialogContent>
      </AddStock>

      <RecordRepair
        isModelOpen={recordRepairOpen}
        closeAddModel={closeRecordRepair}
        handleCreate={handleRecordRepair}
      >
        <DialogTitle id="form-dialog-title">Record Repaired</DialogTitle>
        <DialogContent>
          {stock.stockItem.category !== "device" && (
            <TextField
              sx={{ mb: 2 }}
              variant="standard"
              label="Quantity Repaired"
              onChange={handleQuantityRepairedChange}
              fullWidth
            />
          )}
          {stock.stockItem.category === "device" && (
            <Autocomplete
              multiple
              sx={{ width: "100%", mb: 2 }}
              id="checkboxes-tags-demo"
              options={damagedDeviceOptions}
              disableCloseOnSelect
              value={selectedRepairedDevices}
              onChange={(event, newValue) => {
                setSelectdRepairedDevices(newValue);
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => {
                return <li {...props}>{option.title}</li>;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Device"
                  variant="standard"
                  placeholder="Choose repaired devices"
                />
              )}
            />
          )}
        </DialogContent>
      </RecordRepair>

      <ReportDamage
        isModelOpen={reportDamageOpen}
        closeAddModel={closeRecordDamage}
        handleCreate={handleReportDamage}
      >
        <DialogTitle id="form-dialog-title">Report Damaged</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="combo-box-demo"
            sx={{ width: "100%", mb: 2 }}
            options={["Stock", "Deployed"]}
            value={reportedFrom}
            onChange={(event, newValue) => {
              setReportedFrom(newValue ? newValue.toLowerCase() : null);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Recorded from" />
            )}
          />
          {stock.stockItem.category !== "device" && (
            <TextField
              sx={{ mb: 2 }}
              variant="standard"
              label="Quantity Damaged"
              onChange={handleQuantityDamagedChange}
              fullWidth
            />
          )}
          {stock.stockItem.category === "device" && (
            <Autocomplete
              multiple
              sx={{ width: "100%", mb: 2 }}
              id="checkboxes-tags-demo"
              options={
                reportedFrom === "stock"
                  ? inStockDeviceOptions
                  : reportedFrom === "deployed"
                  ? deployedDeviceOptions
                  : []
              }
              disableCloseOnSelect
              value={selectedDevices}
              onChange={(event, newValue) => {
                setSelectedDevices(newValue);
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => {
                // console.log(selected)
                return <li {...props}>{option.title}</li>;
              }}
              disabled={!reportedFrom}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Device"
                  variant="standard"
                  placeholder="Choose devices"
                />
              )}
            />
            // <TextField
            //   sx={{ mb: 2 }}
            //   variant="standard"
            //   label=""
            //   onChange={handleQuantityDamagedChange}
            //   fullWidth
            // />
          )}
        </DialogContent>
      </ReportDamage>
    </div>
  );
};

export default ManageStockScreen;
