import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    makeStyles,
    TextField,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem,
    Grid,
    Button,
    InputLabel
} from "@material-ui/core";
import DisplayAll from "../templates/DisplayAll"
import { useDispatch, useSelector } from "react-redux";
import {
    getInstitutions,
    createInstitution,
    deleteInstitution,
    updateInstitution,
    uploadInstitution,
    clearSnackBar
} from "../../redux/actions/institutions/institutionsActions"
import { getProjects } from "../../redux/actions/projects/projectsAction";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModel from '../templates/DeleteModel';
import EditModel from '../templates/EditModel';

const useStyles = makeStyles((theme) => ({
    selectlabel: {
        marginTop: theme.spacing(3)
    }
}))

const Institutions = (props) => {
    let history = useHistory()
    const institutions = useSelector(state => state.institutions)
    const projects = useSelector(state => state.projects)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjects())
        dispatch(getInstitutions())

    }, [institutions.institution, institutions.deleted, institutions.upload])

    const classes = useStyles()
    const [projectId, setProjectId] = useState(null);
    const [institutionName, setInstitutionName] = useState(null);
    const [institutionId, setInstitutionId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [institutionDescription, setInstitutionDescription] = useState(null);

    const populateSelect = () => {
        const values = []
        projects.projects.forEach((project, index) => {
            let value = <MenuItem value={project.id} key={index}>{project.name}</MenuItem>
            values.push(value)
        });
        return values
    }

    const selectValues = projects.projects ? populateSelect() : null

    const handleChange = (e) => {
        setProjectId(e.target.value);
    }
    const handleName = (e) => {
        setInstitutionName(e.target.value)
    }
    const handleDescription = (e) => {
        setInstitutionDescription(e.target.value)
    }
    const handleCreateProject = () => {
        dispatch(createInstitution(institutionName, institutionDescription, projectId))
        setProjectId(null)
        setInstitutionName(null)
        setInstitutionDescription(null)
    }

    const handleOpenEditModel = (e) => {
        const values = e.currentTarget.id.split(",")
        setInstitutionId(values[0])
        setInstitutionName(values[1])
        setInstitutionDescription(values[2])
        setProjectId(values[3]);
        setOpenEdit(true)
    }

    const handleOpenDeleteModel = (e) => {
        const values = e.currentTarget.id.split(",")
        setInstitutionName(values[1])
        setInstitutionId(values[0])
        setOpenDelete(true)
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleCloseEditModel = () => setOpenEdit(false)

    const handleDelete = () => {
        dispatch(deleteInstitution(institutionId))
        setInstitutionId(null)
        setInstitutionName(null)
        setOpenDelete(false)
    }

    const handleEditProject = () => {
        dispatch(updateInstitution(institutionId, institutionName, institutionDescription, projectId))
        setInstitutionId(null)
        setInstitutionName(null)
        setInstitutionDescription(null)
        setProjectId(null)
        setOpenEdit(false)
    }
    // const columns = [
    //     {
    //         field: 'newId',
    //         headerName: 'ID',
    //         flex: 1,
    //         headerClassName: 'super-app-theme--header',
    //     },
    //     {
    //         field: 'name',
    //         headerName: 'Name',
    //         flex: 1,
    //         headerClassName: 'super-app-theme--header'
    //     },
    //     {
    //         field: 'description',
    //         headerName: 'Description',
    //         flex: 1,
    //         headerClassName: 'super-app-theme--header'
    //     },
    //     {
    //         field: 'theProject',
    //         headerName: 'Project',
    //         flex: 1,
    //         headerClassName: 'super-app-theme--header'
    //     },
    //     {
    //         field: 'action',
    //         headerName: 'Action',
    //         width: 320,
    //         headerClassName: 'super-app-theme--header',
    //         renderCell: (params) => (
    //             <Grid
    //                 container
    //                 direction="row"
    //             >
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={() => history.push(`/dashboard/institution/${params.id}`)}
    //                     >
    //                         <VisibilityIcon color="primary" />
    //                     </Button>
    //                 </Grid>
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={handleOpenEditModel}
    //                         id={`${params.id},${params.row.name},${params.row.description},${params.row.projects.id}`}
    //                     >
    //                         <EditIcon />
    //                     </Button>
    //                 </Grid>
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={handleOpenDeleteModel}
    //                         id={`${params.id},${params.row.name}`} >
    //                         <DeleteIcon color="secondary" />
    //                     </Button>
    //                 </Grid>
    //             </Grid >
    //         )
    //     },
    // ];
    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'name',
            title: 'Name',
        },
        {
            field: 'description',
            title: 'Description',
        },
        {
            field: 'theProject',
            title: 'Project',
        },
        {
            field: 'action',
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={() => history.push(`/dashboard/institution/${rowData.id}`)}
                        >
                            <VisibilityIcon
                                color="primary"
                                fontSize='small'
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={handleOpenEditModel}
                            id={`${rowData.id},${rowData.name},${rowData.description},${rowData.projects.id}`}
                        >
                            <EditIcon
                                fontSize='small'
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={handleOpenDeleteModel}
                            id={`${rowData.id},${rowData.name}`}
                        >
                            <DeleteIcon
                                fontSize='small'
                                color="secondary"
                            />
                        </Button>
                    </Grid>
                </Grid >
            )
        },
    ];
    let rows
    institutions.institutions ? (rows = institutions.institutions) : rows = []
    rows.forEach((row, index) => {
        // console.log("The rows is:", row)
        row.newId = index + 1
        row.theProject = row.projects.name
    })

    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteInstitution(id)))
    }

    return (
        <>
            <DisplayAll
                cTitle={'Available Bus Companies'}
                addBtnTitle={'Add New Bus company'}
                columns={columns}
                rows={rows}
                uploader={uploadInstitution}
                reducer={institutions}
                cleaner={clearSnackBar}
                pending={institutions.pending}
                handleCreate={handleCreateProject}
                multipleDeleteHandler={multipleDeleteHandler}
                usemTable
            >
                <DialogTitle id="form-dialog-title">Create New Bus Company</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Bus Company Name"
                        type="text"
                        onChange={handleName}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Bus Company Description"
                        type="text"
                        onChange={handleDescription}
                        fullWidth
                    />
                    <InputLabel htmlFor="demo-simple-select-outlined" className={classes.selectlabel}>Project</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChange}
                        label="Project"
                        fullWidth
                    >
                        {selectValues}
                    </Select>
                </DialogContent>
            </DisplayAll>
            <DeleteModel
                title={'Delete Bus Company'}
                description={`Are you sure you want to delete this Instituton?`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
            />
            <EditModel
                title={'Edit Bus Company'}
                closeEditDialog={handleCloseEditModel}
                isEditModelOpen={openEdit}
                classes={classes}
                handleEdit={handleEditProject}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        defaultValue={institutionName}
                        id="name"
                        label="Bus Company Name"
                        type="text"
                        onChange={handleName}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        defaultValue={institutionDescription}
                        label="Bus Company Description"
                        type="text"
                        onChange={handleDescription}
                        fullWidth
                    />
                    <InputLabel htmlFor="demo-simple-select-outlined" className={classes.selectlabel}>Project</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChange}
                        value={projectId}
                        label="Project"
                        fullWidth
                    >
                        {selectValues}
                    </Select>
                </DialogContent>
            </EditModel>
        </>
    )

}



export default Institutions