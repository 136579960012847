import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
const cookies = new Cookie()

const token = cookies.get('x-auth-token')
export const createComment = (comment, incidentId) => dispatch => {

    dispatch({
        type: types.COMMENT_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/comments`,
        {
            comment, incidentId
        },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.CREATE_COMMENT,
                comment: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.COMMENTS_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"

            })
        })
}

export const createRequestComment = (comment, requestId) => dispatch => {
    dispatch({
        type: types.COMMENT_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/request/comments`,
        {
            comment, requestId
        })
        .then(res => {
            dispatch({
                type: types.CREATE_COMMENT,
                comment: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.COMMENTS_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"

            })
        })
}


export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}

