import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid
} from '@material-ui/core';
import { Place } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
//import colors from './colors';
//import Ratings from './RatingStars';
import { resolveIncident } from '../../../redux/actions/incidents/incidentActions'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    height: 360,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  media: {
    height: 140
  },
  checkbox: {
    display: 'block',
    background: "white 1px",
    position: 'absolute',
    color: 'secondary',
    right: 0,
    fontSize: '25px'
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  reviews: {
    fontSize: '14px',
    // color: colors.primary100,
  },
  cardContent: {
    overflow: 'hidden'
  },
  titleText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    }
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10)
  },
  label: {
    color: `black`,
    fontWeight: "bold",
    display: "inline",
    marginRight: theme.spacing(0.5)
  },
}));

function IncidentCard({ props, incident }) {
  const classes = useStyles();
  let [index, setIndex] = useState(0);
  const dispatch = useDispatch();



  const handleViewMore = (e) => {
    props.history.push(`/dashboard/incident/${e.currentTarget.id}`);
  }
  const handleResolve = (e) => {
    dispatch(resolveIncident(e.currentTarget.id))
    // console.log("Resolving.....", e.currentTarget.id)
  }

  // console.log("The incident is:", incident.status)
  return (
    <Card className={classes.root} >

      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={incident.images.length !== 0 ? incident.images[index] : "http://www.4me.com/wp-content/uploads/2018/01/4me-icon-alert.png"}
          // src={"https://icon-library.com/images/incident-icon/incident-icon-28.jpg"}
          // image={"https://icon-library.com/images/incident-icon/incident-icon-28.jpg"}

          title={incident.name}
        />
        <CardContent className={classes.cardContent}  >
          <Grid direction="column" container justify="left" alignItems="left" style={{ display: 'inline', marginTop: '15px' }} >
            <div style={{ display: 'block' }} >
              <Typography variant='subtitle1' className={classes.label} >Institution:</Typography>
              <Typography variant='subtitle2' style={{ display: "inline" }}  >{incident.institution.name}</Typography>
            </div>
            <div style={{ display: 'block', backgroundColor: `${incident.status.color}` }}  >
              <Typography variant='subtitle1' className={classes.label}>Status:</Typography>
              <Typography variant='subtitle2' style={{ display: "inline" }} >{incident.status.name}</Typography>
            </div>
            <div style={{ display: 'block' }} >
              <Typography variant='subtitle1' className={classes.label}>Priority:</Typography>
              <Typography variant='subtitle2' style={{ display: "inline" }}  >{incident.priority.name}</Typography>
            </div>
            {incident.requestAmount && (
              <div style={{ display: 'block' }} >
                <Typography variant='subtitle1' className={classes.label}>Amount:</Typography>
                <Typography variant='subtitle2' style={{ display: "inline" }}  >{incident.requestAmount}</Typography>
              </div>)
            }

          </Grid>
        </CardContent>
      </CardActionArea>
      {
        (incident.status.name !== 'resolved' && incident.status.name !== 'rejected') && (
          <CardActions className={classes.cardActions}>
            <Button size="small" color="secondary" variant="contained" style={{ width: "100%" }} id={incident.id} onClick={handleResolve}> resolve </Button>
          </CardActions>
        )
      }

      <CardActions className={classes.cardActions}>
        <Button size="small" color="primary" variant="contained" style={{ width: "100%" }} id={incident.id} onClick={handleViewMore}> view more </Button>
      </CardActions>
    </Card>
  );
}

export default IncidentCard;
