import React from 'react'
import colors from '../colors'
import { Grid, Typography } from '@mui/material'

const DescribeItem = ({ title, description }) => {
    return (
        <Grid
            container
            sx={{ mt: 2 }}
            style={{
                backgroundColor: '#F4F6F7',
                borderRadius: 5,
                padding: '5px 12px'

            }}
        >
            <Grid
                item
                lg={3}
                sm={5}
                style={{
                    borderRight: `2.5px solid ${colors.primary100}`,
                }}
            >
                <Typography>
                    <b style={{ color: colors.primary100 }}>{title}</b>
                </Typography>
            </Grid>
            <Grid
                item
                style={{ paddingLeft: 20 }}
            >
                <Typography>
                    {description}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default DescribeItem