
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    TextField,
    Avatar,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    IconButton,
    FormHelperText,
    InputAdornment,
    Slide
} from '@material-ui/core';
import { useStyles } from './useStyles';
import MaterialTable from 'material-table';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import DisplayAll from '../templates/DisplayAll'

import {
    getOneUserAction,
    updateUserAction,
    getUserRoles,
    getUsersAction,
    resetPasswordAction,
    clearSnackBar,
    deleteUserAction
} from '../../redux/actions/usersAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DeleteModel from '../templates/DeleteModel';
import AdditionalInfo from '../templates/AdditionalInfo';
import { removeSpace } from '../../utils/removeSpaces';


const OneUser = (props) => {
    const userReducer = useSelector(state => state.users)

    const user = userReducer.one

    console.log("The user is:", user)

    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOneUserAction(id))
        dispatch(getUserRoles())
        dispatch(getUsersAction())
    }, [userReducer.updateUser]);

    useEffect(() => {
        // console.log(user.role ? user.role.name : "")
        setRoleName(user.role ? removeSpace(user.role.name) : "")
    }, [user, userReducer.updateUser])

    const [name, setName] = useState(user.name);
    const [username, setUsername] = useState(user.username);
    const [phone, setPhone] = useState(user.phoneNumber);
    const [role, setRole] = useState(user.roleId);
    const [openDelete, setDelete] = useState(false);
    const [openUpdate, setUpdate] = useState(false);
    const [resetPass, setReset] = useState(false);
    const [status, setStatus] = useState(user.status);
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageurl] = useState('')
    const [imageAlt, setImagealt] = useState('')
    const [adminName1, setAdminName1] = useState('')
    const [adminName2, setAdminName2] = useState('')
    const [passErrorMessage, setPassErrorMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [password, setPassword] = useState('');
    const [passwordTwo, setPasswordtwo] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConf, setShowPasswordConf] = useState(false)
    const [roleName, setRoleName] = useState("")
    const [supervisor, setSupervisor] = useState()
    const [superAgent, setSuperAgent] = useState()


    let location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const users = userReducer.users;

    const populateSelectOne = () => {
        const values = []
        userReducer.roles.forEach((role, index) => {
            let value = <MenuItem value={role.id} key={index}>{role.name}</MenuItem>
            values.push(value)
        });
        return values
    }
    const selectValuesOne = userReducer.roles ? populateSelectOne() : null

    useEffect(() => {
        if (users) {
            users.forEach(use => {
                if (use.id === user.createdBy) {
                    setAdminName1(use.name)
                }
                if (use.id === user.updatedBy) {
                    setAdminName2(use.name)
                }
            })
        }
    }, [users])

    const superAgents = users.filter(user => removeSpace(user.role.name) == 'superagent')
    const supervisors = users.filter(user => removeSpace(user.role.name) == 'supervisor')

    const supervisedUser = users.filter(user => user.supervisor == id)
    const superAgentedUser = users.filter(user => user.superAgent == id)

    // console.log("The supervised users are:", supervisedUser)
    // console.log("The superagent users are:", superAgentedUser)
    const columns = [
        {
            field: 'newId',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },

    ];
    let superAgentRows
    superAgentedUser ? (superAgentRows = superAgentedUser) : superAgentRows = []
    superAgentRows.forEach((row, index) => {
        // console.log("The rows is:", row)
        row.newId = index + 1
        // row.theProject = row.projects.name
    })
    let supervisorRows
    supervisedUser ? (supervisorRows = supervisedUser) : supervisorRows = []
    supervisorRows.forEach((row, index) => {
        // console.log("The rows is:", row)
        row.newId = index + 1
        // row.theProject = row.projects.name
    })


    const superAgentOptions = superAgents ? superAgents.map(superAgent => {
        return {
            label: superAgent.name,
            value: superAgent.id
        }
    }) : []
    const supervisorOptions = supervisors ? supervisors.map(supervisor => {
        return {
            label: supervisor.name,
            value: supervisor.id
        }
    }) : []


    // console.log("Super agents are:", superAgents, supervisors)

    const handleUpdate = async () => {
        const updateUser = {
            name,
            username,
            phoneNumber: phone,
            status,
            roleId: role,
            image: imageUrl,
            supervisor,
            superAgent

        }
        dispatch(updateUserAction(id, updateUser))
        handleCloseUpdate()
    }
    const handleImageUpload = (e) => {
        e.preventDefault();
        setLoading(true)
        const { files } = document.querySelector('input[type="file"]')
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('upload_preset', `${process.env.REACT_APP_UPLOAD_PRESET}`);
        const options = {
            method: 'POST',
            body: formData,
        };
        return fetch(`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}`, options)
            .then(res => res.json())
            .then(res => {

                console.log("The URL is", res.secure_url)

                setImageurl(res.secure_url)
                setImagealt(`An image of ${res.original_filename}`)
                setLoading(false)
            })
            .catch(err => console.log(err));
    }
    const handleDeleteDialog = (e) => {
        setDelete(true)
        setUpdate(false)

    }
    const handleDeleteDialogClose = () => {
        setDelete(false)
    }
    const handleDelete = async (e) => {
        dispatch(deleteUserAction(id))
        setDelete(false)
        history.push('/dashboard/users/')
    }
    const handleCloseUpdate = () => {
        setUpdate(false);
        setName(user.name);
        setUsername(user.username);
        setPhone(user.phoneNumber);
        setRole(user.roleId)
        setImageurl(user.image)
    };
    const handleUpdateDialog = (e) => {
        setUpdate(true)
        setImageurl(user.image)
        setName(user.name);
        setUsername(user.username);
        setPhone(user.phoneNumber);
        setStatus(user.status)
        setRole(user.roleId)
    }
    const resetPasswordHandle = (e) => {
        setReset(true)
    }
    const handleCloseReset = (e) => {
        setReset(false)
        setPassword('')
        setPasswordtwo('')
        setPassErrorMessage('')
        setErrorMessage('')
    }
    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const handleReset = async (e) => {
        const pass = { password: passwordTwo }
        if (password !== passwordTwo) {
            setPassErrorMessage("Password do not match!")
            setErrorMessage('')
        } else if (password === '') {
            setErrorMessage("Password can't be blank!")
            setPassErrorMessage('')
        } else {
            dispatch(resetPasswordAction(id, pass))
            handleCloseReset()
        }

    }
    let path = []
    const getPath = () => {
        const general = location.pathname.split("/");
        let url = null;
        for (let i = 1; i < general.length; i++) {
            if (i === general.length - 1) {
                url = <Typography variant="subtitle2" className={classes.header3} >{`${general[i][0].toUpperCase() + general[i].slice(1)}`}</Typography>
                path.push(url)
            } else {
                url = <Typography variant="subtitle2" className={classes.header2}>{`${general[i][0].toUpperCase() + general[i].slice(1)}>`}</Typography>
                path.push(url)
            }
        }
    }
    getPath()

    const handleClickShowPassword = () => {
        setShowPassword(true)
    };

    const handleCloseVisibility = () => {
        setShowPassword(false)
    }
    const handleClickShowPasswordConf = () => {
        setShowPasswordConf(true)
    };

    const handleCloseVisibilityConf = () => {
        setShowPasswordConf(false)
    }
    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }

    // console.log("THe user is:", user)
    return (
        <div className={classes.SingleUserContainer}>

            {/* Snackbar to display errors and success message */}
            <Snackbar
                open={userReducer.snackBarMessage.open}
                onClose={closeSnackBarTimer}
                autoHideDuration={4000}
                TransitionComponent={TransitionUp}
            >
                <MuiAlert
                    severity={userReducer.snackBarMessage.severity}
                    variant='filled'
                    elevation={6}
                >{userReducer.snackBarMessage.message}</MuiAlert>
            </Snackbar>
            {userReducer.pending && <div className={classes.spinner}><CircularProgress /></div>}
            {!userReducer.pending &&
                <div className={classes.singleUserContent}>
                    <Grid container spacing={0} justify="space-between">
                        <Grid item xs={10}>
                            <Typography variant="h5" className={classes.SingleUserHeader}>{user.name}'s details</Typography>
                        </Grid>
                        <div className={classes.path}>
                            {path}
                        </div>
                    </Grid>
                    <hr className={classes.line}></hr>
                    <div className={classes.SingleUserTopBar}>
                        <Button variant="contained" size='small' color="primary" onClick={handleUpdateDialog} className={classes.buttons}>Update</Button>
                        <Button variant="contained" size='small' color="warning" onClick={resetPasswordHandle} className={classes.buttons} style={{ color: 'black' }}>Reset password</Button>
                        <Button variant="contained" size='small' color="secondary" onClick={handleDeleteDialog} className={classes.buttons}>Delete</Button>
                    </div>
                    <div className={classes.singleUserContents}>
                        <div className={classes.avatar}>
                            <center><Avatar alt={user.name} src={openUpdate ? imageUrl : user.image} className={classes.sizeAvatar} /></center>
                            {openUpdate && <Grid container style={{ marginTop: "20px" }}>
                                <Grid item style={{ display: "flex", flexDirection: 'row', justifyContent: 'start' }}>
                                    <label htmlFor="file">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            component="span"
                                        >
                                            Browse an image
                                        </Button>
                                    </label>
                                    <input
                                        type="file"
                                        id="upload-image-file"
                                    ></input>
                                    <button
                                        onClick={handleImageUpload}
                                        variant="contained"
                                        color="primary"
                                        className={classes.uploadBtn}>Upload</button>
                                    <button onClick={(e) => {
                                        setLoading(false)
                                        setImageurl("")
                                    }}
                                        variant="contained"
                                        color="secondary"
                                        className={classes.cancelBtn}>
                                        Cancel
                                    </button>
                                </Grid>
                                <Grid item style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center' }}>
                                    <label>
                                        {loading && <CircularProgress color="primary" />}
                                    </label>
                                </Grid>
                            </Grid>}
                        </div>

                        {/* Display basic information about the user */}
                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '50% 49%', gridGap: '1%' }}>
                            <TextField variant="outlined" label="Name" value={openUpdate ? name : user.name} defaultValue={user.name} onChange={(e) => setName(e.target.value)} disabled={openUpdate ? false : true} />
                            <TextField variant="outlined" label="Email" value={user.email} defaultValue={user.email} disabled={true} />
                        </div>
                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '50% 49%', gridGap: '1%' }}>
                            <TextField variant="outlined" label="Username" value={openUpdate ? username : user.username} defaultValue={user.username} onChange={(e) => setUsername(e.target.value)} disabled={openUpdate ? false : true} />
                            <TextField variant="outlined" label="Phone Number" value={openUpdate ? phone : user.phoneNumber} defaultValue={user.phoneNumber} onChange={(e) => setPhone(e.target.value)} inputProps={{ maxLength: 10 }} disabled={openUpdate ? false : true} />

                        </div>
                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '50% 49%', gridGap: '1%' }}>
                            <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select
                                    value={openUpdate ? status : user.status}
                                    disabled={openUpdate ? false : true}
                                    onChange={handleChange}
                                    label="Status"
                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined">
                                <InputLabel>Role</InputLabel>
                                <Select value={openUpdate ? role : user.roleId} onChange={(e) => setRole(e.target.value)} label="Role" disabled={openUpdate ? false : true}>
                                    {selectValuesOne}
                                </Select>
                            </FormControl>
                        </div>

                        {roleName.toLowerCase() == 'agent' && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '50% 49%',
                                    gridGap: '1%'
                                }}
                            >
                                <Autocomplete
                                    className={classes.forminput}
                                    id="combo-box-demo"
                                    options={superAgentOptions}
                                    sx={{ width: '100%' }}
                                    // value={openUpdate ? superAgentOptions.value : user.superAgent}
                                    value={superAgentOptions.value}
                                    disabled={openUpdate ? false : true}
                                    onChange={(event, newValue) => {
                                        setSuperAgent(newValue.value)
                                        // console.log(newValue)
                                        // setFieldValue("damageId", newValue.value)
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        variant="outlined"
                                        label={`Super Agent - ${user.superAgentUser ? user.superAgentUser.name : "Unassigned"}`}
                                    />}
                                />
                                <Autocomplete
                                    className={classes.forminput}
                                    id="combo-box-demo"
                                    options={supervisorOptions}
                                    sx={{ width: '100%' }}
                                    value={supervisorOptions.value}
                                    disabled={openUpdate ? false : true}
                                    onChange={(event, newValue) => {
                                        setSupervisor(newValue.value)
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        variant="outlined"
                                        label={`Supservisor - ${user.supervisorUser ? user.supervisorUser.name : "Unassigned"}`}
                                    />}
                                />
                                {/* <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Super Agent</InputLabel>
                                    <Select
                                        value={openUpdate ? status : user.status}
                                        disabled={openUpdate ? false : true}
                                        onChange={handleChange}
                                        label="Status"
                                    >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>Inactive</MenuItem>
                                    </Select>
                                </FormControl> */}
                                {/* <FormControl variant="outlined">
                                    <InputLabel>Supervisor</InputLabel>
                                    <Select value={openUpdate ? role : user.roleId} onChange={(e) => setRole(e.target.value)} label="Role" disabled={openUpdate ? false : true}>
                                        {selectValuesOne}
                                    </Select>
                                </FormControl> */}
                            </div>
                        )
                        }

                    </div>

                    {/* Buttons to update and cancel */}
                    {openUpdate === true && <div className={classes.actionBtns}>
                        <Button color="primary" variant='contained' onClick={handleUpdate}>Save Changes</Button><Button variant="contained" color="secondary" onClick={handleCloseUpdate}>Cancel</Button>
                    </div>}

                    {/* Additional information table */}

                    {((roleName == "superagent") || (roleName == "supervisor")) && (
                        <DisplayAll
                            cTitle={'Agents'}
                            columns={columns}
                            rows={roleName == 'superagent' ? superAgentRows : supervisorRows}
                            showAddBtn={false}
                            showSearch={false}
                            showImport={false}

                        />
                    )
                    }

                    <AdditionalInfo
                        creator={adminName1 ? adminName1 : "Unkown"}
                        updator={adminName2 ? adminName2 : "Unkown"}
                        creationDate={user.createdAt}
                        updateDate={user.updatedAt}
                    />
                    {/* <div>
                    <Typography variant={'h5'} className={classes.subtitle}>Additional Information</Typography>
                    <MaterialTable
                     data={rows} 
                     columns={columns} 
                     options={{paging: false, search: false, toolbar: false}} 
                     style={{marginTop: "20px"}}
                     />
                </div> */}

                    {/* This is a dialog for deleting a user, it goes to the other reusable component */}
                    <DeleteModel
                        title="Delete user"
                        description="Are You sure you want to delete this user? Once deleted, there is no going back."
                        handleDelete={handleDelete}
                        closeDialog={handleDeleteDialogClose}
                        isDeleteModelOpen={openDelete}
                        btnText="Delete User"
                    />

                    {/* Dialog to reset password */}
                    <Dialog open={resetPass} onClose={handleCloseReset} >
                        <DialogTitle id="form-dialog-title" className={classes.title}>Reset User Password</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                value={user.email}
                                type="text"
                                label="Email"
                                disabled="true"
                                fullWidth
                            />
                            <FormControl className={classes.status}>
                                <InputLabel htmlFor="confirm-password">Password</InputLabel>
                                <Input
                                    margin="dense"
                                    id="confirm-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    required={true}
                                    autoComplete={false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility onClick={handleCloseVisibility} /> : <VisibilityOff onClick={handleClickShowPassword} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={errorMessage}
                                    helperText={errorMessage}
                                />
                                <FormHelperText id="component-helper-text" style={{ color: "red" }}>{errorMessage}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.status}>
                                <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                                <Input
                                    margin="dense"
                                    id="confirm-password"
                                    value={passwordTwo}
                                    onChange={(e) => setPasswordtwo(e.target.value)}
                                    type={showPasswordConf ? 'text' : 'password'}
                                    label="Comfirm Password"
                                    required={true}
                                    autoComplete={false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {showPasswordConf ? <Visibility onClick={handleCloseVisibilityConf} /> : <VisibilityOff onClick={handleClickShowPasswordConf} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={passErrorMessage}
                                    helperText={passErrorMessage}
                                />
                                <FormHelperText id="component-helper-text" style={{ color: "red" }}>{passErrorMessage}</FormHelperText>
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant='contained' onClick={handleReset}>
                                Reset
                            </Button>
                            <Button color="secondary" variant='contained' onClick={(e) => setReset(false)}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button color="primary" variant='contained' className={classes.btn} onClick={() => history.push("/dashboard/users")} style={{ marginTop: "20px" }}> View Users</Button>
                </div>}

        </div>
    )
}

export default OneUser;