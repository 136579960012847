import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Grid,
    Button,
    Typography,
    makeStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    InputLabel,
    MenuItem,
    CircularProgress,
    Slide,
    Snackbar,
} from '@material-ui/core';
import {
    Visibility,
    Edit,
    Delete
} from '@material-ui/icons/';
import { useHistory } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete';


import PageHeader from '../templates/TopPartOfPage'
import DeleteModel from '../templates/DeleteModel';

import {
    getRoutes,
    clearSnackBar,
    createRoute,
    deleteRoute,
    updateRoute,
    uploadRoutes
} from '../../redux/actions/routes/routes';
import {
    deleteSite,
    getSites,
    // clearSnackBar,
    // deleteSite,
    // createSite,
    // updateSite,
    // uploadSites
} from '../../redux/actions/sites/sites';
import DisplayAll from '../templates/DisplayAll'
import EditModel from '../templates/EditModel';
import color from "../colors";


const useStyles = makeStyles((theme) => ({
    element: {
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    progress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    header: {
        marginTop: theme.spacing(7),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
    },
    header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
    },
    selectlabel: {
        marginTop: theme.spacing(2)
    }
}))


const Routes = props => {
    const routeState = useSelector(state => state.routes)
    const sites = useSelector(state => state.sites)

    const [selectedSite, setSelectedSite] = useState()
    const [destinationSite, setDestinationSite] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const [routeToDelete, setRouteToDelete] = useState()
    const [routeToEdit, setRouteToEdit] = useState()
    const [editOrigin, setEditOrigin] = useState()
    const [editDestination, setEditDestination] = useState()
    const [openEdit, setOpenEdit] = useState()
    const [editingData, setEditingData] = useState([])

    const handleOpenDeleteModel = (e) => {
        setOpenDelete(true)
        const element = e.currentTarget.id.split(",")
        setRouteToDelete(element[0])
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)

    const handleDelete = (e) => {
        dispatch(deleteRoute(routeToDelete))
        setOpenDelete(false)
    }

    const handleEditRoute = () => {
        // console.log(routeToEdit, editOrigin, editDestination)
        dispatch(updateRoute(editingData.id, editOrigin, editDestination))
        // dispatch(updateSite(siteToEdit, name, editLocation, editProject))
        handleCloseEditModel()
    }

    const handleCloseEditModel = () => {
        setEditOrigin(null)
        setEditDestination(null)
        // setName(null)
        // setEditProject(null)
        // setEditLocation(null)
        setOpenEdit(false)
    }

    const sitesOptions = sites.sites ? sites.sites.map(site => {
        return {
            name: site.name,
            value: site.id
        }
    }) : []

    const fromSite = sites.sites ? sites.sites.filter(site => site.name === editOrigin) : []
    const toSite = sites.sites ? sites.sites.filter(site => site.name === editDestination) : []

    const originOptions = fromSite.map(site => {
        return {
            label: site.name,
            name: site.id
        }
    })
    const destinationOptions = toSite.map(site => {
        return {
            label: site.name,
            name: site.id
        }
    })

    const handleOpenEditModel = (data) => {
        setEditingData(data)
        // console.log("The site data we are editing is:", data)
        // const element = e.currentTarget.id.split(":")
        // const origin = element[1].split("-")[0]
        // const destination = element[1].split("-")[1]
        // setEditOrigin(origin)
        // setEditDestination(destination)
        // // console.log("The original origin is:", origin, destination)
        // setRouteToEdit(element[0])
        // // setSiteToEdit(element[0])
        // setName(element[1])

        // const filteredProjects = projects.filter(project => project.name == element[2])
        // const filteredLocations = locations.filter(location => location.name == element[3])

        // setEditProject(filteredProjects[0].id)
        // setEditLocation(filteredLocations[0].id)
        setOpenEdit(true)
    }


    let history = useHistory()
    const dispatch = useDispatch()
    const classes = useStyles();


    const handleCreateRoute = () => {

        dispatch(createRoute(selectedSite, destinationSite))
    }

    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "10%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'name',
            title: 'Name',
        },
        {
            field: 'action',
            title: 'Actions',
            headerStyle: {
                textAlign: 'right',
            },
            render: rowData => (

                <Grid
                    container
                    spacing={1}
                    direction="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => history.push(`/dashboard/routes/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => handleOpenEditModel(rowData)}
                            id={`${rowData.id}:${rowData.name}`}
                        >
                            <Edit />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            id={`${rowData.id},${rowData}`}
                        >
                            <Delete color="secondary" />
                        </Button>


                    </Grid>
                </Grid>
            )
        },
    ];
    let rows

    useEffect(() => {
        dispatch(getRoutes())
        dispatch(getSites())
    }, [routeState.route, routeState.delete, routeState.upload])

    const routes = routeState.routes

    if (routes) {
        routes.map(route => route.newId = (routes.indexOf(route)) + 1)
        // routes.map(route => route.source ? route.start = route.source.name : route.start = "Unknown")
        // routes.map(route => route.destination ? route.end = route.destination.name : route.end = "Unknown")
    }
    routes ? rows = routes : rows = []

    // console.log("Teh routes are:", routeState)

    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteRoute(id)))
    }

    return (
        <div>
            {routeState.pending ?
                <div
                    className={classes.progress}
                >
                    <CircularProgress />
                </div> :
                <DisplayAll
                    cTitle={'Available Routes'}
                    addBtnTitle={'Add New Route'}
                    rows={rows}
                    columns={columns}
                    cleaner={clearSnackBar}
                    reducer={routeState}
                    pending={false}
                    uploader={uploadRoutes}
                    handleCreate={handleCreateRoute}
                    multipleDeleteHandler={multipleDeleteHandler}
                    usemTable
                >
                    <DialogTitle id="form-dialog-title">Create New Route</DialogTitle>
                    <DialogContent>
                        {/* <Autocomplete
                            id="combo-box-demo"
                            options={sitesOptions}
                            fullWidth
                            value={editingData.origin}
                            onChange={(event, newValue) => {
                                setSelectedSite(newValue.name)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Origin"
                            />}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={sitesOptions}
                            fullWidth
                            value={sitesOptions.name}
                            onChange={(event, newValue) => {
                                setDestinationSite(newValue.name)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Destination"
                            />}
                        /> */}
                        <Autocomplete
                            id="checkboxes-tags-demo"
                            options={sitesOptions}
                            value={sitesOptions.name}
                            onChange={(event, newValue) => {
                                setSelectedSite(newValue.value);
                            }}
                            getOptionLabel={(option) => {
                                return option.name
                            }}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        {option.name}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Origin"
                                    placeholder="Choose origin"
                                />
                            )}
                        />
                        <Autocomplete
                            id="checkboxes-tags-demo"
                            options={sitesOptions}
                            value={sitesOptions.name}
                            onChange={(event, newValue) => {
                                setDestinationSite(newValue.value);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        {option.name}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Destination"
                                    placeholder="Choose destination"
                                />
                            )}
                        />

                    </DialogContent>
                </DisplayAll>
            }
            <DeleteModel
                title={'Delete Route'}
                description={`Are you sure you want to delete this route?`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
            />
            <EditModel
                title={'Edit Route'}
                closeEditDialog={handleCloseEditModel}
                isEditModelOpen={openEdit}
                classes={classes}
                handleEdit={handleEditRoute}
            >
                <DialogContent>
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        options={sitesOptions}
                        value={editingData.source}
                        onChange={(event, newValue) => {
                            setEditOrigin(newValue.value);
                        }}
                        getOptionLabel={(option) => {
                            // console.log("The options is:", option)
                            return option.name
                        }}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Origin"
                                placeholder="Choose origin"
                            />
                        )}
                    />
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        options={sitesOptions}
                        value={editingData.destination}
                        onChange={(event, newValue) => {
                            setEditDestination(newValue.value);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Destination"
                                placeholder="Choose destination"
                            />
                        )}
                    />
                </DialogContent>
            </EditModel>
        </div>
    )
}

export default Routes
