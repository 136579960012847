import React from 'react'
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    element:{
        padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    }
}))

const Groups=props =>{
    let location = useLocation();
    const classes = useStyles();
    const theme = useTheme();

    const currentPage=location.pathname.toUpperCase()
    return (
        <div
        className={classes.element}
        >
            Rendering from groups component {currentPage}
        </div>
    )
}

export default Groups
