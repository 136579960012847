import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';

import {
    createBus,
    deleteBus,
    getBuses,
    updateBus,
    clearSnackBar,
    uploadBuses
} from "../../redux/actions/buses/buses"
import { getBusMakes } from '../../redux/actions/buses/busMakes'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import color from "../colors";
import {
    Grid,
    Button,
    CircularProgress,
    TextField,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    InputLabel
} from '@material-ui/core';
import DisplayAll from '../templates/DisplayAll'
import EditModel from '../templates/EditModel'
import DeleteModel from '../templates/DeleteModel'
import { getInstitutions } from '../../redux/actions/institutions/institutions';
import { removeSpace } from '../../utils/removeSpaces';

const useStyles = makeStyles((theme) => ({
    element: {
        // padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    progress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
}))

const Buses = () => {
    const classes = useStyles()
    let history = useHistory();
    const dispatch = useDispatch()

    const [plateNumber, setPlateNumber] = useState(null)
    const [institution, setInsitution] = useState("")
    const [institutionName, setInstitutionName] = useState("")

    const [openEdit, setOpenEdit] = useState(false)
    const [plate, setPlate] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [busToDelete, setBusToDelete] = useState(null)
    const [institutionToEdit, setInstitutionToEdit] = useState("")
    const [makeId, setMakeId] = useState()
    const [editingData, setEditingData] = useState([])
    const [editingInstitution, setEditingInstitution] = useState()
    const [editingMake, setEditingMake] = useState()


    const buses = useSelector(state => state.buses.buses)
    const busState = useSelector(state => state.buses)
    const institutions = useSelector(state => state.institutions.institutions)
    const busesReducer = useSelector(state => state.buses)


    useEffect(() => {
        dispatch(getBuses())
    }, [busesReducer.added, busesReducer.edited, busesReducer.deleted, busesReducer.upload])

    useEffect(() => {
        dispatch(getInstitutions())
        dispatch(getBusMakes())
    }, [])

    const handleCreateBus = () => {
        let numberPlate = plateNumber ? removeSpace(plateNumber).toUpperCase() : ""
        dispatch(createBus(numberPlate, institution, makeId))
        setPlateNumber(null)
        setInsitution("")
        setMakeId(null)
    }
    const handlePlateNumber = (e) => setPlateNumber(e.target.value)
    const handlePlateToEdit = (e) => setPlate(e.target.value)

    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleOpenDeleteModel = (e) => {
        setOpenDelete(true)
        const element = e.currentTarget.id.split(",")
        setBusToDelete(element[0])
    }


    const handleEditBus = () => {
        dispatch(updateBus(editingData.id, plate, editingInstitution, editingMake))
        handleCloseEditModel()
    }
    const handleOpenEditModel = (data) => {
        setEditingData(data)
        setOpenEdit(true)
    }
    const handleCloseEditModel = (e) => {
        setPlate("")
        setInstitutionToEdit("")
        setMakeId(null)
        setOpenEdit(false)
    }
    const handleDelete = (e) => {
        dispatch(deleteBus(busToDelete))
        setOpenDelete(false)
    }

    const institutionOptions = institutions ? institutions.map(item => {
        return {
            name: item.name,
            id: item.id
        }
    }) : []

    const busMakeOptions = busState.busMakes ? busState.busMakes.map(bMake => {
        return {
            name: bMake.name,
            id: bMake.id
        }
    }) : []

    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'plateNumber',
            title: 'Plate Number',

        },
        {
            field: 'busMake',
            title: 'Bus Make',

        },
        {
            field: 'institutionName',
            title: 'Institution',

        },
        {
            field: 'action',
            title: 'Actions',
            headerStyle: {
                textAlign: 'right',
            },
            render: rowData => (
                <Grid
                    container
                    direction="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => history.push(`/dashboard/buses/${rowData.id}`)}
                        >
                            <VisibilityIcon color="primary" />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => handleOpenEditModel(rowData)}
                            id={`${rowData.id},${rowData.plateNumber},${rowData.institutionName}`}
                        >
                            <EditIcon />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            id={`${rowData.id},${rowData.plateNumber}`} >
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];
    let rows

    if (buses) {
        buses.map(bus => {
            bus.newId = (buses.indexOf(bus)) + 1
            bus.institutionName = bus.institutions ? bus.institutions.name : ""
            bus.busMake = bus.make ? bus.make.name : ""
        })
    }

    buses ? rows = buses : rows = []
    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteBus(id)))
    }
    return (
        <div className={classes.element}>
            {busesReducer.pending ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <div>
                    <DisplayAll
                        cTitle={'Available Buses'}
                        addBtnTitle={'Add New Bus'}
                        rows={rows}
                        columns={columns}
                        busMakes={true}
                        pending={false}
                        uploader={uploadBuses}
                        handleCreate={handleCreateBus}
                        cleaner={clearSnackBar}
                        reducer={busesReducer}
                        multipleDeleteHandler={multipleDeleteHandler}
                        usemTable
                    >
                        <DialogTitle id="form-dialog-title">Create New Bus</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Plate Number"
                                type="text"
                                onChange={handlePlateNumber}
                                fullWidth
                            />
                            <Autocomplete
                                id="combo-box-demo"
                                options={institutionOptions}
                                sx={{ width: '100%' }}
                                value={institutionOptions.id}
                                onChange={(event, newValue) => {
                                    setInsitution(newValue.id)
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Institution"
                                />}
                            />
                            <Autocomplete
                                className={classes.forminput}
                                id="combo-box-demo"
                                fullWidth
                                options={busMakeOptions}
                                sx={{ width: '100%' }}
                                value={busMakeOptions.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    setMakeId(newValue.id)
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Bus Make"
                                />}
                            />
                        </DialogContent>
                    </DisplayAll>
                    <DeleteModel
                        title={'Delete Bus'}
                        description={`Are you sure you want to delete this bus?`}
                        isDeleteModelOpen={openDelete}
                        closeDialog={handleCloseDeleteModel}
                        handleDelete={handleDelete}
                    />
                    <EditModel
                        title={'Edit Bus'}
                        closeEditDialog={handleCloseEditModel}
                        isEditModelOpen={openEdit}
                        classes={classes}
                        handleEdit={handleEditBus}
                    >
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                defaultValue={editingData.plateNumber}
                                id="name"
                                label="Plate Number"
                                type="text"
                                onChange={handlePlateToEdit}
                                fullWidth
                            />
                            <Autocomplete
                                id="checkboxes-tags-demo"
                                options={institutionOptions}
                                value={editingData.institutions}
                                onChange={(event, newValue) => {
                                    setEditingInstitution(newValue.id);
                                }}
                                getOptionLabel={(option) => {
                                    return option.name
                                }}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props}>
                                            {option.name}
                                        </li>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Institution"
                                        placeholder="Choose institution"
                                    />
                                )}
                            />
                            <Autocomplete
                                id="checkboxes-tags-demo"
                                options={busMakeOptions}
                                value={editingData.make}
                                onChange={(event, newValue) => {
                                    setEditingMake(newValue.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props}>
                                            {option.name}
                                        </li>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Bus Make"
                                        placeholder="Choose make"
                                    />
                                )}
                            />

                        </DialogContent>
                    </EditModel>

                </div>

            }
        </div>
    )
}

export default Buses
