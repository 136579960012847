import React , {useState, useEffect} from 'react';
import {Typography, makeStyles,Grid,FormControlLabel,Checkbox,FormGroup,Button,CircularProgress,Snackbar,Slide} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
    singleRole,
    changePermission,
    changeUnallowedPermission,
    updatePermission,
    selectAllUnAllowed,
    selectAllAllowed,
    clearSnackBar} from "../../redux/actions/roles/rolesPermissons"
import {connect} from "react-redux";
import color from "../colors/index"


const useStyles=makeStyles((theme)=>({
        headers:{
            marginTop:theme.spacing(2),
            alignContent:"center",
            marginBottom:theme.spacing(1),
            color:`${color.primary100}`,
            fontWeight:"bold"
        },
        descriptions:{
            marginBottom:theme.spacing(1),
        },
        permissions:{
            fontWeight:"bold",
            marginBottom:theme.spacing(3)
        },
        permission:{
            backgroundColor:`${color.permission}`,
            padding:theme.spacing(3)
        },
        header:{
            marginTop:theme.spacing(4.5),
            fontWeight:"bold",
            color:`${color.primary100}`
          },
        contain:{
            marginTop:theme.spacing(0),
        },
        container:{
            marginTop:theme.spacing(5),
            alignItems:"center"
        },
        header2:{
            marginTop:theme.spacing(0),
            fontWeight:"bold",
            color:`${color.path}`
          },
        header3:{
            marginTop:theme.spacing(0),
            fontWeight:"bold",
            color:`${color.primary100}`
        },
        path:{
            display:'flex',
            flexDirection:'row',
            alignItems:"flex-end"
        },
        btn:{
            display:"flex",
            justifyContent:"flex-end"
        },
        separator:{
            marginTop:theme.spacing(2),
        },
        spinner:{
            display: "flex",
            justifyContent: "center",
            marginTop:theme.spacing(20),
            marginBottom:theme.spacing(30),
          }

    })
)

const SingleRole =  (props)=>{

    useEffect(()=>{
        props.singleRole(props.match.params.id)
    },[props.newPermission])
    const classes = useStyles();
    const [all,setAll]= useState(false)
    const [alls,setAlls]= useState(true)
    let path = [];
    const getPath = ()=>{
        const general= props.match.path.split("/");
        let url=null;
        for(let i=1;i<general.length;i++){
          if(i === general.length-1){
            url=<Typography variant="subtitle2" className={classes.header3} >{`${props.match.params.id}`}</Typography>
            path.push(url)
          }else{
            const word= general[i].charAt(0).toUpperCase()+general[i].slice(1)
            url=<Typography variant="subtitle2" className={classes.header2}>{`${word} >`}</Typography>
            path.push(url)
          }
        }
      }
  
      getPath()

      const handleCheckBox =(e)=>{
        let allowed = e.target.checked ? false :true
        props.changePermission(e.target.name, allowed)
      }

      const handleUnAllowedCheckBox =(e)=>{
        let allowed = e.target.checked ? true :false
        props.changeUnallowedPermission(e.target.name, allowed)
      }
      const handelClick =()=>{
         props.history.push("/dashboard/roles")
      }
      const handelAdd=()=>{
        props.updatePermission(props.match.params.id,props.unAllowedPermissions)
      }

      const handelRemove=()=>{
        props.updatePermission(props.match.params.id,props.allowedpermissions)
    }

    const handleAll =(e)=>{
        let allowed = e.target.checked ? true :false
        setAll(allowed);
        props.selectAllUnAllowed(allowed)
    }
    const handleAlls =(e)=>{
        let allowed = e.target.checked ? false :true
        setAlls(allowed);
        props.selectAllAllowed(allowed)
    }
      let labels=[];
      const populateAllowedChecbox=()=>{
        let label;
        let count=0;
        for(const property in props.allowedpermissions){
          label=<FormControlLabel
                key={property}
                control={<Checkbox checked={!props.allowedpermissions[property]?true:false} color={'primary'} name={property} id={`${count}`}  onChange={handleCheckBox} />}
                label={property}
          />
          count++;
          labels.push(label);
        }
        return labels;
      }
      populateAllowedChecbox()


      let unlabels=[];
      const populateUnAllowedChecbox=()=>{
        let label;
        let count=0;
        for(const property in props.unAllowedPermissions){
          label=<FormControlLabel
                key={property}
                control={<Checkbox checked={props.unAllowedPermissions[property]?true:false} color={'primary'} name={property} id={`${count}`}  onChange={handleUnAllowedCheckBox} />}
                label={property}
          />
          count++;
          unlabels.push(label);
        }
        return unlabels;
      }
      populateUnAllowedChecbox()

      const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
      }

      const closeSnackBarTimer = () => {
        props.clearSnackBar()
      }
return(
      <Grid
      container 
      direction="column"
      className={classes.contain}    
      >

            <Grid container item justify="space-between">
                <Typography variant="h5"  className={classes.header}>{`Role Details`} </Typography>
            
                <div className={classes.path}>
                    {path}
                </div>
            </Grid>
            <Snackbar
                open={props.snackBarMessage.open}
                onClose={closeSnackBarTimer}
                autoHideDuration={4000}
                TransitionComponent={TransitionUp}
            >
                <MuiAlert
                severity={props.snackBarMessage.severity}
                variant='filled'
                elevation={6}
                >{props.snackBarMessage.message}</MuiAlert>
            </Snackbar>
            {props.pending ? 
            <div className={classes.spinner}>
                <CircularProgress/>
            </div>
        :
                <>
            <Grid container direction="column" alignItems="left">
                <Grid direction="column" container justify="left" alignItems="left" >
                    <Typography variant='h6' className={classes.headers} >{props.permissions.name}</Typography>
                    <Typography variant='subtitle1' className={classes.descriptions} >{props.permissions.description}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' className={classes.permissions} >{`${props.permissions.name}'s permissions`}</Typography>

                </Grid>
            </Grid>
            <Grid
                container
                justify="space-between"
                alignItems="baseline"
                
            >
                <Grid
                    item
                    xs={5}
                    className={classes.permission}
                >
                    <Typography variant='h6' className={classes.permissions} >{`Available Permissions`}</Typography>
                    {unlabels.length ===0 ?<Typography variant='subtitle2' className={classes.permissions} >{` No Available Permissions`}</Typography>:
                    <form >
                        <FormGroup>
                        {unlabels.length >= 2?
                            <FormControlLabel
                            key={"all"}
                            control={<Checkbox checked={all} color={'primary'} name="select all" id={`all`}  onChange={handleAll} />}
                            label={"Select all"}
                            />
                            : null}
                            {unlabels}
                        </FormGroup>
                        <div className={classes.btn} >
                        {unlabels.length >= 1?
                            <Button color="primary" variant='contained' onClick={handelAdd}>Add Selected</Button>
                            :null}
                        </div>
                    </form>}
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    
                </Grid>
                <Grid
                    item
                    xs={5}
                    className={classes.permission}
                >
                    <Typography variant='h6' className={classes.permissions} >{`Assigned Permissions`}</Typography>
                    {labels.length ===0 ?<Typography variant='subtitle2' className={classes.permissions} >{` No Assigned Permissions`}</Typography>:
                    <form >
                        <FormGroup>
                            {labels.length >= 2?
                                <FormControlLabel
                                key={"all"}
                                control={<Checkbox checked={!alls} color={'primary'} name="select all" id={`all`}  onChange={handleAlls} />}
                                label={"Select all"}
                                />
                                : null
                            }
                            {labels}
                        </FormGroup>
                        <div className={classes.btn} >
                        {labels.length >= 1?
                            <Button color="secondary" variant='contained' onClick={handelRemove}>Remove Selected</Button>
                            :null}
                        </div>
                    </form>
                    }
                </Grid>
            </Grid>
        </>
        }
        <Grid item className={classes.separator}>
            <Button color="primary" variant='contained' onClick={handelClick}>View all Roles</Button>
        </Grid>
    </Grid>
)

}

const mapStateToProps=state=>({
    permissions:state.permissions.permissions,
    newPermission:state.permissions.newPremissions,
    message:state.permissions.message,
    snackBarMessage:state.permissions.snackBarMessage,
    pending:state.permissions.pending,
    allowedpermissions:state.permissions.allowedpermissions,
    unAllowedPermissions:state.permissions.unAllowedPermissions
})

export default connect(mapStateToProps,{singleRole,changePermission,changeUnallowedPermission,updatePermission,selectAllUnAllowed,selectAllAllowed,clearSnackBar})(SingleRole)





