// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.MuiTableCell-sizeSmall {\n  padding: 1px 24px 1px 16px !important;\n}\n\n.MuiAutocomplete-tagSizeSmall {\n  height: \"300px\" !important;\n  background-color: aqua !important;\n  font-size: x-large !important;\n}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,sBAAA;EACA,6CAAA;EACA,mCAAA;EACA,kCAAA;AAAF;;AAGA;EACE,+EAAA;AAAF;;AAIA;EACE,qCAAA;AADF;;AAIA;EACE,0BAAA;EACA,iCAAA;EACA,6BAAA;AADF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');\n\nbody {\n  margin: 0;\n  box-sizing: border-box;\n  font-family: 'Poppins', sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.MuiTableCell-sizeSmall {\n  padding: 1px 24px 1px 16px !important;\n}\n\n.MuiAutocomplete-tagSizeSmall {\n  height: '300px' !important;\n  background-color: aqua !important;\n  font-size: x-large !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
