import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    reports: null,
    // report: null,
    pending: false,
    // pendingRefunds: null,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.REPORT_PENDING:
            return {
                ...state,
                pending: true
            }

        case types.GET_REPORTS_SUCCESS:
            return {
                ...state,
                reports: action.reports,
                message: action.message,
                pending: false
            };

        case types.REPORT_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };
        default:
            return state;
    }
};