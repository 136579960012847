import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  institutions: [],
  institution: {},
  locations: [],
  pending: false,
  deleted: false,
  upload: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INSTITUTIONS_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: action.institutions,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        institution: action.institution,
        locations: action.institution.locations,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.CREATE_INSTITUTION_SUCCESS:
      return {
        ...state,
        institution: action.institution,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.UPLOAD_INSTITUTIONS:
      return {
        ...state,
        message: action.message,
        upload: state.upload ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.DELETE_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        message: action.message,
        deleted: state.deleted ? false : true,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      }
    case types.UPDATE_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        message: action.message,
        institution: action.institution,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_INSTITUTIONS_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
