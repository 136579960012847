import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material/';
import { useHistory } from 'react-router-dom'

import { approveCancel } from '../../redux/actions/stock/stock'

const ApproveCancel = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        const { token } = props.match.params
        try {
            dispatch(approveCancel(token))
            history.push(`/stock`)
        }
        catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <div style={{
            height: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress />
        </div>
    )
}

export default ApproveCancel