import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Grid, Button, Typography, makeStyles, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, CircularProgress, Slide, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/PublishOutlined';
import color from "../colors";
import {
  getRoles,
  createRole,
  deleteRole,
  updateRole,
  clearSnackBar,
  uploadRoles
} from "../../redux/actions/roles/rolesAction"
import { connect } from "react-redux";
import XLSX from "xlsx";


const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(4.5),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  path: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "flex-end"
  },
  contain: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px"
  },
  title: {
    fontWeight: "bold",
    alignContent: "center"
  },
  container: {
    position: "relative",
    width: "100%",
    border: `solid 1px ${color.border}`,
    marginBottom: "2px",
    borderRadius: "3px"
  },
  btn: {
    backgroundColor: `${color.primary100}`,
    color: 'white',
    padding: "9px",
    height: theme.spacing(5),
    marginTop: theme.spacing(-0.2),
    marginRight: theme.spacing(5)
  },
  grid: {

    margin: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  btn2: {
    backgroundColor: `${color.primary100}`,
    height: theme.spacing(5),

  },
  btn3: {
    height: theme.spacing(3.4),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  search: {
    display: 'flex',
    alignItems: "flex-end",
    marginBottom: theme.spacing(4),
    height: "0px",
    marginRight: "0px",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(0)
  },
  root: {
    '& .super-app-theme--header': {
      fontWeight: "bolder",
    },
  },
  down: {
    minWidth: 100,
    height: 12
  },
  formControl: {
    height: theme.spacing(5),
    padding: "10",
    paddingBottom: "0",
    backgroundColor: `${color.primary100}`,
    color: "white",
    font: "14px Roboto,Helvatica,Arail,sans-serif",
    minWidth: " 64px",
    boxSizing: "border-box",
    transition: " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
    fontWeight: 500,
    lineHeight: 1.60,
    borderRadius: "3px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    paddingRight: "0",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "9px",
    marginTop: theme.spacing(1),
    marginBottom: "0px",
  },
  table: {
    marginTop: theme.spacing(4),
  },
  label: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d5d5d5',
    color: 'black',
    padding: '0.3rem',
    fontFamily: 'sans-serif',
    borderRadius: '0.3rem',
    cursor: 'pointer',
    margin: '1rem',
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  whiteColor: {
    color: "white"
  },
  importbtn: {

    display: "flex",
    textTransform: "uppercase",
    color: `${color.primary100}`,
    marginBottom: theme.spacing(2),
    '&:hover': {
      background: "#edf4fc",
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10)
  },
  top: {
    marginBottom: theme.spacing(2),
  },
  middle: {
    marginBottom: theme.spacing(3),
  }
})
)
function RoleTable(props) {
  useEffect(() => {
    props.getRoles()
    setTableRows(null)
    setUpload("none");
    setTableColomns(null)
  }, [props.role, props.changes, props.upload])

  const [open, setOpen] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const [openUpdate, setUpdate] = useState(false);
  const [name, setName] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [roleName, setRoleName] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [description, setDescription] = useState(null);
  const [tableColomns, setTableColomns] = useState(null);
  const [tableRows, setTableRows] = useState(null);
  const [saveUpload, setUpload] = useState("none");
  const classes = useStyles();
  let path = [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUpload = () => {
    props.uploadRoles(tableRows)
    setTableRows(null)
    setTableColomns(null)
    setUpload("none");
  };
  const handlecancleUpload = () => {
    setTableRows(null)
    setTableColomns(null)
    setUpload("none");
  }
  const getPath = () => {
    const general = props.match.path.split("/");
    let url = null;
    for (let i = 1; i < general.length; i++) {
      if (i === general.length - 1) {
        const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
        url = <Typography variant="subtitle2" className={classes.header3} >{`${word}`}</Typography>
        path.push(url)
      } else {
        const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
        url = <Typography variant="subtitle2" className={classes.header2}>{`${word} >`}</Typography>
        path.push(url)
      }
    }
  }

  getPath()
  const handleDeleteDialog = (e) => {
    let values = e.currentTarget.id.split(":")
    setRoleId(values[0]);
    setRoleName(values[1]);
    setDelete(true)

  }
  const handleDeleteDialogYes = () => {
    props.deleteRole(roleId)
    handleDeleteDialogClose();
  }
  const handleDeleteDialogClose = () => {
    setDelete(false)
  }
  const handleDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleUpdateDialog = (e) => {
    let values = e.currentTarget.id.split(":");
    setRoleId(values[0])
    setName(values[1])
    setDescription(values[2])
    setUpdate(true)
  }

  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleSubmit = () => {
    props.createRole(name, description)
    handleClose()
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseUpdate = () => {
    setRoleId(null)
    setName(null)
    setDescription(null)
    setUpdate(false);
  };

  const handleUpdateSubmit = () => {
    props.updateRole(roleId, name, description);
    setUpdate(false);
  }

  const handleChange = (e) => {
    setPerPage(e.target.value);
  }

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  }

  const closeSnackBarTimer = () => {
    props.clearSnackBar()
  }
  const handelClick = (e) => {
    props.history.push(`/dashboard/role/${e.currentTarget.id}`)
  }
  const columns = [
    {
      field: 'newId',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1, headerClassName: 'super-app-theme--header'
    },
    {
      field: 'action', headerName: 'Action', width: 320, headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={params.id}
              onClick={handelClick}
            >
              <VisibilityIcon color="primary" />
            </Button>

          </Grid>
          <Grid
            item
          >
            <Button onClick={handleUpdateDialog}
              id={`${params.id}:${params.row.name}:${params.row.description}`}
            >
              <EditIcon />
            </Button>
          </Grid>
          <Grid
            item
          >
            <Button onClick={handleDeleteDialog}
              id={`${params.id}:${params.row.name}`}
            >
              <DeleteIcon color="secondary" />
            </Button>
          </Grid>
        </Grid >
      )
    },
  ];
  const ImportExcel = (file) => {
    const EXTENSIONS = ["xlsx", "xls", "csv"]
    const getExtensions = (file) => {
      const name = file.name.split(".")
      return EXTENSIONS.includes(name[name.length - 1])
    }
    const convertDataToJson = (header, fileData) => {
      let rows = []
      let id = -1;
      fileData.forEach(row => {
        let rowData = {}
        row.forEach((cell, index, arr) => {
          rowData[header[index]] = cell
          rowData.id = index + id
          if (arr.length === index + 1) id++

        })

        rows.push(rowData)
      })
      return rows
    }
    let data = null
    const reader = new FileReader()
    if (file) {
      if (getExtensions(file)) {
        reader.readAsBinaryString(file)
        setUpload("flex");
      } else (
        alert("Invalid file input, Select an Excel or CSV file")
      )

    } else {
      setUpload("none");
      setTableRows(null)
      setTableColomns(null)
    }

    reader.onload = (e) => {
      const binaryData = e.target.result
      const workBook = XLSX.read(binaryData, { type: "binary" })
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      const excelData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
      const header = excelData[0].map(head => (head.toLowerCase()))
      const headers = header.map(head => ({ headerName: head.toUpperCase(), field: head, width: 365 }))
      excelData.splice(0, 1)
      setTableRows(convertDataToJson(header, excelData))

      data = headers

      setTableColomns(data)
    }
  }
  const handleImport = (e) => {
    ImportExcel(e.target.files[0])
    e.target.value = null;
  }
  let rows
  props.roles ? (rows = props.roles) : rows = []
  rows.forEach((row, index) => {
    row.newId = index + 1
  })


  return (

    <div className={classes.contain}>
      <Grid
        container
        spacing={0}
        justify="space-between"
        className={classes.top}
      >
        <Grid
          item
          xs={10}
        >
          <Typography variant="h5" color={color.primary100} className={classes.header}> Available Roles</Typography>
        </Grid>
        <div className={classes.path}>
          {path}
        </div>
      </Grid>
      <Grid container className={classes.middle}
        justify="space-between"
      >
        <Grid item  >
          <Button color="primary" variant='contained' className={classes.btn} onClick={handleClickOpen}> Add New ROLE</Button>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={perPage}
            color="primary"
            onChange={handleChange}
            label="Age"
            className={classes.formControl}
          >
            <MenuItem value={5}>SHOW 5 ITEMS</MenuItem>
            <MenuItem value={10}>SHOW 10 ITEMS</MenuItem>
            <MenuItem value={20}>SHOW 20 ITEMS</MenuItem>
            <MenuItem value={30}>SHOW 30 ITEMS</MenuItem>
            <MenuItem value={rows.length}>SHOW ALL ITEMS</MenuItem>
          </Select>

        </Grid>
        <Grid item className={classes.search} justify="flex-end">
          <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
          <Button
            color='primary'
            variant='contained'
            className={classes.btn2}
          >
            search
          </Button>
        </Grid>
      </Grid>
      <Grid container >
        <div>
          <input id="file" name="upload_data" type="file" onChange={handleImport} hidden={true} />
          <label htmlFor="file" >
            <div variant='contained' className={classes.importbtn}>
              <PublishIcon />
              <Typography variant="subtitle1" >Import</Typography>
            </div>
          </label>
        </div>
        <div style={{ display: `${saveUpload}` }}>
          <Button color="primary" variant='contained' onClick={handleUpload} className={classes.btn3}> Save</Button>
          <Button color="secondary" variant='contained' onClick={handlecancleUpload} className={classes.btn3} > Cancel</Button>
        </div>
      </Grid>
      <Snackbar
        open={props.snackBarMessage.open}
        onClose={closeSnackBarTimer}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
      >
        <MuiAlert
          severity={props.snackBarMessage.severity}
          variant='filled'
          elevation={6}
        >{props.snackBarMessage.message}</MuiAlert>
      </Snackbar>
      {props.pending ? (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ) :
        <div style={{ height: 460, width: '100%' }}>
          <DataGrid
            rows={tableRows ? tableRows : rows}
            columns={tableColomns ? tableColomns : columns}
            components={{
              Toolbar: GridToolbar,
            }}
            className={classes.root} pageSize={perPage} checkboxSelection />
        </div>}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Role</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Role Name"
            type="text"
            onChange={handleName}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Role Desctiption"
            type="text"
            onChange={handleDescription}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.title}>Update Role</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            defaultValue={name}
            id="name"
            label="Role Name"
            type="text"
            onChange={handleName}
            fullWidth
          />
          <TextField
            margin="dense"
            defaultValue={description}
            id="description"
            label="Role Desctiption"
            type="text"
            onChange={handleDescription}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateSubmit} color="primary" variant='contained'>
            Submit
          </Button>
          <Button onClick={handleCloseUpdate} color="secondary" variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">Delete Role</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are You sure you want to delete Role ${roleName} ? Once deleted it will not be avaible for use anymore!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogYes} color="primary" autoFocus variant='contained'>
            Delete Role
          </Button>
          <Button onClick={handleDeleteDialogClose} color="secondary" variant='contained'>
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
    </div >

  );
}

const mapStateToProps = state => ({
  roles: state.roles.roles,
  role: state.roles.role,
  message: state.roles.message,
  pending: state.roles.pending,
  changes: state.roles.changes,
  upload: state.roles.upload,
  snackBarMessage: state.roles.snackBarMessage
})

export default connect(mapStateToProps, { getRoles, createRole, deleteRole, updateRole, clearSnackBar, uploadRoles })(RoleTable)

