import {
  LOGIN_CLICKED,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../../actions/auth/login';
import { types } from "../../actions/types";
const initialState = {
  message: null,
  error: null,
  isLoading: false,
  isLoggedIn: false,
  user: null,
  loggedInUser: null,
  pending: false,
  profileUpdated: false
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CLICKED:
      return {
        ...state,
        isLoading: true,
        email: action.payload,
        message: null,
        error: null,
      };
    case types.CHECK_AUTH_PENDING:
      return {
        ...state,
        pending: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        message: action.payload.msg,
        isLoading: false,
        isLogedIn: true,
      };
    case types.LOGIN_CHECK:
      return {
        ...state,
        user: action.payload,
      };
    case types.LOGIN_CHECK_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        message: action.payload.msg,
        isLoading: false,
        isLogedIn: false,
        loggedInUser: null
      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLogedIn: false,
        pending: false,
      };
    case types.CHECK_AUTH_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CHECK_AUTH_SUCCESS:
      return {
        ...state,
        loggedInUser: action.loggedInUser,
        message: action.message,
        pending: false,
        isLoggedIn: true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      };
    case types.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        pending: false,
        profileUpdated: state.profileUpdated ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      };
    default:
      return state;
  }
};
