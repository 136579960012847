import { types } from "../types";

import http from '../../../utils/axios/axios';

const auth = localStorage.getItem('x-auth-token')

export const getBusMakes = () => dispatch => {
    dispatch({ type: types.GET_BUSES_PENDING })
    return http.get('/api/make', {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            const filteredMakes = res.data.data.filter(make => make.makeFor == "bus")
            // console.log("the filtered makes are", filteredMakes)
            dispatch({
                type: types.GET_BUS_MAKES,
                payload: filteredMakes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)),
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}

export const getOneMake = (id) => dispatch => {
    dispatch({ type: types.GET_BUSES_PENDING })
    return http.get(`/api/make/${id}`, {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            dispatch({
                type: types.GET_ONE_BUS_MAKE,
                payload: res.data.make,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}

export const createMake = (make) => dispatch => {
    dispatch({ type: types.GET_BUSES_PENDING })
    return http.post(`/api/make/`, make, {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            dispatch({
                type: types.CREATE_BUS_MAKE,
                payload: res.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}

export const updateMake = (id, make) => dispatch => {
    dispatch({ type: types.GET_BUSES_PENDING })
    return http.patch(`/api/make/${id}`, make, {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            dispatch({
                type: types.UPDATE_BUS_MAKE,
                payload: res.data,
                message: res.data.msg
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}

export const deleteMake = (id) => dispatch => {
    dispatch({ type: types.GET_BUSES_PENDING })
    return http.delete(`/api/make/${id}`, {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            dispatch({
                type: types.DELETE_BUS_MAKE,
                payload: res.data,
                message: res.data.msg
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}