import axios from "axios";
axios.defaults.withCredentials = true
const baseURL = process.env.REACT_APP_BACKEND_URL;

const formDatahttp = axios.create({
    baseURL,
    headers: {
        "Content-Type": "multipart/form-data",
        "enctype": "multipart/form-data"
    },
});

export default formDatahttp;


