import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    noContent: {
        backgroundColor: '#00A5DF',
        color: '#fff',
        padding: '0.5em',
        margin: '1em 0',
        width: '99%',
        fontSize: '1rem',
        marginBottom: '2rem',
        width: '100%'

    }
}))

const NoEntries = props => {
    const { eText } = props
    const classes = useStyles();
    return (
        <div
            className={classes.noContent}
        >
            <Typography
                variant={'p'}
            >
                {eText}
            </Typography>
        </div>

    )
}

export default NoEntries
