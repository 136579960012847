import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  InputLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  CircularProgress,
  Slide,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import color from "../colors";
import {
  getSingleLocation,
  clearSnackBar,
  removeInstitution,
  getLocations
} from "../../redux/actions/locations/locationActions"
import {
  getProjects
} from "../../redux/actions/projects/projectsAction"
import {
  createSite,
  deleteSite
} from '../../redux/actions/sites/sites';
import { useSelector, useDispatch, connect } from "react-redux";
import DisplayAll from '../templates/DisplayAll';
import DeleteModel from '../templates/DeleteModel'
import NoEntries from '../templates/NoEntries';


const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(7),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  headers: {
    marginTop: theme.spacing(2),
    alignContent: "center",
    marginBottom: theme.spacing(1),
    color: `${color.primary100}`,
    fontWeight: "bold"
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  path: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "flex-end"
  },
  contain: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px"
  },
  title: {
    fontWeight: "bold",
    alignContent: "center"
  },
  container: {
    position: "relative",
    width: "100%",
    border: `solid 1px ${color.border}`,
    marginBottom: "2px",
    borderRadius: "3px"
  },
  btn: {
    backgroundColor: `${color.primary100}`,
    color: 'white',
    padding: "9px",
    margin: theme.spacing(0),
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  btn2: {
    backgroundColor: `${color.primary100}`,
    height: theme.spacing(5),

  },
  search: {
    float: "right",
    height: "10px",
    margin: theme.spacing(1),
    marginRight: "0px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  },
  root: {
    '& .super-app-theme--header': {
      fontWeight: "bolder",
    },
  },
  down: {
    minWidth: 100,
    height: 12
  },
  formControl: {
    height: "15",
    padding: "10",
    paddingBottom: "0",
    backgroundColor: `${color.primary100}`,
    color: "white",
    fontSize: " -0.875rem",
    minWidth: " 64px",
    boxSizing: "border-box",
    transition: " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
    fontWeight: 500,
    lineHeight: 1.60,
    borderRadius: "3px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    paddingRight: "0",
    paddingTop: "4px",
    paddingLeft: "9px",
    marginTop: "4px",
    marginBottom: "-3px",

  },
  table: {
    marginTop: theme.spacing(4),
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  input: {
    paddingBottom: theme.spacing(2)
  },
  whiteColor: {
    color: "white"
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10)
  },
  locations: {
    color: `${color.primary100}`,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontSize: "1.25rem",

  },

})
)
function SingleLocationTable(props) {
  const dispatch = useDispatch();
  let history = useHistory()
  const location = useSelector(state => state.locationInstitutions);
  const institutions = useSelector(state => state.locationInstitutions.institutions);
  const projects = useSelector(state => state.projects.projects)
  const sitesState = useSelector(state => state.sites)

  const { id } = useParams()

  useEffect(() => {
    dispatch(getSingleLocation(props.match.params.id));
  }, [location.remove, sitesState.delete, sitesState.site])
  useEffect(() => {
    dispatch(getProjects())
  }, [sitesState.delete, sitesState.site])


  const [openDelete, setDelete] = useState(false);
  const [institutionName, setInstitutionName] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [institutionId, setInstitutionId] = useState(null);
  const [siteId, setSiteId] = useState(null)
  const [siteName, setSiteName] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [openSiteDelete, setOpenSiteDelete] = useState(null)
  const [siteToDelete, setSiteToDelete] = useState(null)

  const classes = useStyles();
  let path = [];

  const getPath = () => {
    const general = props.match.path.split("/");
    let url = null;
    for (let i = 1; i < general.length; i++) {
      if (i === general.length - 1) {
        url = <Typography variant="subtitle2" className={classes.header3} >{`${props.match.params.id}`}</Typography>
        path.push(url)
      } else {
        const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
        url = <Typography variant="subtitle2" className={classes.header2}>{`${word} >`}</Typography>
        path.push(url)
      }
    }
  }

  getPath()

  const handleDeleteDialogClose = () => {
    setDelete(false)
  }
  const handleOpenSiteDelete = (e) => {
    const element = e.currentTarget.id.split(":")
    setSiteToDelete(element[0])
    setOpenSiteDelete(true)
  }
  const handleCloseSiteDelete = () => {
    setOpenSiteDelete(false)
  }

  const handleDelete = (e) => {
    dispatch(deleteSite(siteToDelete))
    setOpenSiteDelete(false)
  }
  const handleDeleteDialogOpen = (e) => {
    let values = e.currentTarget.id.split(":");
    setInstitutionName(values[1])
    setInstitutionId(values[0])
    setDelete(true)
  }

  const handleremove = () => {
    dispatch(removeInstitution(props.match.params.id, institutionId))
    setDelete(false)
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  }

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar())
  }
  const dis = (params) => console.log(params.row.name)

  const handleSiteId = (e) => {
    const element = e.currentTarget.id.split(":")
    history.push(`/dashboard/sites/${element[0]}`)
  }
  const handleCreateSite = () => {
    dispatch(createSite(siteName, projectId, id))
    setSiteName(null)
  }
  const handleName = (e) => setSiteName(e.target.value)
  const handleProjectChange = (e) => setProjectId(e.target.value)


  const columns = [
    {
      field: 'newId',
      headerName: 'ID',
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 370,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 470,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 260,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (

        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={`${params.id}:${params.row.name}`}
              onClick={handleDeleteDialogOpen}
            >
              <DeleteIcon color="secondary" />
            </Button>


          </Grid>
        </Grid>
      )
    },
  ];
  const sitesColumns = [
    {
      field: 'newId',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (

        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={`${params.id}:${params.row.name}`}
              onClick={handleSiteId}
            // href={}
            >
              <VisibilityIcon color="primary" />
            </Button>
          </Grid>
          <Grid
            item
          >
            <Button
              id={`${params.id}:${params.row.name}`}
              onClick={handleOpenSiteDelete}
            >
              <DeleteIcon color="secondary" />
            </Button>


          </Grid>
        </Grid>
      )
    },
  ];

  let rows
  institutions ? (rows = institutions) : rows = []
  rows.forEach((row, index) => {
    row.newId = index + 1
  })

  let sitesRows;
  location.location.sites ? sitesRows = location.location.sites : sitesRows = []
  sitesRows.forEach((row, index) => row.newId = index + 1)


  return (

    <div className={classes.contain}>
      <Grid
        container
        spacing={0}
        justify="space-between"
      >
        <Grid
          item
        >
          <Typography variant="h5" color={color.primary100} className={classes.header}>Location Details</Typography>
        </Grid>
        <div className={classes.path}>
          {path}
        </div>

      </Grid>
      <hr />
      <div>
        {location.pending ? (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        ) :
          <div >
            <Snackbar
              open={location.snackBarMessage.open}
              onClose={closeSnackBarTimer}
              autoHideDuration={4000}
              TransitionComponent={TransitionUp}
            >
              <MuiAlert
                severity={location.snackBarMessage.severity}
                variant='filled'
                elevation={6}
              >{location.snackBarMessage.message}</MuiAlert>
            </Snackbar>
            <Grid container direction="column" alignItems="left">
              <Grid direction="column" container justify="left" alignItems="left" >
                <p>
                  <b style={{ color: color.primary100 }}>Name: </b>
                  {location ? location.location.name : ""}
                </p>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1' className={classes.locations} >Institutions</Typography>
              </Grid>
            </Grid>
            <Dialog
              open={openDelete}
              onClose={handleDeleteDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"

            >
              <DialogTitle id="alert-dialog-title"> Remove Institution</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`Are You sure you want to remove Institution ${institutionName} from this Location?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleremove}
                  color="primary" autoFocus variant='contained'>
                  Remove
                </Button>
                <Button
                  onClick={handleDeleteDialogClose}
                  color="secondary" variant='contained'>
                  Cancel
                </Button>

              </DialogActions>
            </Dialog>
            <div style={{ height: 430, width: '100%' }}>

              {rows.length == 0 ?
                <NoEntries
                  eText={"This location does not have institutions"}
                /> :
                <DataGrid
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  className={classes.root}
                  pageSize={perPage}
                  checkboxSelection
                  sortModel={[{ field: 'newId', sort: 'asc', }]}
                />}
              <Grid item>
                <Typography variant='subtitle1' className={classes.locations} >Sites</Typography>
              </Grid>
              {sitesRows.length == 0 ?
                <NoEntries
                  eText={"This location does not have sites"}
                />
                :
                <div>
                  <DisplayAll
                    addBtnTitle={'Add New Site'}
                    rows={sitesRows}
                    columns={sitesColumns}
                    cleaner={clearSnackBar}
                    showSearch={false}
                    showTitle={false}
                    showImport={false}
                    pending={false}
                    handleCreate={handleCreateSite}
                    reducer={sitesState}
                  >
                    <DialogTitle id="form-dialog-title">Create New Site</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Site Name"
                        type="text"
                        onChange={handleName}
                        fullWidth
                      />
                      <InputLabel htmlFor="demo-simple-select-outlined" className={classes.selectlabel}>Project</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleProjectChange}
                        label="Project"
                        fullWidth
                      >
                        {projects ? projects.map(project => (
                          <MenuItem value={project.id}>{project.name}</MenuItem>
                        )) : null}
                      </Select>
                      {/* <InputLabel className={classes.selectlabel}>Location</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleLocationChange}
                        label="Project"
                        fullWidth
                      >
                        {locations ? locations.map(location => (
                          <MenuItem value={location.id}>{location.name}</MenuItem>
                        )) : null}
                      </Select> */}
                    </DialogContent>
                  </DisplayAll>
                  <DeleteModel
                    title={'Delete Site'}
                    description={`Are you sure you want to delete this site?`}
                    isDeleteModelOpen={openSiteDelete}
                    closeDialog={handleCloseSiteDelete}
                    handleDelete={handleDelete}
                  />
                  {/* <div>
                    <div >
                      <Button
                        color="primary"
                        variant='contained'
                        className={classes.btn}
                      // onClick={handleOpenAddModel}
                      >
                        Add Site
                      </Button>

                      {sitesRows.length !=== 0 && <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={perPage}
                        color="primary"
                        // onChange={handleChange}
                        label="Age"
                        className={classes.formControl}
                      >
                        <MenuItem value={5}>SHOW 5 ITEMS</MenuItem>
                        <MenuItem value={10}>SHOW 10 ITEMS</MenuItem>
                        <MenuItem value={20}>SHOW 20 ITEMS</MenuItem>
                        <MenuItem value={30}>SHOW 30 ITEMS</MenuItem>
                        <MenuItem value={rows.length}>SHOW ALL ITEMS</MenuItem>
                      </Select>}

                    </div>
                  </div>
                  <DataGrid
                    rows={sitesRows}
                    columns={sitesColumns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    className={classes.root}
                    pageSize={perPage}
                    checkboxSelection
                    sortModel={[{ field: 'newId', sort: 'asc', }]}
                  /> */}
                </div>
              }
            </div>
          </div>
        }
      </div>
      {/* <Button
        color="primary"
        variant='contained'
        className={classes.btn}
        // style={{
        //   marginTop: sitesRows.length !=== 0 ? '19em' : '0'
        // }}
        onClick={() => props.history.push("/dashboard/locations")} > View Locations</Button> */}
    </div>

  );
}


export default SingleLocationTable