import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    damages: null,
    damage: null,
    pending: false,
    deleted: false,
    upload: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.DAMAGES_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_DAMAGES_SUCCESS:
            return {
                ...state,
                damages: action.damages,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.UPLOAD_DAMAGES:
            return {
                ...state,
                message: action.message,
                upload: state.upload ? false : true,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.GET_DAMAGE_SUCCESS:
            return {
                ...state,
                damage: action.damage,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.CREATE_DAMAGE:
            return {
                ...state,
                damage: action.damage,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.DELETE_DAMAGE:
            return {
                ...state,
                message: action.message,
                pending: false,
                deleted: state.deleted ? false : true,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            }
        case types.UPDATE_DAMAGE:
            return {
                ...state,
                message: action.message,
                damage: action.damage,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }

        case types.GET_DAMAGES_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };
        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
