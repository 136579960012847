import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, FieldArray } from 'formik';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    TextField,
    Grid,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    CircularProgress,
    Fab,
    Snackbar,
    Slide
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from '@material-ui/icons/Add';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'
import { types } from "../../../redux/actions/types";
import { createIncindent, clearSnackBar } from "../../../redux/actions/incidents/incidentActions";
import { getUsersAction } from "../../../redux/actions/usersAction"
import { getBus } from "../../../redux/actions/buses/buses"
import colors from '../../colors';
import { formatToTextfieldDate } from '../../../utils/formatDates';
import {
    getInstitutions,
    getInstitution
} from "../../../redux/actions/institutions/institutionsActions"
import {
    getProjects,
    getProject
} from "../../../redux/actions/projects/projectsAction";
import { getDamages } from '../../../redux/actions/damages/damages'
import { getCategories } from '../../../redux/actions/categories/categoriesActions'
import { getPriorities } from '../../../redux/actions/priority/prioritiesActions'
import { getRoutes } from '../../../redux/actions/routes/routes';
import { removeSpace } from '../../../utils/removeSpaces';
import Header from '../../templates/TopPartOfPage'
import IncNavLinks from './IncNavLinks'

export const useStyles = makeStyles((theme) => ({
    bottomMargin: {
        marginBottom: '1em'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: '',
        '@media(max-width: 320px)': {
            display: 'grid'
        },
    },
    picker: {
        width: "100%",
        height: "3em",
        border: "1px solid #B3B6B7",
        fontSize: '18px',
        color: "grey",
        paddingLeft: "0.6em",
        borderRadius: '5px',

    },
    forminput: {
        display: "block",
        marginBottom: theme.spacing(4),
        fontWeight: "bold"
    },
    formlabel: {
        fontWeight: "bold"
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    separate: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(4)
    },
    separator: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2)
    },
    reviews: {
        fontSize: '14px',
        color: colors.primary100,
    },
    cardContent: {
        overflow: 'hidden'
    },
    container: {
        position: "relative",
        width: "100%",
        marginBottom: "2px",
        borderRadius: "3px",

    },
    btncontainer1: {
        display: "flex",
        justifyContent: "flex-start"
    },
    btncontainer2: {
        display: "flex",
        justifyContent: "flex-end"
    },
    titleText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        }
    },
    item: {
        display: 'block'
    },
    spinner: {
        width: '100%',
        textAlign: 'center'
    }
}));


const SubmitIncident = props => {

    const classes = useStyles();
    const projects = useSelector(state => state.projects)
    const buses = useSelector(state => state.buses)
    const institutions = useSelector(state => state.institutions)
    const damages = useSelector(state => state.damages)
    const categories = useSelector(state => state.categories)
    const priorities = useSelector(state => state.priorities)
    const uploadimage = useSelector(state => state.uploadimage)
    const users = useSelector(state => state.users)
    const incidents = useSelector(state => state.incidents)
    const theRoutes = useSelector(state => state.routes.routes)


    const agents = users.users.filter(user => (user.status && removeSpace(user.role.name)) == 'agent')
    const superAgents = users.users.filter(user => (user.status && removeSpace(user.role.name)) == 'superagent')


    const [projectId, setProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState('')
    const [instit, setInstit] = useState(null);
    const [bus, setBus] = useState(null);
    const [institutionId, setInstitutionId] = useState(null);
    const [isBusIncident, setIsBusIncident] = useState('no')
    const [isDeviceIncident, setIsDeviceIncident] = useState('no')
    const [photos, setPhotos] = useState(uploadimage.images)
    const [photo, setPhoto] = useState(uploadimage.images)
    const [filteredIssues, setFilteredIssues] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [typeOfRequest, setTypeOfRequest] = useState('')
    const [categoryId, setCategoryId] = useState(null)
    const [customAgent, setCustomAgent] = useState(false)
    const [strictToBus, setStrictToBus] = useState(false)
    const [strictToRoute, setStrictToRoute] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProjects())
        dispatch(getInstitutions())
        dispatch(getDamages())
        dispatch(getCategories())
        dispatch(getPriorities())
        dispatch(getUsersAction());
        dispatch(getProject(projectId))
        dispatch(getInstitution(institutionId))
        dispatch(getBus(bus))
        dispatch(getRoutes())
    }, [projectId, institutionId, bus])
    useEffect(() => {
        setPhotos(uploadimage.images)
        setPhoto(uploadimage.images)
    }, [])
    useEffect(() => {
        const thisProject = projects.projects ? projects.projects.filter(project => project.id == projectId) : []
        if (thisProject) setSelectedProject(thisProject.length !== 0 ? removeSpace(thisProject[0].name) : "")

        const theIssues = damages.damages ? damages.damages.filter(damage => damage.categoryId == categoryId) : []
        if (theIssues) setFilteredIssues(theIssues)
    }, [projectId, categoryId])

    useEffect(() => {
        console.log(customAgent)
    }, [customAgent])

    const handleBusRelated = (e) => setIsBusIncident(e.target.value)
    const handleDeviceRelated = (e) => setIsDeviceIncident(e.target.value)


    const validationSchema = yup.object({
        routeId: isBusIncident === 'yes' && strictToRoute ? yup
            .string()
            .required('Route is required') :
            yup.string().nullable(true),
        location: yup
            .string()
            .required('Location is required'),
        description: yup
            .string()
            .required('Description is required')
            .max(100),
        projectId: yup
            .string()
            .required('Project is required'),
        institutionId: yup
            .string()
            .required('Institution is required')
            .when("projectId", {
                is: val => (val && val.length > 0 ? true : false),
                otherwise: yup.string().oneOf(
                    [yup.ref("projectId")],
                    "you should first select a Project")
            }),
        agent: yup.number().nullable(true),
        agentName: yup.string().nullable(true),
        supervisorName: yup.string().nullable(true),
        superAgentName: yup.string().nullable(true),
        doneBy: yup.string().nullable(true),
        occuranceTime: yup.date(),
        busId: isBusIncident === 'yes' && strictToBus ? yup
            .string()
            .required("Bus is required")
            .when("institutionId", {
                is: val => (val && val.length > 0 ? true : false),
                otherwise: yup.string().oneOf(
                    [yup.ref("institutionId")],
                    "you should first select a Institution")
            }) :
            yup.string().nullable(true),
        deviceId: removeSpace(selectedCategory) == 'equipments' ? yup
            .string()
            .required('Device is required') :
            yup.string().nullable(true),
        damageId: yup
            .string()
            .required('Issue is required'),
        categoryId: yup
            .string()
            .required('Category is required'),
        priorityId: yup
            .string()
            .required('Priority is required'),
        images: yup
            .array()
            .required('an Image is required')
            .min(1),
        busTicketId: yup.string().nullable(true),
        momoTransactionId: yup.string().nullable(true),
        customerName: yup.string().nullable(true),
        customerPhone: yup.string().nullable(true),
        agentUsername: yup.string().nullable(true),
        requestType: selectedCategory == 'financial' ? yup
            .string()
            .required('Type of request is required')
            :
            yup.string().nullable(),
        requestAmount: selectedCategory == 'financial' ? yup
            .string()
            .required('Amount is required') :
            yup.string().nullable(),
        ccdUsers: yup.array().nullable(true)
    });

    const initialValues = {
        routeId: null,
        agent: null,
        agentName: null,
        supervisorName: null,
        superAgentName: null,
        // occuranceTime: new Date(Date.now()),
        occuranceTime: formatToTextfieldDate(new Date(Date.now())),
        location: "",
        description: "",
        projectId: "",
        institutionId: "",
        busId: null,
        deviceId: null,
        damageId: "",
        categoryId: "",
        priorityId: "",
        images: [''],
        busTicketId: null,
        momoTransactionId: null,
        customerName: null,
        customerPhone: null,
        requestType: selectedProject == 'upcountry' ? "" : null,
        requestAmount: selectedProject == 'upcountry' ? "" : null,
        ccdUsers: [],
        doneBy: null,
        busPlate: null,
        busRouteName: null,
        agentUsername: null,
    }

    const projectOptions = projects.projects ? projects.projects.map(project => {
        return {
            label: project.name,
            value: project.id
        }
    }) : []
    const priorityOptions = priorities.priorities ? priorities.priorities.map(priority => {
        return {
            label: priority.name,
            value: priority.id
        }
    }) : []


    const categoryOptions = categories.categories ? categories.categories.map(category => {
        return {
            label: category.name,
            value: category.id
        }
    }) : []
    const deviceOptions = buses.bus ? buses.bus.devices.map(device => {
        return {
            label: `${device.itsDeviceType.name} - ${device.deviceImei}`,
            value: device.id
        }
    }) : []
    const damageOptions = filteredIssues.length !== 0 ? filteredIssues.map(damage => {
        return {
            label: damage.name,
            value: damage.id
        }
    }) : []

    const routeOptions = theRoutes ? theRoutes.map(route => {
        return {
            label: route.name,
            value: route.id
        }
    }) : []
    const busOptions = institutions.institution.buses ? institutions.institution.buses.map(bus => {
        return {
            label: bus.plateNumber,
            value: bus.id
        }
    }) : []

    const agentOptions = agents ? agents.map(agent => {
        return {
            label: agent.name,
            value: agent.id
        }
    }) : []
    const superAgentOptions = superAgents ? superAgents.map(agent => {
        return {
            label: agent.name,
            value: agent.name
        }
    }) : []

    const userOptions = []
    users.users ? users.users.map(user => {
        return userOptions.push(user.name)
    }) : userOptions.push()


    const institutionOptions = projects.project ? projects.project.institutions.map(institution => {
        return {
            label: institution.name,
            value: institution.id
        }
    }) : []
    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }
    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }

    return (
        <div>
            <Header
                props={props}
                name='Create Incident'
            />
            <React.Fragment>
                {incidents.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <IncNavLinks
                            hideSubmitIncident
                        />
                        <Grid>
                            <Snackbar
                                open={incidents.snackBarMessage.open}
                                onClose={closeSnackBarTimer}
                                autoHideDuration={4000}
                                TransitionComponent={TransitionUp}
                            >
                                <MuiAlert
                                    severity={incidents.snackBarMessage.severity}
                                    variant='filled'
                                    elevation={6}
                                >{incidents.snackBarMessage.message}</MuiAlert>
                            </Snackbar>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                validateOnBlur={false}
                                validateOnChange
                                onSubmit={values => {
                                    dispatch(createIncindent(values, uploadimage.images, props))
                                }}
                            >
                                {({ values, errors, touched, setFieldValue }) => {
                                    return (
                                        <Form>
                                            <Autocomplete
                                                className={classes.forminput}
                                                id="combo-box-demo"
                                                options={projectOptions}
                                                sx={{ width: '100%' }}
                                                value={projectOptions.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("projectId", newValue ? newValue.value : 0)
                                                    setProjectId(newValue ? newValue.value : 0)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Project *"
                                                    error={touched.projectId && errors.projectId}
                                                    helperText={touched.projectId && errors.projectId}
                                                />}
                                            />

                                            <Autocomplete
                                                className={classes.forminput}
                                                id="combo-box-demo"
                                                options={institutionOptions}
                                                sx={{ width: '100%' }}
                                                value={institutionOptions.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("institutionId", newValue ? newValue.value : 0)
                                                    setInstitutionId(newValue ? newValue.value : 0)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label="Bus Company *"
                                                    variant="outlined"
                                                    error={touched.institutionId && errors.institutionId}
                                                    helperText={touched.institutionId && errors.institutionId}
                                                />}
                                            />
                                            <Field
                                                className={classes.forminput}
                                                name='location'
                                                label='Location *'
                                                margin='normal'
                                                variant="outlined"
                                                as={TextField}
                                                error={touched.location && errors.location}
                                                helperText={touched.location && errors.location}
                                                fullWidth
                                            />
                                            <Autocomplete
                                                className={classes.forminput}
                                                id="combo-box-demo"
                                                options={categoryOptions}
                                                sx={{ width: '100%' }}
                                                value={categoryOptions.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("categoryId", newValue ? newValue.value : 0)
                                                    setSelectedCategory(newValue ? newValue.label : "")
                                                    setCategoryId(newValue ? newValue.value : 0)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Category"
                                                />}
                                            />
                                            <Autocomplete
                                                className={classes.forminput}
                                                id="combo-box-demo"
                                                options={damageOptions}
                                                sx={{ width: '100%' }}
                                                value={damageOptions.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("damageId", newValue ? newValue.value : 0)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Issue"
                                                />}
                                            />

                                            <FormControl
                                                component="fieldset"
                                                className={classes.forminput}
                                            >
                                                <FormLabel component="legend">Is this a bus-related incident?</FormLabel>
                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="controlled-radio-buttons-group"
                                                    value={isBusIncident}
                                                    onChange={handleBusRelated}
                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                >
                                                    <FormControlLabel value='yes' control={<Radio />} label="Yes" />
                                                    <FormControlLabel value='no' control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>

                                            {isBusIncident === 'yes' && (
                                                <span>
                                                    <Autocomplete
                                                        className={classes.forminput}
                                                        freeSolo
                                                        id="combo-box-demo"
                                                        options={routeOptions}
                                                        sx={{ width: '100%' }}
                                                        value={routeOptions.value}
                                                        onChange={(event, newValue) => {
                                                            typeof newValue === 'string' ? setFieldValue('routeName', newValue) : setFieldValue("routeId", newValue.value) && setStrictToRoute(true)
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Route"
                                                            error={touched.routeId && errors.routeId}
                                                            helperText={touched.routeId && errors.routeId}

                                                        />}
                                                    />

                                                    <Autocomplete
                                                        className={classes.forminput}
                                                        id="combo-box-demo"
                                                        freeSolo
                                                        options={busOptions}
                                                        sx={{ width: '100%' }}
                                                        value={busOptions.value}
                                                        onChange={(event, newValue) => {
                                                            typeof newValue === 'string' ? setFieldValue('busPlate', newValue) : setBus(newValue.value) && setFieldValue("busId", newValue.value) && setStrictToBus(true)
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Plate Number"
                                                            error={touched.busId && errors.busId}
                                                            helperText={touched.busId && errors.busId}
                                                        />}
                                                    />

                                                </span>
                                            )
                                            }
                                            {removeSpace(selectedCategory) == 'equipments' && (
                                                <div>
                                                    <Autocomplete
                                                        className={classes.forminput}
                                                        id="combo-box-demo"
                                                        options={deviceOptions}
                                                        sx={{ width: '100%' }}
                                                        value={deviceOptions.value}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue("deviceId", newValue ? newValue.value : 0)
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Device"
                                                            error={touched.deviceId && errors.deviceId}
                                                            helperText={touched.deviceId && errors.deviceId}
                                                        />}
                                                    />
                                                </div>
                                            )}


                                            <Autocomplete
                                                className={classes.forminput}
                                                id="combo-box-demo"
                                                options={priorityOptions}
                                                sx={{ width: '100%' }}
                                                value={priorityOptions.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("priorityId", newValue ? newValue.value : 0)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Priority"
                                                />}
                                            />
                                            <Autocomplete
                                                className={classes.forminput}
                                                freeSolo
                                                autoSelect
                                                id="combo-box-demo"
                                                options={agentOptions}
                                                sx={{ width: '100%' }}
                                                value={agentOptions.value}
                                                onChange={(event, newValue) => {
                                                    typeof newValue === 'string' ? setFieldValue('agentName', newValue) : setFieldValue("agent", newValue.value)
                                                    newValue.value ? setCustomAgent(false) : setCustomAgent(true)
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Agent name"
                                                />}
                                            />
                                            <Field
                                                className={classes.forminput}
                                                name='agentUsername'
                                                label='Agent username'
                                                margin='normal'
                                                as={TextField}
                                                aria-label="minimum height"
                                                variant="outlined"
                                                rowsMax={8}
                                                rows={4}
                                                error={touched.agentUsername && errors.agentUsername}
                                                helperText={touched.agentUsername && errors.agentUsername}
                                                fullWidth
                                            />

                                            {
                                                customAgent && (
                                                    <div>
                                                        <Autocomplete
                                                            className={classes.forminput}
                                                            id="combo-box-demo"
                                                            options={superAgentOptions}
                                                            sx={{ width: '100%' }}
                                                            value={superAgentOptions.value}
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("superAgentName", newValue.value)
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Super Agent"
                                                                error={touched.superAgentName && errors.superAgentName}
                                                                helperText={touched.superAgentName && errors.superAgentName}

                                                            />}
                                                        />
                                                    </div>
                                                )
                                            }

                                            {removeSpace(selectedCategory) == 'financial' && (
                                                <div>
                                                    <Autocomplete
                                                        className={classes.forminput}
                                                        id="combo-box-demo"
                                                        options={["Refund of MoMo issues", "Refund of Ticket cancelled", "Refund of Tap&Go e-value"]}
                                                        sx={{ width: '100%' }}
                                                        value={values.requestType}
                                                        onChange={(event, newValue) => {
                                                            setTypeOfRequest(newValue)
                                                            setFieldValue("requestType", newValue)
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Type of Request *"
                                                            name="requestType"
                                                            error={touched.requestType && errors.requestType}
                                                            helperText={touched.requestType && errors.requestType}
                                                        />}
                                                    />
                                                    {removeSpace(typeOfRequest) == 'refundofmomoissues' && (
                                                        <Field
                                                            className={classes.forminput}
                                                            name='momoTransactionId'
                                                            label='Momo Transaction Id'
                                                            margin='normal'
                                                            as={TextField}
                                                            aria-label="minimum height"
                                                            variant="outlined"
                                                            rowsMax={8}
                                                            rows={4}
                                                            error={touched.momoTransactionId && errors.momoTransactionId}
                                                            helperText={touched.momoTransactionId && errors.momoTransactionId}
                                                            fullWidth
                                                        />
                                                    )}
                                                    <Field
                                                        className={classes.forminput}
                                                        name='busTicketId'
                                                        label='Ticket Id'
                                                        margin='normal'
                                                        as={TextField}
                                                        aria-label="minimum height"
                                                        variant="outlined"
                                                        error={touched.busTicketId && errors.busTicketId}
                                                        helperText={touched.busTicketId && errors.busTicketId}
                                                        fullWidth
                                                    />

                                                    <Field
                                                        className={classes.forminput}
                                                        name='requestAmount'
                                                        label='Request Amount (Rwf) *'
                                                        margin='normal'
                                                        as={TextField}
                                                        aria-label="minimum height"
                                                        variant="outlined"
                                                        rowsMax={8}
                                                        rows={4}
                                                        error={touched.requestAmount && errors.requestAmount}
                                                        helperText={touched.requestAmount && errors.requestAmount}
                                                        fullWidth
                                                    />
                                                    <Field
                                                        className={classes.forminput}
                                                        name='customerName'
                                                        label='Customer Name'
                                                        margin='normal'
                                                        as={TextField}
                                                        aria-label="minimum height"
                                                        variant="outlined"
                                                        rowsMax={8}
                                                        rows={4}
                                                        error={touched.customerName && errors.customerName}
                                                        helperText={touched.customerName && errors.customerName}
                                                        fullWidth
                                                    />
                                                    <Field
                                                        className={classes.forminput}
                                                        name='customerPhone'
                                                        label='Customer Phone'
                                                        margin='normal'
                                                        as={TextField}
                                                        inputProps={{ maxLength: 10 }}
                                                        aria-label="minimum height"
                                                        variant="outlined"
                                                        rowsMax={8}
                                                        rows={4}
                                                        error={touched.customerPhone && errors.customerPhone}
                                                        helperText={touched.customerPhone && errors.customerPhone}
                                                        fullWidth
                                                    />
                                                    <Field
                                                        className={classes.forminput}
                                                        name='doneBy'
                                                        label={`Done by AC Group & Bus Company`}
                                                        margin='normal'
                                                        as={TextField}
                                                        aria-label="minimum height"
                                                        variant="outlined"
                                                        rowsMax={8}
                                                        rows={4}
                                                        error={touched.doneBy && errors.doneBy}
                                                        helperText={touched.doneBy && errors.doneBy}
                                                        fullWidth
                                                    />
                                                </div>
                                            )}
                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                name='occuranceTime'
                                                id="datetime-local"
                                                fullWidth
                                                label="Time of incident"
                                                type="datetime-local"
                                                className={classes.bottomMargin}
                                                error={touched.occuranceTime && errors.occuranceTime}
                                                helperText={touched.occuranceTime && errors.occuranceTime}
                                            />
                                            <Field
                                                className={classes.forminput}
                                                name='description'
                                                label='Description *'
                                                margin='normal'
                                                as={TextField}
                                                aria-label="minimum height"
                                                variant="outlined"
                                                rowsMax={8}
                                                rows={4}
                                                error={touched.description && errors.description}
                                                helperText={touched.description && errors.description}
                                                fullWidth
                                            />

                                            <Grid container justify="center" className={classes.forminput}>
                                                <Grid item>
                                                    <FieldArray name="images">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                <div>
                                                                    {values.images.map((_, index) => (
                                                                        <Grid container justify="center" item className={classes.forminput}>
                                                                            {
                                                                                typeof uploadimage.images[index] !== 'undefined' && uploadimage.images[index] !== "" ?
                                                                                    <div>
                                                                                        <img src={uploadimage.images[index]} style={{ width: "35%", height: "30%" }} />

                                                                                    </div>
                                                                                    : <img src={typeof photo[index] !== 'undefined' ? photo[index] : " "} style={{ width: "35%", height: "30%" }} />
                                                                            }
                                                                            <Grid item>
                                                                                <Field
                                                                                    name={`images[${index}]`}
                                                                                    id="file"
                                                                                    as='input'
                                                                                    type="file"
                                                                                    accept="image/*"
                                                                                    hidden={true}
                                                                                    onChange={async (e) => {
                                                                                        const formData = new FormData()
                                                                                        formData.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET)
                                                                                        formData.append('file', e.target.files[0])
                                                                                        setPhotos((photos) => [...photos, formData])
                                                                                        setPhoto(arr => [...arr, URL.createObjectURL(e.target.files[0])])

                                                                                    }
                                                                                    }
                                                                                />
                                                                                {photo[index] !== null || uploadimage.images[index] ?
                                                                                    null :
                                                                                    <label htmlFor="file">
                                                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                                                            <PhotoCamera />
                                                                                        </IconButton>
                                                                                    </label>
                                                                                }
                                                                            </Grid>
                                                                            <Grid item>
                                                                                {uploadimage.images[index] ? null :
                                                                                    uploadimage.pending ?
                                                                                        (
                                                                                            <div className={classes.spinner}>
                                                                                                <CircularProgress />
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        <div>
                                                                                            <Button onClick={() => {
                                                                                                photo.splice(index, 1)
                                                                                                photos.splice(index, 1)
                                                                                                remove(index)
                                                                                            }}
                                                                                            >
                                                                                                <DeleteIcon color="secondary" onClick={() => {
                                                                                                    photo.splice(index, 1)
                                                                                                    photos.splice(index, 1)
                                                                                                    remove(index)
                                                                                                }} />
                                                                                            </Button>
                                                                                            {typeof photos[index] !== 'undefined' ?

                                                                                                <Button variant="contained" color="primary" id={index} onClick={(e) => {
                                                                                                    const index = e.currentTarget.id
                                                                                                    dispatch({
                                                                                                        type: types.UPLOAD_IMAGES_PENDING
                                                                                                    })
                                                                                                    fetch(process.env.REACT_APP_IMAGE_UPLOAD_LINK, { method: "POST", body: photos[index] })
                                                                                                        .then(link => link.json())
                                                                                                        .then(link => {
                                                                                                            const imagesLink = link.secure_url

                                                                                                            dispatch({
                                                                                                                type: types.UPLOAD_IMAGES_SUCCESS,
                                                                                                                link: imagesLink
                                                                                                            })
                                                                                                        }).catch(err => {
                                                                                                            dispatch({
                                                                                                                type: types.UPLOAD_IMAGES_FAIL,
                                                                                                                error: err.message
                                                                                                            })
                                                                                                        })
                                                                                                }}
                                                                                                >
                                                                                                    Upload
                                                                                                </Button>

                                                                                                : null}

                                                                                        </div>

                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}


                                                                    <Grid container justify="center" item className={classes.forminput}>
                                                                        <Fab color="primary" size="medium" component="span" onClick={() => push("")} >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Grid>
                                                                </div>
                                                            </React.Fragment>

                                                        )

                                                        }
                                                    </FieldArray>
                                                </Grid>

                                            </Grid>
                                            <Button
                                                type={"submit"}
                                                variant='contained'
                                                color='primary'
                                            >Submit</Button>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    </>}
            </React.Fragment>

        </div>
    )
}

export default SubmitIncident
