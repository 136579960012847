import React, { useEffect, useState } from 'react'
import { TextField, Typography, Button, Grid, Slide } from '@material-ui/core'
import { useStyles } from '../devices/useStyles'
import { useDispatch, useSelector } from "react-redux";
import {
    getBusMakes,
    createMake,
    deleteMake,
    updateMake,
} from '../../redux/actions/buses/busMakes';
import { clearSnackBar } from '../../redux/actions/devices/snack'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModel from '../templates/DeleteModel';
import { useLocation, useHistory } from "react-router-dom";
import RoleTable from '../templates/DisplayAll'
import EditModel from '../templates/EditModel'

const BusMakes = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const makeReducer = useSelector(state => state.buses);
    let location = useLocation();
    const history = useHistory();

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [update, setUpdate] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [id, setId] = useState('')

    useEffect(() => {
        dispatch(getBusMakes())
    }, [makeReducer.make, makeReducer.deleteMake, makeReducer.updateMake])

    let rows;
    makeReducer.busMakes ? rows = makeReducer.busMakes : rows = [];
    const columns = [
        {
            field: 'newId',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 2,
        },
        {
            field: "action",
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenEditModel}
                            id={`${params.id},${params.row.name},${params.row.description}`}
                        >
                            <EditIcon />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            id={`${params.id},${params.row.name}`} >
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Grid>
                </Grid >
            )
        }
    ];

    const handleAddMake = () => {
        const make = { name, description, makeFor: 'bus' }
        dispatch(createMake(make))
        setName('')
        setDescription('')
    }

    const handleUpdate = () => {
        dispatch(updateMake(id, { name, description }))
        handleClodeEditModel()
    }

    const handleOpenEditModel = (e) => {
        const values = e.currentTarget.id.split(",")
        setId(values[0])
        setName(values[1])
        setDescription(values[2])
        setUpdate(true)
    }
    const handleOpenDeleteModel = (e) => {
        const values = e.currentTarget.id.split(",")
        setId(values[0])
        setDeleted(true)
    }
    const handleClodeEditModel = (e) => {
        setUpdate(false)
        setName('')
        setDescription('')
    }

    const handleCloseDeleteModel = () => setDeleted(false)

    const handleDelete = () => {
        dispatch(deleteMake(id))
        handleCloseDeleteModel()
    }

    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }

    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }

    rows.forEach((row, index) => {
        row.newId = index + 1
    })

    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteMake(id)))
    }
    return (
        <div className={classes.contain}>
            <RoleTable
                cTitle="Bus Makes"
                addBtnTitle="Add Bus Make"
                rows={rows}
                columns={columns}
                cleaner={closeSnackBarTimer}
                reducer={makeReducer}
                handleCreate={handleAddMake}
                iMakes={true}
                showImport={false}
                multipleDeleteHandler={multipleDeleteHandler}
            >
                <div className={classes.addForm} style={{ padding: '20px', boxSizing: 'border-box' }}>
                    <Typography variant="h5">Add a new bus make</Typography>
                    <TextField style={{ width: '100%', marginBottom: '20px' }} label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField style={{ width: '100%', marginBottom: '20px' }} label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </RoleTable>
            <EditModel
                title="Edit bus make"
                isEditModelOpen={update}
                closeEditDialog={handleClodeEditModel}
                handleEdit={handleUpdate}
                classes={classes}
            >
                <div className={classes.addForm} style={{ padding: '20px', boxSizing: 'border-box' }}>
                    <TextField style={{ width: '100%', marginBottom: '20px' }} label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField style={{ width: '100%', marginBottom: '20px' }} label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </EditModel>
            <DeleteModel
                title={"Delete bus make"}
                description={"Are you sure you want to delete this make?"}
                isDeleteModelOpen={deleted}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
                btnText={"Delete make"}
            />
        </div>
    )
}

export default BusMakes