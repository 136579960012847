import React from "react"
import { useDispatch } from "react-redux";
import {
  Slide,
  Snackbar
} from "@material-ui/core"

import MuiAlert from '@material-ui/lab/Alert';


function CustomMessage({ reducer, cleaner }) {
  const dispatch = useDispatch()
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  }

  const closeSnackBarTimer = () => {
    dispatch(cleaner())
  }

  // console.log(reducer ? reducer.snackBarMessage.message : "")

  return <Snackbar
    open={reducer ? reducer.snackBarMessage.open : false}
    onClose={closeSnackBarTimer}
    autoHideDuration={4000}
    TransitionComponent={TransitionUp}
  >
    <MuiAlert
      severity={reducer ? reducer.snackBarMessage.severity : ""}
      variant='filled'
      elevation={6}
    >{reducer ? reducer.snackBarMessage.message : ""}</MuiAlert>
  </Snackbar>
}

export default CustomMessage