import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    makeStyles,
    CircularProgress,
    Typography,
    Button,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import 'react-image-lightbox/style.css';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';


import {
    getUserIncident,
    getIncidentManager,
    getIncidentAdmin,
    acceptIncident,
    rejectIncident,
    resolveIncident,
    clearSnackBar
} from "../../redux/actions/incidents/incidentActions"
import CustomMessage from "../templates/CustomMessages";
import { getStatuses } from "../../redux/actions/status/statusAction"
import { getPriorities } from '../../redux/actions/priority/prioritiesActions'
import { getDamages } from '../../redux/actions/damages/damages'
import { getCategories } from '../../redux/actions/categories/categoriesActions'
import { checkLogin } from "../../redux/actions/auth/login"
import Top from "../templates/TopPartOfPageWithId"
import color from "../colors"
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    contain: {
        position: "relative",
        width: "100%",
        marginBottom: "2px",
        borderRadius: "3px"
    },
    btncontainer: {
        marginBottom: theme.spacing(2.5)
    },
    btn: {
        height: theme.spacing(3.5),

    },
    submitbtn: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2)

    },
    acceptbtn: {
        backgroundColor: `${color.success}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.success}`,

        }

    },
    rejectbtn: {
        backgroundColor: `${color.yellow}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.yellow}`,

        }

    },
    requestbtn: {
        backgroundColor: `${color.mix}`,
        color: "white",
        height: theme.spacing(3.5),
        "&:hover": {
            backgroundColor: `${color.mix}`,

        }

    },
}))

function SingleRequest(props) {
    const dispatch = useDispatch();
    const incidents = useSelector(state => state.incidents)
    const auth = useSelector(state => state.login)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        dispatch(checkLogin());
        dispatch(getStatuses());
        dispatch(getCategories())
        dispatch(getDamages())
        dispatch(getPriorities());
    }, [])
    useEffect(() => {
        if (auth.user) {
            if (auth.user.role.Permissions.manageIncident) {
                dispatch(getIncidentAdmin(props.match.params.id));
            } else if (auth.user.role.Permissions.institutionManager) {
                dispatch(getIncidentManager(props.match.params.id));
            }
            else {

                dispatch(getUserIncident(props.match.params.id));
            }
        }
    }, [incidents.changed, auth.user])

    const classes = useStyles();


    console.log(incidents.incident)

    return (
        <div className={classes.contain}>
            <Top props={props} name="Request Form" />
            <CustomMessage reducer={incidents} cleaner={clearSnackBar} />
            <React.Fragment>
                {incidents.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Grid
                            ref={componentRef}
                            style={{
                                paddingLeft: '0.5em'
                            }}
                        >
                            <h1 style={{
                                color: color.primary100,
                                fontSize: '1.1em',
                                textAlign: 'center'
                            }}>Incident Report Form</h1>
                            <div style={{ marginBottom: '3em' }}>
                                <Typography><b>Date and Time of Incident:</b>  {moment(incidents.incident.createdAt).format('MMMM D, YYYY h:mm a')}</Typography>
                                {incidents.incident.device && <Typography><b>Device Name: </b>  {incidents.incident.device.name}</Typography>}
                                {incidents.incident.device && <Typography><b>Device IMEI: </b>  {incidents.incident.device.deviceImei}</Typography>}
                                {incidents.incident.institution && <Typography><b>Institution: </b>  {incidents.incident.institution.name}</Typography>}
                                {incidents.incident.category && <Typography><b>Issue Category: </b>  {incidents.incident.category.name}</Typography>}
                                {incidents.incident.damage && <Typography><b>Issue: </b>  {incidents.incident.damage.name}</Typography>}
                                <Typography><b>Description: </b>  {incidents.incident.description}</Typography>
                                {incidents.incident.agent && <Typography><b>POS Owner/Agent Name:</b> {incidents.incident.agent.name}</Typography>}

                            </div>
                            <div style={{
                                marginBottom: '2em'

                            }}>
                                <h2
                                    style={{
                                        fontSize: '0.9em',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Names and Signatures
                                </h2>
                                <Typography>AC Group Supervisor:</Typography>
                                <Typography>AC Group Technician:</Typography>
                                <Typography>Bus Operator Representative:</Typography>
                                <Typography>AC Group IT Department Representative:</Typography>
                            </div>
                        </Grid>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handlePrint}
                        >Print</Button>
                    </>

                }
            </React.Fragment>
        </div>
    )
}

export default SingleRequest