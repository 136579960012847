import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
import API from "../../../utils/axios/axios";
import { authRedirect } from "../../../utils/redirects";

const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const getRoles = () => dispatch => {

  dispatch({
    type: types.GET_ROLES_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/roles`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      const roles = res.data.roles.sort((a, b) => a.id - b.id)
      dispatch({
        type: types.GET_ROLES,
        roles: roles,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ROLES_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const createRole = (name, description) => dispatch => {

  dispatch({
    type: types.GET_ROLES_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/roles/create`,
    {
      name, description
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.CREATE_ROLE,
        role: res.data.role,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ROLES_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const uploadRoles = (roles) => dispatch => {

  dispatch({
    type: types.GET_ROLES_PENDING
  })
  roles.forEach(role => {
    delete role.id
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/roles/upload`,
    {
      roles
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPLOAD_ROLES,
        roles: res.data.role,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ROLES_ERROR,
        error: err,
        message: err.response ? err.response.data.error.errors[0].message || err.response.data.error : err.message
        // message: err.response ? err.response.data.error : err.message
      })
    })
}

export const deleteRole = (id) => dispatch => {

  dispatch({
    type: types.GET_ROLES_PENDING
  })
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/roles/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.DELETE_ROLE,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ROLES_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const updateRole = (id, name, description) => dispatch => {

  dispatch({
    type: types.GET_ROLES_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/roles/${id}`,
    {
      name, description
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPDATE_ROLE,
        message: res.data.message,
        role: res.data.newRole
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ROLES_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_SNACKBAR
  })
}
