import { types } from "../../actions/types";

const initialState = {
  incidents: null,
  incident: {},
  pending: false,
  changed: false,
  error: false,
  formData: {},
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INCIDENT_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_INCIDENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        incidents: action.incidents,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };

    case types.CREATE_INCIDENT_SUCCESS:
      return {
        ...state,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.incident,
        formData: {
          location: action.incident.location,
          route: action.incident.route ? action.incident.route.name : "",
          ticket: action.incident.ticketId,
          description: action.incident.description,
          project: action.incident.project.name,
          repaired: action.incident.repaired,
          refunded: action.incident.refunded,
          replaced: action.incident.replaced,
          actionRequired: action.incident.actionRequired,
          institution: action.incident.institution.name,
          bus: action.incident.bus ? action.incident.bus.plateNumber : "",
          device: action.incident.device ? action.incident.device.name : "",
          deviceImei: action.incident.device ? action.incident.device.deviceImei : "",
          damageId: action.incident && action.incident.damage ? action.incident.damage.id : [],
          category: action.incident && action.incident.category ? action.incident.category.name : "",
          priorityId: action.incident && action.incident.priority ? action.incident.priority.id : [],
          statusId: action.incident && action.incident.status ? action.incident.status.id : [],
          momoTransactionId: action.incident && action.incident.momoTransactionId ? action.incident.momoTransactionId : "",
          busTicketId: action.incident && action.incident.busTicketId ? action.incident.busTicketId : "",
          requestAmount: action.incident && action.incident.requestAmount ? action.incident.requestAmount : ""
        },
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.GET_INCIDENT_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.FORM_DATA:
      return {
        ...state,
        formData: action.values
      }
    case types.UPDATE_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true
      }
    case types.REFUND_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true
      }
    case types.ACCEPT_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.REJECT_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.ASSIGN_INCIDENT_TO_USER:
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.CONVERT_TO_REQUEST:
      // console.log("Basically, the action is:", action.incident)
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.CLOSE_INCIDENT_SUCCESS:
      // console.log(action.incident)
      return {
        ...state,
        incident: action.incident,
        changed: state.changed ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
