import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { useDispatch } from 'react-redux'
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "./App.scss";
// import { checkAuthedUser } from './redux/actions/auth/login'
import AdminDashboard from "./components/dashboard/pages/AdminDashboard";
// import Requests from './components/dashboard/pages/Requests';
import Requests from "./components/requests/requests";
import SingleRequest from "./components/requests/singleRequest";
import Groups from "./components/dashboard/pages/Groups";
import Users from "./components/users/usersMain";
import Navigation from "./components/Navigation";
import Home from "./components/landing/home";
import Devices from "./components/devices/index";
import Roles from "./components/roles/roles";
import SingleRole from "./components/roles/singleRole";
import PrivateRoute from "./components/PrivateRoute";
import Locations from "./components/locations/locations";
import SingleLocation from "./components/locations/singleLocation";
import Institutions from "./components/institutions/institutions";
import SingleInstitution from "./components/institutions/singleInstitution";
import OneUser from "./components/users/oneUser";
import SingleDevice from "./components/devices/singleDevice";
import DeviceTypes from "./components/devices/deviceTypes";
import Buses from "./components/buses/buses";
import SingleBus from "./components/buses/singleBus";
import Issues from "./components/issues/issues";
import SingleIssue from "./components/issues/singleIssue";
import Projects from "./components/projects/projects";
import SingleProject from "./components/projects/SingleProject";
import Sites from "./components/sites/sites";
import SingleSite from "./components/sites/singleSite";
// import IncidentForm from './components/incidents/incidentSubmission/form'
import Incidents from "./components/incidents/incidents";
import Incident from "./components/incidents/incident";
import ManageIncidents from "./components/incidents/manageIncidents";
import ErrorPage from "./components/ErrorPage";
import NotFound from "./components/NotFound";
import Profile from "./components/users/Profile";
import UnAuthorized from "./components/UnAuthorized";
import { theme } from "./theme";
import ResetPassword from "./components/users/ResetPassword";
import ResetSucess from "./components/users/ResetSuccess";
import Routes from "./components/routes/routes";
import SingleRoute from "./components/routes/singleRoute";
import CheckBoxTesting from "./components/institutions/newComponent";
import SubmitIncident from "./components/incidents/incidentSubmission/submitIncident";
import DeviceMakes from "./components/devices/deviceMake";
import BusMakes from "./components/buses/busMakes";
import DeviceReplacements from "./components/devices/deviceReplacements";
import Welcome from "./components/Welcome";
import Refunds from "./components/refunds/refunds";
import Reports from "./components/reports/reports";
import StockView from "./components/stock/StockView";
// import ManageStock from './components/stock/ManageStock';
// import MyStockDetails from './components/stock/MyStockDetails';
import ManageStockScreen from "./components/stock/ManageStockScreen";
import MyStockScreen from "./components/stock/MyStockScreen";
import RequestDetails from "./components/stock/RequestDetails";
import ApprovePayment from "./components/stock/ApprovePayment";
import ApproveCancel from "./components/stock/ApproveCancel";
// import Loan from './components/incidents/incidentSubmission/loans/Loan';
import SuperagentRefund from "./components/incidents/incidentSubmission/refunds/SuperagentRefund";
import SingleSuperRefund from "./components/incidents/superagentRefund";
// import CommingSoon from './components/CommingSoon';

const App = () => {
  // const dispatch = useDispatch()
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "100b8f1d-7966-45ad-b091-85f5168493f9";
    (function () {
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);
  const errorHandler = (error, errorInfo) => {
    // return
    // console.log(error, errorInfo)
  };

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorPage} onError={errorHandler}>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Switch>
                <Route exact path="/" component={Home} />
                <PrivateRoute exact path="/welcome" component={Welcome} />
                <Navigation>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={AdminDashboard}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute
                      exact
                      path="/test"
                      component={CheckBoxTesting}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/incidents"
                      component={Incidents}
                    />
                    <PrivateRoute
                      exact
                      tag="loan"
                      path="/dashboard/request/loan"
                      component={SuperagentRefund}
                    />
                    <PrivateRoute
                      exact
                      tag="refund"
                      path="/dashboard/superagent/refund"
                      component={SuperagentRefund}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/groups"
                      component={Groups}
                      userHasPermission={"manageGroups"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/requests"
                      component={Requests}
                      userHasPermission={"manageIncident"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/requests/:id"
                      component={SingleRequest}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/refunds"
                      component={Refunds}
                      userHasPermission={"manageIncident"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/reports"
                      component={Reports}
                      userHasPermission={"manageIncident"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/locations"
                      component={Locations}
                      userHasPermission={"manageLocation"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/location/:id"
                      component={SingleLocation}
                      userHasPermission={"manageLocation"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/projects"
                      component={Projects}
                      userHasPermission={"manageProjects"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/projects/:id"
                      component={SingleProject}
                      userHasPermission={"manageProjects"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/roles"
                      component={Roles}
                      userHasPermission={"manageRoles"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/role/:id"
                      component={SingleRole}
                      userHasPermission={"manageRoles"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/institutions"
                      component={Institutions}
                      userHasPermission={"manageInstitution"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/institution/:id"
                      component={SingleInstitution}
                      userHasPermission={"manageInstitution"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/buses"
                      component={Buses}
                      userHasPermission={"manageBus"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/buses/:id"
                      component={SingleBus}
                      userHasPermission={"manageBus"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/issues"
                      component={Issues}
                      userHasPermission={"manageDamage"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/issues/:id"
                      component={SingleIssue}
                      userHasPermission={"manageDamage"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/sites"
                      component={Sites}
                      userHasPermission={"manageSite"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/sites/:id"
                      component={SingleSite}
                      userHasPermission={"manageSite"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/newincident"
                      component={SubmitIncident}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/incident/:id"
                      component={Incident}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/superagent/refund/:id"
                      component={SingleSuperRefund}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/manageIncidents"
                      component={ManageIncidents}
                      userHasPermission={"manageIncident"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/devices"
                      component={Devices}
                      userHasPermission={"viewDevices"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/devices/:id"
                      component={SingleDevice}
                      userHasPermission={"viewDevice"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/types/manage"
                      component={DeviceTypes}
                      userHasPermission={"manageDivice"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/devices/makes/manage"
                      component={DeviceMakes}
                      userHasPermission={"manageDivice"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/buses/makes/manage"
                      component={BusMakes}
                      userHasPermission={"manageDivice"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/exchange/devices/replacements"
                      component={DeviceReplacements}
                      userHasPermission={"manageDivice"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/users"
                      component={Users}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/users/:id"
                      component={OneUser}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/routes"
                      component={Routes}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/routes/:id"
                      component={SingleRoute}
                      userHasPermission={"manageUser"}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/stock" component={StockView} />
                    <PrivateRoute
                      exact
                      path="/stock/manage/:id"
                      component={ManageStockScreen}
                    />
                    <PrivateRoute
                      exact
                      path="/stock/details/:id"
                      component={MyStockScreen}
                    />
                    <PrivateRoute
                      exact
                      path="/stock/requests/:id"
                      component={RequestDetails}
                    />
                    <PrivateRoute
                      exact
                      path="/stock/payments/approve/:token/:id"
                      component={ApprovePayment}
                    />
                    <PrivateRoute
                      exact
                      path="/stock/requests/cancel/:token"
                      component={ApproveCancel}
                    />
                    <PrivateRoute
                      exact
                      path="/password/reset/:token/:id/:email"
                      component={ResetPassword}
                    />
                    <PrivateRoute
                      exact
                      path="/reset/success"
                      component={ResetSucess}
                    />
                    <Route exact path="/error" component={UnAuthorized} />
                    <Route component={NotFound} />
                  </Switch>
                </Navigation>
              </Switch>
            </LocalizationProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    </Router>
  );
};

export default App;
