import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
const cookies = new Cookie()

const token = cookies.get('x-auth-token')

export const getDamages = () => dispatch => {
    dispatch({
        type: types.DAMAGES_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/damages`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sortedDamages = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_DAMAGES_SUCCESS,
                damages: sortedDamages,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}

export const getDamage = (id) => dispatch => {

    dispatch({
        type: types.DAMAGES_PENDING

    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/damages/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.GET_DAMAGE_SUCCESS,
                damage: res.data.damage,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}


export const createDamage = (name, description, projectIds, categoryId) => dispatch => {

    dispatch({
        type: types.DAMAGES_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/damages`,
        {
            name, description, projectIds, categoryId
        },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.CREATE_DAMAGE,
                project: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"

            })
        })
}

export const uploadDamages = (damages) => dispatch => {

    dispatch({
        type: types.DAMAGES_PENDING
    })
    damages.forEach(data => {
        delete data.id
    })
    damages.forEach(async (damage) => {
        // damage.projectId = damage.projectid
        const stringfiedIds = String(damage.projectids)
        damage.projectIds = stringfiedIds.split(",")
        damage.categoryId = damage.categoryid
        // damage.projectIds = damage.projectids.split(",")

        // site.locationId = site.locationid
        await delete damage.projectid
        await delete damage.categoryid
        // await delete site.locationid
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/damages/upload`,
        { damages },
        {
            headers: {
                "x-auth-token": token
            }
        }
    )
        .then(res => {
            dispatch({
                type: types.UPLOAD_DAMAGES,
                damages: res.data.damages,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Validation error occured"
            })
        })
}



export const deleteDamage = (id) => dispatch => {

    dispatch({
        type: types.DAMAGES_PENDING
    })
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/damages/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.DELETE_DAMAGE,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}

export const updateDamage = (id, name, description, categoryId) => dispatch => {

    dispatch({
        type: types.DAMAGES_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/damages/${id}`,
        {
            name, description, categoryId
        },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.UPDATE_DAMAGE,
                message: res.data.message,
                damage: res.data.newDamage
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_DAMAGES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}
export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}

