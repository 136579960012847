import { types } from "../../actions/types";

const initialState = {
  message: null,
  permissions: {},
  allowedpermissions: {},
  unAllowedPermissions: {},
  newPremissions: {},
  pending: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PERMISSIONS_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_ROLE_PERMISSIONS:
      let permissions = {};
      let unpermissions = {};
      const populatePermissions = () => {
        for (let permission in action.role.Permissions) {
          action.role.Permissions[permission] ? permissions[permission] = action.role.Permissions[permission] : unpermissions[permission] = action.role.Permissions[permission]
        }
      }
      populatePermissions()
      return {
        ...state,
        permissions: action.role,
        allowedpermissions: permissions,
        unAllowedPermissions: unpermissions,
        message: action.message,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // },
        pending: false
      }

    case types.CHANGE_PERMISSIONS:
      const copy = () => {
        let perm = {};
        for (const property in state.allowedpermissions) {
          if (property === action.property) {
            perm[property] = action.value
          } else {
            perm[property] = state.allowedpermissions[property]

          }
        }
        return perm;
      }

      return {
        ...state,
        allowedpermissions: copy()
      }
    case types.CHANGE_UNALLOWED_PERMISSIONS:
      const copys = () => {
        let perm = {};
        for (const property in state.unAllowedPermissions) {
          if (property === action.property) {
            perm[property] = action.value
          } else {
            perm[property] = state.unAllowedPermissions[property]

          }
        }
        return perm;
      }

      return {
        ...state,
        unAllowedPermissions: copys()
      }

    case types.SELECT_ALL_UNALLOWED_PERMISSIONS:
      const all = () => {
        let perm = {};
        for (const property in state.unAllowedPermissions) {
          perm[property] = action.value
        }
        return perm;
      }

      return {
        ...state,
        unAllowedPermissions: all()
      }

    case types.SELECT_ALL_ALLOWED_PERMISSIONS:
      const alls = () => {
        let perm = {};
        for (const property in state.allowedpermissions) {
          perm[property] = action.value
        }
        return perm;
      }

      return {
        ...state,
        allowedpermissions: alls()
      }

    case types.UPDATE_PERMISSIONS:
      return {
        ...state,
        newPremissions: action.role,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }

      }

    case types.GET_PERMISSIONS_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
