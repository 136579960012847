import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
    Avatar,
    Typography,
    TextField,
    Button,
    Snackbar,
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    FormHelperText
} from '@material-ui/core'
import {
    VisibilityOff,
    Visibility
} from '@material-ui/icons/';
import Cookie from 'universal-cookie'
import { useStyles } from './useStyles'
import { resetUserPassword } from '../../redux/actions/usersAction';

const ResetPassword = () => {
    const classes = useStyles()
    let history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const cookies = new Cookie()

    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [showPswd, setShowPswd] = useState(false)
    const [showConfPswd, setShowConfPswd] = useState(false)
    const [passErrorMessage, setPassErrorMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const general = location.pathname.split("/");

    console.log("The general URL is:", general)

    const userMail = general[5]

    const handleClickShowPassword = () => {
        setShowPswd(true)
    };

    const handleCloseVisibility = () => {
        setShowPswd(false)
    }
    const handleClickShowPasswordConf = () => {
        setShowConfPswd(true)
    };

    const handleCloseVisibilityConf = () => {
        setShowConfPswd(false)
    }
    const handleReset = async (e) => {
        const pass = {
            password: confPassword,
            email: userMail
        }
        if (password !== confPassword) {
            setPassErrorMessage("Password do not match!")
            setErrorMessage('')
        } else if (password === '') {
            setErrorMessage("Password can't be blank!")
            setPassErrorMessage('')
        } else {
            dispatch(resetUserPassword(general[4], pass))
            history.push('/reset/success')
        }

    }
    return (
        <div>
            <Dialog open={true}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.title}
                >Reset User Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={userMail}
                        type="text"
                        label="Email"
                        disabled="true"
                        fullWidth
                    />
                    <FormControl className={classes.status}>
                        <InputLabel htmlFor="confirm-password">Password</InputLabel>
                        <Input
                            margin="dense"
                            id="confirm-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPswd ? 'text' : 'password'}
                            label="Password"
                            required={true}
                            autoComplete={false}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        {showPswd ? <Visibility onClick={handleCloseVisibility} /> : <VisibilityOff onClick={handleClickShowPassword} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={errorMessage}
                            helperText={errorMessage}
                        />
                        <FormHelperText id="component-helper-text" style={{ color: "red" }}>
                            {errorMessage}
                        </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.status}>
                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                        <Input
                            margin="dense"
                            id="confirm-password"
                            value={confPassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                            type={showConfPswd ? 'text' : 'password'}
                            label="Comfirm Password"
                            required={true}
                            autoComplete={false}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        {
                                            showConfPswd ?
                                                <Visibility
                                                    onClick={handleCloseVisibilityConf}
                                                /> :
                                                <VisibilityOff
                                                    onClick={handleClickShowPasswordConf}
                                                />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={passErrorMessage}
                            helperText={passErrorMessage}
                        />
                        <FormHelperText id="component-helper-text" style={{ color: "red" }}>
                            {passErrorMessage}
                        </FormHelperText>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant='contained'
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button
                        color="secondary"
                        variant='contained'
                        onClick={(e) => history.push('/profile')}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ResetPassword
