import React, { useEffect, useState } from "react";
import { getDevices } from "../../redux/actions/devices/getDevices";
import {
  createDevice,
  uploadDevices,
} from "../../redux/actions/devices/createDevice";
import {
  getDeviceTypes,
  getOneType,
} from "../../redux/actions/devices/deviceTypes";
import { getDeviceMakes } from "../../redux/actions/devices/deviceMakes";
import { clearSnackBar } from "../../redux/actions/devices/snack";
import RoleTable from "../templates/DisplayAll";
import DeleteModel from "../templates/DeleteModel";
import {
  Grid,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useStyles } from "./useStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjects,
  getProject,
} from "../../redux/actions/projects/projectsAction";
import { deleteDevice } from "../../redux/actions/devices/deleteDevice";
import EditModel from "../templates/EditModel";
import { updateDevice } from "../../redux/actions/devices/updateDevice";
import { getBuses } from "../../redux/actions/buses/buses";
import { removeSpace } from "../../utils/removeSpaces";
import { getSites } from "../../redux/actions/sites/sites";
import { getProcurements } from "../../redux/actions/stock/stock";

const Devices = (props) => {
  const devices = useSelector((state) => state.devices);
  const stock = useSelector((state) => state.stock);
  const projects = useSelector((state) => state.projects);
  const buses = useSelector((state) => state.buses);
  const sitesState = useSelector((state) => state.sites);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [projectId, setProjectId] = useState();
  const [deviceDescription, setDeviceDescription] = useState("");
  const [deviceImei, setDeviceimei] = useState("");
  const [busId, setBusId] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [type, setType] = useState("");
  const [selectedDeviceType, setSelectedDeviceType] = useState();
  const [mtnSim, setMtnSim] = useState(null);
  const [airtelSim, setAirtelSim] = useState(null);
  const [fourGSim, setFourGSim] = useState(null);
  const [location, setLocation] = useState(null);
  const [posOnLocation, setPosOnLocation] = useState(null);
  const [makeId, setMakeId] = useState();
  const [price, setPrice] = useState();

  const [editingProjectId, setEditingProjectId] = useState();
  const [editingDeviceDescription, setEditingDeviceDescription] = useState();
  const [editingDeviceName, setEditingDeviceName] = useState();
  const [editingDeviceImei, setEditingDeviceimei] = useState();
  const [editingBusId, setEditingBusId] = useState();
  const [editingDeviceId, setEditingDeviceId] = useState();
  const [editingDeviceType, setEditingDeviceType] = useState();
  const [editingSelectedDeviceType, setEditingSelectedDeviceType] = useState();
  const [editingMtnSim, setEditingMtnSim] = useState();
  const [editingAirtelSim, setEditingAirtelSim] = useState();
  const [editingFourGSim, setEditingFourGSim] = useState();
  const [editingLocation, setEditingLocation] = useState();
  const [editingPosOnLocation, setEditingPosOnLocation] = useState();
  const [editingMakeId, setEditingMakeId] = useState();
  const [editingPrice, setEditingPrice] = useState();
  const [lpoNumber, setLpoNumber] = useState();
  const [editingLpoNumber, setEditingLpoNumber] = useState();
  const [editingData, setEditingData] = useState([]);

  const types = devices.deviceTypes;
  console.log("The sites state is:", sitesState);
  useEffect(() => {
    dispatch(getProjects());
    dispatch(getDevices());
    dispatch(getDeviceTypes());
    dispatch(getDeviceMakes());
    dispatch(getBuses());
    dispatch(getProcurements());
  }, [devices.deleted, devices.updated, devices.newDevice, devices.upload]);

  useEffect(() => {
    dispatch(getProject(projectId));
    dispatch(getSites());
  }, [projectId]);

  useEffect(() => {
    const thisDevice = devices.deviceTypes.filter(
      (devType) => devType.id == deviceType
    );
    if (thisDevice)
      setSelectedDeviceType(
        thisDevice.length !== 0 ? removeSpace(thisDevice[0].name) : ""
      );
    const editingDevice = devices.deviceTypes.filter(
      (devType) => devType.id == editingDeviceType
    );
    if (editingDevice)
      setEditingSelectedDeviceType(
        editingDevice.length !== 0 ? removeSpace(editingDevice[0].name) : ""
      );
  }, [deviceType, editingDeviceType]);

  const populateSelect = () => {
    const values = [];
    projects.projects.forEach((project, index) => {
      let value = (
        <MenuItem value={project.id} key={index}>
          {project.name}
        </MenuItem>
      );
      values.push(value);
    });
    return values;
  };
  const selectValues = projects.projects ? populateSelect() : null;

  const populateSelectOne = () => {
    const values = [];
    devices.deviceTypes.forEach((type, index) => {
      let value = (
        <MenuItem value={type.id} key={index}>
          {type.name}
        </MenuItem>
      );
      values.push(value);
    });
    return values;
  };

  const busOptions = buses.buses
    ? buses.buses.map((bus) => {
        return {
          plateNumber: bus.plateNumber,
          id: bus.id,
        };
      })
    : [];

  useEffect(() => {
    let id;
    devices.devices.forEach((device) => {
      id = device.id;
    });
    dispatch(getOneType(id));
  }, [devices.devices]);

  const columns = [
    {
      field: "newId",
      title: "ID",
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        width: "5%",
      },
      headerStyle: {
        backgroundColor: "#039be5",
      },
    },
    {
      field: "deviceImei",
      title: "IMEI",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company",
      title: "Company",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "location",
      title: "Location",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "creationDate",
      title: "Created At",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "registrar",
      title: "Registered By",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sims",
      title: "SIM Cards",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deviceTypeName",
      title: "Type",
    },
    {
      title: "Status",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.status ? rowData.status.color : "#fff",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <p>{rowData.statusName}</p>
        </div>
      ),
    },
    {
      field: "action",
      title: "Action",
      headerStyle: {
        textAlign: "right",
      },
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            whiteSpace: "nowrap",
          }}
        >
          <Grid item>
            <Button href={`/dashboard/devices/${rowData.id}`}>
              <VisibilityIcon color="primary" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleOpenEditModel(rowData)}
              id={`${rowData.id},${rowData.name},${rowData.description},${rowData.deviceType},${rowData.projectId},${rowData.deviceImei}`}
            >
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleOpenDeleteModel} id={`${rowData.id}`}>
              <DeleteIcon color="secondary" />
            </Button>
          </Grid>
        </div>
      ),
    },
  ];

  let rows;
  if (type === "") {
    rows = devices.devices;
  } else if (devices.devices.filter((device) => device.deviceType === type)) {
    rows = devices.devices.filter((device) => device.deviceType === type);
  } else if (devices.devices.filter((device) => device.deviceType === null)) {
    rows = devices.devices.filter((device) => device.deviceType === null);
  } else {
    rows = devices.devices;
  }

  rows.forEach((row, index) => {
    // console.log(row);

    row.newId = index + 1;
    row.statusName = row.status ? row.status.name : "No status";
    row.company = (row.busCompany && row.busCompany.name) || "Unassigned";
    row.creationDate = row.createdAt.split("T")[0] || "";
    row.registrar =
      (row.createdByUser && row.createdByUser.name) || "Unavailable";
    row.sims = `${row.mtnSim || ""} ${
      (row.airtelSim && row.mtnSim) || (row.mtnSim && row.fourGSim) ? "|" : ""
    } ${row.airtelSim || ""} ${row.fourGSim && row.airtelSim ? "|" : ""} ${
      row.fourGSim || ""
    }`;
  });

  rows.forEach((row) => {
    if (types) {
      types.forEach((type) => {
        if (type.id === row.deviceType) {
          row.deviceTypeName = type.name;
        }
      });
    }
  });

  const handleDescription = (e) => {
    setDeviceDescription(e.target.value);
  };
  const handleOpenDeleteModel = (e) => {
    const values = e.currentTarget.id.split(",");
    setDeviceId(values[0]);
    setOpenDelete(true);
  };
  const handleOpenEditModel = (data) => {
    setEditingData(data);
    setEditingDeviceId(data.id);
    setOpenEdit(true);
  };
  const handleCloseDeleteModel = () => setOpenDelete(false);
  const handleCloseEditModel = () => {
    setOpenEdit(false);
    setEditingProjectId("");
    setEditingDeviceName("");
    setEditingDeviceimei("");
    setEditingDeviceDescription("");
    setEditingMtnSim("");
    setEditingAirtelSim("");
    setEditingFourGSim("");
    setEditingBusId(null);
    setEditingLocation("");
    setEditingPosOnLocation(null);
  };
  const handleCreateDevice = () => {
    dispatch(
      createDevice({
        name: "name",
        description: deviceDescription,
        deviceType,
        projectId,
        mtnSim,
        airtelSim,
        fourGSim,
        location,
        posOnLocation,
        deviceImei,
        busId,
        makeId,
        price,
        lpoNumber,
      })
    );
    setProjectId(null);
    setDeviceimei(null);
    setDeviceType(null);
    setDeviceDescription(null);
    setBusId(null);
    // setPrice(0);
    setLocation(null);
  };

  const handleDelete = () => {
    dispatch(deleteDevice(deviceId));
    setOpenDelete(false);
  };

  const handleEditDevice = () => {
    const updatedData = {
      airtelSim: editingAirtelSim,
      projectId: editingProjectId,
      busId: editingBusId,
      description: editingDeviceDescription,
      deviceImei: editingDeviceImei,
      deviceName: editingDeviceName,
      deviceType: editingDeviceType,
      fourGSim: editingFourGSim,
      location: editingLocation,
      makeId: editingMakeId,
      mtnSim: editingMtnSim,
      posOnLocation: editingPosOnLocation,
      price: editingPrice,
      lpoNumber: editingLpoNumber,
    };
    dispatch(updateDevice(editingDeviceId, updatedData));
    handleCloseEditModel();
  };

  const projectOptions = projects.projects
    ? projects.projects.map((project) => {
        return {
          name: project.name,
          id: project.id,
        };
      })
    : [];
  const siteOptions = sitesState.sites
    ? sitesState.sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      })
    : [];
  const deviceTypeOptions = devices.deviceTypes
    ? devices.deviceTypes.map((dType) => {
        return {
          name: dType.name,
          id: dType.id,
        };
      })
    : [];

  // console.log("tge stock state is:", stock);
  const procurementOptions = stock.procurements
    ? stock.procurements.map((proc) => {
        return {
          name: proc.lpoNumber,
          id: proc.id,
        };
      })
    : [];

  console.log("The proc options:", procurementOptions);
  const deviceMakeOptions = devices.deviceMakes
    ? devices.deviceMakes.map((dMake) => {
        return {
          name: dMake.name,
          id: dMake.id,
        };
      })
    : [];
  const multipleDeleteHandler = (ids) => {
    ids.selectionModel.map((id) => dispatch(deleteDevice(id)));
  };
  return (
    <>
      <RoleTable
        cTitle={"Available Devices"}
        addBtnTitle="Add new device"
        rows={rows}
        columns={columns}
        reducer={devices}
        cleaner={clearSnackBar}
        pending={devices.pending}
        handleCreate={handleCreateDevice}
        uploader={uploadDevices}
        types={types}
        type={type}
        makes={true}
        replacements={true}
        setType={setType}
        multipleDeleteHandler={multipleDeleteHandler}
        usemTable
      >
        <DialogTitle id="form-dialog-title">Create New Device</DialogTitle>
        <DialogContent>
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            options={projectOptions}
            sx={{ width: "100%" }}
            value={projectOptions.id}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setProjectId(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project *" autoFocus />
            )}
          />
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            fullWidth
            options={procurementOptions}
            sx={{ width: "100%" }}
            value={procurementOptions.name}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setLpoNumber(newValue.name);
            }}
            renderInput={(params) => (
              <TextField {...params} label="LPO Number" />
            )}
          />
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            fullWidth
            options={deviceTypeOptions}
            sx={{ width: "100%" }}
            value={deviceTypeOptions.id}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setDeviceType(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Device Type *" />
            )}
          />
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            fullWidth
            options={deviceMakeOptions}
            sx={{ width: "100%" }}
            value={deviceMakeOptions.id}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setMakeId(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Device Make *" />
            )}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description *"
            type="text"
            value={deviceDescription}
            onChange={handleDescription}
            fullWidth
          />

          <TextField
            margin="dense"
            id="description"
            label="Device IMEI *"
            type="text"
            value={deviceImei}
            onChange={(e) => setDeviceimei(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="MTN SIM"
            type="text"
            value={mtnSim}
            onChange={(e) => setMtnSim(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Airtel SIM"
            type="text"
            value={airtelSim}
            onChange={(e) => setAirtelSim(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="4G SIM"
            type="text"
            value={fourGSim}
            onChange={(e) => setFourGSim(e.target.value)}
            fullWidth
          />
          {selectedDeviceType == "busvalidators" && (
            <Autocomplete
              className={classes.forminput}
              id="combo-box-demo"
              fullWidth
              options={busOptions}
              sx={{ width: "100%" }}
              value={busOptions.id}
              getOptionLabel={(option) => option.plateNumber}
              onChange={(event, newValue) => {
                setBusId(newValue.id);
              }}
              renderInput={(params) => <TextField {...params} label="Bus" />}
            />
          )}
          {/* <TextField
            margin="dense"
            label="Location"
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            fullWidth
          /> */}
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            freeSolo
            options={siteOptions}
            sx={{ width: "100%" }}
            value={siteOptions.value}
            onChange={(event, newValue) => {
              typeof newValue === "string"
                ? setLocation(newValue)
                : setLocation(newValue.label);
            }}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
          {selectedDeviceType == "pointofsale" && (
            <div>
              <TextField
                margin="dense"
                label="Number of POSs on this Location"
                type="text"
                value={posOnLocation}
                onChange={(e) => setPosOnLocation(e.target.value)}
                fullWidth
              />
            </div>
          )}
        </DialogContent>
      </RoleTable>

      <DeleteModel
        title={"Delete Device"}
        description={`Are you sure you want to delete this device?`}
        isDeleteModelOpen={openDelete}
        closeDialog={handleCloseDeleteModel}
        handleDelete={handleDelete}
        btnText={"Delete device"}
      />
      <EditModel
        title={"Edit Device"}
        closeEditDialog={handleCloseEditModel}
        isEditModelOpen={openEdit}
        classes={classes}
        handleEdit={handleEditDevice}
      >
        <DialogContent>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={projectOptions}
            onChange={(event, newValue) => {
              setEditingProjectId(newValue.id);
            }}
            defaultValue={editingData.project}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              return <li {...props}>{option.name}</li>;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project"
                placeholder="Choose a project"
              />
            )}
          />
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            fullWidth
            options={procurementOptions}
            sx={{ width: "100%" }}
            value={procurementOptions.name}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setEditingLpoNumber(newValue.name);
            }}
            renderInput={(params) => (
              <TextField {...params} label="LPO Number" />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={deviceTypeOptions}
            onChange={(event, newValue) => {
              setEditingDeviceType(newValue.id);
            }}
            defaultValue={editingData.itsDeviceType}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              return <li {...props}>{option.name}</li>;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Device type"
                placeholder="Choose a type"
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={deviceMakeOptions}
            onChange={(event, newValue) => {
              setEditingMakeId(newValue.id);
            }}
            defaultValue={editingData.make}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              return <li {...props}>{option.name}</li>;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Device make"
                placeholder="Choose a make"
              />
            )}
          />

          <TextField
            margin="dense"
            id="description"
            label="Description *"
            type="text"
            defaultValue={editingData.description}
            onChange={(e) => setEditingDeviceDescription(e.target.value)}
            fullWidth
          />

          <TextField
            margin="dense"
            id="description"
            label="Device IMEI *"
            type="text"
            defaultValue={editingData.deviceImei}
            onChange={(e) => setEditingDeviceimei(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Price"
            type="text"
            value={editingData.price}
            onChange={(e) => setEditingPrice(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="MTN SIM"
            type="text"
            defaultValue={editingData.mtnSim}
            onChange={(e) => setEditingMtnSim(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Airtel SIM"
            type="text"
            defaultValue={editingData.airtelSim}
            onChange={(e) => setEditingAirtelSim(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="4G SIM"
            type="text"
            defaultValue={editingData.fourGSim}
            onChange={(e) => setEditingFourGSim(e.target.value)}
            fullWidth
          />
          {editingSelectedDeviceType == "busvalidators" && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={busOptions}
              onChange={(event, newValue) => {
                setEditingBusId(newValue.id);
              }}
              defaultValue={editingData.bus}
              getOptionLabel={(option) => option.plateNumber}
              renderOption={(props, option, { selected }) => {
                return <li {...props}>{option.plateNumber}</li>;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Bus" placeholder="Select a bus" />
              )}
            />
          )}
          {/* <TextField
            margin="dense"
            label="Location"
            type="text"
            defaultValue={editingData.location}
            onChange={(e) => setEditingLocation(e.target.value)}
            fullWidth
          /> */}
          <Autocomplete
            className={classes.forminput}
            id="combo-box-demo"
            freeSolo
            options={siteOptions}
            sx={{ width: "100%" }}
            value={siteOptions.value}
            onChange={(event, newValue) => {
              typeof newValue === "string"
                ? setEditingLocation(newValue)
                : setEditingLocation(newValue.label);
            }}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
          {editingSelectedDeviceType === "pointofsale" && (
            <div>
              <TextField
                margin="dense"
                label="Number of POSs on this Location"
                type="text"
                defaultValue={editingData.posOnLocation}
                onChange={(e) => setEditingPosOnLocation(e.target.value)}
                fullWidth
              />
            </div>
          )}
        </DialogContent>
      </EditModel>
    </>
  );
};

export default Devices;
