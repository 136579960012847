import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
import API from "../../../utils/axios/axios";
import { authRedirect } from "../../../utils/redirects";
const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const getRefunds = () => dispatch => {
    dispatch({
        type: types.REFUNDS_PENDING
    })

    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/refunds`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sortedRefunds = res.data.refunds.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_REFUNDS_SUCCESS,
                refunds: sortedRefunds,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.REFUNDS_ERROR,
                error: err,
                message: err.response ? err.response.data.error : err.message
            })
        })
}

export const getPendingRefunds = () => dispatch => {
    dispatch({
        type: types.REFUNDS_PENDING
    })

    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/refunds/pending`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sortedRefunds = res.data.refunds.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_PENDING_REFUNDS,
                pendingRefunds: sortedRefunds,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.REFUNDS_ERROR,
                error: err,
                message: err.response ? err.response.data.error : err.message
            })
        })
}

export const getRefundedRefunds = () => dispatch => {
    dispatch({
        type: types.REFUNDS_PENDING
    })

    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/refunds/refunded`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sortedRefunds = res.data.refunds.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_REFUNDS_SUCCESS,
                refunds: sortedRefunds,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.REFUNDS_ERROR,
                error: err,
                message: err.response ? err.response.data.error : err.message
            })
        })
}
export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_PROJECTS_SNACKBAR
    })
}
