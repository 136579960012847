import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import {
    Grid,
    Typography,
    TableCell,
    TableFooter,
    TableRow,
    TextField,
    Button
} from "@material-ui/core";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MaterialTable, { MTableToolbar, MTableBody } from 'material-table';
import Autocomplete from '@mui/material/Autocomplete';
import DateFnsUtils from '@date-io/date-fns'
import printJS from 'print-js'
import {
    Visibility,
    Cancel,
    Check
} from '@material-ui/icons/'
import { formatter } from '../../utils/currencyFormater'
import {
    getPendingRefunds,
    getRefundedRefunds,
} from '../../redux/actions/refunds/refunds';
import { checkAuthedUser } from '../../redux/actions/auth/login'
import { rejectIncident, refundIncident } from '../../redux/actions/incidents/incidentActions'
import Header from '../templates/TopPartOfPage'
import { getSuperagentRequests, refundRequest, rejectRequest } from "../../redux/actions/superagent_requests/superagentRequests";




const Refunds = props => {
    const dispatch = useDispatch();
    const refunds = useSelector(state => state.refunds)
    const superagentRequests = useSelector(state => state.superRequests)
    const authedUser = useSelector(state => state.login.loggedInUser)

    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const [viewOption, setViewOption] = useState("Pending")
    const [viewItems, setViewItems] = useState("Incidents")
    const [selectedStartDate, handleStartDateChange] = useState(yesterday);
    const [selectedEndDate, handleEndDateChange] = useState(today);
    const [refundedOwned, setRefundedOwned] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        dispatch(getPendingRefunds())
        dispatch(getRefundedRefunds())
        dispatch(getSuperagentRequests())
    }, [refunds.changed, refresh])
    useEffect(() => {
        dispatch(checkAuthedUser())
        
    }, [refresh])
    console.log("The authed user is:", authedUser);
    console.log("The refunds are is:", refunds);

    const columns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {

                width: "5%"
            },
        },
        {
            field: 'dataId',
            title: 'ID',
        },
        {
            field: 'createdUser',
            title: 'Creator',
        },
        {
            field: 'agentUserName',
            title: 'Agent Username',

        },
        {
            field: 'agentNames',
            title: 'Agent Name',
        },
        {
            field: 'superAgentUser',
            title: 'Super Agent',
        },
        {
            field: 'damageName',
            title: 'Issue',
        },
        {
            field: 'time',
            title: 'Date',
        },
        {
            field: 'requestAmount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.requestAmount)}</Typography>
                    </Grid>
                </Grid>
            )

        },
        {
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/incident/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                    {!refundedOwned && <Grid
                        item
                    >
                        <Button
                            onClick={() => rejectRefund(rowData)}
                        >
                            <Cancel color="secondary" />
                        </Button>
                    </Grid>}

                </Grid>
            )
        },

    ];
    const superColumns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {

                width: "5%"
            },
        },
        {
            field: 'agentId',
            title: 'Agent ID',
        },
        {
            field: 'agentusername',
            title: 'Agent Username',

        },
        {
            field: 'agentnames',
            title: 'Agent Name',
        },
        {
            field: 'version',
            title: 'Version',
        },
        {
            field: 'time',
            title: 'Date',
        },
        {
            field: 'createdUser',
            title: 'Creator',
        },
        {
            field: 'amount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.amount)}</Typography>
                    </Grid>
                </Grid>
            )

        },
        {
            // field: 'requestAmount',
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/superagent/refund/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                    {!refundedOwned && <Grid
                        item
                    >
                        <Button
                            onClick={() => rejectSuperRequest(rowData)}
                        >
                            <Cancel color="secondary" />
                        </Button>
                    </Grid>}

                </Grid>
            )
        },

    ];
    const loanColumns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {

                width: "5%"
            },
        },
        {
            field: 'version',
            title: 'Version',
        },
        {
            field: 'time',
            title: 'Date',
        },
        {
            field: 'createdUser',
            title: 'Creator',
        },
        {
            field: 'amount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.amount)}</Typography>
                    </Grid>
                </Grid>
            )

        },
        {
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/superagent/refund/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                    {!refundedOwned && <Grid
                        item
                    >
                        <Button
                            onClick={() => rejectSuperRequest(rowData)}
                        >
                            <Cancel color="secondary" />
                        </Button>
                    </Grid>}

                </Grid>
            )
        },

    ];

    let rows
    let pendingRefundsRows;

    let superRequestRows;
    let superRequestPendingRows;

    let loanRows;
    let loanPendingRows;

    refunds.refunds ? rows = refunds.refunds : rows = []
    refunds.pendingRefunds ? pendingRefundsRows = refunds.pendingRefunds : pendingRefundsRows = []

    if (superagentRequests.superagentRequests) {
        let submittedRequests = superagentRequests.superagentRequests.filter(request => request.tag === "refund")
        superRequestPendingRows = submittedRequests.filter(incident => !incident.refunded)
        superRequestRows = submittedRequests.filter(incident => incident.refunded)
    } else {
        superRequestRows = []
        superRequestPendingRows = []
    }
    //loans
    if (superagentRequests.superagentRequests) {
        let submittedRequests = superagentRequests.superagentRequests.filter(request => request.tag === "loan")
        loanPendingRows = submittedRequests.filter(incident => !incident.refunded)
        loanRows = submittedRequests.filter(incident => incident.refunded)
    } else {
        loanRows = []
        loanPendingRows = []
    }
    superRequestRows.forEach((row, index) => {
        row.newId = index + 1
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.time = row.createdAt ? `${row.createdAt.split("T")[0]} at ${row.createdAt.split("T")[1].split(".")[0]}` : ""
    })
    superRequestPendingRows.forEach((row, index) => {
        row.newId = index + 1
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.time = row.createdAt ? `${row.createdAt.split("T")[0]} at ${row.createdAt.split("T")[1].split(".")[0]}` : ""
    })
    loanRows.forEach((row, index) => {
        row.newId = index + 1
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.time = row.createdAt ? `${row.createdAt.split("T")[0]} at ${row.createdAt.split("T")[1].split(".")[0]}` : ""
    })
    loanPendingRows.forEach((row, index) => {
        row.newId = index + 1
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.time = row.createdAt ? `${row.createdAt.split("T")[0]} at ${row.createdAt.split("T")[1].split(".")[0]}` : ""
    })
    const filteredRows = rows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    //filter the rows by only the ones that are accepted if the authenticated user is a finance member
    const filteredPendingRefunds=authedUser&&authedUser.role.name=="AC Finance Member"?pendingRefundsRows.filter(row=>row.acceptedByAc):pendingRefundsRows;

    //Filter rows by date provided
    const filteredPendingRows = filteredPendingRefunds.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })

    const filteredSuperRows = superRequestRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const filteredPendingSuperRows = superRequestPendingRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const filteredLoanRows = loanRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const filteredPendingLoanRows = loanPendingRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    filteredRows.forEach((row, index) => {
        row.newId = index + 1
        row.superAgentUser = row.incidentAgent ? row.incidentAgent.superAgentUser.name : row.superAgentName ? row.superAgentName : "No Superagent"
        row.damageName = row.damage ? row.damage.name : "No issue"
        row.projectName = row.project ? row.project.name : "No project"
        row.agentNames = row.incidentAgent ? row.incidentAgent.name : row.agentName ? row.agentName : "No agent"
        row.dataId = row.busTicketId || row.momoTransactionId || "No Id"
        row.agentUserName = row.agentUsername || "No username"
        row.time = row.occuranceTime ? `${row.occuranceTime.split("T")[0]} at ${row.occuranceTime.split("T")[1].split(".")[0]}` : "Date"
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
    })

    filteredPendingRows.forEach((row, index) => {
        row.newId = index + 1
        row.superAgentUser = row.incidentAgent ? row.incidentAgent.superAgentUser.name : row.superAgentName ? row.superAgentName : "No Superagent"
        row.damageName = row.damage ? row.damage.name : "No issue"
        row.projectName = row.project ? row.project.name : "No project"
        row.agentNames = row.incidentAgent ? row.incidentAgent.name : row.agentName ? row.agentName : "No agent"
        row.dataId = row.busTicketId || row.momoTransactionId || "No Id"
        row.agentUserName = row.agentUsername || "No username"
        row.time = row.occuranceTime ? `${row.occuranceTime.split("T")[0]} at ${row.occuranceTime.split("T")[1].split(".")[0]}` : "Date"
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
    })


    const markAsRefunded = (dataRows) => {
        dataRows.forEach(item => {
            if (!item.acceptedByAc) {
                alert("One of the incidents cannot be refunded because it was not accepted")
            } else {
                dispatch(refundIncident(item.id, { ...item, refunded: true }))
            }
        })
        setRefresh(cv => !cv)
    }
    const markSuperRequestAsRefunded = (dataRows) => {
        dataRows.forEach(item => {
            dispatch(refundRequest(item.id))
        })
        setRefresh(cv => !cv)
    }
    const rejectRefund = (dataRows) => {
        dispatch(rejectIncident(dataRows.id))
        setRefresh(cv => !cv)
    }
    const rejectSuperRequest = (dataRows) => {
        dispatch(rejectRequest(dataRows.id))
        setRefresh(cv => !cv)
    }
    return (
        <div>
            <Header
                name={viewItems == "Loans" ? "Loans" : "Refunds"}
                props={props}
            />
            <Grid
                container
                justify="flex-end"
                alignItems="flex-end"
                style={{
                    marginBottom: "1em",
                }}
            >
                <Grid
                    item
                    lg={2}
                    sm={3}
                    style={{
                        marginRight: "1em"
                    }}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={["Loans", "Incidents", "Refund Requests"]}
                        value={viewItems}
                        onChange={(event, newValue) => {
                            setViewItems(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="View  Options"
                        />
                        }
                    />
                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                    style={{
                        marginRight: "1em"
                    }}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={["Pending", viewItems == "Loans" ? "Loans Offered" : "Refunded"]}
                        value={viewOption}
                        onChange={(event, newValue) => {
                            setViewOption(newValue);
                            setRefundedOwned(newValue === "Pending" ? false : true);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="View Refund Options"
                        />
                        }
                    />
                </Grid>
                <Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item>
                                <DateTimePicker
                                    label="Start Date"
                                    disableFuture
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    autoOk
                                    ampm={false}
                                    disableFuture
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    label="End Date"
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>

            </Grid>
            {viewItems == "Incidents" ?
                <MaterialTable
                    columns={columns}
                    data={viewOption == "Pending" ? filteredPendingRows : filteredRows}
                    title={viewOption == "Pending" ? "Pending Refunds" : "Refunded"}
                    options={{
                        exportMenu: [
                            {
                                label: "Export PDF",
                                exportFunc: (cols, datas) => {
                                    const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                    ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                },
                            },
                            {
                                label: "Export CSV",
                                exportFunc: (cols, datas) => {
                                    const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                    ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                }
                            },
                        ],
                        exportButton: true,
                        actionsColumnIndex: -1,
                        padding: "dense",
                        paging: true,
                        pageSize: 300,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [6, 12, 20, 50],
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            whiteSpace: 'nowrap'
                        },
                        rowStyle: {
                            whiteSpace: 'nowrap',
                            fontSize: 13,
                            fontFamily: "Poppins', sans-serif !important"
                        },
                        selection: viewOption == "Pending" ? true : false,
                    }}
                    actions={viewOption == "Pending" ? [
                        {
                            icon: () => <Check color="primary" />,
                            tooltip: "Refund Selected",
                            onClick: (event, rowData) => {
                                markAsRefunded(rowData)
                            },
                        }
                    ] : []}
                    components={{
                        Toolbar: MTableToolbar,
                        Body: (props) => {
                            let renderValues = [...props.renderData]
                            const totalRefund = renderValues.reduce((acc, cv) => {
                                return acc += Number(cv.requestAmount)
                            }, 0)

                            return (
                                <>
                                    <MTableBody {...props} />
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={7} />
                                            <TableCell colSpan={7}
                                                style={{
                                                    fontSize: "1em",
                                                    color: "black",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                {formatter.format(totalRefund)}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </>
                            )
                        }

                    }}
                /> : viewItems == "Loans" ?
                    <MaterialTable
                        columns={loanColumns}
                        data={viewOption == "Pending" ? filteredPendingLoanRows : filteredLoanRows}
                        title={viewOption == "Pending" ? "Pending Loan Requests" : "Refunded Loan Requests"}
                        options={{
                            exportMenu: [
                                {
                                    label: "Export PDF",
                                    exportFunc: (cols, datas) => {
                                        const total = datas.reduce((acc, cv) => acc + Number(cv.amount), 0)
                                        ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                    },
                                },
                                {
                                    label: "Export CSV",
                                    exportFunc: (cols, datas) => {
                                        const total = datas.reduce((acc, cv) => acc + Number(cv.amount), 0)
                                        ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                    }
                                }
                            ],
                            exportButton: true,
                            actionsColumnIndex: -1,
                            padding: "dense",
                            paging: true,
                            pageSize: 300,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [6, 12, 20, 50],
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF'
                            },
                            rowStyle: {
                                whiteSpace: 'nowrap',
                                fontSize: 13,
                                fontFamily: "Poppins', sans-serif !important"
                            },
                            selection: viewOption == "Pending" ? true : false,
                        }}
                        actions={viewOption == "Pending" ? [
                            {
                                icon: () => <Check color="primary" />,
                                tooltip: "Mark as laon offered",
                                onClick: (event, rowData) => {
                                    markSuperRequestAsRefunded(rowData)
                                },
                            }
                        ] : []}
                        components={{
                            Toolbar: MTableToolbar,
                            Body: (props) => {
                                let renderValues = [...props.renderData]
                                const totalRefund = renderValues.reduce((acc, cv) => {
                                    return acc += Number(cv.amount)
                                }, 0)
                                return (
                                    <>
                                        <MTableBody {...props} />
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                                <TableCell colSpan={6}
                                                    style={{
                                                        fontSize: "1em",
                                                        color: "black",
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    {formatter.format(totalRefund)}
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </>
                                )
                            }

                        }}
                    /> :
                    <MaterialTable
                        columns={superColumns}
                        data={viewOption == "Pending" ? filteredPendingSuperRows : filteredSuperRows}
                        title={viewOption == "Pending" ? "Pending Refund Requests" : "Refunded Requests"}
                        options={{
                            exportMenu: [
                                {
                                    label: "Export PDF",
                                    exportFunc: (cols, datas) => {
                                        const total = datas.reduce((acc, cv) => acc + Number(cv.amount), 0)
                                        ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                    },
                                },
                                {
                                    label: "Export CSV",
                                    exportFunc: (cols, datas) => {
                                        const total = datas.reduce((acc, cv) => acc + Number(cv.amount), 0)
                                        ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                    }
                                }
                            ],
                            exportButton: true,
                            actionsColumnIndex: -1,
                            padding: "dense",
                            paging: true,
                            pageSize: 300,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [6, 12, 20, 50],
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF'
                            },
                            rowStyle: {
                                whiteSpace: 'nowrap',
                                fontSize: 13,
                                fontFamily: "Poppins', sans-serif !important"
                            },
                            selection: viewOption == "Pending" ? true : false,
                        }}
                        actions={viewOption == "Pending" ? [
                            {
                                icon: () => <Check color="primary" />,
                                tooltip: "Refund Selected",
                                onClick: (event, rowData) => {
                                    markSuperRequestAsRefunded(rowData)
                                },
                            }
                        ] : []}
                        components={{
                            Toolbar: MTableToolbar,
                            Body: (props) => {
                                let renderValues = [...props.renderData]
                                const totalRefund = renderValues.reduce((acc, cv) => {
                                    return acc += Number(cv.amount)
                                }, 0)
                                return (
                                    <>
                                        <MTableBody {...props} />
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                                <TableCell colSpan={6}
                                                    style={{
                                                        fontSize: "1em",
                                                        color: "black",
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    {formatter.format(totalRefund)}
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </>
                                )
                            }

                        }}
                    />
            }


        </div>
    )
}

export default Refunds
