import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
axios.defaults.withCredentials = true


const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const getInstitutions = () => dispatch => {
  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/institutions`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      const institutions = res.data.data.sort((a, b) => a.id - b.id)
      dispatch({
        type: types.GET_INSTITUTIONS_SUCCESS,
        institutions: institutions,
        message: res.data.message,
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getInstitution = (id) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.GET_INSTITUTION_SUCCESS,
        institution: res.data.data,
        message: res.data.message,
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const createInstitution = (name, description, project_id) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/create`,
    {
      name, description, project_id
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.CREATE_INSTITUTION_SUCCESS,
        institution: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const uploadInstitution = (instituions) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  instituions.forEach(data => {
    delete data.id
  })

  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/upload`,
    { instituions },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPLOAD_INSTITUTIONS,
        locations: res.data.instituions,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const assignRouteToInstitution = (id, routeId) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/assignRoute/${id}`,
    {
      routeId
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.ASSIGN_ROUTE_TO_INSTITUTION,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const assignUserToInstitution = (institution_id, email) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/assign`,
    {
      institution_id, email
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.ASSIGN_USER_TO_INSTITUTION,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const removeUserFromInstitution = (institution_id, email) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/remove/${institution_id}`,
    {
      email
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.REMOVE_USER_FROM_INSTITUTION,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const deleteInstitution = (id) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.DELETE_INSTITUTIONS_SUCCESS,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const removeRoute = (id, routeId) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/removeRoute/${id}`,
    { routeId },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.REMOVE_ROUTE_SUCCESS,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const updateInstitution = (id, name, description, project_id) => dispatch => {

  dispatch({
    type: types.GET_INSTITUTIONS_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/institutions/${id}`,
    {
      name, description, project_id
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPDATE_INSTITUTIONS_SUCCESS,
        message: res.data.message,
        institution: res.data.data
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INSTITUTIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_SNACKBAR
  })
}


