import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';

import {
    assign,
    getProject,
    removeUser,
    clearSnackBar
} from "../../redux/actions/projects/projectsAction"
import DeleteIcon from '@material-ui/icons/Delete';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import color from "../colors";
import '../landing/landingstyle.scss';
import {
    Grid,
    Button,
    CircularProgress,
    Typography,
    Select,
    MenuItem,
    TextField,
    DialogContent,
    DialogTitle,
    Snackbar,
    Slide,
    Link
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { getUsersAction } from "../../redux/actions/usersAction"



import { formatDate } from '../../utils/formatDates'
import AdditionalInfo from '../templates/AdditionalInfo';
import moment from 'moment';
import AddModel from '../templates/AddModel'
import DeleteModel from '../templates/DeleteModel'

// moment(user.createdAt).format('MMMM Do YYYY, h:mm:ss a')

const useStyles = makeStyles((theme) => ({
    componentWrapper: {
        padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    headers: {
        marginTop: theme.spacing(1),
        alignContent: "center",
        marginBottom: theme.spacing(0),
        color: `${color.primary100}`,
        fontWeight: "bold"
    },
    progress: {
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
    },
    header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
    },
    header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: color.primary100
    },
    root: {
        '& .super-app-theme--header': {
            fontWeight: "bolder",
        },
    },
    btn: {
        backgroundColor: `${color.primary100}`,
        color: 'white',
        padding: "9px",
        margin: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(5)
    },
    formControl: {
        height: "15",
        padding: "10",
        paddingBottom: "0",
        backgroundColor: `${color.primary100}`,
        color: "#fff",
        fontSize: "15px",
        minWidth: " 64px",
        boxSizing: "border-box",
        transition: " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
        fontWeight: 500,
        lineHeight: 1.60,
        borderRadius: "3px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        paddingRight: "0",
        paddingTop: "4px",
        paddingLeft: "9px",
        marginTop: "4px",
        marginBottom: "-3px",

    },
}))



const SingleProject = props => {
    let location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams()
    const dispatch = useDispatch()
    const [perPage, setPerPage] = useState(10);
    const [openAddModel, setAddModelOpen] = useState(false);
    const [email, setEmail] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [userToRemove, setUserToRemove] = useState(null)
    const [userEmails, setUserEmails] = useState()


    const handleChange = (e) => {
        setPerPage(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleCloseAdd = () => setAddModelOpen(false)
    const handleOpenAddModel = () => setAddModelOpen(true)
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleOpenDeleteModel = (e) => {
        const element = e.currentTarget.id.split(",")
        setUserToRemove(element[1])
        setOpenDelete(true)
    }

    const handleAddUser = () => {
        if (userEmails) {
            userEmails.map(email => {
                dispatch(assign(props.match.params.id, email))
            })
            setUserEmails([]);
            handleCloseAdd()
        } else {
            alert("Select at least one user")
        }
    }
    const handleRemoveUser = () => {
        dispatch(removeUser(id, userToRemove))
        handleCloseDeleteModel()
    }

    const project = useSelector(state => state.projects.project)
    const projects = useSelector(state => state.projects)
    const userToSelect = useSelector(state => state.users)
    const users = project ? project.users : null

    const userOptions = []
    userToSelect.users ? userToSelect.users.map(user => {
        return userOptions.push(user.email)
    }) : userOptions.push()

    useEffect(() => {
        dispatch(getProject(id))
        dispatch(getUsersAction());
    }, [projects.assigned, projects.userRemoved])


    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }

    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }


    let path = []
    const getPath = () => {
        const general = location.pathname.split("/");
        let url = null;
        for (let i = 1; i < general.length; i++) {
            if (i === general.length - 1) {
                url = <Typography variant="subtitle2" className={classes.header3} >{`${general[i][0].toUpperCase() + general[i].slice(1)}`}</Typography>
                path.push(url)
            } else {
                url = <Typography variant="subtitle2" className={classes.header2}>{`${general[i][0].toUpperCase() + general[i].slice(1)}>`}</Typography>
                path.push(url)
            }
        }
    }

    getPath()
    const columns = [
        {
            field: 'newId',
            headerName: 'ID',
            width: 120,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 330,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 490,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            id={`${params.getValue('id')},${params.getValue('email')}`} >
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];

    let rows = []
    if (users) users.map(user => user.newId = (users.indexOf(user)) + 1)
    users ? rows = users : rows = []


    return (
        <div
            className={classes.componentWrapper}
        >
            {!users ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <div>
                    <Snackbar
                        open={projects.snackBarMessage.open}
                        onClose={closeSnackBarTimer}
                        autoHideDuration={4000}
                        TransitionComponent={TransitionUp}
                    >
                        <MuiAlert
                            severity={projects.snackBarMessage.severity}
                            variant='filled'
                            elevation={6}
                        >{projects.snackBarMessage.message}</MuiAlert>
                    </Snackbar>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant={'h5'} className={classes.headers}>Project Details</Typography>
                            <div className={classes.path}>{path}</div>
                        </div>
                        <hr />
                        <div>
                            <p> <b style={{ color: color.primary100 }}>Name:</b> {project ? project.name : ''}</p>
                            <p> <b style={{ color: color.primary100 }}>Description:</b> {project ? project.description : ''}</p>
                        </div>
                    </div>
                    <Typography variant={'h6'} className={classes.subtitle}>Users</Typography>
                    {users ?
                        <div
                            style={{
                                width: '100%'
                            }}>
                            <div>
                                <div >
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        className={classes.btn}
                                        onClick={handleOpenAddModel}
                                    >
                                        Add Users
                                    </Button>

                                    {users.length !== 0 && <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={perPage}
                                        color="primary"
                                        onChange={handleChange}
                                        label="Age"
                                        className={classes.formControl}
                                    >
                                        <MenuItem value={5}>SHOW 5 ITEMS</MenuItem>
                                        <MenuItem value={10}>SHOW 10 ITEMS</MenuItem>
                                        <MenuItem value={20}>SHOW 20 ITEMS</MenuItem>
                                        <MenuItem value={30}>SHOW 30 ITEMS</MenuItem>
                                        <MenuItem value={rows.length}>SHOW ALL ITEMS</MenuItem>
                                    </Select>}

                                </div>
                            </div>
                            {users.length === 0 ?
                                <Typography
                                    variant={'h6'}
                                    style={{
                                        backgroundColor: '#00A5DF',
                                        color: '#fff',
                                        padding: '0.5em',
                                        margin: '1em 0',
                                        width: '99%',
                                        fontSize: '16px'
                                    }}
                                >
                                    This project does not have users.
                                </Typography> :
                                <DataGrid
                                    rows={users}
                                    columns={columns}
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    className={classes.root}
                                    pageSize={10}
                                    checkboxSelection
                                    autoHeight={true} />


                            }
                        </div> :
                        <div>
                            <Typography variant={'h6'}>No users</Typography>
                        </div>
                    }
                    {users ?
                        <AdditionalInfo
                            creator={project.creator ? project.creator : "Unknown"}
                            updator={project.updator ? project.updator : "Unknown"}
                            creationDate={project.createdAt}
                            updateDate={project.updatedAt}

                        /> :
                        <div>
                            <Typography variant={'h6'}>No Additional info</Typography>
                        </div>
                    }
                </div>}
            <Button
                color="primary"
                variant='contained'
                className={classes.btn}
                style={{ marginTop: '2em' }}
                onClick={() => props.history.push("/dashboard/projects")}
            >
                View all Projects
            </Button>

            {/* <Button>
                <Link to='/dashboard/projects'>
                    View all Projects
                </Link></Button> */}
            <AddModel
                isModelOpen={openAddModel}
                closeAddModel={handleCloseAdd}
                handleCreate={handleAddUser}
            >
                <DialogTitle id="form-dialog-title">Add Users To This Project</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="free-solo-demo"
                        multiple
                        options={userOptions}
                        value={userEmails}
                        onChange={(event, newValue) => {
                            setUserEmails(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params} label="User emails"
                        />
                        }
                    />
                </DialogContent>
            </AddModel>
            <DeleteModel
                title={'Remove User'}
                description={`Are you sure you want to remove this user?`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleRemoveUser}
            />
        </div>
    )
}

export default SingleProject
