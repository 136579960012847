import React, { useEffect, useState } from "react";
import Cookie from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import color from "./colors";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Grid,
  Avatar,
  List,
  Drawer,
  ListItemIcon,
  ListItemText,
  ListItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Dashboard,
  ReportProblem,
  AccountTree,
  SupervisedUserCircle,
  TouchApp,
  AccountCircle,
  Menu,
  ChevronRight,
  Notifications,
  Mail,
  VerifiedUser,
  DirectionsBus,
  Gavel,
  DeviceHub,
  ReplayOutlined,
  Money,
  MonetizationOn,
  Assessment,
  ShoppingBasket,
} from "@material-ui/icons/";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import PinDropIcon from "@material-ui/icons/PinDrop";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import StyledBadge from "./styledBudge";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AC_Logo from "../assets/images/AC Group logo_White.png";
import { Link } from "react-router-dom";
import { checkAuthedUser, logoutRequest } from "../redux/actions/auth/login";
import "./navStyles.scss";
import useWindowSize from "../utils/windowSize";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  allContainer: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 65,
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: 65,
  },
  menuButton: {
    marginRight: 25,
    marginTop: -46,
    marginLeft: 7,
  },
  responsiveMenuButton: {
    marginRight: 25,
    marginTop: 0,
    marginLeft: 7,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#19459D",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#19459D",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "inline",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navIcons: {
    color: "#CCD1D1",
  },
  navText: {
    color: "#fff",
  },
  bar: {
    position: "relative",
  },
  subBar: {
    position: "absolute",
    // display: barOpen ? 'flex' : 'none',
    flexDirection: "column",
    backgroundColor: color.primary100,
    color: "#fff",
    right: 0,
    padding: "1em 6px",
    borderBottomLeftRadius: "7px",
    // borderBottomRadius: '5px'
    // opacity: '0.6',
  },
  profileBtn: {
    marginBottom: "1em",
  },
  progress: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "absolute",
  },
}));

const Navigation = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const cookies = new Cookie();
  const { children } = props;
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const [open, setOpen] = useState(false);
  const [barOpen, setBarOpen] = useState(false);

  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(checkAuthedUser());
  }, []);

  useEffect(() => {
    loggedInUser = loginState.loggedInUser;
  }, [loginState.loggedInUser]);

  let loggedInUser = loginState.loggedInUser;

  const userPermissions = loggedInUser ? loggedInUser.role.Permissions : {};

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const openBar = () => {
    const openOrClose = !barOpen;
    setBarOpen(openOrClose);
  };
  const handleCloseBar = () => setBarOpen(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    await dispatch(logoutRequest());
    history.push("/");
    window.location.reload();
  };
  const handleViewProfile = (e) => {
    e.preventDefault();
    history.push("/profile");
    setBarOpen(false);
  };
  const navList = [
    {
      name: "Dashboard",
      icon: <Dashboard />,
      userHasPermission: userPermissions.manageUser,
      onclick: () => history.push("/dashboard"),
    },
    {
      name: "Stock",
      icon: <ShoppingBasket />,
      userHasPermission: userPermissions.viewStock,
      onclick: () => history.push("/stock"),
    },
    {
      name: "Reports",
      icon: <Assessment />,
      userHasPermission: userPermissions.viewReports,
      onclick: () => history.push("/dashboard/reports"),
    },
    {
      name: "Users",
      icon: <AccountCircle />,
      userHasPermission: userPermissions.manageUser,
      onclick: () => history.push("/dashboard/users"),
    },
    {
      name: "Roles",
      icon: <VerifiedUser />,
      userHasPermission: userPermissions.manageRoles,
      onclick: () => history.push("/dashboard/roles"),
    },
    {
      name: "Submit Incident",
      icon: <ReportProblem />,
      userHasPermission: userPermissions.createIncident,
      onclick: () => history.push("/dashboard/newincident"),
    },
    {
      name: "My Incidents",
      icon: <ErrorOutlineIcon />,
      userHasPermission: userPermissions.createIncident,
      onclick: () => history.push("/dashboard/incidents"),
    },
    {
      name: "Manager Incidents",
      icon: <SyncProblemIcon />,
      userHasPermission: userPermissions.manageIncident,
      onclick: () => history.push("/dashboard/manageIncidents"),
    },
    {
      name: "Projects",
      icon: <AccountTree />,
      userHasPermission: userPermissions.manageProjects,
      onclick: () => history.push("/dashboard/projects"),
    },
    {
      name: "Routes",
      icon: <ReplayOutlined />,
      userHasPermission: userPermissions.manageSite,
      onclick: () => history.push("/dashboard/routes"),
    },
    {
      name: "Sites",
      icon: <PinDropIcon />,
      userHasPermission: userPermissions.manageSite,
      onclick: () => history.push("/dashboard/sites"),
    },
    {
      name: "Bus Companies",
      icon: <EmojiTransportationIcon />,
      userHasPermission: userPermissions.manageInstitution,
      onclick: () => history.push("/dashboard/institutions"),
    },
    {
      name: "Buses",
      icon: <DirectionsBus />,
      userHasPermission: userPermissions.manageBus,
      onclick: () => history.push("/dashboard/buses"),
    },
    {
      name: "Devices",
      icon: <DeviceHub />,
      userHasPermission: userPermissions.viewDevices,
      onclick: () => history.push("/dashboard/devices"),
    },
    {
      name: "Issues",
      icon: <Gavel />,
      userHasPermission: userPermissions.manageDamage,
      onclick: () => history.push("/dashboard/issues"),
    },
    {
      name: "Requests",
      icon: <TouchApp />,
      userHasPermission: userPermissions.manageIncident,
      onclick: () => history.push("/dashboard/requests"),
    },
    {
      name: "Refunds",
      icon: <MonetizationOn />,
      userHasPermission: userPermissions.manageIncident,
      onclick: () => history.push("/dashboard/refunds"),
    },
  ];
  return (
    <div>
      {loginState.isLoading ? (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.allContainer}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Grid
              container
              style={{ backgroundColor: "#19459D", height: "4em" }}
              justify="space-between"
              alignItems={"center"}
            >
              <Grid item lg={10} sm={9}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={
                    windowSize.width >= 508
                      ? clsx(classes.menuButton, {
                          [classes.hide]: open,
                        })
                      : clsx(classes.responsiveMenuButton, {
                          [classes.hide]: open,
                        })
                  }
                >
                  <Menu />
                </IconButton>
                <Link to="/dashboard">
                  <img
                    className="logoImg"
                    style={{
                      maxWidth: "60%",
                      maxHeight: "6vh",
                      marginTop: "1em",
                      marginLeft: "1em",
                    }}
                    src={AC_Logo}
                  />
                </Link>
              </Grid>
              <Grid item lg={2} sm={3} className={classes.bar}>
                <Toolbar>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={17} color="secondary">
                      <Notifications />
                    </Badge>
                  </IconButton>
                  <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                      <Mail />
                    </Badge>
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    color="inherit"
                    onClick={openBar}
                  >
                    <Grid style={{ flexDirection: "row" }}>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          className={classes.smallAvatar}
                          src={
                            loggedInUser
                              ? `${loggedInUser.image}`
                              : "https://unsplash.com/photos/qCINTP6j0xQ"
                          }
                        />
                      </StyledBadge>
                    </Grid>
                  </IconButton>
                </Toolbar>
                <Grid
                  className={classes.subBar}
                  style={{ display: barOpen ? "flex" : "none" }}
                >
                  <Button
                    variant="contained"
                    className={classes.profileBtn}
                    onClick={handleViewProfile}
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                  >
                    View Profile
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRight className={classes.navIcons} />
              </IconButton>
            </div>
            <List>
              {navList.map((navItem, index) => {
                const { name, icon, userHasPermission, onclick } = navItem;
                return (
                  userHasPermission && (
                    <ListItem button key={name} onClick={onclick}>
                      <ListItemIcon className={classes.navIcons}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        className={classes.navText}
                      />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div onClick={handleCloseBar} className={classes.toolbar}>
              {children}
            </div>
          </main>
        </div>
      )}
    </div>
  );
};
export default Navigation;
