import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button } from '@material-ui/core'
import Cookie from 'universal-cookie'
import '../landing/landingstyle.scss';
import color from '../colors'
import { logoutRequest } from '../../redux/actions/auth/login';


function LoggedIn() {
    const dispatch = useDispatch()
    const cookies = new Cookie()
    let history = useHistory()

    const handleOut = async (e) => {
        e.preventDefault();
        // cookies.remove('x-auth-token')
        // cookies.remove("userRoles")
        await dispatch(logoutRequest())
        window.location.reload()
    }
    return (
        <div className="loggedin">
            <div>
                <Typography>You are logged in</Typography>
                <Button
                    color="primary"
                    onClick={() => history.push('/dashboard')}
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                >Go to dashboard</Button>
                <Typography>Or</Typography>
                <Button
                    color="primary"
                    onClick={handleOut}
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                >Logout</Button>
            </div>
        </div>
    )
}

export default LoggedIn;