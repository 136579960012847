import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import NoAccess from '../assets/images/unauthorized.png'
import color from './colors'

const UnAuthorized = () => {
    let history = useHistory();
    const navigateToHome = () => {
        history.push('/')
        window.location.reload()
    }
    return (
        <div
            style={{
                marginTop: '1em',
                textAlign: 'center',
                // backgroundColor: 'aqua',
                width: '100%'
            }}>
            {!NoAccess ?
                <div
                // className={classes.progress}
                >
                    <CircularProgress />
                </div> :
                <div>
                    <img
                        style={{
                            width: '50%',
                        }} src={NoAccess} /><br />
                    <Typography>You are not authorized to view this page. Contact your manager for permissions!</Typography>
                    <Button
                        color='primary'
                        variant="contained"
                        style={{ backgroundColor: color.secondary }}
                        onClick={navigateToHome}
                    >Go back to the homepage</Button>
                </div>}
        </div>
    )
}

export default UnAuthorized
