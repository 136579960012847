import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
import API from "../../../utils/axios/axios";
import { authRedirect } from "../../../utils/redirects";
const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const getProjects = () => dispatch => {
  dispatch({
    type: types.PROJECTS_PENDING
  })

  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/projects`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      const sortedProjects = res.data.projects.sort((a, b) => b.id - a.id)
      dispatch({
        type: types.GET_PROJECTS_SUCCESS,
        projects: sortedProjects,
        message: res.data.message,
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getProject = (id) => dispatch => {
  dispatch({
    type: types.PROJECTS_PENDING

  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/${id}`)
    .then(res => {
      dispatch({
        type: types.GET_PROJECT_SUCCESS,
        project: res.data.project,
        message: res.data.message,
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const createProject = (name, description) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/projects/create`,
    {
      name, description
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.CREATE_PROJECT,
        project: res.data.project,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message

      })
    })
}

export const uploadProjects = (projects) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  projects.forEach(data => {
    delete data.id
  })

  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/projects/upload`,
    { projects },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPLOAD_PROJECTS,
        projects: res.data.projects,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}



export const assign = (projectId, email) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/projects/assign`,
    {
      projectId, email
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.ASSIGN_PROJECT,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const removeUser = (id, email) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/projects/remove/${id}`,
    { email },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.REMOVE_PROJECT_ASSIGNEE,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const deleteProject = (id) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/projects/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.DELETE_PROJECT,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const updateProject = (id, name, description) => dispatch => {

  dispatch({
    type: types.PROJECTS_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/projects/${id}`,
    {
      name, description
    },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPDATE_PROJECT,
        message: res.data.message,
        project: res.data.newProject
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_PROJECTS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_PROJECTS_SNACKBAR
  })
}
