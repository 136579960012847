import React, { useEffect, useState } from "react";
import { TextField, Typography, Button, Grid, Slide } from "@material-ui/core";
import { useStyles } from "./useStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeviceTypes,
  createType,
  deleteType,
  updateType,
} from "../../redux/actions/devices/deviceTypes";
import { clearSnackBar } from "../../redux/actions/devices/snack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModel from "../templates/DeleteModel";
import RoleTable from "../templates/DisplayAll";
import EditModel from "../templates/EditModel";
import UploadImage from "../users/dialogs/uploadImage";

const DeviceTypes = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const typeReducer = useSelector((state) => state.devices);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [pricePerUnit, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageValid, setImageValid] = useState("");
  const [imageAlt, setImagealt] = useState(null);

  const [image, setImage] = useState();
  const [update, setUpdate] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(getDeviceTypes());
  }, [typeReducer.type, typeReducer.deleteType, typeReducer.updateType]);

  let rows;
  typeReducer.deviceTypes ? (rows = typeReducer.deviceTypes) : (rows = []);
  const columns = [
    {
      field: "newId",
      headerName: "No",
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <Grid container direction="row">
          <Grid item>
            <Button
              onClick={handleOpenEditModel}
              id={`${params.id},${params.row.name},${params.row.description}`}
            >
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleOpenDeleteModel}
              id={`${params.id},${params.row.name}`}
            >
              <DeleteIcon color="secondary" />
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  const handleAddType = () => {
    const type = {
      name,
      description,
      image: image
        ? image
        : "https://i.pinimg.com/564x/3e/d9/e7/3ed9e7bc7ef5d0879118ff8165e90980.jpg",
      pricePerUnit,
    };
    // console.log(type);
    dispatch(createType(type));
    setName("");
    setDescription("");
  };

  const handleUpdate = () => {
    dispatch(updateType(id, { name, description }));
    handleClodeEditModel();
  };

  const handleOpenEditModel = (e) => {
    const values = e.currentTarget.id.split(",");
    setId(values[0]);
    setName(values[1]);
    setDescription(values[2]);
    setUpdate(true);
  };
  const handleOpenDeleteModel = (e) => {
    const values = e.currentTarget.id.split(",");
    setId(values[0]);
    setDeleted(true);
  };
  const handleClodeEditModel = (e) => {
    setUpdate(false);
    setName("");
    setDescription("");
  };

  const handleCloseDeleteModel = () => setDeleted(false);

  const handleDelete = () => {
    dispatch(deleteType(id));
    handleCloseDeleteModel();
  };

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };

  rows.forEach((row, index) => {
    row.newId = index + 1;
  });
  const multipleDeleteHandler = (ids) => {
    ids.map((id) => dispatch(deleteType(id)));
  };
  console.log(rows);
  return (
    <div className={classes.contain}>
      <RoleTable
        cTitle="Device Types"
        addBtnTitle="Add Device Type"
        rows={rows}
        columns={columns}
        cleaner={closeSnackBarTimer}
        reducer={typeReducer}
        handleCreate={handleAddType}
        iTypes={true}
        multipleDeleteHandler={multipleDeleteHandler}
      >
        <div
          className={classes.addForm}
          style={{ padding: "20px", boxSizing: "border-box" }}
        >
          <Typography variant="h5">Add a new device type</Typography>
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Standard price/unit"
            value={pricePerUnit}
            onChange={(e) => setPrice(e.target.value)}
          />
          <UploadImage
            setLoading={setLoading}
            imageUrl={image}
            imageAlt={imageAlt}
            setImageurl={setImage}
            setImageAlt={setImagealt}
            loading={loading}
            imageValid={imageValid}
            setImageValid={setImageValid}
          />
        </div>
      </RoleTable>
      <EditModel
        title="Edit device type"
        isEditModelOpen={update}
        closeEditDialog={handleClodeEditModel}
        handleEdit={handleUpdate}
        classes={classes}
      >
        <div
          className={classes.addForm}
          style={{ padding: "20px", boxSizing: "border-box" }}
        >
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </EditModel>
      <DeleteModel
        title={"Delete device type"}
        description={"Are you sure you want to delete this type?"}
        isDeleteModelOpen={deleted}
        closeDialog={handleCloseDeleteModel}
        handleDelete={handleDelete}
        btnText={"Delete type"}
      />
    </div>
  );
};

export default DeviceTypes;
