import {DEVICES_ERROR, DEVICES_PENDING, DELETE_DEVICE} from './type';
import http from '../../../utils/axios/axios';

const auth = localStorage.getItem('x-auth-token')
export const deleteDevice = (id) => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.delete(`/api/devices/${id}`, {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: DELETE_DEVICE,
            payload: res.data.message
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload: err.response ? err.response.data.error : err.message
        })
    })

}