import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  CircularProgress,
  Slide,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


import color from "../colors";
import { removeSpace } from '../../utils/removeSpaces';

import {
  getInstitution,
  clearSnackBar,
  removeRoute,
  assignRouteToInstitution,
  assignUserToInstitution,
  removeUserFromInstitution
} from "../../redux/actions/institutions/institutionsActions"
import { getBusMakes } from '../../redux/actions/buses/busMakes'
import {
  createBus,
  deleteBus,
  getBuses,
} from "../../redux/actions/buses/buses"
import { getUsersAction } from "../../redux/actions/usersAction"
import { useSelector, useDispatch } from "react-redux";
import NoEntries from '../templates/NoEntries'
import DeleteModel from '../templates/DeleteModel'
import { getRoutes } from '../../redux/actions/routes/routes';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(5),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  userheader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  headers: {
    marginTop: theme.spacing(2),
    alignContent: "center",
    marginBottom: theme.spacing(1),
    color: `${color.primary100}`,
    fontWeight: "bold"
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  path: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "flex-end"
  },
  contain: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px"
  },
  title: {
    fontWeight: "bold",
    alignContent: "center"
  },
  container: {
    position: "relative",
    width: "100%",
    border: `solid 1px ${color.border}`,
    marginBottom: "2px",
    borderRadius: "3px"
  },
  btn: {

    backgroundColor: `${color.primary100}`,
    color: 'white',
    padding: "9px",
    margin: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5)
  },
  btnstly: {
    height: theme.spacing(4),
    backgroundColor: `${color.primary100}`,
    marginBottom: theme.spacing(2),
  },
  btn2: {
    backgroundColor: `${color.primary100}`,
    height: theme.spacing(5),

  },
  search: {
    float: "right",
    height: "10px",
    margin: theme.spacing(1),
    marginRight: "0px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  },
  root: {
    '& .super-app-theme--header': {
      fontWeight: "bolder",
    },
  },
  down: {
    minWidth: 100,
    height: 12
  },
  table: {
    marginTop: theme.spacing(4),
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  input: {
    paddingBottom: theme.spacing(2)
  },
  whiteColor: {
    color: "white"
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10)
  },
  locations: {
    color: `${color.primary100}`,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  label: {
    color: `${color.primary100}`,
    display: "inline",
    marginRight: theme.spacing(0.5)
  },

})
)
function SingleInstitution(props) {
  const dispatch = useDispatch();
  const institution = useSelector(state => state.institution);
  const route = useSelector(state => state.routes);
  const users = useSelector(state => state.users)
  const buses = useSelector(state => state.buses)

  const { id } = useParams()

  const [selectedRoutes, setSelectedRoutes] = useState();
  const [userEmails, setUserEmails] = useState()
  const [makeId, setMakeId] = useState()

  useEffect(() => {
    dispatch(getInstitution(props.match.params.id));
    dispatch(getUsersAction());
    dispatch(getBuses())
    dispatch(getRoutes())
  }, [institution.removed, buses.deleted, buses.added])

  useEffect(() => {
    dispatch(getBusMakes())
  }, [])

  const routeOptions = route.routes ? route.routes.map(route => {
    return {
      title: route.name,
      id: route.id
    }
  }) : []
  const busMakeOptions = buses.busMakes ? buses.busMakes.map(bMake => {
    return {
      label: bMake.name,
      value: bMake.id
    }
  }) : []
  const userOptions = []
  users.users ? users.users.map(user => {
    return userOptions.push(user.email)
  }) : userOptions.push()

  const [openDelete, setDelete] = useState(false);
  const [openDeleteUser, setDeleteUser] = useState(false);
  const [userName, setUserName] = useState(false);
  const [openAddRoute, setAddRoute] = useState(false);
  const [assignUser, setAssignUser] = useState(false);
  const [routeName, setRouteName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [routeId, setRouteId] = useState(null);
  const [busToView, setBusToView] = useState(null)
  const [busDelete, setBusDelete] = useState(false)
  const [busToDelete, setBusToDelete] = useState(null)
  const [openAddBus, setOpenAddBus] = useState(false)
  const [plateNumber, setPlateNumber] = useState(null)


  const classes = useStyles();
  const routes = institution.institution.routes
  let rows

  routes ? (rows = routes) : rows = []
  rows.forEach((row, index) => {
    row.newId = index + 1
  })

  let rows2

  institution.institution.users ? (rows2 = institution.institution.users) : rows2 = []
  rows2.forEach((row, index) => {
    row.newId = index + 1
  })
  let busesRows;
  institution.institution.buses ? busesRows = institution.institution.buses : busesRows = []
  busesRows.forEach((row, index) => row.newId = index + 1)


  let path = [];

  const getPath = () => {
    const general = props.match.path.split("/");
    let url = null;
    for (let i = 1; i < general.length; i++) {
      if (i === general.length - 1) {
        url = <Typography variant="subtitle2" className={classes.header3} >{`${props.match.params.id}`}</Typography>
        path.push(url)
      } else {
        const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
        url = <Typography variant="subtitle2" className={classes.header2}>{`${word} >`}</Typography>
        path.push(url)
      }
    }
  }

  getPath()

  const handleDeleteDialogClose = () => {
    setDelete(false)
  }
  const handleDeleteDialogOpen = (e) => {
    let values = e.currentTarget.id.split(":");
    setRouteName(values[1])
    setRouteId(values[0])
    setDelete(true)
  }

  const handleremove = () => {
    dispatch(removeRoute(props.match.params.id, routeId))
    setRouteId(null)
    setDelete(false)
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  }

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar())
  }
  const handleAddRoute = () => {
    setAddRoute(true)
  }

  const handleClosseAddRoute = () => {
    setRouteId(null);
    setAddRoute(false)
  }
  const handleAssignUser = () => {
    setAssignUser(true)
  }

  const handleClosseAssignUser = () => {
    setUserEmails([]);
    setAssignUser(false)
  }

  const populateSelect = () => {
    let values = []
    let value;
    route.routes.forEach((route, index) => {
      value = <MenuItem value={route.id} key={index}>{route.name}</MenuItem>
      values.push(value)
    });
    return values
  }

  const populateUserSelect = () => {
    let values = []
    let value
    users.users.forEach((user, index) => {
      let exist = false
      rows2.forEach((row) => {
        if (row.email === user.email) {
          exist = true
        }
      })

      if (!exist) {
        value = <MenuItem value={user.email} key={index}>{user.name}</MenuItem>;
        values.push(value)
      }
    });
    return values.length === 0 ? values = <MenuItem value={null} key="kk">No New User</MenuItem> : values
  }

  const addRoute = () => {
    if (selectedRoutes.length !== 0) {
      const routesToAssign = selectedRoutes.filter((v, i, a) => a.findIndex(t => (t.title.toLowerCase() === v.title.toLowerCase())) === i)
      routesToAssign.map(routeToAssign => dispatch(assignRouteToInstitution(props.match.params.id, routeToAssign.id))
      )
      setRouteId(null);
      setAddRoute(false)
      setSelectedRoutes(null)
    } else {
      alert("Select at least Route")
    }
  }
  const addUser = () => {
    if (userEmails) {
      userEmails.map(email => {
        dispatch(assignUserToInstitution(props.match.params.id, email))
      })
      setUserEmails([]);
      setAssignUser(false)
    } else {
      alert("Select at least one user")
    }
  }

  const handleDeleteUserDialogOpen = (e) => {
    let values = e.currentTarget.id.split(":");
    setUserName(values[1])
    setUserEmail(values[2])
    setDeleteUser(true)
  }
  const removeUser = () => {
    dispatch(removeUserFromInstitution(props.match.params.id, userEmail))
    setDeleteUser(false)
    setUserName(null)
    setUserEmail(null)
  }

  const handleDeleteUserDialogClose = () => {
    setUserName(null)
    setUserEmail(null)
    setDeleteUser(false)
  }

  const handleBusId = (e) => {
    const element = e.currentTarget.id.split(":")
    setBusToView(element[0])
  }
  const handleOpenBusDelete = (e) => {
    const element = e.currentTarget.id.split(":")
    setBusToDelete(element[0])
    setBusDelete(true)
  }
  const handleCloseBusDelete = () => {
    setBusToDelete(null)
    setBusDelete(false)
  }
  const handleBusDelete = (e) => {
    dispatch(deleteBus(busToDelete))
    handleCloseBusDelete()
  }
  const handleOpenAddBus = () => setOpenAddBus(true)
  const handleCloseAddBus = () => setOpenAddBus(false)
  const handlePlateNumberChange = e => setPlateNumber(e.target.value)
  const handleCreateBus = () => {
    let numberPlate = plateNumber ? removeSpace(plateNumber).toUpperCase() : ""
    dispatch(createBus(numberPlate, id, makeId))
    setPlateNumber(null)
    setOpenAddBus(false)
  }

  const columns = [
    { field: 'newId', headerName: 'ID', flex: 1, headerClassName: 'super-app-theme--header', },
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'action', headerName: 'Action', flex: 1, headerClassName: 'super-app-theme--header',
      renderCell: (params) => (

        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={`${params.id}:${params.row.name}`}
              onClick={handleDeleteDialogOpen}
            >
              <DeleteIcon color="secondary" />
            </Button>


          </Grid>
        </Grid>
      )
    },
  ];
  const columns2 = [
    { field: 'newId', headerName: 'ID', flex: 1, headerClassName: 'super-app-theme--header', },
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'action', headerName: 'Action', flex: 1, headerClassName: 'super-app-theme--header',
      renderCell: (params) => (

        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={`${params.id}:${params.row.name}:${params.row.email}`}
              onClick={handleDeleteUserDialogOpen}
            >
              <DeleteIcon color="secondary" />
            </Button>


          </Grid>
        </Grid>
      )
    },
  ];
  const busesColumns = [
    {
      field: 'newId',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'plateNumber',
      headerName: 'Plate Number',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (

        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid
            item
          >
            <Button
              id={`${params.id}`}
              onClick={handleBusId}
              href={`/dashboard/buses/${busToView}`}>
              <VisibilityIcon color="primary" />
            </Button>
          </Grid>
          <Grid
            item
          >
            <Button
              id={`${params.id}`}
              onClick={handleOpenBusDelete}
            >
              <DeleteIcon color="secondary" />
            </Button>


          </Grid>
        </Grid>
      )
    },
  ];


  return (

    <div className={classes.contain}>
      <Grid
        container
        spacing={0}
        justify="space-between"
      >
        <Grid
          item
        >
          <Typography variant="h5" color={color.primary100} className={classes.header}>Bus Company Details</Typography>
        </Grid>
        <div className={classes.path}>
          {path}
        </div>
      </Grid>
      <div>
        {institution.pending ? (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        ) :
          <div >
            <Snackbar
              open={institution.snackBarMessage.open}
              onClose={closeSnackBarTimer}
              autoHideDuration={4000}
              TransitionComponent={TransitionUp}
            >
              <MuiAlert
                severity={institution.snackBarMessage.severity}
                variant='filled'
                elevation={6}
              >{institution.snackBarMessage.message}</MuiAlert>
            </Snackbar>
            <Grid container direction="column" alignItems="left">
              <Grid direction="column" container justify="left" alignItems="left" style={{ display: 'inline', marginTop: '15px' }} >
                <div style={{ display: 'block' }} >
                  <Typography
                  >
                    <b
                      className={classes.label}
                    >
                      Name
                    </b>
                    {institution.institution.name}
                  </Typography>
                </div>
                <div style={{ display: 'block' }} >
                  <Typography
                  >
                    <b
                      className={classes.label}
                    >
                      Description
                    </b>
                    {institution.institution.description}
                  </Typography>
                </div>
                <div style={{ display: 'block' }} >
                  <Typography
                  >
                    <b
                      className={classes.label}
                    >
                      Project:
                    </b>
                    {institution.project.name}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Typography variant='h6' className={classes.locations} >Routes</Typography>
              </Grid>
            </Grid>
            <Button
              color="primary"
              variant='contained'
              className={classes.btnstly}
              onClick={handleAddRoute} > Add new route</Button>
            <div style={{ height: rows.length == 0 ? 'auto' : 370, width: '100%' }}>
              {rows.length == 0 ?

                <NoEntries
                  eText={"This Bus Company does not have routes "}
                /> :
                <DataGrid
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  className={classes.root}
                  pageSize={perPage}
                  checkboxSelection
                  sortModel={[{ field: 'newId', sort: 'asc', }]}
                />
              }

            </div>

            <Typography
              variant="h6"
              color={color.black}
              className={classes.userheader}>
              Users
            </Typography>
            <Button
              color="primary"
              variant='contained'
              className={classes.btnstly}
              onClick={handleAssignUser} >
              ADD NEW USER
            </Button>
            <div style={{ height: rows2.length == 0 ? 'auto' : 370, width: '100%' }}>
              {rows2.length == 0 ?
                <NoEntries
                  eText={"This Bus Company does not have users"}
                />
                : <DataGrid rows={rows2} columns={columns2}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  className={classes.root}
                  pageSize={perPage}
                  checkboxSelection
                  sortModel={[{ field: 'newId', sort: 'asc', }]}
                />}

            </div>


            <Typography
              variant="h6"
              color={color.primary100}
              className={classes.userheader}>
              Buses
            </Typography>
            <Button
              color="primary"
              variant='contained'
              className={classes.btnstly}
              onClick={handleOpenAddBus} >
              ADD NEW BUS
            </Button>
            <div
              style={{
                height: busesRows.length == 0 ? 'auto' : 370,
                width: '100%'
              }}>
              {busesRows.length == 0 ?
                <NoEntries
                  eText={"This Bus Company does not have buses"}
                />
                :
                <DataGrid
                  rows={busesRows}
                  columns={busesColumns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  className={classes.root}
                  pageSize={perPage}
                  checkboxSelection
                  sortModel={[{ field: 'newId', sort: 'asc', }]}
                />}

            </div>
          </div>
        }
      </div>


      <Button color="primary" variant='contained' className={classes.btn} onClick={() => props.history.push("/dashboard/institutions")} > View Bus Companies</Button>
      <Dialog
        open={openDelete}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove Route</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are You sure you want to remove this route from this Bus Company?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleremove}
            color="primary" autoFocus variant='contained'>
            Remove
          </Button>
          <Button
            onClick={handleDeleteDialogClose}
            color="secondary" variant='contained'>
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteUser}
        onClose={handleDeleteUserDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">REMOVE USER FROM Bus Company</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are You sure you want to remove ${userName} from Bus Company ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={removeUser}
            color="primary" autoFocus variant='contained'>
            Yes
          </Button>
          <Button
            onClick={handleDeleteUserDialogClose}
            color="secondary" variant='contained'>
            No
          </Button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openAddRoute}
        onClose={handleClosseAddRoute}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ADD A ROUTE TO THIS BUS COMPANY</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={routeOptions}
              disableCloseOnSelect
              value={selectedRoutes}
              onChange={(event, newValue) => {
                setSelectedRoutes(newValue);
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    {option.title}
                  </li>
                )
              }}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} label="Routes" placeholder="Select routes" />
              )}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={addRoute}
            color="primary" autoFocus variant='contained'>
            Yes
          </Button>
          <Button
            onClick={handleClosseAddRoute}
            color="secondary" variant='contained'>
            No
          </Button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={assignUser}
        onClose={handleClosseAssignUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id="alert-dialog-title">ASSIGN NEW USER</DialogTitle>
        <DialogContent
          style={{
            width: '100%'
          }}
        >

          <DialogContentText id="alert-dialog-description">
            <Autocomplete
              id="free-solo-demo"
              multiple
              options={userOptions}
              value={userEmails}
              onChange={(event, newValue) => {
                setUserEmails(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="User emails" />
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={addUser}
            color="primary" autoFocus variant='contained'>
            Add Users
          </Button>
          <Button
            onClick={handleClosseAssignUser}
            color="secondary" variant='contained'>
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openAddBus}
        onClose={handleCloseAddBus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id="alert-dialog-title">ADD NEW BUS</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              autoFocus
              margin="dense"
              id="plateNumber"
              label="Plate Number"
              type="text"
              onChange={handlePlateNumberChange}
              fullWidth
            />
            <Autocomplete
              className={classes.forminput}
              id="combo-box-demo"
              fullWidth
              options={busMakeOptions}
              sx={{ width: '100%' }}
              value={busMakeOptions.value}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setMakeId(newValue.value)
              }}
              renderInput={(params) => <TextField
                {...params}
                label="Bus Make"
              />}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateBus}
            color="primary"
            autoFocus
            variant='contained'>
            Add Bus
          </Button>
          <Button
            onClick={handleCloseAddBus}
            color="secondary" variant='contained'>
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
      <DeleteModel
        title={'Delete Bus'}
        description={`Are you sure you want to delete this bus?`}
        isDeleteModelOpen={busDelete}
        closeDialog={handleCloseBusDelete}
        handleDelete={handleBusDelete}
      />
    </div>

  );
}

export default SingleInstitution

