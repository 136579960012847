import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { Link } from 'react-router-dom'
import { Link, useHistory } from "react-router-dom";
import image from "../assets/images/AC Group logo_White.png";
import Footer from "./landing/Footer";
import { Typography, Button } from "@material-ui/core";
import color from "./colors";
import { checkAuthedUser, logoutRequest } from "../redux/actions/auth/login";

// import image from "../../assets/images/AC Group logo_White.png";

const Welcome = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(checkAuthedUser());
  }, []);

  useEffect(() => {
    loggedInUser = loginState.loggedInUser;
  }, [loginState.loggedInUser]);

  let loggedInUser = loginState.loggedInUser;

  const userPermissions = loggedInUser ? loggedInUser.role.Permissions : {};

  return (
    <div>
      <div>
        <div className="top-bar">
          <img src={image} className="top-logo" alt="ac group logo"></img>
        </div>
        <div className="main-content">
          <div>
            <div className="signin-title">
              <p>Welcome to Incident Management System</p>
            </div>
            <div className="text-form-area">
              <div className="desc-text">
                <Typography>
                  AC Group is a technology company providing smart transport
                  solutions in Rwanda and across African cities since 2015.
                </Typography>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5em",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="p"
                    style={{
                      fontSize: 20,
                      color: "#19459D",
                    }}
                  >
                    {" "}
                    What would you like to do?
                  </Typography>

                  {userPermissions.viewStock && (
                    <Button
                      color="primary"
                      onClick={() => history.push("/stock")}
                      variant="contained"
                      style={{ backgroundColor: color.secondary }}
                    >
                      Stock
                    </Button>
                  )}
                  {userPermissions.manageIncident && (
                    <Button
                      color="primary"
                      onClick={() => history.push("/dashboard/reports")}
                      variant="contained"
                      style={{ backgroundColor: color.secondary }}
                    >
                      View Reports
                    </Button>
                  )}
                  {userPermissions.manageIncident && (
                    <Button
                      color="primary"
                      onClick={() => history.push("/dashboard/manageIncidents")}
                      variant="contained"
                      style={{ backgroundColor: color.secondary }}
                    >
                      Manage Incidents
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={() => history.push("/dashboard/newincident")}
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                  >
                    create new incident
                  </Button>

                  <Button
                    color="primary"
                    onClick={() => history.push("/dashboard/incidents")}
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                  >
                    view submitted incidents
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
