import { types } from "../../actions/types";

const initialState = {
    categories: null,
    pending: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CATEGORIES_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            };
 
        case types.GET_CATEGORIES_ERROR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
