import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import MaterialTable, {
  MTableToolbar,
  MTableBody,
  MTableAction,
} from "material-table";

import {
  // DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Slide,
  Snackbar,
  InputLabel,
  FormControl,
  TablePagination,
  Divider,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

import XLSX from "xlsx";
import PublishIcon from "@material-ui/icons/PublishOutlined";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import color from "../colors";
import AddModel from "./AddModel";
import { useLocation } from "react-router-dom";
import {
  Visibility,
  ImportExport,
  GetApp,
  Cancel,
  Check,
} from "@material-ui/icons/";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(4.5),
    fontWeight: "bold",
    color: `${color.primary100}`,
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`,
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`,
  },
  path: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  contain: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px",
  },
  title: {
    fontWeight: "bold",
    alignContent: "center",
  },
  container: {
    position: "relative",
    width: "100%",
    border: `solid 1px ${color.border}`,
    marginBottom: "2px",
    borderRadius: "3px",
  },
  btn: {
    backgroundColor: `${color.primary100}`,
    color: "white",
    padding: "9px",
    height: theme.spacing(5),
    marginTop: theme.spacing(-0.2),
    marginRight: theme.spacing(5),
  },
  deleteBtn: {
    backgroundColor: `#f44336`,
    color: "white",
    padding: "9px",
    height: theme.spacing(5),
    marginTop: theme.spacing(-0.2),
    marginRight: theme.spacing(5),
  },
  grid: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(5),
  },
  btn2: {
    backgroundColor: `${color.primary100}`,
    height: theme.spacing(5),
  },
  btn3: {
    height: theme.spacing(3.4),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  search: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: theme.spacing(4),
    height: "0px",
    marginRight: "0px",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(0),
  },
  root: {
    "& .super-app-theme--header": {
      fontWeight: "bolder",
    },
  },
  down: {
    minWidth: 100,
    height: 12,
  },
  formControl: {
    height: theme.spacing(5),
    padding: "10",
    paddingBottom: "0",
    backgroundColor: `${color.primary100}`,
    color: "white",
    font: "14px Roboto,Helvatica,Arail,sans-serif",
    minWidth: " 64px",
    boxSizing: "border-box",
    transition:
      " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
    fontWeight: 500,
    lineHeight: 1.6,
    borderRadius: "3px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    paddingRight: "0",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "9px",
    marginTop: theme.spacing(1),
    marginBottom: "0px",
  },
  table: {
    marginTop: theme.spacing(4),
  },
  label: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#d5d5d5",
    color: "black",
    padding: "0.3rem",
    fontFamily: "sans-serif",
    borderRadius: "0.3rem",
    cursor: "pointer",
    margin: "1rem",
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  whiteColor: {
    color: "white",
  },
  importbtn: {
    display: "flex",
    textTransform: "uppercase",
    color: `${color.primary100}`,
    marginBottom: theme.spacing(2),
    "&:hover": {
      background: "#edf4fc",
    },
    importname: {
      font: "13px Roboto,Helvatica,Arail,sans-serif",
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
  },
  top: {
    marginBottom: theme.spacing(2),
  },
  middle: {
    marginBottom: theme.spacing(3),
  },
}));
function DisplayAll(props) {
  const {
    cTitle,
    addBtnTitle,
    showAddBtn = true,
    rows,
    columns,
    cleaner,
    uploader,
    reducer,
    children,
    handleCreate,
    types,
    makes,
    busMakes,
    type,
    setType,
    iTypes,
    replacements,
    iMakes,
    showSearch = true,
    showTitle = true,
    showImport = true,
    pending,
    direct,
    categories,
    multipleDeleteHandler,
    hideDeleteAll,
    usemTable,
    tLayout = "auto",
  } = props;

  const history = useHistory();

  const [openAddModel, setAddModelOpen] = useState(false);
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(10);
  const [tableColomns, setTableColomns] = useState(null);
  const [tableRows, setTableRows] = useState(null);
  const [saveUpload, setUpload] = useState("none");
  const [selectedIds, setSelectedIds] = useState(null);

  const handleUpload = () => {
    dispatch(uploader(tableRows));
    setTableRows(null);
    setTableColomns(null);
    setUpload("none");
  };
  const handlecancleUpload = () => {
    setTableRows(null);
    setTableColomns(null);
    setUpload("none");
  };

  const classes = useStyles();
  let location = useLocation();

  let path = [];
  const getPath = () => {
    const general = location.pathname.split("/");
    let url = null;
    for (let i = 1; i < general.length; i++) {
      if (i === general.length - 1) {
        url = (
          <Typography variant="subtitle2" className={classes.header3}>{`${
            general[i][0].toUpperCase() + general[i].slice(1)
          }`}</Typography>
        );
        path.push(url);
      } else {
        url = (
          <Typography variant="subtitle2" className={classes.header2}>{`${
            general[i][0].toUpperCase() + general[i].slice(1)
          }>`}</Typography>
        );
        path.push(url);
      }
    }
  };

  const ImportExcel = (file) => {
    const EXTENSIONS = ["xlsx", "xls", "csv"];
    const getExtensions = (file) => {
      const name = file.name.split(".");
      return EXTENSIONS.includes(name[name.length - 1]);
    };
    const convertDataToJson = (header, fileData) => {
      let rows = [];
      let id = 0;
      fileData.forEach((row) => {
        let rowData = {};
        row.forEach((cell, index, arr) => {
          rowData[header[index]] = cell;
          rowData.id = index + id;
          rowData.newId = index + id;
          if (arr.length === index + 1) id++;
        });

        rows.push(rowData);
      });
      return rows;
    };
    let data = null;
    const reader = new FileReader();
    if (file) {
      if (getExtensions(file)) {
        reader.readAsBinaryString(file);
        setUpload("flex");
      } else alert("Invalid file input, Select an Excel or CSV file");
    } else {
      setUpload("none");
      setTableRows(null);
      setTableColomns(null);
    }

    reader.onload = (e) => {
      const binaryData = e.target.result;
      const workBook = XLSX.read(binaryData, { type: "binary" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const excelData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const header = excelData[0].map((head) => head.toLowerCase());
      header.push("newId");
      const headers = header.map((head) => ({
        headerName: head.toUpperCase(),
        field: head,
        flex: 1,
        title: head.toUpperCase(),
      }));
      headers.forEach((head) => {
        if (head.field === "newId") {
          head.hide = true;
        }
      });
      excelData.splice(0, 1);
      data = headers;
      setTableColomns(data);
      setTableRows(convertDataToJson(header, excelData));
    };
  };
  const handleImport = (e) => {
    ImportExcel(e.target.files[0]);
    e.target.value = null;
  };
  const handleChange = (e) => {
    setPerPage(e.target.value);
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const closeSnackBarTimer = () => {
    dispatch(cleaner());
  };

  const handleOpenAddModel = direct ? direct : () => setAddModelOpen(true);
  const handleCloseAdd = () => setAddModelOpen(false);
  const addItem = () => {
    handleCreate();
    handleCloseAdd();
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {showImport && (
          <Grid item>
            <input
              id="file"
              name="upload_data"
              type="file"
              onChange={handleImport}
              hidden={true}
            />
            <label htmlFor="file">
              <div
                variant="contained"
                className={classes.importbtn}
                style={{
                  display: "inherit",
                  alignItems: "inherit",
                  justifyContent: "inherit",
                  cursor: "pointer",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.importname}
                    style={{
                      fontSize: "0.8125rem",
                    }}
                  >
                    &nbsp; Import
                  </Typography>
                </Grid>
              </div>
            </label>
          </Grid>
        )}
      </GridToolbarContainer>
    );
  };
  return (
    <div className={classes.contain}>
      {showTitle && (
        <Grid
          container
          spacing={0}
          justify="space-between"
          className={classes.top}
        >
          <Grid item xs={10}>
            <Typography
              variant="h5"
              color={color.primary100}
              className={classes.header}
            >
              {" "}
              {cTitle}
            </Typography>
          </Grid>
          <div className={classes.path}>{path}</div>
        </Grid>
      )}
      <Grid container className={classes.middle} justify="space-between">
        <Grid item>
          {showAddBtn && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={handleOpenAddModel}
            >
              {addBtnTitle}
            </Button>
          )}

          {types && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => history.push("/dashboard/types/manage")}
            >
              Manage Types
            </Button>
          )}
          {makes && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => history.push("/dashboard/devices/makes/manage")}
            >
              Manage Makes
            </Button>
          )}
          {busMakes && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => history.push("/dashboard/buses/makes/manage")}
            >
              Manage Makes
            </Button>
          )}
          {iTypes && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => history.push("/dashboard/devices")}
            >
              {" "}
              View Devices
            </Button>
          )}
          {iMakes && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => history.push("/dashboard/buses")}
            >
              {" "}
              View Buses
            </Button>
          )}
          {replacements && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() =>
                history.push("/dashboard/exchange/devices/replacements")
              }
            >
              {" "}
              Replacements
            </Button>
          )}
          {categories && (
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() =>
                history.push("/dashboard/incident/categories/manage")
              }
            >
              {" "}
              Manage Categories
            </Button>
          )}
          {selectedIds && selectedIds.length !== 0 && !hideDeleteAll && (
            <Button
              color="secondary"
              variant="contained"
              className={classes.deleteBtn}
              onClick={() => {
                console.log("jhere we goo", selectedIds);
                multipleDeleteHandler(selectedIds);
              }}
            >
              {" "}
              Delete Selected
            </Button>
          )}

          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={perPage}
            color="primary"
            onChange={handleChange}
            label="Age"
            className={classes.formControl}
          >
            <MenuItem value={5}>SHOW 5 ITEMS</MenuItem>
            <MenuItem value={10}>SHOW 10 ITEMS</MenuItem>
            <MenuItem value={20}>SHOW 20 ITEMS</MenuItem>
            <MenuItem value={30}>SHOW 30 ITEMS</MenuItem>
            <MenuItem value={rows.length}>SHOW ALL ITEMS</MenuItem>
          </Select>
        </Grid>
        {showSearch && (
          <Grid item className={classes.search} justify="flex-end">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
            />
            <Button
              color="primary"
              variant="contained"
              className={classes.btn2}
            >
              search
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        style={{
          display: iTypes ? "none" : "flex",
          alignItems: types ? "center" : "center",
        }}
      >
        {showImport && (
          <div>
            <input
              id="file"
              name="upload_data"
              type="file"
              onChange={handleImport}
              hidden={true}
            />
            <label htmlFor="file">
              <div variant="contained" className={classes.importbtn}>
                <PublishIcon />
                <Typography variant="subtitle1" className={classes.importname}>
                  Import
                </Typography>
              </div>
            </label>
          </div>
        )}
        <div style={{ display: `${saveUpload}` }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpload}
            className={classes.btn3}
          >
            {" "}
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handlecancleUpload}
            className={classes.btn3}
          >
            {" "}
            Cancel
          </Button>
        </div>
        {types && (
          <FormControl style={{ marginLeft: "auto", width: "25%" }}>
            <InputLabel
              htmlFor="demo-simple-select-outlined"
              className={classes.selectlabel}
            >
              <i>Select a type to view devices</i>
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={type}
              color="primary"
              onChange={(e) => setType(e.target.value)}
              label="Select a type to view devices"
            >
              <MenuItem value="">
                <i>
                  <b>View all devices</b>
                </i>
              </MenuItem>
              {types.map((typ) => (
                <MenuItem value={typ.id}>{typ.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Snackbar
        open={reducer ? reducer.snackBarMessage.open : false}
        onClose={closeSnackBarTimer}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
      >
        <MuiAlert
          severity={reducer ? reducer.snackBarMessage.severity : ""}
          variant="filled"
          elevation={6}
        >
          {reducer ? reducer.snackBarMessage.message : ""}
        </MuiAlert>
      </Snackbar>

      {pending ? (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            height: usemTable ? "auto" : 460,
            width: "100%",
            fontFamily: "Poppins', sans-serif !important",
          }}
        >
          {usemTable ? (
            <MaterialTable
              columns={tableColomns ? tableColomns : columns}
              data={tableRows ? tableRows : rows}
              title={cTitle}
              options={{
                exportButton: true,
                tableLayout: tLayout,
                padding: "dense",
                paging: true,
                pageSize: perPage,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [15, 30, 50, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  whiteSpace: "nowrap",
                },
                rowStyle: {
                  whiteSpace: "nowrap",
                  fontSize: 13,
                  fontFamily: "Poppins', sans-serif !important",
                },
              }}
              components={{
                Toolbar: MTableToolbar,
              }}
            />
          ) : (
            <DataGrid
              columns={tableColomns ? tableColomns : columns}
              rows={tableRows ? tableRows : rows}
              components={{
                Toolbar: GridToolbar,
              }}
              onSelectionModelChange={(ids) => {
                // console.log("The items selectd are", ids);
                setSelectedIds(ids);
              }}
              className={classes.root}
              pageSize={perPage}
              checkboxSelection
            />
          )}
        </div>
      )}
      <AddModel
        isModelOpen={openAddModel}
        closeAddModel={handleCloseAdd}
        handleCreate={addItem}
      >
        {children}
      </AddModel>
    </div>
  );
}

export default DisplayAll;
