import React from 'react'
import { DataGrid } from '@material-ui/data-grid';
import color from "../colors";
import { makeStyles } from '@material-ui/core'
import moment from 'moment';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    subtitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(7),
        color: color.primary100,
        fontSize: '1.25rem',
    },
}))

const AdditionalInfo = props => {
    const classes = useStyles()
    const { creator, updator, creationDate, updateDate } = props
    const rows = [
        {
            key: "Created At", Value: moment(creationDate).format('MMMM Do YYYY, h:mm:ss a')
        },
        {
            key: "Created By", Value: creator
        },
        {
            key: "Updated At", Value: moment(updateDate).format('MMMM Do YYYY, h:mm:ss a')
        },
        {
            key: "Updated By", Value: updator
        }
    ];
    const columns = [
        {
            field: 'key',
            title: 'Key',
            width: "15%",
            cellStyle: {
                backgroundColor: '#fff',
                color: '#000',
                fontWeight: 'bolder',
                fontSize: '0.9em'
            },
            headerStyle: {
                backgroundColor: '#587ECA',
                color: '#fff',
                fontWeight: 'bolder',
                fontSize: '1.2em',
            }
        },
        {
            field: 'Value',
            title: 'Value',
            width: "85%",
            cellStyle: {
                backgroundColor: '#fff',
                color: 'grey',
                fontSize: '0.9em'

            },
            headerStyle: {
                backgroundColor: '#587ECA',
                color: '#fff',
                fontWeight: 'bolder',
                fontSize: '1.2em',
            }
        },
    ]
    // const columns = [
    //     {
    //         field: 'key',
    //         headerName: 'Key',
    //         width: '25em',
    //         headerClassName: `${classes.tableHeading}`,
    //     },
    //     {
    //         field: 'value',
    //         headerName: 'Value',
    //         width: '60em',
    //         headerClassName: `${classes.tableHeading}`,
    //     },
    // ]
    // let rows = [
    //     {
    //         id: 1,
    //         key: "Created At",
    //         value: creationDate
    //     },
    //     {
    //         id: 2,
    //         key: "Created By",
    //         value: creator
    //     },
    //     {
    //         id: 3,
    //         key: "Updated At",
    //         value: updateDate
    //     },
    //     {
    //         id: 4,
    //         key: "Updated By",
    //         value: updator
    //     }

    // ]

    return (
        <div>
            <div
                style={{
                    width: '100%'
                }}>

                <Typography variant={'h5'} className={classes.subtitle}>Additional Information</Typography>
                <MaterialTable
                    data={rows}
                    columns={columns}
                    options={{
                        paging: false,
                        search: false,
                        toolbar: false
                    }}
                    style={{ marginTop: "20px" }}
                />
                {/* <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    hideFooter={true}
                    columnHeader={{ backgroundColor: 'black' }}
                /> */}
            </div>
        </div>
    )
}

export default AdditionalInfo
