import React, { useEffect } from 'react'
import { Grid, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { checkAuthedUser } from '../../../redux/actions/auth/login'
import { useHistory } from 'react-router'
// import { useDispatch } from 'react-redux'

const IncNavLinks = ({
    hideSubmitIncident,
    hideRequestLoan,
    hideRequestRefund
}) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const authedUser = useSelector(state => state.login.loggedInUser)

    useEffect(() => {
        dispatch(checkAuthedUser())
    }, [])

    // console.log("The authed user here is:", authedUser)

    return (
        <div>
            <Grid
                container
                style={{ marginBottom: '1em' }}
            >
                {authedUser && (authedUser.role.Permissions.requestLoan || authedUser.role.Permissions.requestSuperRefund) && !hideSubmitIncident && <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '1em' }}
                        onClick={() => history.push('/dashboard/newincident')}
                    >
                        Report Incident
                    </Button>
                </Grid>
                }
                {authedUser && authedUser.role.Permissions.requestLoan && !hideRequestLoan && <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '1em' }}
                        onClick={() => history.push('/dashboard/request/loan')}
                    >
                        Request Loan
                    </Button>
                </Grid>
                }
                {authedUser && authedUser.role.Permissions.requestSuperRefund && !hideRequestRefund && <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '1em' }}
                        onClick={() => history.push('/dashboard/superagent/refund')}
                    >
                        Request Refund
                    </Button>
                </Grid>
                }
            </Grid>
        </div>
    )
}

export default IncNavLinks