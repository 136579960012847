import React, { useEffect, useState } from "react";
import { TextField, Typography, Button, Grid, Slide } from "@material-ui/core";
import { useStyles } from "./useStyles";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceReplacements } from "../../redux/actions/devices/deviceReplacements";
import { clearSnackBar } from "../../redux/actions/devices/snack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModel from "../templates/DeleteModel";
import { useLocation, useHistory } from "react-router-dom";
import RoleTable from "../templates/DisplayAll";
import EditModel from "../templates/EditModel";

const DeviceReplacements = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const replacementReducer = useSelector((state) => state.devices);
  let location = useLocation();
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [update, setUpdate] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(getDeviceReplacements());
  }, []);

  let rows;
  replacementReducer.deviceReplacements
    ? (rows = replacementReducer.deviceReplacements)
    : (rows = []);
  const columns = [
    {
      field: "newId",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "actor",
      headerName: "Replacer",
      flex: 2,
    },
    {
      field: "plateNumber",
      headerName: "Bus",
      flex: 2,
    },
    {
      field: "prevDevice",
      headerName: "Previous Device",
      flex: 2,
    },
    {
      field: "currDevice",
      headerName: "Current Device",
      flex: 2,
    },
  ];

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };

  rows.forEach((row, index) => {
    row.newId = index + 1;
    row.actor = row.createdUser ? row.createdUser.name : "";
    row.plateNumber = row.replacementBus ? row.replacementBus.plateNumber : "";
    row.prevDevice = row.previousDevice ? row.previousDevice.deviceImei : "";
    row.currDevice = row.currDevice ? row.currDevice.deviceImei : "";
  });
  return (
    <div className={classes.contain}>
      <RoleTable
        cTitle="Device Replacements"
        addBtnTitle="Add Device Replacement"
        rows={rows}
        columns={columns}
        cleaner={closeSnackBarTimer}
        reducer={replacementReducer}
        showAddBtn={false}
        iTypes={true}
      ></RoleTable>
    </div>
  );
};

export default DeviceReplacements;
