import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  projects: null,
  project: null,
  pending: false,
  assigned: false,
  deleted: false,
  userRemoved: false,
  upload: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROJECTS_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.UPLOAD_PROJECTS:
      return {
        ...state,
        message: action.message,
        upload: state.upload ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.CREATE_PROJECT:
      return {
        ...state,
        project: action.project,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.DELETE_PROJECT:
      return {
        ...state,
        message: action.message,
        pending: false,
        deleted: state.deleted ? false : true,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      }
    case types.UPDATE_PROJECT:
      return {
        ...state,
        message: action.message,
        project: action.project,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.ASSIGN_PROJECT:
      return {
        ...state,
        message: action.message,
        pending: false,
        assigned: state.assigned ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.REMOVE_PROJECT_ASSIGNEE:
      return {
        ...state,
        message: action.message,
        pending: false,
        userRemoved: state.userRemoved ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_PROJECTS_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CLEAR_PROJECTS_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};