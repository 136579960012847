import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import {
    Dashboard,
    ReportProblem,
    AccountTree,
    SupervisedUserCircle,
    TouchApp,
    AccountCircle,
    Menu,
    ChevronRight,
    Notifications,
    Mail,
    VerifiedUser,
    DirectionsBus,
    PinDrop,
    Gavel,
    EmojiTransportation,
    DeviceHub,
    Public,
    GridOn
} from '@material-ui/icons/';
import CountUp from 'react-countup';
import { Bar, Line } from 'react-chartjs-2'
import { getInstitutions } from '../../../redux/actions/institutions/institutions'
import { getBuses } from '../../../redux/actions/buses/buses'
import { getDevices } from '../../../redux/actions/devices/getDevices'
import { getLocations } from '../../../redux/actions/locations/locationActions'
import { getProjects } from '../../../redux/actions/projects/projectsAction'
import { getSites } from '../../../redux/actions/sites/sites'
import { getIncidentsManager, getIncidentsAdmin } from '../../../redux/actions/incidents/incidentActions'
import { getDeviceTypes } from '../../../redux/actions/devices/deviceTypes'
import { getRoutes } from '../../../redux/actions/routes/routes'
import { getUsersAction } from '../../../redux/actions/usersAction';
import colors from '../../colors';
// import { setWeek } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    element: {
        // backgroundColor: '#F0F3F4',
        padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    viewMode: {
        marginRight: '1em',
        cursor: 'pointer',
        color: '#BDBDBC'
    },
    selectedViewMode: {
        marginRight: '1em',
        cursor: 'pointer',
        color: colors.primary100,
        fontWeight: 'bolder',
        borderBottom: `2px solid ${colors.primary100}`
    }
}))

const AdminDashboard = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const institutions = useSelector(state => state.institutions.institutions)
    const projects = useSelector(state => state.projects.projects)
    const users = useSelector(state => state.users.users)
    const devices = useSelector(state => state.devices.devices)
    const sites = useSelector(state => state.sites.sites)
    const routes = useSelector(state => state.routes.routes)
    const buses = useSelector(state => state.buses.buses)
    const typeReducer = useSelector(state => state.devices.deviceTypes);
    const incidents = useSelector(state => state.incidents.incidents)

    const [daily, setDaily] = useState(false)
    const [weekly, setWeekly] = useState(true)
    const [monthly, setMonthly] = useState(false)
    const [label, setLabel] = useState(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])

    // let xAxis = 



    const viewDaily = () => {
        setDaily(true)
        setWeekly(false)
        setMonthly(false)
        setLabel(['1', '2,', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'])
    }

    const viewWeekly = () => {
        setWeekly(true)
        setMonthly(false)
        setDaily(false)
        setLabel(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])

    }
    const viewMonthly = () => {
        setMonthly(true)
        setDaily(false)
        setWeekly(false)
        setLabel(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'])
    }

    let incidentsReport = {}
    if (incidents) {
        incidents.map(incident => {
            let incidentNumber = 0
            let d = new Date(incident.createdAt);
            let weekday = new Array(7);
            weekday[0] = "Sun";
            weekday[1] = "Mon";
            weekday[2] = "Tue";
            weekday[3] = "Wed";
            weekday[4] = "Thu";
            weekday[5] = "Fri";
            weekday[6] = "Sat";
            let n = weekday[d.getDay()];
            incidentsReport[n] = incidentNumber
            if (incidentsReport[n]) {
                incidentsReport[n] = incidentNumber + 1
            } else {
                incidentsReport[n] = incidentNumber

            }
        })
    }
    useEffect(() => {
        dispatch(getProjects())
        dispatch(getInstitutions())
        dispatch(getDevices())
        dispatch(getSites())
        dispatch(getRoutes())
        dispatch(getBuses())
        dispatch(getDeviceTypes())
        dispatch(getIncidentsAdmin())
        dispatch(getUsersAction())
    }, [])


    const acceptedIncidents = incidents ? incidents.filter(incident => incident.accepted) : []
    const rejectedIncidents = incidents ? incidents.filter(incident => incident.declined) : []
    const resolvedIncidents = incidents ? incidents.filter(incident => incident.resolved) : []
    const pendingIncidents = incidents ? incidents.filter(incident => !incident.accepted && !incident.declined && !incident.resolved) : []

    const cardInfo = [
        {
            id: 1,
            icon: <DeviceHub />,
            text: "Devices",
            quantity: devices ? devices.length : 0,
            path: '/dashboard/devices'
        },
        {
            id: 2,
            icon: <AccountCircle />,
            text: "Users",
            quantity: users ? users.length : 0,
            path: '/dashboard/users'
        },
        {
            id: 3,
            icon: <EmojiTransportation />,
            text: "Institutions",
            quantity: institutions ? institutions.length : 0,
            path: '/dashboard/institutions'
        },
        {
            id: 4,
            icon: <PinDrop />,
            text: "Sites",
            quantity: sites ? sites.length : 0,
            path: '/dashboard/sites'
        },
        {
            id: 5,
            icon: <Public />,
            text: "Routes",
            quantity: routes ? routes.length : 0,
            path: '/dashboard/routes'
        },
        {
            id: 6,
            icon: <DirectionsBus />,
            text: "Buses",
            quantity: buses ? buses.length : 0,
            path: '/dashboard/buses'
        },
    ]
    const incidentsInfo = [
        {
            id: 1,
            text: "Resolved Incidents",
            quantity: resolvedIncidents ? resolvedIncidents.length : 0,
        },
        {
            id: 2,
            text: "Rejected Incidents",
            quantity: rejectedIncidents ? rejectedIncidents.length : 0,
        },
        {
            id: 3,
            text: "Accepted Incidents",
            quantity: acceptedIncidents ? acceptedIncidents.length : 0,
        },
        {
            id: 4,
            text: "Pending Incidents",
            quantity: pendingIncidents ? pendingIncidents.length : 0,
        },
    ]

    return (
        <div
            className={classes.element}
        >
            <Grid
                container
            >
                {cardInfo.map(info => {
                    return (
                        <Grid
                            key={info.id}
                            onClick={() => props.history.push(`${info.path}`)}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '1em',
                                padding: '0.5em',
                                alignItems: 'center',
                                boxShadow: '0 0 3px 2px #D0D3D4 ',
                                width: '10.5em',
                                cursor: 'pointer',
                                borderRadius: '5px'

                            }}>
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >

                                <span
                                    style={{
                                        color: colors.primary100
                                    }}
                                >
                                    {info.icon}&nbsp;
                                </span>
                                <Typography
                                    style={{ color: '#BDBDBC' }}
                                >{info.text}</Typography>
                            </Grid>
                            <Grid
                                style={{ fontWeight: 'bold' }}>
                                <CountUp
                                    start={0}
                                    end={Number(info.quantity)}
                                    duration={1}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid
                container
            >
                <Grid
                    onClick={viewDaily}
                    className={daily ? classes.selectedViewMode : classes.viewMode}
                >
                    <Typography>
                        View Daily
                    </Typography>
                </Grid>
                <Grid
                    onClick={viewWeekly}
                    className={weekly ? classes.selectedViewMode : classes.viewMode}
                >
                    <Typography>
                        View Weekly
                    </Typography>
                </Grid>
                <Grid
                    onClick={viewMonthly}
                    className={monthly ? classes.selectedViewMode : classes.viewMode}
                >
                    <Typography>
                        View Monthly
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    marginTop: '1em'
                }}>
                <Grid
                    item
                    lg={9}
                >
                    <Line
                        data={{
                            labels: label,
                            datasets: [
                                {
                                    label: 'Accepted Incidents',
                                    data: [1, 10, 3, 8, 2, 3, 2],
                                    borderColor: '#5BBA47',
                                    borderWidth: 2
                                },
                                {
                                    label: 'Rejected Incidents',
                                    data: [7, 20, 3, 5, 2, 18, 6],
                                    borderColor: '#F0532C',
                                    borderWidth: 2
                                },
                                {
                                    label: 'Resolved Incidents',
                                    data: [9, 7, 3, 5, 2, 8, 20],
                                    borderColor: '#066C3F',
                                    borderWidth: 2
                                },
                                {
                                    label: 'Pending Incidents',
                                    data: [8, 6, 15, 5, 4, 3, 17],
                                    borderColor: '#FEBF33',
                                    borderWidth: 2
                                }
                            ]
                        }}
                        height={450}
                        width={600}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            }

                        }}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                >
                    <Grid

                        style={{
                            backgroundColor: colors.primary100,
                            color: '#fff',
                            width: '100%',
                            borderRadius: '10px',
                            padding: '10px',
                            fontWeight: 'bolder'
                        }}
                    >

                        <Typography>Total Submitted Incidents</Typography>
                        <Grid
                            style={{
                                fontSize: '50px'
                            }}>
                            <CountUp
                                start={0}
                                end={incidents ? incidents.length : 0}
                                duration={2}
                            />

                        </Grid>
                        <Button
                            variant='contained'
                            onClick={() => props.history.push('/dashboard/manageIncidents')}
                        >View All Incidents</Button>
                    </Grid>
                    <Grid
                        container
                    >
                        {incidentsInfo.map(info => {
                            return (
                                <Grid
                                    key={info.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '1em',
                                        padding: '0.5em',
                                        alignItems: 'center',
                                        boxShadow: '0 0 3px 2px #D0D3D4 ',
                                        width: '120%',
                                        borderRadius: '5px'
                                    }}>
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            style={{ color: '#BDBDBC' }}
                                        >{info.text}</Typography>
                                    </Grid>
                                    <Grid
                                        style={{ fontWeight: 'bold' }}>
                                        <CountUp
                                            start={0}
                                            end={Number(info.quantity)}
                                            duration={1}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default AdminDashboard
