import axios from 'axios';
import { types } from "../types";


export const getSuperagentRequests = () => dispatch => {
    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests`)
        .then(res => {
            console.log("The response is:", res)
            // const sortedItems = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_SUPER_REQUESTS_SUCCESS,
                superagentRequests: res.data.data,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}
export const getSingleSuperRequest = (id) => dispatch => {
    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/${id}`)
        .then(res => {
            console.log("The single request we are receiving is:", res.data.data)
            dispatch({
                type: types.GET_SUPER_REQUEST_SUCCESS,
                superagentRequest: res.data.data,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                error: err,
                message: err.response && err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}
export const createSuperagentRequest = (data, props) => dispatch => {
    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests`, data)
        .then(res => {
            dispatch({
                type: types.CREATE_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message,
            })
            props.history.push("/dashboard/incidents")
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}
export const resolveRequest = (id) => dispatch => {

    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/resolve/${id}`, {})
        .then(res => {
            dispatch({
                type: types.RESOLVE_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}
export const rejectRequest = (id) => dispatch => {

    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/reject/${id}`, {})
        .then(res => {
            dispatch({
                type: types.REJECT_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}

export const assignRefund = (id, assigneeId, tag) => dispatch => {

    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/assign/${id}`, { assigneeId, tag })
        .then(res => {
            dispatch({
                type: types.ASSIGN_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}
export const acceptRequest = (id) => dispatch => {

    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/accept/${id}`, {})
        .then(res => {
            dispatch({
                type: types.ACCEPT_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}
export const refundRequest = (id) => dispatch => {

    dispatch({
        type: types.SUPER_REQUESTS_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/superagent/requests/refund/${id}`, {})
        .then(res => {
            dispatch({
                type: types.REFUND_SUPER_REQUEST,
                superagentRequest: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.SUPER_REQUESTS_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}