import {
    DEVICES_ERROR,
    DEVICES_PENDING,
    GET_DEVICES,
    GET_ONE_DEVICE,
    CREATE_DEVICE,
    UPDATE_DEVICE,
    DELETE_DEVICE,
    CLEAR_SNACKBAR,
    UPLOAD_DEVICES,
    GET_DEVICE_TYPES,
    GET_ONE_TYPE,
    CREATE_TYPE,
    UPDATE_TYPE,
    DELETE_TYPE,
    GET_DEVICE_MAKES,
    CREATE_MAKE,
    DELETE_MAKE,
    GET_ONE_MAKE,
    UPDATE_MAKE,
    GET_DEVICE_REPLACEMENTS
} from '../../actions/devices/type';

const initialState = {
    devices: [],
    pending: false,
    device: {},
    newDevice: '',
    deleted: '',
    updated: null,
    upload: true,
    error: '',
    // changed: false,
    deviceTypes: [],
    type: {},
    updateType: '',
    deleteType: '',
    deviceMakes: [],
    make: {},
    deleteMake: '',
    deviceReplacements: [],
    updateMake: '',
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
}

export default function devicesReducer(state = initialState, action) {
    switch (action.type) {
        case DEVICES_ERROR:
            return {
                ...state,
                error: action.payload,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.payload
                }
            }
        case DEVICES_PENDING:
            return {
                ...state,
                pending: true
            }
        case GET_DEVICES:
            return {
                ...state,
                devices: action.payload,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            }
        case GET_ONE_DEVICE:
            return {
                ...state,
                device: action.payload,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            }
        case CREATE_DEVICE:
            return {
                ...state,
                newDevice: action.payload,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case DELETE_DEVICE:
            return {
                ...state,
                deleted: state.deleted ? false : true,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.payload
                // }
            }
        case UPDATE_DEVICE:
            return {
                ...state,
                updated: action.payload,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case UPLOAD_DEVICES: {
            return {
                ...state,
                upload: action.payload,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        }
        case CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        case GET_DEVICE_TYPES: {
            return {
                ...state,
                pending: false,
                deviceTypes: action.payload
            }
        }
        case GET_ONE_TYPE:
            return {
                ...state,
                pending: false,
                type: action.payload
            }
        case CREATE_TYPE:
            return {
                ...state,
                type: action.payload,
                pending: false,
            }
        case DELETE_TYPE:
            return {
                ...state,
                deleteType: action.payload,
                pending: false,
            }
        case UPDATE_TYPE:
            return {
                ...state,
                pending: false,
                updateType: action.payload,
            }


        case GET_DEVICE_MAKES: {
            return {
                ...state,
                pending: false,
                deviceMakes: action.payload
            }
        }
        case GET_ONE_MAKE:
            return {
                ...state,
                pending: false,
                make: action.payload
            }
        case CREATE_MAKE:
            return {
                ...state,
                make: action.payload,
                pending: false,
            }
        case DELETE_MAKE:
            return {
                ...state,
                deleteMake: action.payload,
                pending: false,
            }
        case UPDATE_MAKE:
            return {
                ...state,
                pending: false,
                updateMake: action.payload,
            }

        case GET_DEVICE_REPLACEMENTS: {
            // console.log("The action is:", action)
            return {
                ...state,
                pending: false,
                deviceReplacements: action.payload
            }
        }
        default:
            return state;
    }
}
