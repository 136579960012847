import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

import {
    getDamages,
    clearSnackBar,
    createDamage,
    deleteDamage,
    updateDamage,
    uploadDamages
} from '../../redux/actions/damages/damages'
import {
    getProjects
} from "../../redux/actions/projects/projectsAction"
import { getCategories } from '../../redux/actions/categories/categoriesActions'

import color from "../colors";
import DisplayAll from '../templates/DisplayAll';
import DeleteModel from '../templates/DeleteModel';
import EditModel from '../templates/EditModel';


const useStyles = makeStyles((theme) => ({
    element: {
        // padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    header: {
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    progress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 3, 1),
    },
}))

const Issues = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    let history = useHistory()
    // const damages = useSelector(state => state.damages.damages)
    const issuesState = useSelector(state => state.damages)
    const projects = useSelector(state => state.projects.projects)
    const categories = useSelector(state => state.categories)



    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [issueName, setIssueName] = useState(null)
    const [issueDescription, setIssueDescription] = useState(null)
    const [issueToDelete, setIssueToDelete] = useState(null)
    const [issueToEdit, setIssueToEdit] = useState(null)
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    const [project, setProject] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const [projectToEdit, setProjectToEdit] = useState()
    const [editingProject, setEditingProject] = useState({})
    const [selectedProjects, setSelectedProjects] = useState([])
    const [editingData, setEditingData] = useState([])
    const [editingCategory, setEditingCategory] = useState()



    useEffect(() => {
        dispatch(getDamages())
        dispatch(getProjects())
        dispatch(getCategories())

    }, [issuesState.damage, issuesState.deleted, issuesState.upload])

    const handleOpenEditModel = (data) => {
        setEditingData(data)
        // const element = e.currentTarget.id.split(",")
        // // console.log("The element we are manupulating is:", element)
        // const projectWeEdit = projects.filter(project => project.id == element[3])

        // // console.log("The project we edit is", projectWeEdit)
        // // console.log("The damages we have are:,", damagesState.damages)

        // setIssueToEdit(element[0])
        // setName(element[1])
        // setDescription(element[2])
        setOpenEdit(true)
        // setEditingProject({ ...projectWeEdit })
        // // console.log("The project we are editing is:", editingProject ? editingProject.id : 0)
    }

    const handleCloseEditModel = () => {
        setName(null)
        setDescription(null)
        setOpenEdit(false)
    }
    const handleNameToEdit = (e) => setName(e.target.value)
    const handleDescriptionToEdit = (e) => setDescription(e.target.value)
    // console.log("The editing data is here:", editingData)

    const handleEditIssue = () => {
        // console.log(editingProject)
        dispatch(updateDamage(editingData.id, name, description, editingCategory))
        handleCloseEditModel()

    }
    const handleOpenDeleteModel = (e) => {
        setOpenDelete(true)
        const element = e.currentTarget.id.split(",")
        setIssueToDelete(element[0])
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleDelete = (e) => {
        dispatch(deleteDamage(issueToDelete))
        setOpenDelete(false)
    }

    const handleName = (e) => setIssueName(e.target.value)
    const handleDescription = (e) => setIssueDescription(e.target.value)
    const handleCreateIssue = () => {
        let projectIds = []
        selectedProjects.map(oneProject => projectIds.push(oneProject.id))
        // console.log(selectedProjects)
        dispatch(createDamage(issueName, issueDescription, projectIds, categoryId))

        setIssueName(null)
        setIssueDescription(null)
        setSelectedProjects([])
    }

    const projectOptions = projects ? projects.map(project => {
        return {
            name: project.name,
            id: project.id
        }
    }) : []
    const categoryOptions = categories.categories ? categories.categories.map(category => {
        return {
            name: category.name,
            id: category.id
        }
    }) : []

    useEffect(() => {
        const nextEdit = projectOptions.filter(project => project.value == editingProject)

        // console.log("The next edit is:", nextEdit, "from", editingProject)


    }, [editingProject])

    const columns = [
        {
            field: 'newId',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'projectName',
            headerName: 'Project',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'categoryName',
            headerName: 'Category',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 3,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 250,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => history.push(`/dashboard/issues/${params.getValue('id')}`)}
                        >
                            <VisibilityIcon color="primary" />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => handleOpenEditModel(params.row)}
                            id={`${params.getValue('id')},${params.getValue('name')},${params.getValue('description')},${params.getValue('projectId')}`}>
                            <EditIcon />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            id={`${params.getValue('id')},${params.getValue('name')}`} >
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];
    let rows
    const issues = issuesState.damages

    //     if (damages) {
    //         damages.map(damage => {
    //             damage.newId = (damages.indexOf(damage)) + 1)

    //     }
    // }
    issues ? rows = issues : rows = []


    rows.forEach((row, index) => {
        let names = []
        row.projects.map(project => {
            names.push(project.name)
        })
        row.newId = index + 1
        row.projectName = names.join(" | ")
        row.categoryName = row.category ? row.category.name : ""

    })

    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteDamage(id)))
    }

    return (
        <div className={classes.element}>
            {issuesState.pending ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <DisplayAll
                    cTitle={'Available Issues'}
                    addBtnTitle={'Add New Issue'}
                    rows={rows}
                    columns={columns}
                    cleaner={clearSnackBar}
                    reducer={issuesState}
                    pending={issuesState.pending}
                    uploader={uploadDamages}
                    handleCreate={handleCreateIssue}
                    multipleDeleteHandler={multipleDeleteHandler}
                >
                    <DialogTitle id="form-dialog-title">Create New Issue</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Issue Name"
                            type="text"
                            onChange={handleName}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Issue Description"
                            type="text"
                            onChange={handleDescription}
                            fullWidth
                        />

                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={projectOptions}
                            fullWidth
                            disableCloseOnSelect
                            value={selectedProjects}
                            onChange={(event, newValue) => {
                                setSelectedProjects(newValue);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        {option.name}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Projects" placeholder="Choose projects" />
                            )}
                        />
                        {/* <Autocomplete
                            id="combo-box-demo"
                            options={projectOptions}
                            sx={{ width: '100%' }}
                            value={projectOptions.value}
                            onChange={(event, newValue) => {
                                setProject(newValue.value)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Project"
                            />}
                        /> */}
                        <Autocomplete
                            id="combo-box-demo"
                            fullWidth
                            options={categoryOptions}
                            // sx={{ width: '100%' }}
                            value={categoryOptions.id}
                            onChange={(event, newValue) => {
                                setCategoryId(newValue.id)
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Category"
                            />}
                        />
                    </DialogContent>
                </DisplayAll>
            }
            <DeleteModel
                title={'Delete Issue'}
                description={`Are you sure you want to delete this issue?`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
            />
            <EditModel
                title={'Edit Issue'}
                closeEditDialog={handleCloseEditModel}
                isEditModelOpen={openEdit}
                classes={classes}
                handleEdit={handleEditIssue}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        defaultValue={editingData.name}
                        id="name"
                        label="Issue Name"
                        type="text"
                        onChange={handleNameToEdit}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        defaultValue={editingData.description}
                        id="description"
                        label="Issue Description"
                        type="text"
                        onChange={handleDescriptionToEdit}
                        fullWidth
                    />
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        options={categoryOptions}
                        defaultValue={editingData.category}
                        onChange={(event, newValue) => {
                            setEditingCategory(newValue.id);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Category"
                                placeholder="Choose destination"
                            />
                        )}
                    />
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        multiple
                        options={projectOptions}
                        disabled
                        value={editingData.projects}
                        onChange={(event, newValue) => {
                            let ids = []
                            newValue.map(v => ids.push(v.id))
                            setEditingProject(ids);
                        }}
                        getOptionLabel={(option) => {
                            return option.name
                        }}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Project"
                                placeholder="Choose project"
                            />
                        )}
                    />


                </DialogContent>
            </EditModel>
        </div>
    )
}

export default Issues
