import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";

const cookies = new Cookie()
const token = cookies.get('x-auth-token');

export const getRoutes = () => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/routes`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sites = res.data.data.sort((a, b) => a.id - b.id)
            dispatch({
                type: types.GET_ROUTES_SUCCESS,
                sites,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const getRoute = (id) => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/routes/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            // console.log("The data after getting the route is: ", res.data.route)
            dispatch({
                type: types.GET_ROUTE_SUCCESS,
                route: res.data.route,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}
export const createRoute = (from, to) => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/routes`,
        { from, to },
        {
            headers: {
                "x-auth-token": token
            }
        }
    )
        .then(res => {
            dispatch({
                type: types.CREATE_ROUTE,
                route: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

// export const importRoutes = (routes) => dispatch => {

//     dispatch({
//         type: types.ROUTE_PENDING
//     })
//     routes.forEach(data => {
//         delete data.id
//     })

//     return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/routes/upload`,
//         { routes },
//         {
//             headers: {
//                 "x-auth-token": token
//             }
//         })
//         .then(res => {
//             dispatch({
//                 type: types.UPLOAD_ROUTES,
//                 routes: res.data.routes,
//                 message: res.data.msg
//             })
//         })
//         .catch(err => {
//             dispatch({
//                 type: types.ROUTE_ERROR,
//                 error: err,
//                 message: err.response ? err.response.data.error : err.message
//             })
//         })
// }

export const uploadRoutes = (routes) => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    routes.forEach(data => {
        delete data.id
    })

    // console.log('The routes being uploaded are:', routes)

    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/routes/upload`,
        { routes },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            // console.log("The response from uplading routes is:", res)
            dispatch({
                type: types.UPLOAD_ROUTES,
                routes: res.data.routes,
                message: res.data.message
            })
        })
        .catch(err => {
            // console.log("The error while uploading the routes is:", err.response)

            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const deleteRoute = (id) => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/routes/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            // console.log("Route deleted successfully")
            dispatch({
                type: types.DELETE_ROUTE,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const updateRoute = (id, from, to) => dispatch => {

    dispatch({
        type: types.ROUTE_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/routes/${id}`,
        { from, to },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            // console.log("The response from updating the route is:", res)
            dispatch({
                type: types.UPDATE_ROUTE,
                message: res.data.message,
                route: res.data.newRoute
            })
        })
        .catch(err => {
            // console.log("The error from updating the route is:", err.response)
            dispatch({
                type: types.ROUTE_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}


export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}



