import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    comment: null,
    pending: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.COMMENT_PENDING:
            return {
                ...state,
                pending: true
            }

        case types.CREATE_COMMENT:
            return {
                ...state,
                comment: action.comment,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }

        case types.COMMENTS_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };
        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
