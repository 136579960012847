import { types } from "../../actions/types";

const initialState = {
  pending: false,
  images: [],
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_IMAGES_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.ClEAR_UPLOAD_IMAGES:
      return {
        ...state,
        images:[]
      }
    case types.UPLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        images: [...state.images, action.link],
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: "Images Upload successfull"
        }
      };
    case types.UPLOAD_IMAGES_FAIL:
      return {
        ...state,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.error
        }
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
