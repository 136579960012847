import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  stockItems: null,
  inventoryRequests: null,
  inventoryRequest: null,
  userRequests: null,
  stockReports: null,
  itemStockReports: null,
  stockItem: {},
  procurements: null,
  procurement: null,
  pending: false,
  deleted: false,
  upload: false,
  paid: false,
  picked: false,
  refresh: false,
  snackBarMessage: {
    open: false,
    severity: "",
    message: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.STOCK_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.GET_STOCK_SUCCESS:
      return {
        ...state,
        stockItems: action.stockItems,
        message: action.message,
        pending: false,
      };
    case types.GET_STOCK_REPORTS:
      return {
        ...state,
        stockReports: action.stockReports,
        message: action.message,
        pending: false,
      };
    case types.GET_PROCUREMENTS:
      return {
        ...state,
        procurements: action.procurements,
        message: action.message,
        pending: false,
      };
    case types.GET_STOCK_REPORTS_BY_ITEM:
      return {
        ...state,
        itemStockReports: action.itemStockReports,
        message: action.message,
        pending: false,
      };
    case types.GET_SINGLE_STOCK_ITEM:
      return {
        ...state,
        stockItem: action.stockItem,
        message: action.message,
        pending: false,
      };

    case types.CREATE_STOCK_ITEM:
      return {
        ...state,
        stockItem: action.stockItem,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.CREATE_PROCUREMENTS:
      return {
        ...state,
        procurement: action.procurement,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.PRESS_STOCK_ORDER:
      return {
        ...state,
        stockItem: action.stockItem,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.GET_INVENTORY_REQUESTS:
      return {
        ...state,
        inventoryRequests: action.inventoryRequests,
        message: action.message,
        pending: false,
      };
    case types.GET_ALL_INVENTORY_REQUESTS:
      return {
        ...state,
        inventoryRequests: action.inventoryRequests,
        message: action.message,
        pending: false,
      };
    case types.GET_SINGLE_INVENTORY_REQUEST:
      //   console.log("The state dispatched", action);
      return {
        ...state,
        inventoryRequest: action.inventoryRequest,
        message: action.message,
        pending: false,
        // refresh: state.refresh ? false : true,
      };
    case types.GET_USER_INVENTORY_REQUESTS:
      return {
        ...state,
        userRequests: action.userRequests,
        message: action.message,
        pending: false,
      };
    case types.ADD_ITEMS_TO_STOCK:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.REJECT_INVENTORY_REQUEST:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.ASSIGN_INV_REQUEST:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.ACCEPT_INVENTORY_REQUEST:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.INITIATE_PAYMENT:
      return {
        ...state,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.CONFIRM_PAYMENT:
      return {
        ...state,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.RECORD_PICKUP:
      return {
        ...state,
        message: action.message,
        picked: true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.REQUEST_CANCEL:
      return {
        ...state,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.APPROVE_CANCEL:
      return {
        ...state,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.RECORD_REPAIR:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.REPORT_DAMAGES:
      return {
        ...state,
        message: action.message,
        pending: false,
        refresh: state.refresh ? false : true,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case types.STOCK_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "error",
          message: action.message,
        },
      };

    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: "",
          message: null,
        },
      };
    default:
      return state;
  }
};
