import axios from "axios";
import Cookie from "universal-cookie";
import { types } from "../types";
const cookies = new Cookie();

const token = cookies.get("x-auth-token");

export const getStockItems = () => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/stockitems`)
    .then((res) => {
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      dispatch({
        type: types.GET_STOCK_SUCCESS,
        stockItems: sortedItems,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getProcurements = () => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/procurements`)
    .then((res) => {
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      dispatch({
        type: types.GET_PROCUREMENTS,
        procurements: sortedItems,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const createProcurement = (data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/api/procurements`, data)
    .then((res) => {
      dispatch({
        type: types.CREATE_PROCUREMENTS,
        procurement: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getStockItem = (id) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/stockitems/${id}`)
    .then((res) => {
      dispatch({
        type: types.GET_SINGLE_STOCK_ITEM,
        stockItem: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const pressOrder = (order) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/create`,
      order
    )
    .then((res) => {
      // console.log("The response is:", res)
      dispatch({
        type: types.PRESS_STOCK_ORDER,
        stockItem: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      // console.log("The error is:", err.response)
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const createItem = (item) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/api/stockitems/create`, item)
    .then((res) => {
      dispatch({
        type: types.CREATE_STOCK_ITEM,
        stockItem: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      console.log("The error we have from item creation is:", err);
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
//inventory requests
export const getInventoryRequests = (itemId) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/inventory/item/${itemId}`)
    .then((res) => {
      // console.log("The requests from inventory is:", res);
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      dispatch({
        type: types.GET_INVENTORY_REQUESTS,
        inventoryRequests: sortedItems,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const addStock = (data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/stockitems/stock/add/${data.id}`,
      { qtyToAdd: data.qtyToAdd }
    )
    .then((res) => {
      console.log("The stock added", res);
      dispatch({
        type: types.ADD_ITEMS_TO_STOCK,
        message: res.data.message,
      });
    })
    .catch((err) => {
      // console.log(err)
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getSingleRequest = (id) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/inventory/${id}`)
    .then((res) => {
      //   console.log("The request we have received is:", res);
      dispatch({
        type: types.GET_SINGLE_INVENTORY_REQUEST,
        inventoryRequest: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getAllRequest = () => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/inventory`)
    .then((res) => {
      dispatch({
        type: types.GET_ALL_INVENTORY_REQUESTS,
        inventoryRequests: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const acceptRequest = (id) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/approve/${id}`
    )
    .then((res) => {
      dispatch({
        type: types.ACCEPT_INVENTORY_REQUEST,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const rejectRequest = (id) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/reject/${id}`
    )
    .then((res) => {
      dispatch({
        type: types.REJECT_INVENTORY_REQUEST,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const assignInventoryRequest = (id, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/assign/${id}`,
      data
    )
    .then((res) => {
      dispatch({
        type: types.ASSIGN_INV_REQUEST,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const initiatePayment = (id, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/payments/pay/${id}`,
      data
    )
    .then((res) => {
      // console.log("res is:", res)

      dispatch({
        type: types.REJECT_INVENTORY_REQUEST,
        message: res.data.message,
      });
    })
    .catch((err) => {
      // console.log("error is:", err.response.data)
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const confirmPayment = (token) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/payments/approve`,
      { token }
    )
    .then((res) => {
      dispatch({
        type: types.CONFIRM_PAYMENT,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const recordPickup = (id, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/api/inventory/pick/${id}`, data)
    .then((res) => {
      // console.log("The response is:", res)
      dispatch({
        type: types.RECORD_PICKUP,
        message: res.data.message,
      });
    })
    .catch((err) => {
      // console.log("Hey the error is:", err)
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const requestCancel = (id) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/requestcancel/${id}`
    )
    .then((res) => {
      console.log("Hoping cancellation from:", res);
      dispatch({
        type: types.REQUEST_CANCEL,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const approveCancel = (token) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/api/inventory/request/cancel`, {
      token,
    })
    .then((res) => {
      dispatch({
        type: types.APPROVE_CANCEL,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const getUserRequests = (itemId) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/userequests/${itemId}`
    )
    .then((res) => {
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      dispatch({
        type: types.GET_USER_INVENTORY_REQUESTS,
        userRequests: sortedItems,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const recordRepaired = (reqId, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/stockitems/stock/repaired/${reqId}`,
      data
    )
    .then((res) => {
      dispatch({
        type: types.RECORD_REPAIR,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};

export const reportDamage = (reqId, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/stockitems/stock/reportDamages/${reqId}`,
      data
    )
    .then((res) => {
      dispatch({
        type: types.REPORT_DAMAGES,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getStockReports = (data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/activities?applyFilters=${data.applyFilters}&userId=${data.userId}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then((res) => {
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      // console.log("The response from getting the report is:", res)
      dispatch({
        type: types.GET_STOCK_REPORTS,
        message: res.data.message,
        stockReports: sortedItems,
      });
    })
    .catch((err) => {
      // console.log("The error from getting reports is:", err)
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const getStockReportsByItem = (itemId, data) => (dispatch) => {
  dispatch({
    type: types.STOCK_PENDING,
  });
  return axios
    .get(
      `${process.env.REACT_APP_BACKEND_URL}/api/inventory/activities/${itemId}?applyFilters=${data.applyFilters}&userId=${data.userId}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then((res) => {
      const sortedItems = res.data.data.sort((a, b) => b.id - a.id);
      dispatch({
        type: types.GET_STOCK_REPORTS_BY_ITEM,
        message: res.data.message,
        itemStockReports: sortedItems,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.STOCK_ERROR,
        error: err,
        message: err.response.data.error
          ? err.response.data.error
          : "Error occured",
      });
    });
};
export const clearSnackBar = () => (dispatch) => {
  return dispatch({
    type: types.CLEAR_SNACKBAR,
  });
};
