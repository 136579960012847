import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";

const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const getLocations = () => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/locations`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      const locations = res.data.locations.sort((a, b) => a.id - b.id)
      dispatch({
        type: types.GET_LOCATIONS,
        locations: locations,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getSingleLocation = (id) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/locations/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.GET_LOCATION,
        location: res.data.location,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const createLocation = (name) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/locations`,
    { name },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.CREATE_LOCATION,
        location: res.data.location,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const uploadLocation = (locations) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  locations.forEach(data => {
    delete data.id
  })
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/locations/upload`,
    { locations },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPLOAD_LOCATIONS,
        locations: res.data.locations,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const deleteLocation = (id) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/locations/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.DELETE_LOCATION,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : "Error occured"
      })
    })
}

export const updateLocation = (id, name) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/locations/${id}`,
    { name },
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPDATE_LOCATION,
        message: res.data.msg,
        location: res.data.location
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const removeInstitution = (id, institutionId) => dispatch => {

  dispatch({
    type: types.GET_LOCATION_PENDING
  })
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/locations/removeInstitution/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.REMOVE_INSTITUTION,
        message: res.data.msg
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_LOCATIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_SNACKBAR
  })
}



