import React from "react";
import { useSelector, useDispatch } from "react-redux";
import image from "../../assets/images/AC Group logo_White.png";
import { CircularProgress, Typography } from '@material-ui/core'
import Cookie from 'universal-cookie'
import LoginForm from '../auth/LoginForm';
import Footer from './Footer'
import LoggedIn from '../auth/LoggedIn';
import './landingstyle.scss';
import { connect } from 'react-redux'
import { loginRequest } from '../../redux/actions/auth/login';

const Home = (props) => {
  const loginState = useSelector(state => state.login)

  const loading = props.loading;
  return (
    <>
      <div>

        <div className="top-bar">
          <img src={image} className="top-logo" alt="ac group logo"></img>
        </div>
        {loading ? <div className='progress'><CircularProgress /></div> :
          <div className="main-content">

            <div>
              <div className="signin-title">
                <p>Sign in to Incident Management System</p>
              </div>
              <div className="text-form-area">
                <div className="desc-text">
                  <Typography>AC Group is a technology company providing smart transport solutions in Rwanda and across African cities since 2015.</Typography>
                </div>
                {!loginState.loggedInUser ? <LoginForm /> : <LoggedIn />}
              </div>
            </div>
          </div>
        }
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.login.isLoading,
})

export default connect(mapStateToProps, { loginRequest })(Home);
