export const types = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_CLICKED: "LOGIN_CLICKED",
  LOGIN_CHECK_ERROR: "LOGIN_CHECK_ERROR",
  LOGIN_CHECK: "LOGIN_CHECK",
  REMOVE_LOGIN_ERROR: "REMOVE_LOGIN_ERROR",
  GET_ROLES: "GET_ROLES",
  GET_ROLES_ERROR: "GET_ROLES_ERROR",
  GET_ROLES_PENDING: "GET_ROLES_PENDING",
  CREATE_ROLE: "CREATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPLOAD_ROLES: "UPLOAD_ROLES",
  GET_ROLE_PERMISSIONS: "GET_ROLE_PERMISSIONS",
  GET_PERMISSIONS_PENDING: "GET_PERMISSIONS_PENDING",
  GET_PERMISSIONS_ERROR: "GET_PERMISSIONS_ERROR",
  CHANGE_PERMISSIONS: "CHANGE_PERMISSIONS",
  CHANGE_UNALLOWED_PERMISSIONS: "CHANGE_UNALLOWED_PERMISSIONS",
  UPDATE_PERMISSIONS: "UPDATE_PERMISSIONS",
  SELECT_ALL_UNALLOWED_PERMISSIONS: "SELECT_ALL_UNALLOWED_PERMISSIONS",
  SELECT_ALL_ALLOWED_PERMISSIONS: "SELECT_ALL_ALLOWED_PERMISSIONS",
  CLEAR_SNACKBAR: "CLEAR_SNACKBAR",
  CLEAR_PROJECTS_SNACKBAR: "CLEAR_PROJECTS_SNACKBAR",
  CREATE_PROJECT: "CREATE_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_ERROR: "GET_PROJECTS_ERROR",
  ASSIGN_PROJECT: "ASSIGN_PROJECT",
  REMOVE_PROJECT_ASSIGNEE: "REMOVE_PROJECT_ASSIGNEE",
  GET_PROJECT_PENDING: "GET_PROJECT_PENDING",
  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_ERROR: "GET_PROJECT_ERROR",
  GET_LOCATION_PENDING: "GET_LOCATION_PENDING",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LOCATION: "GET_LOCATION",
  GET_LOCATIONS_ERROR: "GET_LOCATIONS_ERROR",
  CREATE_LOCATION: "CREATE_LOCATION",
  DELETE_LOCATION: "DELETE_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  REMOVE_INSTITUTION: "REMOVE_INSTITUTION",
  IMPORT_HEADER: "IMPORT_HEADER",
  UPLOAD_LOCATIONS: "UPLOAD_LOCATIONS",
  DELETE_PROJECTS_ERROR: "DELETE_PROJECTS_ERROR",
  GET_BUSES_ERROR: "GET_BUSES_ERROR",
  GET_BUSES_SUCCESS: "GET_BUSES_SUCCESS",
  GET_BUSES_PENDING: "GET_BUSES_PENDING",
  CREATE_BUS: "CREATE_BUS",
  DELETE_BUS: "DELETE_BUS",
  UPDATE_BUS: "UPDATE_BUS",
  GET_BUS_SUCCESS: "GET_BUS_SUCCESS",
  UPLOAD_BUSES: "UPLOAD_BUSES",
  ASSIGN_DEVICE_TO_BUS: "ASSIGN_DEVICE_TO_BUS",
  REMOVE_DEVICE_FROM_BUS: "REMOVE_DEVICE_FROM_BUS",
  GET_INSTITUTIONS_SUCCESS: "GET_INSTITUTIONS_SUCCESS",
  GET_INSTITUTION_SUCCESS: "GET_INSTITUTION_SUCCESS",
  CREATE_INSTITUTION_SUCCESS: "CREATE_INSTITUTION_SUCCESS",
  GET_INSTITUTIONS_PENDING: "GET_INSTITUTIONS_PENDING",
  GET_INSTITUTIONS_ERROR: "GET_INSTITUTIONS_ERROR",
  DELETE_INSTITUTIONS_SUCCESS: "DELETE_INSTITUTIONS_SUCCESS",
  UPDATE_INSTITUTIONS_SUCCESS: "UPDATE_INSTITUTIONS_SUCCESS",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
  REMOVE_ROUTE_SUCCESS: "REMOVE_ROUTE_SUCCESS",
  ASSIGN_ROUTE_TO_INSTITUTION: "ASSIGN_ROUTE_TO_INSTITUTION",
  ASSIGN_USER_TO_INSTITUTION: "ASSIGN_USER_TO_INSTITUTION",
  REMOVE_USER_FROM_INSTITUTION: "REMOVE_USER_FROM_INSTITUTION",
  UPLOAD_INSTITUTIONS: "UPLOAD_INSTITUTIONS",
  UPLOAD: "UPLOAD",
  UPLOAD_PROJECTS: "UPLOAD_PROJECTS",
  PROJECTS_PENDING: "PROJECTS_PENDING",
  DAMAGES_PENDING: "DAMAGES_PENDING",
  GET_DAMAGES_SUCCESS: "GET_DAMAGES_SUCCESS",
  GET_DAMAGES_ERROR: "GET_DAMAGES_ERROR",
  CREATE_DAMAGE: "CREATE_DAMAGE",
  GET_DAMAGE_SUCCESS: "GET_DAMAGE_SUCCESS",
  DELETE_DAMAGE: "DELETE_DAMAGE",
  UPDATE_DAMAGE: "UPDATE_DAMAGE",
  UPLOAD_DAMAGES: "UPLOAD_DAMAGES",
  SITES_PENDING: "SITES_PENDING",
  GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
  GET_SITES_ERROR: "GET_SITES_ERROR",
  DELETE_SITE: "DELETE_SITE",
  CREATE_SITE: "CREATE_SITE",
  UPDATE_SITE: "UPDATE_SITE",
  UPLOAD_SITES: "UPLOAD_SITES",
  GET_SITE_SUCCESS: "GET_SITE_SUCCESS",
  UPLOAD_IMAGES_PENDING: "UPLOAD_IMAGES_PENDING",
  UPLOAD_IMAGES_SUCCESS: "UPLOAD_IMAGES_SUCCESS",
  ClEAR_UPLOAD_IMAGES: "ClEAR_UPLOAD_IMAGES",
  UPLOAD_IMAGES_FAIL: "UPLOAD_IMAGES_FAIL",
  DEVICE_PENDING: "DEVICE_PENDING",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_ERROR: "GET_DEVICES_ERROR",
  PRIORITY_PENDING: "PRIORITY_PENDING",
  GET_PRIORITIES_SUCCESS: "GET_PRIORITIES_SUCCESS",
  GET_PRIORITIES_ERROR: "GET_PRIORITIES_ERROR",
  CREATE_INCIDENT_SUCCESS: "CREATE_INCIDENT_SUCCESS",
  GET_INCIDENTS_SUCCESS: "GET_INCIDENTS_SUCCESS",
  GET_INCIDENT_SUCCESS: "GET_INCIDENT_SUCCESS",
  GET_INCIDENT_PENDING: "GET_INCIDENT_PENDING",
  GET_INCIDENT_ERROR: "GET_INCIDENT_ERROR",
  CATEGORIES_PENDING: "CATEGORIES_PENDING",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
  STATUS_PENDING: "STATUS_PENDING",
  GET_STATUS_SUCCESS: "GET_STATUS_SUCCESS",
  GET_STATUS_ERROR: "GET_STATUS_ERROR",
  FORM_DATA: "FORM_DATA",
  UPDATE_INCIDENT_SUCCESS: "UPDATE_INCIDENT_SUCCESS",
  ACCEPT_INCIDENT_SUCCESS: "ACCEPT_INCIDENT_SUCCESS",
  REJECT_INCIDENT_SUCCESS: "REJECT_INCIDENT_SUCCESS",
  CLOSE_INCIDENT_SUCCESS: "CLOSE_INCIDENT_SUCCESS",
  ASSIGN_INCIDENT_TO_USER: "ASSIGN_INCIDENT_TO_USER",
  CONVERT_TO_REQUEST: "CONVERT_TO_REQUEST",
  SET_REDIRECT_URL: "SET_REDIRECT_URL",
  CHECK_AUTH_PENDING: "CHECK_AUTH_PENDING",
  CHECK_AUTH_SUCCESS: "CHECK_AUTH_SUCCESS",
  CHECK_AUTH_ERROR: "CHECK_AUTH_ERROR",
  PROFILE_UPDATE_PENDING: "PROFILE_UPDATE_PENDING",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",

  ROUTE_PENDING: "ROUTE_PENDING",
  GET_ROUTES_SUCCESS: "GET_ROUTES_SUCCESS",
  ROUTE_ERROR: "ROUTE_ERROR",
  CREATE_ROUTE: "CREATE_ROUTE",
  DELETE_ROUTE: "DELETE_ROUTE",
  UPDATE_ROUTE: "UPDATE_ROUTE",
  UPLOAD_ROUTES: "UPLOAD_ROUTES",
  GET_ROUTE_SUCCESS: "GET_ROUTE_SUCCESS",

  COMMENT_PENDING: "COMMENT_PENDING",
  CREATE_COMMENT: "CREATE_COMMENT",
  COMMENTS_ERROR: "COMMENTS_ERROR",

  GET_BUS_MAKES: "GET_BUS_MAKES",
  GET_ONE_BUS_MAKE: "GET_ONE_BUS_MAKE",
  CREATE_BUS_MAKE: "CREATE_BUS_MAKE",
  UPDATE_BUS_MAKE: "UPDATE_BUS_MAKE",
  DELETE_BUS_MAKE: "DELETE_BUS_MAKE",

  REFUNDS_PENDING: "REFUNDS_PENDING",
  REFUNDS_ERROR: "REFUNDS_ERROR",
  GET_REFUNDS_SUCCESS: "GET_REFUNDS_SUCCESS",
  GET_PENDING_REFUNDS: "GET_PENDING_REFUNDS",
  GET_REFUNDED_REFUNDS: "GET_REFUNDED_REFUNDS",
  REFUND_INCIDENT_SUCCESS: "REFUND_INCIDENT_SUCCESS",

  REPORT_PENDING: "REPORT_PENDING",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  REPORT_ERROR: "REPORT_ERROR",

  STOCK_PENDING: "STOCK_PENDING",
  GET_STOCK_SUCCESS: "GET_STOCK_SUCCESS",
  GET_SINGLE_STOCK_ITEM: "GET_SINGLE_STOCK_ITEM",
  STOCK_ERROR: "STOCK_ERROR",
  CREATE_STOCK_ITEM: "CREATE_STOCK_ITEM",
  PRESS_STOCK_ORDER: "PRESS_STOCK_ORDER",
  ADD_ITEMS_TO_STOCK: "ADD_ITEMS_TO_STOCK",
  GET_INVENTORY_REQUESTS: "GET_INVENTORY_REQUESTS",
  GET_ALL_INVENTORY_REQUESTS: "GET_ALL_INVENTORY_REQUESTS",
  GET_SINGLE_INVENTORY_REQUEST: "GET_SINGLE_INVENTORY_REQUEST",
  ACCEPT_INVENTORY_REQUEST: "ACCEPT_INVENTORY_REQUEST",
  REJECT_INVENTORY_REQUEST: "REJECT_INVENTORY_REQUEST",
  INITIATE_PAYMENT: "INITIATE_PAYMENT",
  CONFIRM_PAYMENT: "CONFIRM_PAYMENT",
  RECORD_PICKUP: "RECORD_PICKUP",
  REQUEST_CANCEL: "REQUEST_CANCEL",
  APPROVE_CANCEL: "APPROVE_CANCEL",
  ACCEPT_AS_SUPER_AGENT_SUCCESS: "ACCEPT_AS_SUPER_AGENT_SUCCESS",
  GET_USER_INVENTORY_REQUESTS: "GET_USER_INVENTORY_REQUESTS",
  RECORD_REPAIR: "RECORD_REPAIR",
  REPORT_DAMAGES: "REPORT_DAMAGES",
  GET_STOCK_REPORTS: "GET_STOCK_REPORTS",
  GET_STOCK_REPORTS_BY_ITEM: "GET_STOCK_REPORTS_BY_ITEM",
  ASSIGN_INV_REQUEST: "ASSIGN_INV_REQUEST",
  SUPER_REQUESTS_PENDING: "SUPER_REQUESTS_PENDING",
  GET_SUPER_REQUESTS_SUCCESS: "GET_SUPER_REQUESTS_SUCCESS",
  GET_SUPER_REQUEST_SUCCESS: "GET_SUPER_REQUEST_SUCCESS",
  CREATE_SUPER_REQUEST: "CREATE_SUPER_REQUEST",
  SUPER_REQUESTS_ERROR: "SUPER_REQUESTS_ERROR",
  REJECT_SUPER_REQUEST: "REJECT_SUPER_REQUEST",
  ACCEPT_SUPER_REQUEST: "ACCEPT_SUPER_REQUEST",
  RESOLVE_SUPER_REQUEST: "RESOLVE_SUPER_REQUEST",
  REFUND_SUPER_REQUEST: "REFUND_SUPER_REQUEST",
  ASSIGN_SUPER_REQUEST: "ASSIGN_SUPER_REQUEST",
  GET_PROCUREMENTS: "GET_PROCUREMENTS",
  CREATE_PROCUREMENTS: "CREATE_PROCUREMENTS",
};
