import {
    GET_DEVICE_REPLACEMENTS,
    DEVICES_PENDING,
    DEVICES_ERROR,
} from './type';
import http from '../../../utils/axios/axios';

const auth = localStorage.getItem('x-auth-token')

export const getDeviceReplacements = () => dispatch => {
    dispatch({ type: DEVICES_PENDING })
    return http.get('/api/replacements', {
        headers: {
            "x-auth-token": auth
        }
    })
        .then(res => {
            // const filteredMakes = res.data.data.filter(make => make.makeFor == "device")
            // console.log("the filtered makes are", filteredMakes)
            dispatch({
                type: GET_DEVICE_REPLACEMENTS,
                payload: res.data.replacements.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)),
                message: res.data.message
            })
        })
        .catch(err => {
            // console.log("THe error we have here in replacements is:", err.response)
            dispatch({
                type: DEVICES_ERROR,
                payload: err.response ? err.response.data.error : err.message
            })
        })
}
