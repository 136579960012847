import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import color from "../colors";
import '../landing/landingstyle.scss';
import {
    CircularProgress,
    Typography,
    Button,
    Slide,
    Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AdditionalInfo from '../templates/AdditionalInfo';
import moment from 'moment';
// import { getBus, clearSnackBar } from '../../redux/actions/buses/buses';
import { getDamage, clearSnackBar } from '../../redux/actions/damages/damages';

const useStyles = makeStyles((theme) => ({
    componentWrapper: {
        padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    headers: {
        marginTop: theme.spacing(1),
        alignContent: "center",
        marginBottom: theme.spacing(0),
        color: `${color.primary100}`,
        fontWeight: "bold"
    },
    progress: {
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
    },
    header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
    },
    header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        fontSize: '1.25rem'
    },
    root: {
        '& .super-app-theme--header': {
            fontWeight: "bolder",
        },
    },
    btn: {
        backgroundColor: `${color.primary100}`,
        color: 'white',
        padding: "9px",
        margin: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(5)
    },
    formControl: {
        height: "15",
        padding: "10",
        paddingBottom: "0",
        backgroundColor: `${color.primary100}`,
        color: "#fff",
        fontSize: "15px",
        minWidth: " 64px",
        boxSizing: "border-box",
        transition: " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
        fontWeight: 500,
        lineHeight: 1.60,
        borderRadius: "3px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        paddingRight: "0",
        paddingTop: "4px",
        paddingLeft: "9px",
        marginTop: "4px",
        marginBottom: "-3px",

    },
}))



const SingleIssue = props => {
    let location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDamage(id))
    }, [])

    const issue = useSelector(state => state.damages.damage)
    const issues = useSelector(state => state.damages)

    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }

    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }

    let creator;
    let updator;
    // let creationDate;
    // let updateDate;
    if (issue) {
        creator = issue.creator ? issue.creator : "Unknown"
        updator = issue.updator ? issue.updator : "Unknown"
        // creationDate = issue.createdAt ? moment(issue.createdAt).format('MMMM Do YYYY, h:mm:ss a') : "Unknown"
        // updateDate = issue.updatedAt ? moment(issue.updatedAt).format('MMMM Do YYYY, h:mm:ss a') : "Unknown"
    }

    let path = []
    const getPath = () => {
        const general = location.pathname.split("/");
        let url = null;
        for (let i = 1; i < general.length; i++) {
            if (i === general.length - 1) {
                url = <Typography variant="subtitle2" className={classes.header3} >{`${general[i][0].toUpperCase() + general[i].slice(1)}`}</Typography>
                path.push(url)
            } else {
                url = <Typography variant="subtitle2" className={classes.header2}>{`${general[i][0].toUpperCase() + general[i].slice(1)}>`}</Typography>
                path.push(url)
            }
        }
    }

    getPath()
    return (
        <div
            className={classes.componentWrapper}
        >
            {!issue ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <div>
                    <div>
                        <Snackbar
                            open={issues.snackBarMessage.open}
                            onClose={closeSnackBarTimer}
                            autoHideDuration={4000}
                            TransitionComponent={TransitionUp}
                        >
                            <MuiAlert
                                severity={issues.snackBarMessage.severity}
                                variant='filled'
                                elevation={6}
                            >{issues.snackBarMessage.message}</MuiAlert>
                        </Snackbar>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant={'h5'} className={classes.headers}>Issue Details</Typography>
                            <div className={classes.path}>{path}</div>

                        </div>
                        <hr style={{ color: color.primary100 }} />
                        <p> <b style={{ color: color.primary100 }}>Name:</b> {issue ? issue.name : ""}</p>
                        <p> <b style={{ color: color.primary100 }}>Description:</b> {issue ? issue.description : ''}</p>
                    </div>
                    <AdditionalInfo
                        creator={creator}
                        updator={updator}
                        creationDate={issue.createdAt}
                        updateDate={issue.updatedAt}

                    />
                    <Button
                        color="primary"
                        variant='contained'
                        className={classes.btn}
                        style={{ marginTop: '2em' }}
                        onClick={() => props.history.push("/dashboard/issues")}
                    >
                        view all issues
                    </Button>

                </div>


            }

        </div>
    )
}

export default SingleIssue
