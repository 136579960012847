import axios from 'axios';
import { filter } from 'lodash';
import { types } from "../types";
axios.defaults.withCredentials = true

export const getReports = (filters, secondaryFilters) => dispatch => {
    const stringifiedFilters = JSON.stringify(filters)
    const secondaryFiltersStr = JSON.stringify(secondaryFilters)

    // console.log("The stringified filters are:", stringifiedFilters)

    dispatch({
        type: types.REPORT_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/reports?filters=${stringifiedFilters}&secondaryFilters=${secondaryFiltersStr}`, {
        headers: {
            // "x-auth-token": token
        }
    })
        .then(res => {
            const reports = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_REPORTS_SUCCESS,
                reports: reports,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.REPORT_ERROR,
                message: err.response ? err.response.data.error : err.message
            })
        })
}

export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}