import React from "react"
import {Grid,Typography,makeStyles} from "@material-ui/core"
import color from "../colors"

const useStyles = makeStyles((theme)=>({
    header: {
        marginTop: theme.spacing(4.5),
        fontWeight: "bold",
        color: `${color.primary100}`
      },
      top: {
        marginBottom: theme.spacing(1),
      },
      path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
      },
      header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
      },
      header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
      },
      middle: {
        marginBottom: theme.spacing(3),
      }


}))



function TopPart({props,name}){
    const classes= useStyles()
    let path = [];
    const getPath = () => {
        const general = props.match.path.split("/");
        let url = null;
        for (let i = 1; i < general.length; i++) {
        if (i === general.length - 1) {
            const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
            url = <Typography variant="subtitle2" className={classes.header3} >{`${word}`}</Typography>
            path.push(url)
        } else {
            const word = general[i].charAt(0).toUpperCase() + general[i].slice(1)
            url = <Typography variant="subtitle2" className={classes.header2}>{`${word} >`}</Typography>
            path.push(url)
        }
        }
    }
    getPath()

    return(
        <>
            <Grid
            container
            spacing={0}
            justify="space-between"
            className={classes.top}
            >
            <Grid
                item
            >
                <Typography variant="h5" color={color.primary100} className={classes.header}>{name}</Typography>
            </Grid>
            <div className={classes.path}>
                {path}
            </div>
            </Grid>
            <hr style={{ color: color.primary100 }}  className={classes.middle} />
        </>
    )
}


export default TopPart