import React from "react"
import { TextField, MenuItem } from "@material-ui/core"
import { useField, useFormikContext } from "formik"
import { useDispatch } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';


const SelectWrapper = ({ name, options, setProjectId, setInstitutionId, action, ...otherProps }) => {

    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name)
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const { value } = e.target;
        setFieldValue(name, value)
        if (setProjectId) setProjectId(value)
        if (setInstitutionId) setInstitutionId(value)
        if (action) dispatch(action(value))
    }
    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: "outlined",
        fullWidth: true,
        onChange: handleChange
    }
    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        // <Autocomplete
        //     {...configSelect}
        //     disablePortal
        //     id="combo-box-demo"
        //     options={options}
        //     value={options.id}
        //     sx={{ width: 300 }}
        //     renderInput={(params) =>
        //         <TextField {...params} label="Movie" />}
        // />
        <TextField {...configSelect}>
            {options.map((item, pos) => {
                return (
                    <MenuItem key={pos} value={item.id}>
                        {item.name ? item.name : item.plateNumber}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

export default SelectWrapper;
