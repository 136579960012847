import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Grid,
    Button,
    Chip
} from "@material-ui/core";
import DisplayAll from "../templates/DisplayAll"
import { useDispatch, useSelector } from "react-redux";
import {
    getIncidentsManager,
    getIncidentsAdmin,
    clearSnackBar
} from "../../redux/actions/incidents/incidentActions"
import { checkLogin } from "../../redux/actions/auth/login"
import VisibilityIcon from '@material-ui/icons/Visibility';


const useStyles = makeStyles((theme) => ({
    selectlabel: {
        marginTop: theme.spacing(3)
    }
}))

const Institutions = (props) => {
    const incidents = useSelector(state => state.incidents)
    const auth = useSelector(state => state.login)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkLogin());
    }, [])
    useEffect(() => {
        if (auth.user) {
            if (auth.user.role.Permissions.manageIncident) {
                dispatch(getIncidentsAdmin(props.match.params.id));
            } else if (auth.user.role.Permissions.institutionManager) {
                dispatch(getIncidentsManager(props.match.params.id));
            }
        }
    }, [auth.user])

    const classes = useStyles()

    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'projectName',
            title: 'Project',

        },
        {
            field: 'location',
            title: 'Location',
        },
        {
            field: 'description',
            title: 'Description',

        },
        {
            field: 'ticketId',
            title: 'TicketId',
        },
        {
            field: 'createdUser',
            title: 'Creator',

        },
        {
            field: 'busCompany',
            title: 'Bus Company',

        },
        {
            field: 'priorityName',
            title: 'Status',
            render: rowData => <Chip
                size="small"
                label={rowData.reqStatus ? rowData.reqStatus.toUpperCase() : ""}
                style={{
                    backgroundColor: rowData.reqStatus == 'accepted' ? 'green' :
                        rowData.reqStatus == 'pending' ? 'yellow' :
                            rowData.reqStatus == 'cancelled' ? '#EC7063' :
                                rowData.reqStatus == 'resolved' ? '#58D68D' :
                                    rowData.reqStatus == 'rejected' ? '#78281F' : '#ECF0F1',
                    color: rowData.reqStatus == 'accepted' || rowData.reqStatus == 'rejected' ? 'white' : 'black'
                }}
            />
        },
        {
            field: 'action',
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/incident/${rowData.id}`)}
                        >
                            <VisibilityIcon color="primary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];
    let rows
    incidents.incidents ? (rows = incidents.incidents) : rows = []
    rows.forEach((row, index) => {
        // console.log(row)
        row.newId = index + 1
        row.projectName = row.project.name
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.createdUser = row.creator && row.creator.name ? row.creator.name : ""
        row.busCompany = row.institution && row.institution.name ? row.institution.name : ""
    })
    return (
        <>
            <DisplayAll
                cTitle={'Submitted Incident'}
                addBtnTitle={'Add New Incident'}
                columns={columns}
                rows={rows}
                reducer={incidents}
                cleaner={clearSnackBar}
                showImport={false}
                pending={incidents.pending}
                categories={true}
                hideDeleteAll={true}
                direct={() => props.history.push("/dashboard/newincident")}
                usemTable
            />
        </>
    )

}



export default Institutions