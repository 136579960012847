import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  Autocomplete,
  Snackbar,
  Slide,
} from "@mui/material/";
import {
  ShoppingCartOutlined,
  Settings,
  Visibility,
  AddCircleOutline,
  Add,
  Check,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from "@material-ui/lab/Alert";
import PageHeader from "../templates/TopPartOfPage";
import {
  getStockItems,
  createItem,
  pressOrder,
  addStock,
  clearSnackBar,
  createProcurement,
} from "../../redux/actions/stock/stock";
import { getInstitutions } from "../../redux/actions/institutions/institutions";

import { checkAuthedUser } from "../../redux/actions/auth/login";
import AddModel from "../templates/AddModel";
import PressOrder from "../templates/AddModel";
import UploadImage from "../users/dialogs/uploadImage";
import AddStock from "../templates/AddModel";
import NoData from "../../assets/svgs/NoData";

export default function StockView(props) {
  const dispatch = useDispatch();
  let history = useHistory();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [openAddModel, setAddModelOpen] = useState(false);
  const [pressOrderOpen, setPressOrderOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageValid, setImageValid] = useState("");
  const [imageAlt, setImagealt] = useState(null);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [pricePerUnit, setPrice] = useState();
  const [priorityLevel, setPriorityLevel] = useState();
  const [busCompany, setBusCompany] = useState();
  const [reason, setReason] = useState();
  const [itemsNeededBy, setItemsNeededBy] = useState(tomorrow);
  const [quantity, setQuantity] = useState();
  const [orderDescription, setOrderDescription] = useState();
  const [itemId, setItemId] = useState();
  const [itemCategory, setItemCategory] = useState();
  const [addStockOpen, setAddStockOpen] = useState(false);
  const [addItemId, setAddItemId] = useState();
  const [lpoNumber, setLpoNumber] = useState();
  const [openRecordProcurement, setOpenRecordProcurement] = useState();
  const [renderItems, setRenderItems] = useState(1);
  const [currentQuantity, setCurrentQuantity] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [procurements, setProcurements] = useState([]);
  const [comment, setComment] = useState();

  const stockState = useSelector((state) => state.stock);
  const stockItems = useSelector((state) => state.stock.stockItems);
  const institutions = useSelector((state) => state.institutions.institutions);

  const userProfile = useSelector((state) => state.login.loggedInUser);

  useEffect(() => {
    dispatch(getStockItems());
    dispatch(checkAuthedUser());
  }, [stockState.stockItem, stockState.refresh]);

  useEffect(() => {
    dispatch(getInstitutions());
  }, []);
  const handleCloseAdd = () => setAddModelOpen(false);
  const handleOpenAddModel = () => setAddModelOpen(true);

  const handleCloseRecordProcurement = () => {
    setProcurements([]);
    setLpoNumber(null);
    setComment(null);
    setOpenRecordProcurement(false);
    setRenderItems(1);
  };
  const handleOpenRecordProcurement = () => setOpenRecordProcurement(true);
  const addMoreRenders = () => setRenderItems((cv) => cv + 1);
  const saveCurrentEntries = () => {
    setProcurements((prevItems) => {
      if (!prevItems[renderItems - 1]) {
        return [
          ...prevItems,
          {
            itemId: currentItem,
            quantity: currentQuantity,
          },
        ];
      } else {
        return [
          ...prevItems,
          (prevItems[renderItems - 1] = {
            ...prevItems[renderItems - 1],
            itemId: currentItem,
            quantity: currentQuantity,
          }),
        ];
      }
    });
    setCurrentItem(null);
    setCurrentQuantity(null);
  };

  const handleAdd = () => {
    const item = {
      name,
      image: image
        ? image
        : "https://i.pinimg.com/564x/3e/d9/e7/3ed9e7bc7ef5d0879118ff8165e90980.jpg",
      description,
      pricePerUnit,
    };
    dispatch(createItem(item));
    handleCloseAdd();
  };

  const handleProcure = () => {
    const procurementData = {
      lpoNumber,
      totalQuantity: procurements.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.quantity),
        0
      ),
      comment,
      procurements,
    };
    dispatch(createProcurement(procurementData));
    handleCloseRecordProcurement();
  };

  const handleClosePressOrder = () => {
    setItemId(null);
    setItemCategory(null);
    setPressOrderOpen(false);
  };
  const handleOpenPressOrder = () => setPressOrderOpen(true);
  const handlePressOrder = () => {
    const order = {
      itemId,
      qtyRequested: quantity,
      description: orderDescription,
      itemsNeededBy,
      priorityLevel,
      busCompanyId: busCompany,
      requestReason: reason,
    };
    dispatch(pressOrder(order));
    handleClosePressOrder();
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleQuantityChange = (e) => setQuantity(e.target.value);
  const handleOrderDescription = (e) => setOrderDescription(e.target.value);
  const handleChangeLPO = (e) => setLpoNumber(e.target.value);

  const closeAddStock = () => setAddStockOpen(false);
  const openAddStock = () => setAddStockOpen(true);
  const handleAddItemsToStock = () => {
    const data = {
      id: addItemId,
      qtyToAdd: quantity,
    };
    dispatch(addStock(data));
    closeAddStock();
  };

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const priorityLevels = [
    {
      id: 1,
      name: "Critical",
    },
    {
      id: 2,
      name: "High",
    },
    {
      id: 3,
      name: "Medium",
    },
    {
      id: 4,
      name: "Low",
    },
  ];

  const reasons = [
    {
      id: 1,
      name: "Replacement",
    },
    {
      id: 2,
      name: "New Device",
    },
  ];
  const busCompanyOptions = institutions
    ? institutions.map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      })
    : [];
  const itemOptions = stockItems
    ? stockItems.map((item) => {
        return {
          title: item.name,
          id: item.id,
        };
      })
    : [];

  return (
    <div>
      <PageHeader name="Available Stock Items" props={props} />
      <Snackbar
        open={stockState.snackBarMessage.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={closeSnackBarTimer}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
      >
        <MuiAlert
          severity={stockState.snackBarMessage.severity}
          variant="filled"
          elevation={6}
        >
          {stockState.snackBarMessage.message}
        </MuiAlert>
      </Snackbar>
      {userProfile &&
        userProfile.role &&
        userProfile.role.Permissions &&
        userProfile.role.Permissions.addStock && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ mb: 2, mr: 2 }}
              onClick={handleOpenAddModel}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
              onClick={handleOpenRecordProcurement}
            >
              Procurements
            </Button>
          </>
        )}
      {stockItems && stockItems.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {stockItems &&
            stockItems.map((item) => (
              <Card
                style={{
                  width: "48%",
                }}
                sx={{
                  mr: 3,
                  mb: 3,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      style={{
                        color: "#19459D",
                        fontWeight: "bolder",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {item.quantity
                        ? item.quantity.qtyDamaged.toLocaleString()
                        : 0}{" "}
                      damaged
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {item.quantity
                        ? item.quantity.qtyInStock.toLocaleString()
                        : 0}{" "}
                      in stock
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {item.quantity
                        ? item.quantity.qtyDeployed.toLocaleString()
                        : 0}{" "}
                      deployed
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    <IconButton
                      aria-label="press-order"
                      onClick={() => {
                        setItemId(item.id);
                        setItemCategory(item.category);
                        handleOpenPressOrder();
                      }}
                    >
                      <ShoppingCartOutlined />
                    </IconButton>
                    <IconButton
                      aria-label="view-item"
                      onClick={() => history.push(`/stock/details/${item.id}`)}
                    >
                      <Visibility />
                    </IconButton>
                    {userProfile &&
                      userProfile.role &&
                      userProfile.role.Permissions &&
                      userProfile.role.Permissions.addStock && (
                        <IconButton
                          aria-label="manage-item"
                          onClick={() => {
                            console.log("The item is:", item.category);
                            if (item.category === "device") {
                              history.push("/dashboard/devices");
                            } else {
                              setAddItemId(item.id);
                              openAddStock();
                            }
                          }}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      )}
                    {userProfile &&
                      userProfile.role &&
                      userProfile.role.Permissions &&
                      userProfile.role.Permissions.addStock && (
                        <IconButton
                          aria-label="manage-item"
                          onClick={() =>
                            history.push(`/stock/manage/${item.id}`)
                          }
                        >
                          <Settings />
                        </IconButton>
                      )}
                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  sx={{
                    width: 400,
                    height: 200,
                  }}
                  image={item.image}
                  alt="Item Image"
                />
              </Card>
            ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            height: "60vh",
          }}
        >
          <NoData />
        </div>
      )}
      <AddModel
        isModelOpen={openAddModel}
        closeAddModel={handleCloseAdd}
        handleCreate={handleAdd}
      >
        <DialogTitle id="form-dialog-title">Add New Stock Item</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Name"
            onChange={handleNameChange}
            fullWidth
          />
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Description"
            onChange={handleDescriptionChange}
            fullWidth
          />
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Price/Unit"
            onChange={handlePriceChange}
            fullWidth
          />
          <UploadImage
            setLoading={setLoading}
            imageUrl={image}
            imageAlt={imageAlt}
            setImageurl={setImage}
            setImageAlt={setImagealt}
            loading={loading}
            imageValid={imageValid}
            setImageValid={setImageValid}
          />
        </DialogContent>
      </AddModel>
      <AddModel
        isModelOpen={openRecordProcurement}
        closeAddModel={handleCloseRecordProcurement}
        handleCreate={handleProcure}
      >
        <DialogTitle id="form-dialog-title">Record Procurement</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="LPO Number"
            onChange={handleChangeLPO}
            fullWidth
          />
          {[...Array(renderItems)].map((item, index) => (
            <div key={index}>
              <Autocomplete
                id="combo-box-demo"
                options={itemOptions}
                sx={{ width: "100%", mb: 2 }}
                value={itemOptions.id}
                onChange={(event, newValue) => {
                  setCurrentItem(newValue.id);
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Item" />
                )}
                // disabled={index !== renderItems - 1}
              />
              <TextField
                sx={{ mb: 2 }}
                variant="standard"
                label="Quantity"
                onChange={(e) => setCurrentQuantity(e.target.value)}
                fullWidth
                // disabled={index !== renderItems - 1}
              />
            </div>
          ))}
          {currentItem ? (
            <Button
              size="small"
              variant="contained"
              disabled={stockItems && stockItems.length < renderItems}
              sx={{ height: "20px" }}
              onClick={saveCurrentEntries}
            >
              <Check />
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              disabled={stockItems && stockItems.length <= renderItems}
              sx={{ height: "20px" }}
              onClick={addMoreRenders}
            >
              <Add />
            </Button>
          )}
          {/* <Button
            size="small"
            variant="contained"
            // disabled={invRequest && invRequest.qtyRequested <= renderItems}
            sx={{ height: "20px" }}
            onClick={addMoreRenders}
          >
            <Add />
          </Button> */}
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Comment"
            onChange={(e) => setComment(e.target.value)}
            fullWidth
          />
          {/* <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Price/Unit"
            onChange={handlePriceChange}
            fullWidth
          /> */}
          {/* <UploadImage
            setLoading={setLoading}
            imageUrl={image}
            imageAlt={imageAlt}
            setImageurl={setImage}
            setImageAlt={setImagealt}
            loading={loading}
            imageValid={imageValid}
            setImageValid={setImageValid}
          /> */}
        </DialogContent>
      </AddModel>
      <AddStock
        isModelOpen={addStockOpen}
        closeAddModel={closeAddStock}
        handleCreate={handleAddItemsToStock}
      >
        <DialogTitle id="form-dialog-title">Add Stock</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Quantity"
            onChange={handleQuantityChange}
            fullWidth
          />
        </DialogContent>
      </AddStock>
      <PressOrder
        isModelOpen={pressOrderOpen}
        closeAddModel={handleClosePressOrder}
        handleCreate={handlePressOrder}
      >
        <DialogTitle id="form-dialog-title">Press Order</DialogTitle>
        <DialogContent>
          {itemCategory === "device" && (
            <>
              <Autocomplete
                // sx={{ mb: 2 }}
                id="combo-box-demo"
                options={busCompanyOptions}
                sx={{ width: "100%", mb: 2 }}
                value={busCompanyOptions.id}
                onChange={(event, newValue) => {
                  setBusCompany(newValue.id);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Bus Company"
                  />
                )}
              />
              <Autocomplete
                id="combo-box-demo"
                options={reasons}
                sx={{ width: "100%", mb: 2 }}
                value={reasons.name}
                onChange={(event, newValue) => {
                  setReason(newValue.name);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Reason for Request"
                  />
                )}
              />
            </>
          )}
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Quantity"
            onChange={handleQuantityChange}
            fullWidth
          />
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Description"
            onChange={handleOrderDescription}
            fullWidth
          />
          <Autocomplete
            id="combo-box-demo"
            options={priorityLevels}
            sx={{ width: "100%", mb: 2 }}
            value={priorityLevels.id}
            onChange={(event, newValue) => {
              setPriorityLevel(newValue.id);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Priority Level"
              />
            )}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="Items Needed By"
              disablePast
              fullWidth
              value={itemsNeededBy}
              onChange={(date) => {
                setItemsNeededBy(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
      </PressOrder>
    </div>
  );
}
