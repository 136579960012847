import { types } from "../../actions/types";
import { formatToTextfieldDate } from "../../../utils/formatDates";

const initialState = {
    message: null,
    error: null,
    superagentRequests: null,
    superagentRequest: {},
    pending: false,
    formInitials: {},
    changed: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SUPER_REQUESTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_SUPER_REQUESTS_SUCCESS:
            return {
                ...state,
                superagentRequests: action.superagentRequests,
                message: action.message,
                pending: false
            };
        case types.GET_SUPER_REQUEST_SUCCESS:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                formInitials: {
                    agentnames: action.superagentRequest.agentnames,
                    agentusername: action.superagentRequest.agentusername,
                    agentId: action.superagentRequest.agentId,
                    date: action.superagentRequest.date || formatToTextfieldDate(new Date(Date.now())),
                    amount: action.superagentRequest.amount,
                    version: action.superagentRequest.version,
                    description: action.superagentRequest.description,
                    userAssigned: action.superagentRequest.assignedUser && action.superagentRequest.assignedUser.name ? action.superagentRequest.assignedUser.name : "Unassigned"
                }
            };

        case types.CREATE_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.ASSIGN_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                changed: state.changed ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.REJECT_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                changed: state.changed ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.ACCEPT_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                changed: state.changed ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.RESOLVE_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.REFUND_SUPER_REQUEST:
            return {
                ...state,
                superagentRequest: action.superagentRequest,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.SUPER_REQUESTS_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };

        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
