import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  roles: null,
  role: null,
  pending: false,
  changes: false,
  upload: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ROLES_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_ROLES:
      return {
        ...state,
        roles: action.roles,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.CREATE_ROLE:
      return {
        ...state,
        role: action.role,
        message: action.message,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.DELETE_ROLE:
      return {
        ...state,
        message: action.message,
        changes: state.changes ? false : true,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      }
    case types.UPLOAD_ROLES:
      return {
        ...state,
        message: action.message,
        pending: false,
        upload: state.upload ? false : true,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.UPDATE_ROLE:
      return {
        ...state,
        message: action.message,
        role: action.role,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_ROLES_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
