import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import color from "../colors";
import '../landing/landingstyle.scss';
import {
    CircularProgress,
    Typography,
    Button,
    Slide,
    Snackbar,
    Grid
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import AdditionalInfo from '../templates/AdditionalInfo';
import moment from 'moment';
// import { getBus, clearSnackBar } from '../../redux/actions/buses/buses';
import { getSite, clearSnackBar } from '../../redux/actions/sites/sites';
import Header from '../templates/TopPartOfPageWithId'
import {
    getRoute,
    getRoutes
} from '../../redux/actions/routes/routes';
import NoEntries from '../templates/NoEntries'
import DisplayAll from '../templates/DisplayAll'



const useStyles = makeStyles((theme) => ({
    componentWrapper: {
        padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    headers: {
        marginTop: theme.spacing(1),
        alignContent: "center",
        marginBottom: theme.spacing(0),
        color: `${color.primary100}`,
        fontWeight: "bold"
    },
    progress: {
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
    },
    header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
    },
    header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: color.primary100
    },
    root: {
        '& .super-app-theme--header': {
            fontWeight: "bolder",
        },
    },
    btn: {
        backgroundColor: `${color.primary100}`,
        color: 'white',
        padding: "9px",
        margin: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(5)
    },
    formControl: {
        height: "15",
        padding: "10",
        paddingBottom: "0",
        backgroundColor: `${color.primary100}`,
        color: "#fff",
        fontSize: "15px",
        minWidth: " 64px",
        boxSizing: "border-box",
        transition: " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
        fontWeight: 500,
        lineHeight: 1.60,
        borderRadius: "3px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        paddingRight: "0",
        paddingTop: "4px",
        paddingLeft: "9px",
        marginTop: "4px",
        marginBottom: "-3px",

    },
}))



const SingleRoute = props => {
    let location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRoute(id))
        // dispatch(getSite(id))
    }, [])

    const route = useSelector(state => state.routes.route)
    const routesState = useSelector(state => state.routes)

    const [perPage, setPerPage] = useState(5);

    const columns = [
        { field: 'newId', headerName: 'ID', flex: 1, headerClassName: 'super-app-theme--header', },
        { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
        {
            field: 'action', headerName: 'Action', flex: 1, headerClassName: 'super-app-theme--header',
            renderCell: (params) => (

                <Grid
                    container
                    spacing={1}
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            id={`${params.id}:${params.row.name}`}
                        // onClick={handleDeleteDialogOpen}
                        >
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];

    let rows = []

    route && route.institutions ? (rows = route.institutions) : rows = []
    rows.forEach((row, index) => {
        row.newId = index + 1
    })


    // console.log("The route is:", route ? route.source : "No route")
    // console.log("The route is:", route ? route : "No route")


    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" />;
    }

    const closeSnackBarTimer = () => {
        dispatch(clearSnackBar())
    }
    let path = []
    const getPath = () => {
        const general = location.pathname.split("/");
        let url = null;
        for (let i = 1; i < general.length; i++) {
            if (i === general.length - 1) {
                url = <Typography variant="subtitle2" className={classes.header3} >{`${general[i][0].toUpperCase() + general[i].slice(1)}`}</Typography>
                path.push(url)
            } else {
                url = <Typography variant="subtitle2" className={classes.header2}>{`${general[i][0].toUpperCase() + general[i].slice(1)}>`}</Typography>
                path.push(url)
            }
        }
    }

    getPath()
    return (
        <div
            className={classes.componentWrapper}
        >
            {routesState.pending ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <div>
                    <div>
                        <Snackbar
                            open={routesState.snackBarMessage.open}
                            onClose={closeSnackBarTimer}
                            autoHideDuration={4000}
                            TransitionComponent={TransitionUp}
                        >
                            <MuiAlert
                                severity={routesState.snackBarMessage.severity}
                                variant='filled'
                                elevation={6}
                            >{routesState.snackBarMessage.message}</MuiAlert>
                        </Snackbar>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant={'h5'} className={classes.headers}>Route Details</Typography>
                            <div className={classes.path}>{path}</div>

                        </div>
                        <hr style={{ color: color.primary100 }} />
                        <Typography>
                            <b style={{ color: color.primary100 }}>Name: </b>
                            {route ? route.name : ""}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>Origin District: </b>
                            {route ? route.source.district : ""}
                        </Typography>

                        <Typography>
                            <b style={{ color: color.primary100 }}>Origin Sector: </b>
                            {route ? route.source.sector : ""}
                        </Typography>

                        <Typography>
                            <b style={{ color: color.primary100 }}>Origin Cell: </b>
                            {route ? route.source.cell : ""}
                        </Typography>

                        <Typography>
                            <b style={{ color: color.primary100 }}>Destination District: </b>
                            {route ? route.destination.district : ""}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>Destination Sector: </b>
                            {route ? route.destination.sector : ""}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>Destination Cell: </b>
                            {route ? route.destination.cell : ""}
                        </Typography>

                        <Typography variant={'h6'} className={classes.subtitle}>Institutions</Typography>
                        <div style={{ height: rows.length == 0 ? 'auto' : 370, width: '100%' }}>
                            {rows.length == 0 ?

                                <NoEntries
                                    eText={"There are no institutions on this route "}
                                /> :
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    className={classes.root}
                                    pageSize={perPage}
                                    checkboxSelection
                                    sortModel={[{ field: 'newId', sort: 'asc', }]}
                                />
                            }

                        </div>


                        {/* <Typography>
                            <b style={{ color: color.primary100 }}>Project: </b>
                            {route ? route.project.name : ''}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>District: </b>
                            {site ? site.district : ''}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>Sector: </b>
                            {site ? site.sector : ''}
                        </Typography>
                        <Typography>
                            <b style={{ color: color.primary100 }}>Cell: </b>
                            {site ? site.cell : ''}
                        </Typography> */}

                    </div>
                    {/* <AdditionalInfo
                        creator={site ? site.createdUser.name : "Unknown"}
                        updator={site.updatedUser ? site.updatedUser.name : "Unknown"}
                        creationDate={site ? site.createdAt : "Unknown"}
                        updateDate={site.updatedAt ? site.updatedAt : "Unknown"}

                    /> */}
                    <Button
                        color={color.primary100}
                        variant='contained'
                        className={classes.btn}
                        style={{ marginTop: '2em' }}
                        onClick={() => props.history.push("/dashboard/routes")}
                    >
                        view all routes
                    </Button>

                </div>


            }

        </div>
    )
}

export default SingleRoute
