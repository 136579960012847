import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

import color from "../colors";
import "../landing/landingstyle.scss";
import {
  CircularProgress,
  Typography,
  Button,
  Slide,
  Snackbar,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DeleteIcon from "@material-ui/icons/Delete";
import AdditionalInfo from "../templates/AdditionalInfo";
import moment from "moment";
import { useSelect } from "react-select-search";
import { getDevices } from "../../redux/actions/devices/getDevices";
import Checkbox from "@mui/material/Checkbox";
// import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  getBus,
  clearSnackBar,
  getBuses,
  assignDevice,
  removeDevice,
} from "../../redux/actions/buses/buses";
import NoEntries from "../templates/NoEntries";
import DeleteModel from "../templates/DeleteModel";

const useStyles = makeStyles((theme) => ({
  componentWrapper: {
    padding: theme.spacing(6, 1),
    flexGrow: 1,
    ...theme.mixins.toolbar,
  },
  headers: {
    marginTop: theme.spacing(1),
    alignContent: "center",
    marginBottom: theme.spacing(0),
    color: `${color.primary100}`,
    fontWeight: "bold",
  },
  progress: {
    width: "80%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "absolute",
  },
  deviceHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
    color: `${color.primary100}`,
    // color: `${color.black}`
  },
  btnstly: {
    height: theme.spacing(4),
    backgroundColor: `${color.primary100}`,
    marginBottom: theme.spacing(2),
  },
  path: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`,
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`,
  },
  subtitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  root: {
    "& .super-app-theme--header": {
      fontWeight: "bolder",
    },
  },
  btn: {
    backgroundColor: `${color.primary100}`,
    color: "white",
    padding: "9px",
    margin: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
  },
  formControl: {
    height: "15",
    padding: "10",
    paddingBottom: "0",
    backgroundColor: `${color.primary100}`,
    color: "#fff",
    fontSize: "15px",
    minWidth: " 64px",
    boxSizing: "border-box",
    transition:
      " background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
    fontWeight: 500,
    lineHeight: 1.6,
    borderRadius: "3px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    paddingRight: "0",
    paddingTop: "4px",
    paddingLeft: "9px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
}));

const SingleBus = (props) => {
  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();

  const bus = useSelector((state) => state.buses.bus);
  const buses = useSelector((state) => state.buses);
  const devices = useSelector((state) => state.devices);

  useEffect(() => {
    dispatch(getBus(id));
    dispatch(getBuses());
    dispatch(getDevices());
  }, [buses.changed, buses.deleted]);

  // console.log(buses)

  const [openAddDevice, setOpenAddDevice] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [deviceToView, setDeviceToView] = useState(null);
  // const [deviceSelected, setSelectedDevice] = useState(null)
  const [selectedDevices, setSelectedDevices] = useState();
  const [deviceUnAssign, setDeviceUnAssign] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState(null);

  const handleOpenAddDevice = () => setOpenAddDevice(true);
  const handleCloseAddDevice = () => setOpenAddDevice(false);
  const handleCloseDeviceUnAssign = () => setDeviceUnAssign(false);
  const handleOpenDeviceUnAssign = (e) => {
    const element = e.currentTarget.id.split(":");
    setDeviceToRemove(element[0]);
    setDeviceUnAssign(true);
  };
  const handleDeviceId = (e) => {
    const element = e.currentTarget.id.split(":");
    setDeviceToView(element[0]);
  };
  const handleAssignDevice = () => {
    if (selectedDevices) {
      const devicesToAssign = selectedDevices.filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.title.toLowerCase() === v.title.toLowerCase()
          ) === i
      );
      // console.log("The selected devices among all are:", devicesToAssign)

      devicesToAssign.map((deviceToAssign) =>
        dispatch(assignDevice(deviceToAssign.id, props.match.params.id))
      );
      // setRouteId(null);
      // setAddRoute(false)
      setSelectedDevices(null);
      handleCloseAddDevice();
    } else {
      alert("Select at least device");
    }
  };

  const handleRemoveDevice = (e) => {
    dispatch(removeDevice(deviceToRemove, id));
    // console.log("The device to remove is:", deviceToRemove)
    // dispatch(deleteBus(busToDelete))
    setDeviceUnAssign(false);
    // setOpenDelete(false)
  };
  const unAssignedDevices = devices.devices.filter((device) => !device.busId);
  const devicesOptions = unAssignedDevices
    ? unAssignedDevices.map((device) => {
        return {
          title: `${device.itsDeviceType.name} - ${device.deviceImei}`,
          id: device.id,
        };
      })
    : [];

  // console.log("The devices are:", unAssignedDevices)
  // console.log("The device optioins are: ", devicesOptions)

  const devicesColumns = [
    {
      field: "newId",
      headerName: "ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    // {
    //     field: 'name',
    //     headerName: 'Name',
    //     flex: 1,
    //     headerClassName: 'super-app-theme--header'
    // },
    {
      field: "deviceImei",
      headerName: "IMEI",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sims",
      headerName: "SIM Cards",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Grid container spacing={1} direction="row">
          <Grid item>
            <Button
              id={`${params.id}`}
              onClick={handleDeviceId}
              href={`/dashboard/devices/${deviceToView}`}
            >
              <VisibilityIcon color="primary" />
            </Button>
          </Grid>
          <Grid item>
            <Button id={`${params.id}`} onClick={handleOpenDeviceUnAssign}>
              <DeleteIcon color="secondary" />
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  let devicesRow;
  bus ? (devicesRow = bus.devices) : (devicesRow = []);
  devicesRow.forEach((row, index) => {
    row.newId = index + 1;
    row.sims =
      row.mtnSim || row.airtelSim || row.fourGSim
        ? `${row.mtnSim ? row.mtnSim : ""}| ${
            row.airtelSim ? row.airtelSim : ""
          } | ${row.fourGSim ? row.fourGSim : ""}`
        : "No sim cards";
  });

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };

  let path = [];
  const getPath = () => {
    const general = location.pathname.split("/");
    let url = null;
    for (let i = 1; i < general.length; i++) {
      if (i === general.length - 1) {
        url = (
          <Typography variant="subtitle2" className={classes.header3}>{`${
            general[i][0].toUpperCase() + general[i].slice(1)
          }`}</Typography>
        );
        path.push(url);
      } else {
        url = (
          <Typography variant="subtitle2" className={classes.header2}>{`${
            general[i][0].toUpperCase() + general[i].slice(1)
          }>`}</Typography>
        );
        path.push(url);
      }
    }
  };

  getPath();
  return (
    <div className={classes.componentWrapper}>
      {!bus ? (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Snackbar
            open={buses.snackBarMessage.open}
            onClose={closeSnackBarTimer}
            autoHideDuration={4000}
            TransitionComponent={TransitionUp}
          >
            <MuiAlert
              severity={buses.snackBarMessage.severity}
              variant="filled"
              elevation={6}
            >
              {buses.snackBarMessage.message}
            </MuiAlert>
          </Snackbar>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant={"h5"} className={classes.headers}>
              Bus Details
            </Typography>
            <div className={classes.path}>{path}</div>
          </div>
          <hr style={{ color: color.primary100 }} />
          <Typography>
            {" "}
            <b style={{ color: color.primary100 }}>Plate Number:</b>{" "}
            {bus ? bus.plateNumber : ""}
          </Typography>
          <Typography>
            {" "}
            <b style={{ color: color.primary100 }}>Institution:</b>{" "}
            {bus ? bus.institutions.name : ""}
          </Typography>
          <Typography>
            {" "}
            <b style={{ color: color.primary100 }}>Bus Make:</b>{" "}
            {bus && bus.make ? bus.make.name : ""}
          </Typography>

          <div>
            <Typography
              variant="h6"
              color={color.primary100}
              className={classes.deviceHeader}
            >
              Devices
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.btnstly}
              onClick={handleOpenAddDevice}
            >
              ADD NEW DEVICE
            </Button>
            <div
              style={{
                height: devicesRow.length == 0 ? "auto" : 370,
                width: "100%",
              }}
            >
              {devicesRow.length == 0 ? (
                <NoEntries eText={"This bus does not have a device"} />
              ) : (
                <DataGrid
                  rows={devicesRow}
                  columns={devicesColumns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  className={classes.root}
                  pageSize={perPage}
                  checkboxSelection
                  sortModel={[{ field: "newId", sort: "asc" }]}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <AdditionalInfo
        creator={bus ? bus.creator : "Unknown"}
        updator={bus ? bus.updator : "Unknown"}
        creationDate={bus ? bus.createdAt : "Unkown"}
        updateDate={bus ? bus.updatedAt : "Unknown"}
      />
      <Button
        color="primary"
        variant="contained"
        className={classes.btn}
        style={{ marginTop: "2em" }}
        onClick={() => props.history.push("/dashboard/buses")}
      >
        view all buses
      </Button>
      <Dialog
        open={openAddDevice}
        onClose={handleCloseAddDevice}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ADD NEW DEVICE</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Autocomplete
                            id="combo-box-demo"
                            options={devicesOptions}
                            sx={{ width: 300 }}
                            value={devicesOptions.id}
                            onChange={(event, newValue) => {
                                setSelectedDevice(newValue.id)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Device"
                            />}
                        /> */}
            {/* <TextField
                            autoFocus
                            margin="dense"
                            id="plateNumber"
                            label="Plate Number"
                            type="text"
                            // onChange={handlePlateNumberChange}
                            fullWidth
                        /> */}
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={devicesOptions}
              disableCloseOnSelect
              value={selectedDevices}
              onChange={(event, newValue) => {
                setSelectedDevices(newValue);
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => {
                // console.log(selected)
                return <li {...props}>{option.title}</li>;
              }}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Device"
                  placeholder="Choose devices"
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAssignDevice}
            color="primary"
            autoFocus
            variant="contained"
          >
            Add Device
          </Button>
          <Button
            onClick={handleCloseAddDevice}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteModel
        title={"Remove Device"}
        description={`Are you sure you want to remove this device?`}
        isDeleteModelOpen={deviceUnAssign}
        closeDialog={handleCloseDeviceUnAssign}
        handleDelete={handleRemoveDevice}
      />
    </div>
  );
};

export default SingleBus;
