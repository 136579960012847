import http from "../../../utils/axios/axios";
import Cookies from "universal-cookie";
import axios from "axios";
import { types } from "../types";
import { authRedirect } from "../../../utils/redirects";
export const LOGIN_CLICKED = "LOGIN_CLICKED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
axios.defaults.withCredentials = true;
const token = localStorage.getItem("x-auth-token");
const cookies = new Cookies();

export const loginRequest = (data, history) => (dispatch) => {
  dispatch({ type: LOGIN_CLICKED });
  return http
    .post("/api/auth/login", data)
    .then((res) => {
      console.log("the response is here:", res);
      history.push("/welcome");
      const expirationDate = new Date(new Date().getTime() + 18000 * 1000);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};
export const logoutRequest = () => (dispatch) => {
  dispatch({ type: LOGIN_CLICKED });
  return http
    .post("/api/auth/logout")
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};
export const checkLogin = () => (dispatch) => {
  dispatch({ type: types.LOGIN_CHECK });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-login`, {
      headers: {
        "x-auth-token": token,
      },
    })
    .then((res) => {
      console.log("The success from check login lies here:", res);
      dispatch({
        type: types.LOGIN_CHECK,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log("the error while checking login is:", err);
      dispatch({
        type: types.LOGIN_CHECK_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const checkAuthedUser = () => (dispatch) => {
  // const token = sessionStorage.getItem('userToken')
  const token = cookies.get("x-auth-token");
  dispatch({
    type: types.CHECK_AUTH_PENDING,
  });
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-login`, {
      headers: {
        "x-auth-token": token,
      },
    })
    .then((res) => {
      console.log("The success from check login lies here:", res);

      dispatch({
        type: types.CHECK_AUTH_SUCCESS,
        loggedInUser: res.data.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      console.log(
        "the error while checking login is:",
        err.response ? err.response.data.error : "Error occured"
      );

      // window.location.assign("/");
      dispatch({
        type: types.CHECK_AUTH_ERROR,
        error: err,
        message: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const updateUserAction = (id, user) => (dispatch) => {
  dispatch({
    type: types.CHECK_AUTH_PENDING,
  });
  return http
    .patch(`/api/users/${id}`, user)
    .then((res) => {
      dispatch({
        type: types.PROFILE_UPDATE_SUCCESS,
        loggedInUser: res.data.newUser,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};
