import React, {useRef, useState, useEffect} from 'react';
import {useStyles} from '../useStyles';
import {Button, Grid, CircularProgress, Box, Typography, FormHelperText} from "@material-ui/core"

const UploadImage = (props) => {
    const [progress, setProgress] = useState(0);
    const [fil, setFiles] = useState(1);
    const fileSelect = useRef(null);
    const {setLoading, imageUrl, loading, setImageurl, imageValid, setImageValid} = props;
    const classes = useStyles();
    function uploadFile(file) {
        const url = `${process.env.REACT_APP_IMAGE_UPLOAD_LINK}`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        setLoading(true);
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      
        // Update progress (can be used to show progress indicator)
        
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState == 4 && xhr.status == 200) {
            const response = JSON.parse(xhr.responseText);
      
            setImageurl(response.secure_url);
            setLoading(false);
          }
        };
      
        fd.append(
          "upload_preset",`${process.env.REACT_APP_UPLOAD_PRESET}`
        );
        fd.append("tags", "browser_upload");
        fd.append("file", file);
        xhr.send(fd);
    }
    let handleImageUpload;

    let handleFiles;

    handleImageUpload = async (e) => {
        e.preventDefault();
        if (fileSelect) {
            fileSelect.current.click();
        }
    }

    handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
          uploadFile(files[i]);
        }
    }
    return (
        <div>
             <div className={classes.root}>
                <Grid container style={{marginTop: "20px"}}>
                    <div item style={{display: "flex", flexDirection: 'row', justifyContent: 'start'}}>
                        <label htmlFor="image">
                            <Button variant="outlined" color="primary" component="span" onClick={handleImageUpload}>
                                Browse an image
                            </Button>
                            <FormHelperText id="component-helper-text" style={{color: "gray"}}>{imageValid}</FormHelperText>
                        </label>
                        <input 
                            ref={fileSelect}
                            accept="image/*"
                            type="file" 
                            id="image" 
                            style={{display:"none"}}
                            onChange={(e) => handleFiles(e.target.files)}
                        ></input>
                        <button onClick={(e)=> {
                            setImageurl('');
                            setLoading(false)
                        }} variant="contained" color="secondary" className={classes.cancelBtn}>Cancel</button>
                        <label>
                            {loading && <CircularProgress color="primary" style={{margin: "0 30px"}} />}
                        </label>

                    </div>
                </Grid>
                
            <section className="right-side">
                {imageUrl && (
                    <img src={imageUrl} alt={()=> {
                      imageUrl === '' ? setImageValid("Image is required") : setImageValid("")
                    }} style={{width: '100%'}}/>
                )}
            </section>
        </div>
        </div>
    )
}

export default UploadImage;