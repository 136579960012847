import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    getUsersAction,
    getUserRoles,
    getAllProjects,
    postUsersAction,
    uploadUsers,
    clearSnackBar,
    deleteUserAction
} from '../../redux/actions/usersAction';
import RoleTable from '../templates/DisplayAll';
import { Button, Grid, CircularProgress, TextField, MenuItem, Select, InputLabel, FormControl, FormHelperText, Input, InputAdornment, IconButton, Typography } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModel from '../templates/DeleteModel';
import UploadImage from './dialogs/uploadImage'
import { useStyles } from './useStyles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function UsersMain(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const userReducer = useSelector(state => state.users)
    const roles = useSelector(state => state.users.roles)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true)
    const [projectId, setProjectId] = useState('')
    const [imageUrl, setImageurl] = useState('')
    const [imageAlt, setImagealt] = useState(null)
    const [roleId, setRoleId] = useState('')
    const [passwordtwo, setPasswordTwo] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConf, setShowPasswordConf] = useState(false)

    // Validation values
    const [passwordTwoValid, setPasswordTwoValid] = useState('')
    const [imageValid, setImageValid] = useState('')
    const [nameValid, setNameValid] = useState('')
    const [emailValid, setEmailValid] = useState('')
    const [usernameValid, setUsernameValid] = useState('')
    const [projectValid, setProjectValid] = useState('')
    const [roleValid, setRoleValid] = useState('')
    const [passwordValid, setPasswordValid] = useState('')
    const [phoneValid, setPhoneValid] = useState('')

    // initial patching of main functions
    useEffect(() => {
        dispatch(getUsersAction())
        dispatch(getUserRoles())
        dispatch(getAllProjects())
    }, [userReducer.deleted, userReducer.user, userReducer.updated, userReducer.bulkUsers])


    const populateSelectOne = () => {
        const values = []
        userReducer.roles.forEach((role, index) => {
            let value = <MenuItem value={role.id} key={index}>{role.name}</MenuItem>
            values.push(value)
        });
        return values
    }


    const selectValuesOne = userReducer.roles ? populateSelectOne() : null


    const populateSelect = () => {
        const values = []
        userReducer.projects.forEach((project, index) => {
            let value = <MenuItem value={project.id} key={index}>{project.name}</MenuItem>
            values.push(value)
        });
        return values
    }
    const selectValues = userReducer.projects ? populateSelect() : null

    const handleDelete = () => {
        dispatch(deleteUserAction(userId))
        setOpenDelete(false)
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleOpenDeleteModel = (e) => {
        const values = e.currentTarget.id.split(",")
        setUserId(values[0])
        setOpenDelete(true)
    }
    const handleNewUser = async () => {
        dispatch(postUsersAction({ name, email, username, phoneNumber: phone, password, status, project: projectId, image: imageUrl && imageUrl !== "" ? imageUrl : 'https://bit.ly/3mxLIYQ', roleId }))
        setName('')
        setEmail('')
        setUsername('')
        setPhone('')
        setPassword('')
        setPasswordTwo('')
        setImageurl('')
        setProjectId('')
        setRoleId('')
    }
    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'name',
            title: 'Name',
        },
        {
            field: 'email',
            title: 'Email',
        },
        {
            field: "status",
            title: 'Status',

        },
        {
            field: 'action',
            title: 'Actions',
            headerStyle: {
                textAlign: 'right',
            },
            render: rowData => (
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={() => props.history.push(`/dashboard/users/${rowData.id}`)}
                        >
                            <VisibilityIcon
                                color="primary"
                                fontSize='small'
                            />
                        </Button>

                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={handleOpenDeleteModel}
                            id={`${rowData.id},${rowData.name}`}
                        >
                            <DeleteIcon
                                color="secondary"
                                fontSize='small'
                            />
                        </Button>


                    </Grid>
                </Grid>
            )
        },
    ];
    let rows;
    rows = userReducer.users.sort()
    rows.forEach((row, index) => {
        row.newId = index + 1
    })
    rows.forEach(row => {
        if (row.status === true) {
            row.status = "Active"
        } else if (row.status === false) {
            row.status = "Inactive"
        }
    })
    const handleClickShowPassword = () => {
        setShowPassword(true)
    };

    const handleCloseVisibility = () => {
        setShowPassword(false)
    }
    const handleClickShowPasswordConf = () => {
        setShowPasswordConf(true)
    };

    const handleCloseVisibilityConf = () => {
        setShowPasswordConf(false)
    }

    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteUserAction(id)))
    }

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const strongPswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/

    return (
        <>
            <RoleTable
                cTitle="Available Users"
                addBtnTitle="Add new user"
                rows={rows}
                columns={columns}
                cleaner={clearSnackBar}
                uploader={uploadUsers}
                reducer={userReducer}
                handleCreate={handleNewUser}
                multipleDeleteHandler={multipleDeleteHandler}
                usemTable
                showSearch={false}
            >
                <div style={{ width: "100%", padding: "20px", boxSizing: "border-box" }}>
                    <Typography variant='h6'>Add New User</Typography>
                    <TextField
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            e.target.value.length < 3 ? setNameValid("Name length should be over 3 characters!") : setNameValid("")
                            e.target.value.length === 0 && setNameValid("Name is required!")
                        }}
                        label="Name"
                        error={nameValid}
                        helperText={nameValid}
                        style={{ width: '100%' }}
                        required={true}
                    />
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            e.target.value !== '' ? setEmailValid("Email is required!") : setEmailValid("")
                            e.target.value.match(mailformat) ? setEmailValid("") : setEmailValid("Invalid email")
                        }}
                        error={emailValid}
                        helperText={emailValid}
                        style={{ width: '100%' }}
                        required={true}
                    />
                    <TextField
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                            e.target.value.length === 0 ? setUsernameValid("Username is required!") : setUsernameValid("")
                        }}
                        label="Username"
                        error={usernameValid}
                        helperText={usernameValid}
                        style={{ width: '100%' }}
                        required={true}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                            e.target.value.length === 12 ? setPhoneValid("Phone number should not exceed 12 characters and is required!") : setPhoneValid("")
                        }}
                        label="Phone Number"
                        type="text"
                        fullWidth
                        required={true}
                        inputProps={{
                            maxLength: 12,
                        }}
                        error={phoneValid}
                        helperText={phoneValid}
                        style={{ width: '100%' }}
                    />
                    <FormControl className={classes.status}>
                        <InputLabel>Role</InputLabel>
                        <Select onChange={(e) => {
                            setRoleId(e.target.value)
                            e.target.value === '' && setRoleValid("Role is required!")
                        }} label="Role" required={true}>
                            {selectValuesOne}
                        </Select>
                        <FormHelperText id="component-helper-text" style={{ color: "gray" }}>{roleValid}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.status}>
                        <InputLabel htmlFor="demo-simple-select-outlined" className={classes.selectlabel}>Project</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required={true}
                            onChange={(e) => {
                                setProjectId(e.target.value)
                                e.target.value === '' && setProjectValid("Project is required!")
                            }}
                            label="Project"
                            fullWidth
                        >
                            {selectValues}
                        </Select>
                        <FormHelperText id="component-helper-text" style={{ color: "gray" }}>{projectValid}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.status}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            margin="dense"
                            id="password"
                            value={password}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                if (e.target.value === '') {
                                    setPasswordValid("Password is required!")
                                } else if (!e.target.value.match(lowerCaseLetters)) {
                                    setPasswordValid("Password should at least contain small letters")
                                } else if (!e.target.value.match(upperCaseLetters)) {
                                    setPasswordValid("Password should at least contain capital letters")
                                } else if (!e.target.value.match(numbers)) {
                                    setPasswordValid("Password should at least contain numbers")
                                } else if (e.target.value.length < 8) {
                                    setPasswordValid("Password should be over 8 characters")
                                } else if (!e.target.value.match(strongPswd)) {
                                    setPasswordValid("Password must be at least 8 characters including at least one uppercase letter, one lowercase letter, one number, and one special character")
                                } else {
                                    setPasswordValid("Password looks strong")
                                }
                            }}
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            fullWidth
                            required={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility onClick={handleCloseVisibility} /> : <VisibilityOff onClick={handleClickShowPassword} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="component-helper-text" style={{ color: passwordValid === "Password looks strong" ? "green" : "red" }}>{passwordValid}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.status}>
                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                        <Input
                            margin="dense"
                            id="confirm-password"
                            value={passwordtwo}
                            onChange={(e) => {
                                setPasswordTwo(e.target.value)
                                if (e.target.value !== password) {
                                    setPasswordTwoValid("Password do not match!")
                                } else {
                                    setPasswordTwoValid("")
                                }
                            }}
                            type={showPasswordConf ? 'text' : 'password'}
                            label="Comfirm Password"
                            required={true}
                            autoComplete={false}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        {showPasswordConf ? <Visibility onClick={handleCloseVisibilityConf} /> : <VisibilityOff onClick={handleClickShowPasswordConf} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="component-helper-text" style={{ color: "red" }}>{passwordTwoValid}</FormHelperText>
                    </FormControl>
                    <UploadImage setLoading={setLoading} imageUrl={imageUrl} imageAlt={imageAlt} setImageurl={setImageurl} setImageAlt={setImagealt} loading={loading} imageValid={imageValid} setImageValid={setImageValid} />
                </div>
            </RoleTable>
            <DeleteModel
                title="Delete user"
                description="Are You sure you want to delete this user? Once deleted, there is no going back."
                handleDelete={handleDelete}
                closeDialog={handleCloseDeleteModel}
                isDeleteModelOpen={openDelete}
                btnText="Delete User"
            />

        </>
    )
}

export default UsersMain;