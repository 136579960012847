import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom'


const PrivateRoute = ({ authedUser, component: Component, userHasPermission, tag, ...rest }) => {
    const loginState = useSelector(state => state.login)
    let userLoggedIn = loginState.loggedInUser

    useEffect(() => {
        userLoggedIn = loginState.loggedInUser
    }, [userLoggedIn])

    if (userHasPermission) {
        if (userLoggedIn && !userLoggedIn.role.Permissions[userHasPermission]) {
            return (
                <Redirect
                    to='/error' />
            )
        }
    }
    return (
        <Route {...rest} render={(props) => (
            <Component
                tag={tag}
                {...props}
            />
        )} />
    )
}
export default PrivateRoute