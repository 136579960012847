import axios from 'axios';
import { types } from "../types";

//const token = localStorage.getItem('x-auth-token');
const token = document.cookie.split("=")[1];
export const getPriorities  = () => dispatch => {
    dispatch({
        type: types.PRIORITY_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incident-prioritizations`, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(res => {
            const sortedPriorities = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_PRIORITIES_SUCCESS,
                priorities: sortedPriorities,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_PRIORITIES_ERROR,
                error: err,
                message: err.response? err.response.data.error : err.message
            })
        })
}