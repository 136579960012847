import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const EditModel = props => {
    let {
        title,
        description,
        isEditModelOpen,
        closeEditDialog,
        handleEdit,
        classes,
        children
    } = props

    return (
        <div>
            <Dialog
                open={isEditModelOpen}
                onClose={closeEditDialog}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle id="form-dialog-title" className={classes.title}>{title}</DialogTitle>
                {children}
                <DialogActions>
                    <Button
                        onClick={handleEdit}
                        color="primary"
                        variant='contained'>
                        Submit
                    </Button>
                    <Button
                        onClick={closeEditDialog}
                        color="secondary"
                        variant='contained'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditModel
