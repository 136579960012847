import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    buses: null,
    bus: null,
    pending: false,
    added: false,
    edited: false,
    deleted: false,
    upload: false,
    changed: false,
    device: [],
    busMakes: [],
    make: {},
    deleteMake: '',
    updateMake: '',
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BUSES_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_BUSES_SUCCESS:
            return {
                ...state,
                buses: action.buses,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.ASSIGN_DEVICE_TO_BUS:
            return {
                ...state,
                device: action.device,
                message: action.message,
                pending: false,
                changed: state.changed ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.REMOVE_DEVICE_FROM_BUS:
            return {
                ...state,
                message: action.message,
                pending: false,
                deleted: state.deleted ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.GET_BUS_SUCCESS:
            return {
                ...state,
                bus: action.bus,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.CREATE_BUS:
            return {
                ...state,
                bus: action.data,
                message: action.message,
                pending: false,
                added: state.added ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.UPDATE_BUS:
            return {
                ...state,
                message: action.message,
                bus: action.bus,
                pending: false,
                edited: state.edited ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.GET_BUSES_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };
        case types.DELETE_BUS:
            return {
                ...state,
                message: action.message,
                pending: false,
                deleted: state.deleted ? false : true,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            }
        case types.UPLOAD_BUSES:
            return {
                ...state,
                message: action.message,
                upload: state.upload ? false : true,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.GET_BUS_MAKES: {
            return {
                ...state,
                pending: false,
                busMakes: action.payload
            }
        }
        case types.GET_ONE_BUS_MAKE:
            return {
                ...state,
                pending: false,
                make: action.payload
            }
        case types.CREATE_BUS_MAKE:
            return {
                ...state,
                make: action.payload,
                pending: false,
            }
        case types.DELETE_BUS_MAKE:
            return {
                ...state,
                deleteMake: action.payload,
                pending: false,
            }
        case types.UPDATE_BUS_MAKE:
            return {
                ...state,
                pending: false,
                updateMake: action.payload,
            }
        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
