import { combineReducers } from "redux";
import loginReducer from "./auth/loginReducer";
import devicesReducer from "./devices/devicesReducer";
import roles from "./roles/rolesReducer"
import permissions from "./roles/permissionsReducer"
import projects from "./projects/projects"
import locationReducer from "./locations/locationsReducer";
import locationInstitutions from "./locations/locationInstitutionsReducer";
import institutions from "./institutions/institutionReducer"
import singleinstitution from "./institutions/singleInstitutionReducer"
import userReducer from "./usersReducer";
import buses from './buses/buses'
import damages from './damages/damages'
import sites from './sites/sites'
import incidents from './incidents/incidentsReducer'
import uploadimage from "./uploadImages/uploadImagesReducer"
import devices from './devices/devicesReducer'
import priorities from './priority/prioritiesReducer'
import categories from './categories/categoriesReducer'
import statuses from "./status/statusReducer"
import url from "./redirecturl/redirectUrlReducer"
import routes from './routes/routes'
import comments from './comments/comments'
import refunds from './refunds/refunds'
import reports from './reports/reports'
import stock from './stock/stock'
import superRequests from './superagent_requests/superagentRequests'

// function that contains all reducer objects.
const allReducers = combineReducers({
  login: loginReducer,
  devices: devicesReducer,
  roles,
  permissions,
  projects,
  routes,
  comments,
  locations: locationReducer,
  locationInstitutions: locationInstitutions,
  institutions,
  institution: singleinstitution,
  users: userReducer,
  buses,
  damages,
  sites,
  incidents,
  uploadimage,
  devices,
  priorities,
  categories,
  statuses,
  url,
  refunds,
  reports,
  stock,
  superRequests
});

export default allReducers;
