import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    TextField,
    Divider,
    Typography,
    DialogContent,
    DialogTitle,
    CircularProgress
} from "@material-ui/core"
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { Field, Form, Formik, } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import * as yup from 'yup'
import { Chrono } from "react-chrono";
import CommentsBlock from 'simple-react-comments';

import {
    getSingleSuperRequest,
    rejectRequest,
    acceptRequest,
    resolveRequest,
    assignRefund
} from '../../redux/actions/superagent_requests/superagentRequests';
import { getUsersAction } from '../../redux/actions/usersAction'
import PageHeader from '../../components/templates/TopPartOfPageWithId'
import { checkLogin } from "../../redux/actions/auth/login"
import { useStyles } from './incident'
import AddModel from '../templates/AddModel'
import { createRequestComment } from '../../redux/actions/comments/comments';

const SuperagentRefund = props => {

    const dispatch = useDispatch()
    const classes = useStyles();

    const [update, setUpdate] = useState(false)
    const [openAssign, setOpenAssign] = useState(false)
    const [assigneeUser, setAssigneeUser] = useState()


    const { id } = useParams()

    const singleRequest = useSelector(state => state.superRequests.superagentRequest)
    const requestState = useSelector(state => state.superRequests)
    const auth = useSelector(state => state.login)
    const users = useSelector(state => state.users)
    const comments = useSelector(state => state.comments)

    const validationSchema = yup.object({
        agentnames: yup.string("Agent name must be a string")
            .required('Agentname is required'),
        agentusername: yup.string()
            .required('Agent username is required'),
        agentId: yup.string()
            .required('Agentid is required'),
        date: yup.date()
            .required('Date is required'),
        amount: yup.string()
            .required('Amount is required'),
        version: yup.string()
            .required('Version is required'),
        description: yup.string()
            .required('Description is required')
    })

    useEffect(() => {
        dispatch(checkLogin());
        dispatch(getUsersAction())
    }, [])

    useEffect(() => {
        dispatch(getSingleSuperRequest(id))
    }, [requestState.changed, auth.user])

    useEffect(() => {
        dispatch(checkLogin());
    }, [comments.comment])

    useEffect(() => {
        console.log("Changed")
    }, [requestState.formInitials.amount])


    const handleUpdateClick = () => setUpdate(true)
    const handleReject = () => dispatch(rejectRequest(id))
    const handleClose = () => dispatch(resolveRequest(id))
    const handleAccept = () => dispatch(acceptRequest(id))
    const handleOpenAssign = () => setOpenAssign(true)
    const handleCloseAssign = () => setOpenAssign(false)
    const handleAssignUser = () => {
        dispatch(assignRefund(id, assigneeUser, singleRequest.tag))
        handleCloseAssign()
    }
    let timelineActivities = []
    requestState.superagentRequest && requestState.superagentRequest.timeline ? requestState.superagentRequest.timeline.map(activity => {
        timelineActivities.push({
            title: requestState.superagentRequest.timeline.indexOf(activity) + 1,
            cardSubtitle: moment(activity.createdAt).format('MMM D, YYYY h:mm a'),
            cardTitle: activity.user.name,
            cardDetailedText: activity.activity
        })
    }) : timelineActivities.push({})

    let requestComments = []
    requestState.superagentRequest && requestState.superagentRequest.comments ? requestState.superagentRequest.comments.map(comment => {
        requestComments.push({
            avatarUrl: comment.createdUser.image,
            authorUrl: '/',
            fullName: comment.createdUser.name,
            createdAt: new Date(comment.createdAt),
            text: comment.comment
        })
    }) : requestComments = []

    const userOptions = users ? users.users.map(user => {
        return {
            label: user.name,
            value: user.id
        }
    }) : []

    let actionBtn = []
    if (requestState.superagentRequest && requestState.superagentRequest.status && requestState.superagentRequest.status.name !== "declined" && requestState.superagentRequest.status.name !== "resolved") {
        if (auth.user) {
            const { Permissions } = auth.user.role
            if ((auth.user.id === requestState.superagentRequest.createdBy && requestState.superagentRequest.status.name !== "accepted") || (Permissions.manageIncident || Permissions.institutionManager)) {
                let btn = <Grid item xs={6} sm={3} lg={2} >
                    <Button variant="contained" color="primary" onClick={handleUpdateClick} className={classes.btn}>Update</Button>
                </Grid>
                actionBtn.push(btn)
            }
            if (requestState.superagentRequest.status && requestState.superagentRequest.status.name !== "accepted" && (Permissions.confirmIncidentAsAc)) {
                let btn = <Grid item xs={6} sm={3} lg={2} >
                    <Button
                        variant="contained"
                        onClick={handleAccept}
                        className={classes.acceptbtn}
                    >
                        Accept
                    </Button>
                </Grid>
                actionBtn.push(btn)
            }
            if (requestState.superagentRequest.status && requestState.superagentRequest.status.name !== "declined" && (Permissions.disclosureIncident || Permissions.institutionManager)) {
                let btn = <Grid item xs={6} sm={3} lg={2}  >
                    <Button variant="contained" onClick={handleReject} className={classes.rejectbtn}>Reject</Button>
                </Grid>
                actionBtn.push(btn)
            }
            if (Permissions.disclosureIncident || Permissions.manageIncident) {
                let btn = <Grid item xs={6} sm={3} lg={2}  >
                    <Button variant="contained" color="secondary" onClick={handleClose} className={classes.btn}>Resolve</Button>
                </Grid>
                actionBtn.push(btn)
            }
            if (auth.user.id === requestState.superagentRequest.createdBy || Permissions.manageIncident) {
                if (requestState.superagentRequest.assignee) {
                    let btn = <Grid item xs={6} sm={3} lg={2}  >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenAssign}
                            className={classes.btn}>
                            re-ssign
                        </Button>
                    </Grid>
                    actionBtn.push(btn)
                } else {
                    let btn = <Grid item xs={6} sm={3} lg={2}  >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenAssign}
                            className={classes.btn}>
                            Assign
                        </Button>
                    </Grid>
                    actionBtn.push(btn)
                }

            }

        }
    }

    return (
        <div>
            <PageHeader
                props={props}
                name="Single Refund Request"
            />
            <React.Fragment>
                {requestState.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    : <>
                        <Grid
                            container
                            item xs={12} sm={9}
                            spacing={1}
                            className={classes.btncontainer}>
                            {actionBtn}
                        </Grid>
                        <Grid
                            container
                        >
                            <Grid lg={8}>
                                <Formik
                                    initialValues={requestState.formInitials}
                                    validationSchema={validationSchema}
                                    onSubmit={values => {
                                        // console.log("The values we have are:", values)
                                        setUpdate(false)
                                        // dispatch(createSuperagentRequest(values, props))
                                    }}
                                >
                                    {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
                                        // console.log("The values on the other hand are:", values)
                                        return (
                                            <Form>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="space-between"
                                                >
                                                    {requestState.superagentRequest && requestState.superagentRequest.tag !== "loan" && <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            style={{ marginTop: "16px" }}
                                                        >
                                                            <Field
                                                                variant="outlined"
                                                                size='small'
                                                                margin="dense"
                                                                id="agentnames"
                                                                label="Agent names"
                                                                type="text"
                                                                fullWidth
                                                                name='agentnames'
                                                                value={values.agentnames}
                                                                onChange={() => setFieldTouched('agentnames', true)}
                                                                className={classes.bottomMargin}
                                                                as={TextField}
                                                                error={touched.agentnames && errors.agentnames}
                                                                helperText={touched.agentnames && errors.agentnames}
                                                                disabled={!update}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            style={{ marginTop: "16px" }}
                                                        >
                                                            <Field
                                                                variant="outlined"
                                                                size='small'
                                                                margin="dense"
                                                                id="name"
                                                                label="Agent username"
                                                                type="text"
                                                                name='agentusername'
                                                                as={TextField}
                                                                className={classes.bottomMargin}
                                                                error={touched.agentusername && errors.agentusername}
                                                                helperText={touched.agentusername && errors.agentusername}
                                                                fullWidth
                                                                disabled={!update}
                                                            />
                                                        </Grid>
                                                        {values.agentId && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={5}
                                                                style={{ marginTop: "16px" }}
                                                            >
                                                                <Field
                                                                    variant="outlined"
                                                                    size='small'
                                                                    margin="dense"
                                                                    id="name"
                                                                    name='agentId'
                                                                    label="Agent Id"
                                                                    type="text"
                                                                    as={TextField}
                                                                    className={classes.bottomMargin}
                                                                    error={touched.agentId && errors.agentId}
                                                                    helperText={touched.agentId && errors.agentId}
                                                                    fullWidth
                                                                    disabled={!update}

                                                                />
                                                            </Grid>
                                                        )}
                                                    </>
                                                    }
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        style={{ marginTop: "16px" }}
                                                    >
                                                        <Field
                                                            autoFocus
                                                            variant="outlined"
                                                            size='small'
                                                            margin="dense"
                                                            id="name"
                                                            label="Amount"
                                                            type="text"
                                                            name='amount'
                                                            as={TextField}
                                                            className={classes.bottomMargin}
                                                            error={touched.amount && errors.amount}
                                                            helperText={touched.amount && errors.amount}
                                                            fullWidth
                                                            disabled={!update}

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        style={{ marginTop: "16px" }}
                                                    >
                                                        <Field
                                                            variant="outlined"
                                                            size='small'
                                                            margin="dense"
                                                            id="name"
                                                            label="Version"
                                                            name="version"
                                                            type="text"
                                                            as={TextField}
                                                            className={classes.bottomMargin}
                                                            error={touched.version && errors.version}
                                                            helperText={touched.version && errors.version}
                                                            fullWidth
                                                            disabled={!update}

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        style={{ marginTop: "16px" }}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            variant="outlined"
                                                            size='small'
                                                            name='date'
                                                            id="datetime-local"
                                                            fullWidth
                                                            label="Date"
                                                            type="datetime-local"
                                                            className={classes.bottomMargin}
                                                            error={touched.date && errors.date}
                                                            helperText={touched.date && errors.date}
                                                            disabled={!update}

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        style={{ marginTop: "16px" }}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            variant="outlined"
                                                            size='small'
                                                            name='userAssigned'
                                                            fullWidth
                                                            label="Assignee"
                                                            type="text"
                                                            className={classes.bottomMargin}
                                                            error={touched.date && errors.date}
                                                            helperText={touched.date && errors.date}
                                                            disabled={!update}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        style={{ marginTop: "16px" }}
                                                    >
                                                        <Field
                                                            variant="outlined"
                                                            size='small'
                                                            margin="dense"
                                                            id="name"
                                                            label="Description"
                                                            type="text"
                                                            fullWidth
                                                            name='description'
                                                            className={classes.bottomMargin}
                                                            as={TextField}
                                                            error={touched.description && errors.description}
                                                            helperText={touched.description && errors.description}
                                                            disabled={!update}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {update &&
                                                    <Grid>
                                                        <Button
                                                            type={"submit"}
                                                            variant='contained'
                                                            color='primary'
                                                            size='small'
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            size='small'
                                                            onClick={() => setUpdate(false)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>

                                                }
                                            </Form>
                                        )
                                    }}
                                </Formik>
                                <Grid
                                    style={{
                                        marginTop: '1em',
                                        fontFamily: `'Poppins', sans-serif`
                                    }}>
                                    <Typography
                                        className={classes.sectionTitle}
                                        style={{
                                            marginBottom: '1em'

                                        }}
                                    >Comments</Typography>
                                    <CommentsBlock
                                        comments={requestComments}
                                        signinUrl={'/'}
                                        isLoggedIn
                                        onSubmit={text => {
                                            if (text.length > 0) {
                                                // console.log(text)
                                                dispatch(createRequestComment(text, id))
                                                // dispatch(createComment(text, incidents.incident.id))
                                            }
                                        }}
                                        styles={{
                                            btn: base => ({
                                                ...base,
                                                fontSize: '0.8em'
                                            }),

                                        }
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                style={{
                                    padding: '0.5em 1em',
                                }}
                                lg={4}
                            >
                                <Typography
                                    className={classes.sectionTitle}
                                >Timeline</Typography>
                                <Divider className={classes.divider} />
                                <Chrono
                                    items={timelineActivities}
                                    mode="VERTICAL"
                                    hideControls
                                    useReadMore={false}
                                    cardHeight={80}

                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </React.Fragment>
            <AddModel
                isModelOpen={openAssign}
                closeAddModel={handleCloseAssign}
                handleCreate={handleAssignUser}
            >
                <DialogTitle id="form-dialog-title">Assign this incident to a user</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="combo-box-demo"
                        options={userOptions}
                        sx={{ width: '100%' }}
                        value={userOptions.value}
                        onChange={(event, newValue) => {
                            setAssigneeUser(newValue.value)
                        }}

                        renderInput={(params) => <TextField
                            {...params}
                            label="Assignee"
                        />}
                    />

                </DialogContent>
            </AddModel>
        </div>
    )
}

export default SuperagentRefund