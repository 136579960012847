import axios from 'axios';
import { types } from "../types";
axios.defaults.withCredentials = true
//const token= localStorage.getItem('x-auth-token');
const token = document.cookie.split("=")[1];
export const getIncidentsAdmin = () => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/admins`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      const incidents = res.data.data.sort((a, b) => b.id - a.id)
      dispatch({
        type: types.GET_INCIDENTS_SUCCESS,
        incidents: incidents,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const getIncidentAdmin = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/admins/${id}`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      // console.log("The response  is:", res)
      dispatch({
        type: types.GET_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      // console.log("Error getting an incident as a manage:", err.response ? err.response.data : err)
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getIncidentsManager = () => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/manager`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      const incidents = res.data.data.sort((a, b) => b.id - a.id)
      dispatch({
        type: types.GET_INCIDENTS_SUCCESS,
        incidents: incidents,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getIncidentManager = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/manager/${id}`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.GET_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      // console.log("Error getting an incident as a manage:", err.response)

      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const getUserIncidents = () => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/user`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      const incidents = res.data.data.sort((a, b) => b.id - a.id)
      dispatch({
        type: types.GET_INCIDENTS_SUCCESS,
        incidents: incidents,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const getUserIncident = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/user/${id}`, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.GET_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      // console.log("THere was an error while getting an incident and that error is:", err, err.response)
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const refundIncident = (id, values) => dispatch => {
  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/refund/${id}`, { ...values }, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.REFUND_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const updateIncident = (id, values) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/update/${id}`, { ...values }, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.UPDATE_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const acceptAsSuperAgent = id => dispatch => {
  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/accept/superagent/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.ACCEPT_AS_SUPER_AGENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const acceptIncident = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/accept/institution/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.ACCEPT_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const acceptIncidentAsAc = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/accept/ac/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      console.log(res)
      dispatch({
        type: types.ACCEPT_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const rejectIncident = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/reject/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.REJECT_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}
export const resolveIncident = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/resolve/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.CLOSE_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const convertToRequest = (id) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/submitRequest/${id}`, {}, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      console.log("The data after requesting is:", res.data.data)
      dispatch({
        type: types.CONVERT_TO_REQUEST,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const assignIncident = (id, assignee) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/incidents/assign/${Number(id)}`, { assignee }, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.ASSIGN_INCIDENT_TO_USER,
        incident: res.data.data,
        message: res.data.message
      })
    })
    .catch(err => {
      // console.log("THe error while assigning is:", err.response, "We have sent the following data:", id, assignee)
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const updateFromData = (values) => dispatch => {

  return dispatch({
    type: types.FORM_DATA,
    values: values
  })
}

export const createIncindent = (data, images, props) => dispatch => {

  dispatch({
    type: types.GET_INCIDENT_PENDING
  })
  let { imgs, ...values } = { ...data }
  imgs = images
  const datas = { ...values, images: imgs }
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/incidents`, datas, {
    headers: {
      "x-auth-token": token
    }
  })
    .then(res => {
      dispatch({
        type: types.CREATE_INCIDENT_SUCCESS,
        incident: res.data.data,
        message: res.data.message
      })
      dispatch({
        type: types.ClEAR_UPLOAD_IMAGES
      })
      props.history.push("/dashboard/incidents")
    })
    .catch(err => {
      dispatch({
        type: types.GET_INCIDENT_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}


export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_SNACKBAR
  })
}

