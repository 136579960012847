import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    Grid,
    Button,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';


import {
    getIncidentsManager,
    getIncidentsAdmin,
    clearSnackBar
} from "../../redux/actions/incidents/incidentActions"
import { checkLogin } from "../../redux/actions/auth/login"

import Header from '../templates/TopPartOfPage'
import DisplayAll from '../templates/DisplayAll'


const Requests = props => {
    const incidents = useSelector(state => state.incidents)
    const auth = useSelector(state => state.login)

    const dispatch = useDispatch();


    // console.log("The incidents are:", incidents.incidents)

    useEffect(() => {
        dispatch(checkLogin());
    }, [])
    useEffect(() => {
        if (auth.user) {
            if (auth.user.role.Permissions.manageIncident) {
                dispatch(getIncidentsAdmin(props.match.params.id));
            } else if (auth.user.role.Permissions.institutionManager) {
                dispatch(getIncidentsManager(props.match.params.id));
            }
        }
    }, [auth.user])

    const columns = [
        {
            field: 'newId',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'ticketId',
            headerName: 'Ticket Id',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'iDate',
            headerName: 'Date',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'deviceName',
            headerName: 'Device',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'incDamage',
            headerName: 'Issue',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'incInstitution',
            headerName: 'Institution',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 120,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/requests/${params.id}`)}
                        >
                            <VisibilityIcon color="primary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];
    let rows
    const requests = incidents.incidents ? incidents.incidents.filter(incident => incident.isRequest) : []

    rows = requests
    rows.forEach((row, index) => {
        row.newId = index + 1
        row.iDate = moment(row.createdAt).format('MMM D, YYYY h:mm a')
        row.incDamage = row.damage ? row.damage.name : ""
        row.incInstitution = row.institution ? row.institution.name : ""
        row.deviceImei = row.device ? row.device.deviceImei : ""
        row.deviceName = row.device ? row.device.name : ""
    })
    return (
        <div>
            {/* <Header
                name="Requests"
                props={props}
            /> */}
            <DisplayAll
                cTitle={'Available Requests'}
                showSearch={false}
                showAddBtn={false}
                columns={columns}
                rows={rows}
                reducer={incidents}
                cleaner={clearSnackBar}
                showImport={false}
                pending={incidents.pending}
            // direct={() => props.history.push("/dashboard/newincident")}
            />
        </div>
    )
}

export default Requests
