import { types } from "../../actions/types";

const initialState = {
    statuses: [],
    pending: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.STATUS_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_STATUS_SUCCESS:
            return {
                ...state,
                statuses: action.statuses,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.GET_STATUS_ERROR:
            return {
                ...state,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };

        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
