import { types } from "../../actions/types";

const initialState = {
  institution: {},
  locations: [],
  project: {},
  pending: false,
  removed: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INSTITUTIONS_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        institution: action.institution,
        locations: action.institution.locations,
        project: action.institution.projects,
        pending: false,
        // snackBarMessage: {
        //   open: true,
        //   severity: 'success',
        //   message: action.message
        // }
      };
    case types.REMOVE_ROUTE_SUCCESS:
      return {
        ...state,
        removed: state.removed ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.ASSIGN_ROUTE_TO_INSTITUTION:
      return {
        ...state,
        removed: state.removed ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.ASSIGN_USER_TO_INSTITUTION:
      return {
        ...state,
        removed: state.removed ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.REMOVE_USER_FROM_INSTITUTION:
      return {
        ...state,
        removed: state.removed ? false : true,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.ADD_LOCATION_SUCCESS:
      return {
        ...state,
        message: action.message,
        institution: action.institution,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'success',
          message: action.message
        }
      }
    case types.GET_INSTITUTIONS_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
