import {
  DEVICES_ERROR,
  DEVICES_PENDING,
  CREATE_DEVICE,
  UPLOAD_DEVICES,
} from "./type";
import http from "../../../utils/axios/axios";

const auth = localStorage.getItem("x-auth-token");
export const createDevice = (device) => (dispatch) => {
  dispatch({ type: DEVICES_PENDING });
  return http
    .post(`/api/devices/`, device, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: CREATE_DEVICE,
        payload: res.data,
        message: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({
        type: DEVICES_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};

export const uploadDevices = (devices) => (dispatch) => {
  console.log("The devices are:", devices);

  dispatch({
    type: DEVICES_PENDING,
  });
  devices.forEach((data) => {
    delete data.id;
  });

  devices.forEach(async (device) => {
    device.deviceImei = device.deviceimei;
    device.deviceType = device.devicetype;
    device.projectId = device.projectid;
    device.statusId = device.statusid;
    device.lpoNumber = device.lponumber;

    await delete device.deviceimei;
    await delete device.devicetype;
    await delete device.projectid;
    await delete device.lponumber;
  });

  return http
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/api/devices/upload`,
      { devices },
      {
        headers: {
          "x-auth-token": auth,
        },
      }
    )
    .then((res) => {
      dispatch({
        type: UPLOAD_DEVICES,
        payload: res.data,
        message: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({
        type: DEVICES_ERROR,
        payload: err.response ? err.response.data.error : err.message,
      });
    });
};
