import React, { useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import Cookie from 'universal-cookie'
import SuccessImg from '../../assets/images/pswdReset.png'


import color from '../colors'

const ResetSucess = () => {
    let history = useHistory();
    const navigateToHome = () => {
        history.push('/')
        window.location.reload()
    }
    const cookies = new Cookie()
    useEffect(() => {
        cookies.remove('x-auth-token')
        cookies.remove('userRoles')
    }, [])
    return (
        <div
            style={{
                marginTop: '1em',
                // backgroundColo?r: 'aqua',
                width: '100%',
                textAlign: 'center'
            }}>
            {!SuccessImg ?
                <div
                // className={classes.progress}
                >
                    <CircularProgress />
                </div> :
                <div>
                    <img
                        style={{
                            width: '50%',
                        }} src={SuccessImg} /><br />
                    <p>Password Updated Successfullly</p>
                    <Button
                        color='primary'
                        variant="contained"
                        style={{ backgroundColor: color.secondary }}
                        onClick={navigateToHome}
                    >Go back to the homepage</Button>
                </div>
            }
        </div>
    )
}

export default ResetSucess

