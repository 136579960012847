import {
  GET_USERS_SUCCESS,
  USERS_ERROR,
  PENDING_USERS,
  POST_USER_SUCCESS,
  GET_ADMIN_SUCCESS,
  UPDATE_USERS_SUCCESS,
  ONE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USER_ROLES,
  GET_ALL_PROJECTS,
  RESET_PASSWORD_SUCCESS,
  UPLOAD_USERS,
  CLEAR_SNACKBAR,
  RESET_PSWD_LINK,
  USER_PSWD_RESET,
  UPDATE_PROFILE,
  GET_DEPARTMENTS,
} from "../actions/usersAction";

const initialState = {
  users: [],
  error: "",
  pending: false,
  one: {},
  updateUser: "",
  roles: [],
  user: {},
  projects: [],
  message: "",
  resetPassword: "",
  bulkUsers: "",
  deleted: "",
  snackBarMessage: {
    open: false,
    severity: "",
    message: null,
  },
  userPassword: "",
  departments: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case PENDING_USERS:
      return {
        ...state,
        pending: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        pending: false,
        error: "",
        // snackBarMessage: {
        //     open: true,
        //     severity: 'success',
        //     message: action.message
        // }
      };
    case RESET_PSWD_LINK:
      return {
        ...state,
        message: action.message,
        pending: false,
      };
    case USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "error",
          message: action.payload,
        },
      };
    case ONE_USER_SUCCESS:
      return {
        ...state,
        one: action.payload,
        pending: false,
        // snackBarMessage: {
        //     open: true,
        //     severity: 'success',
        //     message: action.message
        // }
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        deleted: action.payload,
        // snackBarMessage: {
        //     open: true,
        //     severity: 'success',
        //     message: action.message
        // }
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case GET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
        pending: false,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments.data || [],
        pending: false,
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        pending: false,
      };
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        updateUser: action.payload,
        pending: false,
        error: "",
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case USER_PSWD_RESET:
      return {
        ...state,
        userPassword: action.payload,
        pending: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
        pending: false,
        error: "",
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case UPLOAD_USERS:
      return {
        ...state,
        bulkUsers: action.payload,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: "success",
          message: action.message,
        },
      };
    case CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: "",
          message: null,
        },
      };

    case UPDATE_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    default:
      return state;
  }
}
