import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
    getProjects,
    deleteProject,
    createProject,
    getProject,
    updateProject,
    clearSnackBar,
    uploadProjects
} from "../../redux/actions/projects/projectsAction"
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress } from '@material-ui/core';
// import { DataGrid } from '@material-ui/data-grid';
import color from "../colors";
import {
    Grid,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
// import Loading from '../Loading'
// import Message from '../Message'
// import { SnackbarProvider, useSnackbar } from 'notistack';
import DisplayAll from '../templates/DisplayAll';
import DeleteModel from '../templates/DeleteModel';
import EditModel from '../templates/EditModel';
// import AddModel from '../templates/AddModel';


const useStyles = makeStyles((theme) => ({
    element: {
        // padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    header: {
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    progress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 3, 1),
    },
}))

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const Projects = props => {
    const projects = useSelector(state => state.projects.projects)
    const projectsReducer = useSelector(state => state.projects)
    let history = useHistory()

    const classes = useStyles();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProjects())
    }, [projectsReducer.project, projectsReducer.deleted, projectsReducer.upload])


    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [projectToDelete, setProjectToDelete] = useState("")
    const [projectToEdit, setProjectToEdit] = useState("")
    const [projectName, setProjectName] = useState(null)
    const [projectDescription, setProjectDescription] = useState(null)
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    const [nameToDelete, setNameToDelete] = useState(null)


    const handleOpenDeleteModel = (e) => {
        setOpenDelete(true)
        const element = e.currentTarget.id.split(",")
        setProjectToDelete(element[0])
        setNameToDelete(element[1])
    }

    const handleOpenEditModel = (e) => {
        const element = e.currentTarget.id.split(",")
        setProjectToEdit(element[0])
        setName(element[1])
        setDescription(element[2])
        setOpenEdit(true)
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleCloseEditModel = () => {
        setName(null)
        setDescription(null)
        setOpenEdit(false)
    }
    const handleDelete = (e) => {
        dispatch(deleteProject(projectToDelete))
        setOpenDelete(false)
    }
    const handleName = (e) => {
        setProjectName(e.target.value)
    }
    const handleNameToEdit = (e) => {
        setName(e.target.value)
    }

    const handleDescription = (e) => {
        setProjectDescription(e.target.value)
    }
    const handleDescriptionToEdit = (e) => {
        setDescription(e.target.value)
    }

    const handleCreateProject = () => {
        dispatch(createProject(projectName, projectDescription))
        setProjectName(null)
        setProjectDescription(null)
    }

    const handleEditProject = () => {
        dispatch(updateProject(projectToEdit, name, description))
        handleCloseEditModel()
    }
    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteProject(id)))
    }
    // const columns = [
    //     {
    //         field: 'newId',
    //         headerName: 'ID',
    //         flex: 1,
    //         headerClassName: 'super-app-theme--header',
    //     },
    //     {
    //         field: 'name',
    //         headerName: 'Name',
    //         flex: 2,
    //         headerClassName: 'super-app-theme--header'
    //     },
    //     {
    //         field: 'description',
    //         headerName: 'Description',
    //         flex: 3,
    //         headerClassName: 'super-app-theme--header'
    //     },
    //     {
    //         field: 'action',
    //         headerName: 'Action',
    //         width: 250,
    //         headerClassName: 'super-app-theme--header',
    //         renderCell: (params) => (
    //             <Grid
    //                 container
    //                 // spacing={1}
    //                 direction="row"
    //             >
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={() => history.push(`/dashboard/projects/${params.getValue('id')}`)}
    //                     >
    //                         <VisibilityIcon color="primary" />
    //                     </Button>
    //                 </Grid>
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={handleOpenEditModel}
    //                         id={`${params.getValue('id')},${params.getValue('name')},${params.getValue('description')}`}>
    //                         <EditIcon />
    //                     </Button>
    //                 </Grid>
    //                 <Grid
    //                     item
    //                 >
    //                     <Button
    //                         onClick={handleOpenDeleteModel}
    //                         id={`${params.getValue('id')},${params.getValue('name')}`} >
    //                         <DeleteIcon color="secondary" />
    //                     </Button>
    //                 </Grid>
    //             </Grid>
    //         )
    //     },
    // ];
    const columns = [
        {
            field: 'newId',
            title: 'ID',

        },
        {
            field: 'name',
            title: 'Name',

        },
        {
            field: 'description',
            title: 'Description'
        },
        {
            field: 'action',
            title: 'Action',
            headerStyle: {
                textAlign: 'right'
            },
            render: rowData => (
                <Grid
                    container
                    direction="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            size='small'
                            onClick={() => history.push(`/dashboard/projects/${rowData.id}`)}
                        >
                            <VisibilityIcon
                                color="primary"
                                fontSize='small'
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenEditModel}
                            size='small'
                            id={`${rowData.id},${rowData.name},${rowData.description}`}>
                            <EditIcon
                                fontSize='small'
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            onClick={handleOpenDeleteModel}
                            size='small'
                            id={`${rowData.id},${rowData.name}`} >
                            <DeleteIcon
                                color="secondary"
                                fontSize='small'
                            />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];
    let rows

    if (projects) projects.map(project => project.newId = (projects.indexOf(project)) + 1)
    projects ? rows = projects : rows = []


    return (
        <div
            className={classes.element}
        >
            {projectsReducer.pending ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <DisplayAll
                    cTitle={'Available Projects'}
                    addBtnTitle={'Add New Project'}
                    rows={rows}
                    columns={columns}
                    cleaner={clearSnackBar}
                    reducer={projectsReducer}
                    pending={false}
                    uploader={uploadProjects}
                    handleCreate={handleCreateProject}
                    multipleDeleteHandler={multipleDeleteHandler}
                    usemTable
                >
                    <DialogTitle id="form-dialog-title">Create New Project</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Project Name"
                            type="text"
                            onChange={handleName}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Project Description"
                            type="text"
                            onChange={handleDescription}
                            fullWidth
                        />
                    </DialogContent>
                </DisplayAll>
            }
            <DeleteModel
                title={'Delete Project'}
                btnText={'Delete Project'}
                description={`Are you sure you want to delete ${nameToDelete} project? Once deleted, you will not be able to see it again.`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
            />
            <EditModel
                title={'Edit Project'}
                // description={'Hello from the editing page'}
                closeEditDialog={handleCloseEditModel}
                isEditModelOpen={openEdit}
                classes={classes}
                handleEdit={handleEditProject}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        defaultValue={name}
                        id="name"
                        label="Project Name"
                        type="text"
                        onChange={handleNameToEdit}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        defaultValue={description}
                        id="description"
                        label="Project Description"
                        type="text"
                        onChange={handleDescriptionToEdit}
                        fullWidth
                    />
                </DialogContent>
            </EditModel>
        </div>
    )
}

export default Projects
