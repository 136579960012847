import React, { useEffect, useState } from "react";
import {
    Grid,
    makeStyles,
    TextField,
    TableFooter,
    TableRow,
    TableCell,
    Typography,
    Chip,
    Button
} from "@material-ui/core"
// import { Visibility } from '@material-ui/icons';
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
    Visibility,
    Cancel,
    Check
} from '@material-ui/icons/'
import DateFnsUtils from '@date-io/date-fns'
import Autocomplete from '@mui/material/Autocomplete';
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import MaterialTable, { MTableToolbar, MTableBody } from 'material-table';
import CustomMessage from "../templates/CustomMessages";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux"
import { getUserIncidents, clearSnackBar } from "../../redux/actions/incidents/incidentActions"
import IncidentCard from "./incidentCards/incidentCard"
import Top from "../templates/TopPartOfPage"
import color from "../colors"
import { removeSpace } from '../../utils/removeSpaces'
import { formatter } from "../../utils/currencyFormater";
import { resolveIncident, acceptAsSuperAgent } from '../../redux/actions/incidents/incidentActions'
import { checkAuthedUser } from '../../redux/actions/auth/login'
import { getSuperagentRequests, resolveRequest } from "../../redux/actions/superagent_requests/superagentRequests";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    tabs: {
        backgroundColor: `${color.primary100}`,
        color: "white"
    },
    tab: {
        color: "white",
    },
    tabPanel: {
        color: "white"
    },
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    contain: {
        position: "relative",
        width: "100%",
        marginBottom: "2px",
        borderRadius: "3px"
    },
}))

function Incidents(props) {

    const incidents = useSelector(state => state.incidents)
    const loginState = useSelector(state => state.login)
    const superagentRequests = useSelector(state => state.superRequests)

    // console.log("The superagent requests are:", superagentRequests)

    const userProfile = loginState.loggedInUser

    const dispatch = useDispatch();
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const [viewOption, setViewOption] = useState("Incidents")
    const [viewStatus, setViewStatus] = useState("Pending")
    const [selectedStartDate, handleStartDateChange] = useState(yesterday);
    const [selectedEndDate, handleEndDateChange] = useState(today);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        dispatch(getUserIncidents())
        dispatch(checkAuthedUser())
        dispatch(getSuperagentRequests())
    }, [refresh])

    const handleResolve = (dataRows) => {
        if (dataRows.category.name == "Financial" && dataRows.project.name == 'Upcountry' && !dataRows.refunded) {
            alert("You can not resolve a financial incident that was not marked as refunded")
        } else {
            dispatch(resolveIncident(dataRows.id))
        }
    }
    const markAsRefunded = (dataRows) => {
        dataRows.forEach(item => {
            if (!item.refunded) {
                alert("The incident is not refunded.")
            } else {
                dispatch(acceptAsSuperAgent(item.id))
            }
        })
        setRefresh(cv => !cv)
    }
    const resolveForSuperagent = (dataRows) => {
        dataRows.forEach(item => {
            if (!item.refunded) {
                if (item.tag == 'loan') {
                    alert("You can not resolve a loan request whose laon is not offered")
                } else {
                    alert("You can not resolve a request that is not refunded")
                }
            }
            dispatch(resolveRequest(item.id))
        })
        setRefresh(cv => !cv)
    }

    const columns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'dataId',
            title: 'ID',
        },
        {
            field: 'agentName',
            title: 'Agent',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'superAgentUser',
            title: 'Super Agent',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'damageName',
            title: 'Issue',
            flex: 2,
            headerClassName: 'super-app-theme--header'
        },
        {
            title: 'Status',
            render: rowData => <Chip
                size="small"
                label={rowData.reqStatus ? rowData.reqStatus.toUpperCase() : ""}
                style={{
                    backgroundColor: rowData.reqStatus == 'accepted' ? 'green' :
                        rowData.reqStatus == 'pending' ? 'yellow' :
                            rowData.reqStatus == 'cancelled' ? '#EC7063' :
                                rowData.reqStatus == 'resolved' ? '#58D68D' :
                                    rowData.reqStatus == 'rejected' ? '#78281F' : '#ECF0F1',
                    color: rowData.reqStatus == 'accepted' || rowData.reqStatus == 'rejected' ? 'white' : 'black'
                }}
            />
        },
        {
            field: 'requestAmount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.requestAmount)}</Typography>
                    </Grid>
                </Grid>
            )
        },

    ];
    const superRefundColumns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'agentnames',
            title: 'Agent Names',
        },
        {
            field: 'agentusername',
            title: 'Agent Username',
        },
        {
            field: 'displayDate',
            title: 'Date',
        },
        {
            title: 'Status',
            render: rowData => <Chip
                size="small"
                label={rowData.reqStatus ? rowData.reqStatus.toUpperCase() : ""}
                style={{
                    backgroundColor: rowData.reqStatus == 'accepted' ? 'green' :
                        rowData.reqStatus == 'pending' ? 'yellow' :
                            rowData.reqStatus == 'cancelled' ? '#EC7063' :
                                rowData.reqStatus == 'resolved' ? '#58D68D' :
                                    rowData.reqStatus == 'rejected' ? '#78281F' : '#ECF0F1',
                    color: rowData.reqStatus == 'accepted' || rowData.reqStatus == 'rejected' ? 'white' : 'black'
                }}
            />
        },
        {
            field: 'amount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.amount)}</Typography>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'description',
            title: 'Description',
        },
        {
            field: 'action',
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/superagent/refund/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];

    const loanColumns = [
        {
            field: 'newId',
            title: 'No',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'displayDate',
            title: 'Date',
        },

        {
            field: 'amount',
            title: 'Amount',
            render: (params) => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Typography style={{ fontSize: 13 }}>{formatter.format(params.amount)}</Typography>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'description',
            title: 'Description',
        },
        {
            title: 'Status',
            render: rowData => <Chip
                size="small"
                label={rowData.reqStatus ? rowData.reqStatus.toUpperCase() : ""}
                style={{
                    backgroundColor: rowData.reqStatus == 'accepted' ? 'green' :
                        rowData.reqStatus == 'pending' ? 'yellow' :
                            rowData.reqStatus == 'cancelled' ? '#EC7063' :
                                rowData.reqStatus == 'resolved' ? '#58D68D' :
                                    rowData.reqStatus == 'rejected' ? '#78281F' : '#ECF0F1',
                    color: rowData.reqStatus == 'accepted' || rowData.reqStatus == 'rejected' ? 'white' : 'black'
                }}
            />
        },
        {
            field: 'action',
            title: 'Action',
            render: rowData => (
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                    >
                        <Button
                            onClick={() => props.history.push(`/dashboard/superagent/refund/${rowData.id}`)}
                        >
                            <Visibility color="primary" />
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];

    let rows;
    let superRequestRows;
    let loanRows;
    if (superagentRequests.superagentRequests) {
        let submittedRequests = superagentRequests.superagentRequests.filter(request => request.tag === "refund")
        superRequestRows = submittedRequests.filter(incident => incident.status.name === removeSpace(viewStatus ? viewStatus : ""))
    } else {
        superRequestRows = []
    }
    if (superagentRequests.superagentRequests) {
        let submittedLoans = superagentRequests.superagentRequests.filter(request => request.tag === "loan")
        loanRows = submittedLoans.filter(incident => incident.status.name === removeSpace(viewStatus ? viewStatus : ""))
    } else {
        loanRows = []
    }
    // console.log("The loan rows are:", loanRows)
    if (incidents.incidents) {
        if (removeSpace(viewStatus ? viewStatus : "") === 'superagentreview') {
            rows = incidents.incidents.filter(incident => incident.refunded && incident.status.name !== 'acceptedbysuperagent')
        } else if (removeSpace(viewStatus ? viewStatus : "") === 'partiallyaccepted') {
            rows = incidents.incidents.filter(incident => !(incident.acceptedByAc && incident.acceptedByInstitution) && (incident.acceptedByAc || incident.acceptedByInstitution) && !(incident.status.name === "rejected") && !(incident.status.name === "resolved"))
        } else {
            rows = incidents.incidents.filter(incident => incident.status.name === removeSpace(viewStatus ? viewStatus : ""))
        }
    } else {
        rows = []
    }

    superRequestRows.forEach((row, index) => {
        row.newId = index + 1
        row.displayDate = row.date.replace("T", ` at `)
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
    })

    loanRows.forEach((row, index) => {
        row.newId = index + 1
        row.displayDate = row.date.replace("T", ` at `)
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
    })

    rows.forEach((row, index) => {
        row.newId = index + 1
        row.reqStatus = row.status && row.status.name ? row.status.name : ""
        row.superAgentUser = row.incidentAgent ? row.incidentAgent.superAgentUser.name : row.superAgentName ? row.superAgentName : "No Superagent"
        row.damageName = row.damage ? row.damage.name : "No issue"
        row.projectName = row.project ? row.project.name : "No project"
        row.agentName = row.incidentAgent ? row.incidentAgent.name : row.agentUsername ? row.agentUsername : "No Agent"
        row.dataId = row.busTicketId || row.momoTransactionId || "No Id"

    })

    const filteredRows = rows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const filteredSuperRows = superRequestRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const filteredLoansRows = loanRows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    const classes = useStyles();
    return (
        <div className={classes.contain}>

            <Top
                props={props}
                name="My Incidents"
            />
            <Grid
                container
                justify="flex-end"
                alignItems="flex-end"
                style={{
                    marginBottom: "1em",
                }}
            >
                {userProfile && (userProfile.role.Permissions.requestLoan || userProfile.role.Permissions.offerLoans || userProfile.role.Permissions.requestSuperRefund) && <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={2}
                    style={{
                        marginRight: "1em",
                    }}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={["Loans", "Incidents", "Refund Requests"]}
                        value={viewOption}
                        onChange={(event, newValue) => {
                            setViewOption(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="View  Options"
                        />
                        }
                    />
                </Grid>
                }

                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={2}
                    style={{
                        marginRight: "1em"
                    }}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={["Pending", "Accepted", "Partially Accepted", "Superagent Review", "Rejected", "Resolved",]}
                        value={viewStatus}
                        onChange={(event, newValue) => {
                            setViewStatus(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="View  Status"
                        />
                        }
                    />
                </Grid>
                <Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item>
                                <DateTimePicker
                                    label="Start Date"
                                    disableFuture
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    autoOk
                                    ampm={false}
                                    disableFuture
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    label="End Date"
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            {viewOption == "Refund Requests" ? <MaterialTable
                columns={superRefundColumns}
                data={filteredSuperRows}
                title="Refund Requests"
                options={{
                    exportMenu: [
                        {
                            label: "Export PDF",
                            exportFunc: (cols, datas) => {
                                const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                            },
                        },
                        {
                            label: "Export CSV",
                            exportFunc: (cols, datas) => {
                                const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                            }
                        }
                    ],
                    exportButton: true,
                    actionsColumnIndex: -1,
                    padding: "dense",
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    showSelectAllCheckbox: true,
                    pageSizeOptions: [6, 12, 20, 50],
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        whiteSpace: 'nowrap'
                    },
                    rowStyle: {
                        whiteSpace: 'nowrap',
                        fontSize: 13,
                        fontFamily: "Poppins', sans-serif !important"
                    },
                    selection: viewStatus !== "Rejected" && viewStatus !== "Resolved" ? true : false
                }}
                actions={viewStatus !== "Rejected" && viewStatus !== "Resolved" ? [
                    {
                        icon: () => <Check color="primary" />,
                        tooltip: "Resolve",
                        onClick: (event, rowData) => {
                            resolveForSuperagent(rowData)
                        },
                    },

                ] : []}
                components={{
                    Toolbar: MTableToolbar
                }}
            /> : viewOption === 'Loans' ? <MaterialTable
                columns={loanColumns}
                data={filteredLoansRows}
                title="Refund Requests"
                options={{
                    exportMenu: [
                        {
                            label: "Export PDF",
                            exportFunc: (cols, datas) => {
                                const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                            },
                        },
                        {
                            label: "Export CSV",
                            exportFunc: (cols, datas) => {
                                const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                            }
                        }
                    ],
                    exportButton: true,
                    actionsColumnIndex: -1,
                    padding: "dense",
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    showSelectAllCheckbox: true,
                    pageSizeOptions: [6, 12, 20, 50],
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        whiteSpace: 'nowrap'
                    },
                    rowStyle: {
                        whiteSpace: 'nowrap',
                        fontSize: 13,
                        fontFamily: "Poppins', sans-serif !important"
                    },
                    selection: viewStatus !== "Rejected" && viewStatus !== "Resolved" ? true : false
                }}
                actions={viewStatus !== "Rejected" && viewStatus !== "Resolved" ? [
                    {
                        icon: () => <Check color="primary" />,
                        tooltip: "Resolve",
                        onClick: (event, rowData) => {
                            resolveForSuperagent(rowData)
                        },
                    },

                ] : []}
                components={{
                    Toolbar: MTableToolbar
                }}
            /> : (
                <>
                    {userProfile && userProfile.role.name === "Super Agent" ? (
                        <MaterialTable
                            columns={columns}
                            data={filteredRows}
                            title="Incidents"
                            options={{
                                exportMenu: [
                                    {
                                        label: "Export PDF",
                                        exportFunc: (cols, datas) => {
                                            const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                            ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                        },
                                    },
                                    {
                                        label: "Export CSV",
                                        exportFunc: (cols, datas) => {
                                            const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                            ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                        }
                                    }
                                ],
                                exportButton: true,
                                actionsColumnIndex: -1,
                                padding: "dense",
                                paging: true,
                                pageSize: 20,
                                emptyRowsWhenPaging: false,
                                showSelectAllCheckbox: true,
                                pageSizeOptions: [6, 12, 20, 50],
                                headerStyle: {
                                    backgroundColor: '#01579b',
                                    color: '#FFF',
                                    whiteSpace: 'nowrap'
                                },
                                rowStyle: {
                                    whiteSpace: 'nowrap',
                                    fontSize: 13,
                                    fontFamily: "Poppins', sans-serif !important"
                                },
                                selection: true
                            }}
                            actions={[
                                {
                                    icon: () => <Check color="primary" />,
                                    tooltip: "Confirm Money Receipt",
                                    onClick: (event, rowData) => {
                                        markAsRefunded(rowData)
                                    },
                                },

                            ]}
                            components={{
                                Toolbar: MTableToolbar
                            }}
                        />
                    ) : (
                        <MaterialTable
                            columns={columns}
                            data={filteredRows}
                            title="Submitted Incidents"
                            options={{
                                exportMenu: [
                                    {
                                        label: "Export PDF",
                                        exportFunc: (cols, datas) => {
                                            const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                            ExportPdf(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                        },
                                    },
                                    {
                                        label: "Export CSV",
                                        exportFunc: (cols, datas) => {
                                            const total = datas.reduce((acc, cv) => acc + Number(cv.requestAmount), 0)
                                            ExportCsv(cols, [...datas, { dataId: "Total", requestAmount: formatter.format(total) }], "Refunds")
                                        }
                                    }
                                ],
                                exportButton: true,
                                actionsColumnIndex: -1,
                                padding: "dense",
                                paging: true,
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [6, 12, 20, 50],
                                headerStyle: {
                                    backgroundColor: '#01579b',
                                    color: '#FFF'
                                },
                                rowStyle: {
                                    whiteSpace: 'nowrap',
                                    fontSize: 13,
                                    fontFamily: "Poppins', sans-serif !important"
                                }
                            }}
                            actions={[
                                {
                                    icon: () => <Visibility color="primary" />,
                                    tooltip: "View",
                                    onClick: (event, rowData) => props.history.push(`/dashboard/incident/${rowData.id}`)
                                },
                                {
                                    icon: () => <Check color="primary" />,
                                    tooltip: "Resolve",
                                    onClick: (event, rowData) => {
                                        handleResolve(rowData)
                                    },
                                    hidden: (removeSpace(viewStatus ? viewStatus : "") === 'resolved') || removeSpace(viewStatus ? viewStatus : "") === 'rejected'

                                }

                            ]}
                            components={{
                                Toolbar: MTableToolbar,
                                Body: (props) => {
                                    let renderValues = [...props.renderData]
                                    const totalRefund = renderValues.reduce((acc, cv) => {
                                        return acc += Number(cv.requestAmount)
                                    }, 0)

                                    return (
                                        <>
                                            <MTableBody {...props} />
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={5} />
                                                    <TableCell colSpan={5}
                                                        style={{
                                                            fontSize: "1em",
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {formatter.format(totalRefund)}
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </>
                                    )
                                }

                            }}
                        />
                    )}

                </>
            )
            }

            {/* <React.Fragment>
                {incidents.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    :
                    (
                        incidents.incidents ?

                            <Paper className={classes.root}>
                                <CustomMessage reducer={incidents} cleaner={clearSnackBar} />
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="white"
                                    centered
                                    className={classes.tabs}
                                >
                                    <Tab value={0} label="Pending" className={classes.tab}  {...a11yProps(0)} />
                                    <Tab value={1} label="Partially Accepted" className={classes.tab} {...a11yProps(3)} />
                                    <Tab value={2} label="Accepted" className={classes.tab} {...a11yProps(1)} />
                                    <Tab value={3} label="Rejected" className={classes.tab} {...a11yProps(2)} />
                                    <Tab value={4} label="Resolved" className={classes.tab} {...a11yProps(4)} />
                                </Tabs>
                                <TabPanel value={value} index={0} >

                                    <Grid container item spacing={3}>
                                        {incidents.incidents.map((incident, index) => (
                                            incident.status.name === "pending" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={classes.insideGrid, classes.separate}>
                                                    <IncidentCard incident={incident} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>

                                </TabPanel>
                                <TabPanel value={value} index={2}>

                                    <Grid container item spacing={3}>
                                        {incidents.incidents.map((incident, index) => {
                                            return (
                                                (incident.acceptedByAc && incident.acceptedByInstitution) ?
                                                    <Grid item xs={8} sm={4} md={3} key={index} className={classes.insideGrid, classes.separate}>
                                                        <IncidentCard incident={incident} props={props} />
                                                    </Grid>
                                                    : null
                                            )
                                        })}
                                    </Grid>

                                </TabPanel>
                                <TabPanel value={value} index={3}>

                                    <Grid container item spacing={3}>
                                        {incidents.incidents.map((incident, index) => (
                                            incident.status.name === "rejected" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={classes.insideGrid, classes.separate}>
                                                    <IncidentCard incident={incident} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>

                                </TabPanel>
                                <TabPanel value={value} index={1}>

                                    <Grid container item spacing={3}>
                                        {incidents.incidents.map((incident, index) => (
                                            !(incident.acceptedByAc && incident.acceptedByInstitution) && (incident.acceptedByAc || incident.acceptedByInstitution) && !(incident.status.name === "rejected") && !(incident.status.name === "resolved") ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={classes.insideGrid, classes.separate}>
                                                    <IncidentCard incident={incident} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>

                                </TabPanel>
                                <TabPanel value={value} index={4}>

                                    <Grid container item spacing={3}>
                                        {incidents.incidents.map((incident, index) => (
                                            incident.status.name === "resolved" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={classes.insideGrid, classes.separate}>
                                                    <IncidentCard incident={incident} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>

                                </TabPanel>
                            </Paper>
                            :
                            (
                                incidents.error ?
                                    <div>
                                        {incidents.snackBarMessage.message}
                                    </div>
                                    :
                                    <div>
                                        you have no submitted incident
                                    </div>

                            )
                    )

                }
            </React.Fragment> */}
        </div>
    )
}


export default Incidents;
