import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";

const cookies = new Cookie()
const token = cookies.get('x-auth-token');

export const getSites = () => dispatch => {

    dispatch({
        type: types.SITES_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sites`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            const sites = res.data.data.sort((a, b) => a.id - b.id)
            dispatch({
                type: types.GET_SITES_SUCCESS,
                sites,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const getSite = (id) => dispatch => {

    dispatch({
        type: types.SITES_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sites/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.GET_SITE_SUCCESS,
                site: res.data.site,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}
export const createSite = (site) => dispatch => {

    dispatch({
        type: types.SITES_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sites`,
        site,
        {
            headers: {
                "x-auth-token": token
            }
        }
    )
        .then(res => {
            dispatch({
                type: types.CREATE_SITE,
                site: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            console.log("The error has been,", err.response)
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}


export const uploadSites = (sites) => dispatch => {


    dispatch({
        type: types.SITES_PENDING
    })
    sites.forEach(data => {
        delete data.id
    })

    sites.forEach(async (site) => {
        const stringfiedIds = String(site.projectids)
        site.projectIds = stringfiedIds.split(",")
        await delete site.projectids
    })

    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sites/upload`,
        { sites },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.UPLOAD_SITES,
                sites: res.data.sites,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const deleteSite = (id) => dispatch => {

    dispatch({
        type: types.SITES_PENDING
    })
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/sites/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.DELETE_SITE,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const updateSite = (id, site) => dispatch => {
    console.log("WE have the following id:", id)
    dispatch({
        type: types.SITES_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/sites/${id}`,
        {
            name: site.name,
            district: site.district,
            sector: site.sector,
            cell: site.cell
        },

        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            console.log("The updated site is:", res.data.newSite)
            dispatch({
                type: types.UPDATE_SITE,
                message: res.data.message,
                site: res.data.newSite
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_SITES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}


export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}



