import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  location: {},
  institutions: [],
  pending: false,
  remove: false,
  snackBarMessage: {
    open: false,
    severity: '',
    message: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOCATION_PENDING:
      return {
        ...state,
        pending: true
      }
    case types.GET_LOCATION:
      return {
        ...state,
        location: action.location[0],
        institutions: action.location[0].institutions,
        message: action.message,
        pending: false,
        // snackBarMessage: {
        //     open: true,
        //     severity: 'success',
        //     message: action.message
        // }
      };
    case types.GET_LOCATIONS_ERROR:
      return {
        ...state,
        message: action.error,
        error: action.error,
        pending: false,
        snackBarMessage: {
          open: true,
          severity: 'error',
          message: action.message
        }
      };
    case types.REMOVE_INSTITUTION:
      return {
        ...state,
        pending: false,
        remove: state.remove ? false : true,
        snackBarMessage: {
          open: false,
          severity: 'success',
          message: action.message
        }
      }
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        snackBarMessage: {
          open: false,
          severity: '',
          message: null
        }
      }
    default:
      return state;
  }
};
