import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import NotFoundImg from '../assets/images/notfound.png'
import color from './colors'

const NotFound = () => {
    let history = useHistory();
    const navigateToHome = () => {
        history.push('/')
    }
    return (
        <div
            style={{
                marginTop: '1em',
                textAlign: 'center'
            }}>
            {!NotFoundImg ?
                <div
                // className={classes.progress}
                >
                    <CircularProgress />
                </div> :
                <div>
                    <img
                        style={{
                            width: '70%',
                        }} src={NotFoundImg} /><br />
                    <Button
                        color='primary'
                        variant="contained"
                        style={{ backgroundColor: color.secondary }}
                        onClick={navigateToHome}
                    >Go back to the homepage</Button>
                </div>}
        </div>
    )
}

export default NotFound
