import axios from 'axios';
import Cookies from 'universal-cookie'
import { types } from "../types";
import API from "../../../utils/axios/axios";
import { authRedirect } from "../../../utils/redirects";
axios.defaults.withCredentials = true

const cookies = new Cookies()

const token = cookies.get('x-auth-token');

export const getBuses = () => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING,
        pending: false
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/buses`,
        {
            headers: {
                "x-auth-token": token
            }
        }
    )
        .then(res => {
            const sortedBuses = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_BUSES_SUCCESS,
                buses: sortedBuses,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}

export const getBus = (id) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING,
        pending: false
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/buses/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.GET_BUS_SUCCESS,
                bus: res.data.data,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}


export const createBus = (plateNumber, institutionId, makeId) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING
    })
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/buses`,
        {
            plateNumber, institutionId, makeId
        },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.CREATE_BUS,
                roles: res.data.data,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}

export const deleteBus = (id) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING
    })
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/buses/${id}`,
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.DELETE_BUS,
                message: res.data.message
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}
export const uploadBuses = (buses) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING
    })
    buses.forEach(data => {
        delete data.id
    })

    buses.forEach(async (bus) => {
        bus.institutionId = bus.institutionid
        bus.plateNumber = bus.platenumber
        bus.makeId = bus.makeid
        await delete bus.platenumber
        await delete bus.institutionid
        await delete bus.makeid

    })

    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/buses/upload`,
        { buses },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.UPLOAD_BUSES,
                buses: res.data.buses,
                message: res.data.msg
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}
export const assignDevice = (deviceId, busId) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING
    })

    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/devices/assignBus/${deviceId}`,
        { busId },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            // console.log("THe response from assigning a device is:", res)
            dispatch({
                type: types.ASSIGN_DEVICE_TO_BUS,
                device: res.data.assignedDevice,
                message: res.data.msg
            })
        })
        .catch(err => {
            // console.log("The error while assigning a device is:", err.response)
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}

export const removeDevice = (deviceId, busId) => dispatch => {

    dispatch({
        type: types.GET_BUSES_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/devices/removeBus/${deviceId}`,
        { busId },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.REMOVE_DEVICE_FROM_BUS,
                message: res.data.msg
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response.data.error ? err.response.data.error : "Error occured"
            })
        })
}


export const updateBus = (id, plateNumber, institutionId, makeId) => dispatch => {

    console.log("We have received the following joh;", id, plateNumber, institutionId, makeId)

    dispatch({
        type: types.GET_BUSES_PENDING
    })
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/buses/${id}`,
        {
            plateNumber, institutionId, makeId
        },
        {
            headers: {
                "x-auth-token": token
            }
        })
        .then(res => {
            dispatch({
                type: types.UPDATE_BUS,
                message: res.data.message,
                bus: res.data.data
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_BUSES_ERROR,
                error: err,
                message: err.response ? err.response.data.error : "Error occured"
            })
        })
}
export const clearSnackBar = () => dispatch => {
    return dispatch({
        type: types.CLEAR_SNACKBAR
    })
}

