import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import ErrorImg from '../assets/images/error.png'
import AppError from '../assets/svgs/AppError';
import color from './colors'

const ErrorPage = () => {
    let history = useHistory();
    const navigateToHome = () => {
        history.push('/')
        window.location.reload()
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1em',
                textAlign: 'center',
                height: '100vh'
            }}>
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'blue',
                    // width: '80%',
                    // height: 'auto'
                }}>
                    <AppError />
                </div>

                {/* <img
                    style={{
                        width: '50%',
                    }} src={ErrorImg}
                /> */}
                <br />
                <Typography>Something went wrong. Check your network and try again later!</Typography><br />
                <Button
                    color='primary'
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={navigateToHome}
                >Go back to the homepage</Button>
            </div>

        </div >
    )
}

export default ErrorPage
