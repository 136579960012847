import {GET_DEVICE_TYPES, DEVICES_PENDING, DEVICES_ERROR, GET_ONE_TYPE, CREATE_TYPE, UPDATE_TYPE, DELETE_TYPE} from './type';
import http from '../../../utils/axios/axios';

const auth = localStorage.getItem('x-auth-token')

export const getDeviceTypes = () => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.get('/api/deviceType', {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: GET_DEVICE_TYPES,
            payload: res.data.deviceTypes.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)),
            message: res.data.message
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload: err.response ? err.response.data.error : err.message
        })
    })
}

export const getOneType = (id) => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.get(`/api/deviceType/${id}`, {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: GET_ONE_TYPE,
            payload: res.data.deviceType,
            message: res.data.message
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload: err.response ? err.response.data.error : err.message
        })
    })
}

export const createType = (type) => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.post(`/api/deviceType/create`, type, {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: CREATE_TYPE,
            payload: res.data,
            message: res.data.msg
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload: err.response ? err.response.data.error : err.message
        })
    })
}

export const updateType = (id, type) => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.patch(`/api/deviceType/${id}`, type, {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: UPDATE_TYPE,
            payload: res.data,
            message: res.data.msg
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload: err.response ? err.response.data.error : err.message
        })
    })
}

export const deleteType = (id) => dispatch =>{
    dispatch({ type: DEVICES_PENDING })
    return http.delete(`/api/deviceType/${id}`, {
        headers: {
            "x-auth-token": auth
        }
    })
    .then(res => {
        dispatch({ 
            type: DELETE_TYPE,
            payload: res.data,
            message: res.data.msg
        })
    })
    .catch(err => {
        dispatch({
            type: DEVICES_ERROR,
            payload:err.response ? err.response.data.error : err.message
        })
    })
}