import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const DeleteModel = props => {
    let {
        title,
        description,
        isDeleteModelOpen,
        closeDialog,
        handleDelete,
        btnText
    } = props

    return (
        <div>
            <Dialog
                open={isDeleteModelOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDelete}
                        color="primary"
                        autoFocus
                        variant='contained'>
                        {btnText ? btnText : "Remove"}
                    </Button>
                    <Button
                        onClick={closeDialog}
                        color="secondary"
                        variant='contained'>
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteModel
