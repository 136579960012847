import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  DialogTitle,
  TextField,
  DialogContent,
  Autocomplete,
  Snackbar,
  Slide,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
} from "@mui/material";
import MuiAlert from "@material-ui/lab/Alert";
import { Chrono } from "react-chrono";
import moment from "moment";
import { Add, Check } from "@material-ui/icons";
import DisplayAllPayments from "../templates/DisplayAll";
import SubmitPayment from "../templates/AddModel";
import PickUp from "../templates/AddModel";
import Pickups from "../templates/DisplayAll";
import Header from "../templates/TopPartOfPageWithId";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AssignModel from "../templates/AddModel";

import {
  getSingleRequest,
  acceptRequest,
  rejectRequest,
  initiatePayment,
  recordPickup,
  clearSnackBar,
  requestCancel,
  assignInventoryRequest,
  getProcurements,
} from "../../redux/actions/stock/stock";
import { checkAuthedUser } from "../../redux/actions/auth/login";
import { updateDevice } from "../../redux/actions/devices/updateDevice";

import colors from "../colors";
import DescribeItem from "../templates/DescribeItem";
import UploadImage from "../users/dialogs/uploadImage";
import {
  getUserDepartments,
  getUsersAction,
} from "../../redux/actions/usersAction";
import { getDevices } from "../../redux/actions/devices/getDevices";
import { useStyles } from "../incidents/incident";
import { green } from "@mui/material/colors";
import { getDeviceTypes } from "../../redux/actions/devices/deviceTypes";

const RequestDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const request = useSelector((state) => state.stock);
  const users = useSelector((state) => state.users);
  const loginState = useSelector((state) => state.login);
  const devices = useSelector((state) => state.devices);
  const stock = useSelector((state) => state.stock);
  const typeReducer = useSelector((state) => state.devices.deviceTypes);

  const userProfile = loginState.loggedInUser;
  const invRequest =
    request && request.inventoryRequest ? request.inventoryRequest : {};
  const { id } = props.match.params;
  // console.log("the users we have here are:", users);

  console.log("The types and requests are,", typeReducer, invRequest);

  const [amount, setAmount] = useState();
  const [refNumber, setRefNumber] = useState();
  const [merchant, setMerchant] = useState();
  const [openSubmitPayment, setOpenSubmitPayment] = useState(false);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [imageAlt, setImagealt] = useState(null);
  const [imageValid, setImageValid] = useState("");
  const [openPick, setOpenPick] = useState(false);
  const [pickQuantity, setPickQuantity] = useState();
  const [picker, setPicker] = useState();
  const [pickedDevices, setPickedDevices] = useState([]);
  const [renderItems, setRenderItems] = useState(1);
  const [currentSIM, setCurrentSim] = useState();
  const [currentDeviceId, setCurrentDeviceId] = useState();
  const [openAssign, setOpenAssign] = useState(false);
  const [assignedDepartment, setAssignedDepartment] = useState();
  const [assignedUser, setAssignedUser] = useState();
  const [assignToIndividual, setAssignToIndividual] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [lpoNumber, setLpoNumber] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const [activ, setActiv] = useState([]);

  const [assComment, setAssComment] = useState();
  // console.log("The assign to individual value is:", assignToIndividual);

  const saveCurrentEntries = () => {
    setPickedDevices((prevItems) => {
      //   console.log(index, prevItems);
      if (!prevItems[renderItems - 1]) {
        return [
          ...prevItems,
          {
            deviceId: currentDeviceId,
            sim: currentSIM,
          },
        ];
      } else {
        return [
          ...prevItems,
          (prevItems[renderItems - 1] = {
            ...prevItems[renderItems - 1],
            deviceId: currentDeviceId,
            sim: currentSIM,
          }),
        ];
      }
    });
    setCurrentDeviceId(null);
    setCurrentSim(null);
  };

  useEffect(() => {
    dispatch(getUsersAction());
    dispatch(getSingleRequest(id));
    dispatch(checkAuthedUser());
    dispatch(getDevices());
    dispatch(getUserDepartments());
    dispatch(getProcurements());
    dispatch(getDeviceTypes());
  }, [request.picked, request.refresh]);
  useEffect(() => {
    console.log("Changed");
  }, [activ]);

  const filteredUsers =
    users.users &&
    users.users.filter(
      (user) =>
        user.role.Permissions.manageGroups &&
        user.departmentId === assignedDepartment
    );
  const theSelectedType =
    typeReducer &&
    invRequest.item &&
    typeReducer.filter((type) => type.name === invRequest.item.name);

  const paymentColumns = [
    { title: "ID", field: "newId" },
    { title: "Amount Paid", field: "amountPaid" },
    { title: "Transaction Ref Number", field: "transactionRefNumber" },
    {
      title: "Proof of payment",
      render: (rowData) => (
        <div>
          <a href={rowData.proofOfPayment} target="_blank">
            Proof
          </a>
        </div>
      ),
    },
    {
      title: "Status",
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.acceptStatus ? "green" : "yellow",
            color: rowData.acceptStatus ? "white" : "black",
            borderRadius: "5px",
            padding: "1px 5px",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {rowData.acceptStatus ? "Approved" : "Pending"}
        </div>
      ),
    },
    {
      title: "Approved by",
      field: "approver",
      render: (rowData) => (
        <div>
          {rowData.approver ? (
            <p
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/dashboard/users/${rowData.approverId}`)
              }
            >
              {rowData.approver}
            </p>
          ) : (
            <p>Not yet approved</p>
          )}
        </div>
      ),
    },
    { title: "Approved at", field: "approvalDate" },
    {
      title: "Merchant",
      field: "merchant",
      render: (rowData) => (
        <div>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push(`/dashboard/users/${rowData.merchantId}`)
            }
          >
            {rowData.merchant}
          </p>
        </div>
      ),
    },
  ];
  const pickupColumns = [
    { title: "ID", field: "newId" },
    { title: "Item", field: "item" },
    { title: "Quantity", field: "qtyPickedUp" },
    {
      title: "Picked by",
      field: "pickedBy",
      render: (rowData) => (
        <div>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push(`/dashboard/users/${rowData.pickedById}`)
            }
          >
            {rowData.pickedBy}
          </p>
        </div>
      ),
    },
    {
      title: "Provided by",
      field: "provider",
      render: (rowData) => (
        <div>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push(`/dashboard/users/${rowData.providerId}`)
            }
          >
            {rowData.provider}
          </p>
        </div>
      ),
    },
    { title: "Picked on", field: "pickedAt" },
  ];

  const handleAccept = () => dispatch(acceptRequest(id));
  const handleReject = () => dispatch(rejectRequest(id));
  const handleRequestCancel = () => dispatch(requestCancel(id));
  const handleOpenAssign = () => setOpenAssign(true);
  const handleCloseAssign = () => setOpenAssign(false);
  const handleAssignUser = () => {
    const assignData = {
      assComment,
      assignee: assignedUser,
      assignedDepartment,
    };
    dispatch(assignInventoryRequest(id, assignData));
    // console.log("user assigned", assignData);
    handleCloseAssign();
  };
  const handleAssignToIndividual = () =>
    setAssignToIndividual((cv) => {
      if (cv) {
        setAssignedUser();
      }
      return !cv;
    });

  const handleAddComment = () =>
    setAddComment((cv) => {
      if (cv) {
        setAssComment();
      }
      return !cv;
    });

  const handleSubmitPayment = () => {
    const data = {
      paidAmount: amount,
      proofUrl: image,
      transactionRefNumber: refNumber,
      merchant,
    };
    dispatch(initiatePayment(id, data));
    handleCloseSubmitPayment();
  };

  const unAssignedDevices = devices.devices.filter(
    (device) => !device.busId && !device.busCompanyId
  );
  const activeType =
    theSelectedType && theSelectedType.length >= 1 && theSelectedType[0].id;
  const matchingDevices =
    unAssignedDevices &&
    unAssignedDevices.filter(
      (item) => item.lpoNumber === lpoNumber && item.deviceType === activeType
    );

  // console.log(
  //   "The matching devices are:",
  //   matchingDevices,
  //   activeType,
  //   lpoNumber,
  //   unAssignedDevices
  // );

  const devicesOptions = matchingDevices
    ? matchingDevices.map((device) => {
        return {
          title: `${device.itsDeviceType.name} - ${device.deviceImei}`,
          id: device.id,
        };
      })
    : [];
  const procurementOptions = stock.procurements
    ? stock.procurements.map((proc) => {
        return {
          name: proc.lpoNumber,
          id: proc.id,
        };
      })
    : [];

  const filteredProcs = stock.procurements
    ? stock.procurements.filter((item) => item.lpoNumber === lpoNumber)
    : [];

  const itemOptions =
    filteredProcs && filteredProcs[0] && filteredProcs[0].items
      ? filteredProcs[0].items.map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        })
      : [];

  // console.log("the selected proc is:", itemOptions);

  const addMoreRenders = () => setRenderItems((cv) => cv + 1);
  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleRefNumberChange = (e) => setRefNumber(e.target.value);
  const handleOpenSubmitPayment = () => setOpenSubmitPayment(true);
  const handleCloseSubmitPayment = () => setOpenSubmitPayment(false);
  const handleClosePick = () => {
    setRenderItems(1);
    setOpenPick(false);
  };
  const handlePickQuantityChange = (e) => setPickQuantity(e.target.value);
  const handleOpenPick = () => setOpenPick(true);
  const handlePick = () => {
    const data = {
      //   qtyPickedUp: pickQuantity,
      qtyPickedUp:
        invRequest && invRequest.item && invRequest.item.category !== "device"
          ? pickQuantity
          : pickedDevices.length,
      pickedUpBy: picker,
    };

    dispatch(recordPickup(id, data));
    if (
      invRequest &&
      invRequest.item &&
      invRequest.item.category === "device" &&
      pickedDevices &&
      pickedDevices.length > 0
    ) {
      pickedDevices.map((item) => {
        // console.log("The device item we are updating is:", item, invRequest);
        dispatch(
          updateDevice(item.deviceId, {
            statusId: 2,
            busCompanyId:
              invRequest && invRequest.buscompany && invRequest.buscompany.id
                ? invRequest.buscompany.id
                : null,
            airtelSim:
              item.sim &&
              (item.sim.startsWith("072") || item.sim.startsWith("073"))
                ? item.sim
                : null,
            fourGSim:
              item.sim &&
              (item.sim.startsWith("077") || item.sim.startsWith("071"))
                ? item.sim
                : null,
            mtnSim:
              item.sim &&
              (item.sim.startsWith("078") || item.sim.startsWith("079"))
                ? item.sim
                : null,
            //change status to deployed
            //assign bus company to be the company in request
            //assign sim to be the sim card in the deployment
          })
        );
      });
    }

    handleClosePick();
  };

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };
  let paymentRows = [];
  let pickupRows = [];

  invRequest && invRequest.pickups
    ? (pickupRows = invRequest.pickups)
    : (pickupRows = []);

  pickupRows.map((row, index) => {
    row.newId = index + 1;
    row.pickedById =
      row.pickerUser && row.pickerUser.id ? row.pickerUser.id : null;
    row.providerId =
      row.providedUser && row.providedUser.id ? row.providedUser.id : null;
    row.pickedBy =
      row.pickerUser && row.pickerUser.name ? row.pickerUser.name : "";
    row.provider =
      row.providedUser && row.providedUser.name ? row.providedUser.name : "";
    row.item = invRequest.item ? invRequest.item?.name : "";
    row.pickedAt = `${row.createdAt.split("T")[0]} at ${
      row.createdAt.split("T")[1].split(".")[0]
    } `;
  });

  invRequest && invRequest.payments
    ? (paymentRows = invRequest.payments)
    : (paymentRows = []);
  paymentRows.map((row, index) => {
    row.newId = index + 1;
    row.approvalDate = row.approvedAt
      ? `${row.approvedAt.split("T")[0]} at ${
          row.approvedAt.split("T")[1].split(".")[0]
        } `
      : "";
    row.approver =
      row.approvedUser && row.approvedUser.name ? row.approvedUser.name : "";
    row.merchant =
      row.merchantUser && row.merchantUser.name ? row.merchantUser.name : "";
    row.approverId =
      row.approvedUser && row.approvedUser.id ? row.approvedUser.id : null;
    row.merchantId =
      row.merchantUser && row.merchantUser.id ? row.merchantUser.id : null;
  });
  // console.log("Request is:", req.inventoryRequest.activities);
  // let timelineActivities = [];
  request.inventoryRequest &&
    request.inventoryRequest.activities &&
    request.inventoryRequest.activities.length > activ.length &&
    invRequest.activities.map((activity) => {
      setActiv(
        (cv) => {
          return [
            ...cv,
            {
              title: invRequest.activities.indexOf(activity) + 1,
              cardSubtitle: moment(activity.createdAt).format(
                "MMM D, YYYY h:mm a"
              ),
              cardTitle: activity.user.name,
              cardDetailedText: activity.activity,
            },
          ];
        }
        // cv.push()
      );
    });

  console.log("the timeline activities are:", activ);

  const userOptions = users
    ? users.users.map((user) => {
        return {
          label: user.name,
          id: user.id,
        };
      })
    : [];
  const assigneeUserOptions = filteredUsers
    ? filteredUsers.map((user) => {
        return {
          label: user.name,
          id: user.id,
        };
      })
    : [];

  const departmentOptions = users
    ? users.departments.map((dep) => {
        return {
          label: dep.name,
          id: dep.id,
        };
      })
    : [];

  const totalPicked =
    invRequest && invRequest.pickups
      ? invRequest.pickups.reduce((acc, cv) => (acc += cv.qtyPickedUp), 0)
      : 0;

  return (
    <div>
      <Header name="Request Details" props={props} />

      <Snackbar
        open={request.snackBarMessage.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={closeSnackBarTimer}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
      >
        <MuiAlert
          severity={request.snackBarMessage.severity}
          variant="filled"
          elevation={6}
        >
          {request.snackBarMessage.message}
        </MuiAlert>
      </Snackbar>
      {invRequest &&
      invRequest.status &&
      (invRequest.status.title === "rejected" ||
        invRequest.status.title === "cancelled") ? (
        <div>
          {userProfile &&
            userProfile.role &&
            userProfile.role.Permissions &&
            userProfile.role.Permissions.manageGroups && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAccept}
              >
                Restart
              </Button>
            )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          {invRequest &&
            invRequest.status &&
            invRequest.status.title === "accepted" && (
              <>
                {invRequest &&
                  invRequest.item &&
                  invRequest.item.category !== "device" && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#19459D",
                      }}
                      // color="primary"
                      size="small"
                      onClick={handleOpenSubmitPayment}
                    >
                      Pay
                    </Button>
                  )}
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    backgroundColor: "#19459D",
                  }}
                  size="small"
                  onClick={handleOpenPick}
                >
                  Pick
                </Button>
              </>
            )}
          {userProfile &&
            userProfile.role &&
            userProfile.role.Permissions &&
            userProfile.role.Permissions.manageGroups &&
            invRequest &&
            invRequest.status &&
            invRequest.status.title !== "accepted" && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    backgroundColor: "green",
                  }}
                  size="small"
                  onClick={handleAccept}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{
                    backgroundColor: "red",
                  }}
                  onClick={handleReject}
                >
                  Reject
                </Button>
              </>
            )}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{
              backgroundColor: "#19459D",
            }}
            onClick={handleOpenAssign}
          >
            Assign
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{
              backgroundColor: "red",
            }}
            onClick={handleRequestCancel}
          >
            Request Cancel
          </Button>
        </div>
      )}
      <Grid
        container
        // justifyContent={"space-around"}
        // style={{ display: "flex", width: "100%", backgroundColor: "green" }}
      >
        <Grid item lg={9}>
          <DescribeItem
            title="Description"
            description={invRequest.description}
          />
          <DescribeItem
            title="Submission date"
            description={
              invRequest && invRequest.createdAt
                ? `${invRequest.createdAt.split("T")[0]} at ${
                    invRequest.createdAt.split("T")[1].split(".")[0]
                  }`
                : ""
            }
          />
          <DescribeItem
            title="Quantity requested"
            description={invRequest.qtyRequested}
          />
          <DescribeItem title="Total items picked" description={totalPicked} />
          <DescribeItem
            title="Items to be picked"
            description={
              invRequest ? invRequest.qtyRequested - totalPicked : ""
            }
          />
          {invRequest &&
            invRequest.item &&
            invRequest.item.category !== "device" && (
              <>
                <DescribeItem
                  title="Total amount"
                  description={
                    invRequest && invRequest.ledger
                      ? invRequest.ledger.totalAmount
                      : ""
                  }
                />
                <DescribeItem
                  title="Amount paid"
                  description={
                    invRequest && invRequest.ledger
                      ? invRequest.ledger.paidAmount
                      : ""
                  }
                />
                <DescribeItem
                  title="Amount due"
                  description={
                    invRequest && invRequest.ledger
                      ? invRequest.ledger.amountDue
                      : ""
                  }
                />
              </>
            )}
          {invRequest &&
            invRequest.item &&
            invRequest.item.category !== "device" && (
              <>
                <Typography
                  variant={"h5"}
                  style={{
                    color: colors.primary100,
                    fontSize: "1.25rem",
                    fontWeight: "bolder",
                    marginBottom: "-3rem",
                    marginTop: "2rem",
                  }}
                >
                  Payments
                </Typography>
                <DisplayAllPayments
                  rows={paymentRows}
                  columns={paymentColumns}
                  usemTable
                  showSearch={false}
                  showAddBtn={false}
                  showImport={false}
                />
              </>
            )}
          <Typography
            variant={"h5"}
            style={{
              color: colors.primary100,
              fontSize: "1.25rem",
              fontWeight: "bolder",
              marginBottom: "-3rem",
              marginTop: "2rem",
            }}
          >
            Pickups
          </Typography>
          <Pickups
            rows={pickupRows}
            columns={pickupColumns}
            usemTable
            cleaner={clearSnackBar}
            showSearch={false}
            showAddBtn={false}
            showImport={false}
          />
        </Grid>
        <Grid
          style={{
            padding: "0.5em 1em",
          }}
          lg={3}
        >
          <Typography className={classes.sectionTitle}>Timeline</Typography>
          <Divider className={classes.divider} />
          <Chrono
            items={activ}
            mode="VERTICAL"
            hideControls
            useReadMore={false}
            cardHeight={80}
          />
        </Grid>
      </Grid>
      <SubmitPayment
        isModelOpen={openSubmitPayment}
        closeAddModel={handleCloseSubmitPayment}
        handleCreate={handleSubmitPayment}
      >
        <DialogTitle id="form-dialog-title">Submit Payment</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Amount paid"
            onChange={handleAmountChange}
            fullWidth
          />
          <TextField
            sx={{ mb: 2 }}
            variant="standard"
            label="Transaction reference number"
            onChange={handleRefNumberChange}
            fullWidth
          />
          <Autocomplete
            id="combo-box-demo"
            options={userOptions}
            sx={{ width: "100%" }}
            value={userOptions.id}
            onChange={(event, newValue) => {
              setMerchant(newValue ? newValue.id : null);
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Merchant" />
            )}
          />
          <UploadImage
            setLoading={setLoading}
            imageUrl={image}
            imageAlt={imageAlt}
            setImageurl={setImage}
            setImageAlt={setImagealt}
            loading={loading}
            imageValid={imageValid}
            setImageValid={setImageValid}
          />
        </DialogContent>
      </SubmitPayment>
      <AssignModel
        isModelOpen={openAssign}
        closeAddModel={handleCloseAssign}
        handleCreate={handleAssignUser}
      >
        <DialogTitle id="form-dialog-title">Assign this request</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="combo-box-demo"
            options={departmentOptions}
            sx={{ width: "100%", mb: 2 }}
            value={departmentOptions.id}
            onChange={(event, newValue) => {
              setAssignedDepartment(newValue ? newValue.id : null);
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Department" />
            )}
          />
          {assignToIndividual && (
            <Autocomplete
              id="combo-box-demo"
              options={assigneeUserOptions}
              sx={{ width: "100%", mb: 2 }}
              value={assigneeUserOptions.id}
              onChange={(event, newValue) => {
                setAssignedUser(newValue ? newValue.id : null);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Assignee" />
              )}
              disabled={!assignedDepartment}
            />
          )}
          {addComment && (
            <TextField
              sx={{ mb: 2 }}
              variant="standard"
              label="Comment"
              onChange={(e) => setAssComment(e.target.value)}
              fullWidth
            />
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={addComment} onChange={handleAddComment} />
              }
              label="Add comment"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={assignToIndividual}
                  onChange={handleAssignToIndividual}
                />
              }
              label="Assign to individual"
            />
          </FormGroup>
          {/* <Checkbox {...label} defaultChecked size="small" /> */}
        </DialogContent>
      </AssignModel>
      <PickUp
        isModelOpen={openPick}
        closeAddModel={handleClosePick}
        handleCreate={handlePick}
      >
        <DialogTitle id="form-dialog-title">Record items picked</DialogTitle>
        <DialogContent>
          {invRequest &&
            invRequest.item &&
            invRequest.item.category === "device" && (
              <div>
                <Autocomplete
                  className={classes.forminput}
                  id="combo-box-demo"
                  fullWidth
                  options={procurementOptions}
                  sx={{ width: "100%", mb: 2 }}
                  value={procurementOptions.name}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setLpoNumber(newValue.name);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="LPO Number"
                      variant="standard"
                    />
                  )}
                />
                {/* <Autocomplete
                  className={classes.forminput}
                  id="combo-box-demo"
                  fullWidth
                  options={itemOptions}
                  sx={{ width: "100%", mb: 2 }}
                  value={itemOptions.name}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue.name);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Item" variant="standard" />
                  )}
                /> */}
                {/* 1. choose lpo number++++done */}
                {/* 2. choose item  */}
                {/* 3.filter devices to be selected later basing on that lpo number
                and items selected
                
                BAse on item name for the item selected,
                and lpo number for devices to filter devices*/}
                {[...Array(renderItems)].map((item, index) => (
                  <div key={index}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={devicesOptions}
                      sx={{ width: "100%", mb: 2 }}
                      value={devicesOptions.id}
                      onChange={(event, newValue) => {
                        setCurrentDeviceId(newValue.id);
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose device"
                        />
                      )}
                      disabled={index !== renderItems - 1}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      variant="standard"
                      label="SIM Card"
                      onChange={(e) => setCurrentSim(e.target.value)}
                      fullWidth
                      disabled={index !== renderItems - 1}
                    />
                  </div>
                ))}
                {currentDeviceId ? (
                  <Button
                    size="small"
                    variant="contained"
                    disabled={
                      invRequest && invRequest.qtyRequested < renderItems
                    }
                    sx={{ height: "20px" }}
                    onClick={saveCurrentEntries}
                  >
                    <Check />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    disabled={
                      (invRequest && invRequest.qtyRequested <= renderItems) ||
                      matchingDevices.length <= renderItems
                    }
                    sx={{ height: "20px" }}
                    onClick={addMoreRenders}
                  >
                    <Add />
                  </Button>
                )}
              </div>
            )}
          {
            //display quantity only if we have non-device related requested item
          }
          {invRequest &&
            invRequest.item &&
            invRequest.item.category !== "device" && (
              <TextField
                sx={{ mb: 2 }}
                variant="standard"
                label="Quantity"
                onChange={handlePickQuantityChange}
                fullWidth
              />
            )}
          <Autocomplete
            id="combo-box-demo"
            options={userOptions}
            sx={{ width: "100%", mb: 2 }}
            value={userOptions.id}
            onChange={(event, newValue) => {
              setPicker(newValue ? newValue.id : null);
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Picked by" />
            )}
          />
        </DialogContent>
      </PickUp>
    </div>
  );
};

export default RequestDetails;
