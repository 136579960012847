import { types } from "../../actions/types";

const initialState = {
 url:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REDIRECT_URL:
      return {
        ...state,
       url:action.url
      }
    default:
      return state;
  }
};