import axios from 'axios';
import { types } from "../types";

//const token = localStorage.getItem('x-auth-token');
const token = document.cookie.split("=")[1];
export const getStatuses = () => dispatch => {
    dispatch({
        type: types.STATUS_PENDING
    })
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/incident-status`, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(res => {
            const sortedStatus = res.data.data.sort((a, b) => b.id - a.id)
            dispatch({
                type: types.GET_STATUS_SUCCESS,
                statuses: sortedStatus,
                message: res.data.message,
            })
        })
        .catch(err => {
            dispatch({
                type: types.GET_STATUS_ERROR,
                error: err,
                message: err.response? err.response.data.error : err.message
            })
        })
}

export const clearSnackBar = () => dispatch => {
    return dispatch({
      type: types.CLEAR_SNACKBAR
    })
  }