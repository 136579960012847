import React, { useEffect, useState } from "react";
import AdditionalInfo from "../templates/AdditionalInfo";
import { clearSnackBar } from "../../redux/actions/devices/snack";
import { useDispatch, useSelector } from "react-redux";
import { getOneDevice } from "../../redux/actions/devices/getDevices";
import { useStyles } from "./useStyles";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Slide,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import { getOneType } from "../../redux/actions/devices/deviceTypes";
import { getUsersAction } from "../../redux/actions/usersAction";
import DescribeItem from "../../components/templates/DescribeItem";

const SingleDevice = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const device = useSelector((state) => state.devices);
  const [project, setProject] = useState({});
  const type = device.type;
  const [creator, setCreator] = useState("");
  const users = useSelector((state) => state.users.users);

  useEffect(() => {
    device.device.project
      ? setProject({
          name: device.device.project.name,
          description: device.device.project.description,
        })
      : setProject({});
  }, [device.device.project]);
  useEffect(() => {
    dispatch(getOneDevice(id));
  }, []);
  useEffect(() => {
    dispatch(getOneType(device.device.deviceType));
  }, [device.device.deviceType]);
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  useEffect(() => {
    let creator;
    let updator;
    if (device.device.updatedBy === "") {
      updator = "Not updated yet!";
    }
    dispatch(getUsersAction());
    users.forEach((user) => {
      if (user.id === device.device.createdBy) {
        creator = user.name;
      }
      if (user.id === device.device.updatedBy) {
        updator = user.name;
      }
    });
    device.device && setCreator({ creator: creator, updator: updator });
  }, [device]);

  const closeSnackBarTimer = () => {
    dispatch(clearSnackBar());
  };

  return (
    <div className={classes.contain}>
      <Grid container spacing={0} justify="space-between">
        <Grid item>
          <Typography variant="h5" className={classes.header}>
            Device Details
          </Typography>
        </Grid>
      </Grid>
      <hr
        style={{
          width: "100%",
          color: `#19459D`,
          height: "2px",
          backgroundColor: "#19459D",
          border: "1px solid #19459D",
        }}
      ></hr>
      <div>
        {device.pending ? (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <Snackbar
              open={device.snackBarMessage.open}
              onClose={closeSnackBarTimer}
              autoHideDuration={4000}
              TransitionComponent={TransitionUp}
            >
              <MuiAlert
                severity={device.snackBarMessage.severity}
                variant="filled"
                elevation={6}
              >
                {device.snackBarMessage.message}
              </MuiAlert>
            </Snackbar>
            <Grid container direction="column" alignItems="left">
              <Grid direction="column" item justify="left" alignItems="left">
                {/* <DescribeItem title="Name" description={device.device.name} /> */}
                <DescribeItem
                  title="Description"
                  description={device.device.description}
                />
                <DescribeItem
                  title="LPO Number"
                  description={device.device.lpoNumber}
                />
                <DescribeItem
                  title="Device IMEI"
                  description={device.device.deviceImei}
                />
                <DescribeItem
                  title="Device Type"
                  description={type && type.name}
                />
                <DescribeItem
                  title="Type description"
                  description={type.description}
                />
                <DescribeItem title="Project Name" description={project.name} />
                <DescribeItem
                  title="Price"
                  description={device.device.price || ""}
                />
              </Grid>
            </Grid>
            <Grid direction="column">
              <Grid>
                <AdditionalInfo
                  creator={creator.creator}
                  updator={creator.updator}
                  creationDate={device.device.createdAt}
                  updateDate={device.device.updatedAt}
                />
              </Grid>
            </Grid>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => props.history.push("/dashboard/devices")}
              style={{ marginTop: "20px" }}
            >
              {" "}
              View Devices
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleDevice;
