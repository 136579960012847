import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Grid
} from '@material-ui/core';
import { resolveIncident } from '../../../redux/actions/incidents/incidentActions'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        height: 360,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    media: {
        height: 140
    },
    checkbox: {
        display: 'block',
        background: "white 1px",
        position: 'absolute',
        color: 'secondary',
        right: 0,
        fontSize: '25px'
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    reviews: {
        fontSize: '14px',
    },
    cardContent: {
        overflow: 'hidden'
    },
    titleText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        }
    },
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    label: {
        color: `black`,
        fontWeight: "bold",
        display: "inline",
        marginRight: theme.spacing(0.5)
    },
}));

function InventoryRequestCard({ props, request }) {
    const classes = useStyles();
    let [index, setIndex] = useState(0);
    const dispatch = useDispatch();



    const handleViewMore = (e) => {
        props.history.push(`/stock/requests/${e.currentTarget.id}`);
    }
    const handleResolve = (e) => {
        dispatch(resolveIncident(e.currentTarget.id))
    }
    return (
        <Card className={classes.root} >

            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={request.item ? request.item.image : "http://www.4me.com/wp-content/uploads/2018/01/4me-icon-alert.png"}
                    title={request.name}
                />
                <CardContent className={classes.cardContent}  >
                    <Grid direction="column" container justify="left" alignItems="left" style={{ display: 'inline', marginTop: '15px' }} >
                        <div style={{ display: 'block' }} >
                            <Typography variant='subtitle1' className={classes.label} >Quantity requested:</Typography>
                            <Typography variant='subtitle2' style={{ display: "inline" }}  >{request.qtyRequested}</Typography>
                        </div>
                        <div style={{ display: 'block' }} >
                            <Typography variant='subtitle1' className={classes.label} >Requested on:</Typography>
                            <Typography variant='subtitle2' style={{ display: "inline" }}  >{request.createdAt.split("T")[0]}</Typography>
                        </div>
                        <div style={{ display: 'block', backgroundColor: `${request.status.color}` }}  >
                            <Typography variant='subtitle1' className={classes.label}>Status:</Typography>
                            <Typography variant='subtitle2' style={{ display: "inline" }} >{request.status.title}</Typography>
                        </div>
                        <div style={{ display: 'block' }} >
                            <Typography variant='subtitle1' className={classes.label}>Priority:</Typography>
                            <Typography variant='subtitle2' style={{ display: "inline" }}  >{request.priority.title}</Typography>
                        </div>
                        {request.requestAmount && (
                            <div style={{ display: 'block' }} >
                                <Typography variant='subtitle1' className={classes.label}>Amount due:</Typography>
                                <Typography variant='subtitle2' style={{ display: "inline" }}  >{request.ledger.amountDue}</Typography>
                            </div>)
                        }

                    </Grid>
                </CardContent>
            </CardActionArea>
            {
                (request.status.title !== 'resolved' && request.status.title !== 'rejected') && (
                    <CardActions className={classes.cardActions}>
                        <Button size="small" color="secondary" variant="contained" style={{ width: "100%" }} id={request.id} onClick={handleResolve}> Request cancel </Button>
                    </CardActions>
                )
            }

            <CardActions className={classes.cardActions}>
                <Button size="small" color="primary" variant="contained" style={{ width: "100%" }} id={request.id} onClick={handleViewMore}> view more </Button>
            </CardActions>
        </Card>
    );
}

export default InventoryRequestCard;
