import color from "../colors/index";
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(7),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  contain: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px",
  },
  title: {
    fontWeight: "bold",
    alignContent: "center"
  },
  container: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px",
    border: `solid 1px ${color.border}`,
  },

  root: {
    '& .super-app-theme--header': {
      fontWeight: "bolder",
    },
  },
  down: {
    minWidth: 100,
    height: 12
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  input: {
    paddingBottom: theme.spacing(2),
    display: 'none',
  },
  whiteColor: {
    color: "white"
  },
  singleUserContent: {
    width: "100%",
  },
  singleUserContents: {
    display: "grid",
    gridTemplateColumns: "100%",
    gridGap: "20px"
  },

  SingleUserTopBar: {
    display: "grid",
    gridTemplateColumns: "10% 15% 10%",
    justifyContent: "start",
    alignItems: "center",
    gridGap: "20px"
  },
  SingleUserHeader: {
    textAlign: "start",
    marginTop: theme.spacing(7),
    fontWeight: "bolder",
    color: `${color.primary100}`,
  },
  line: {
    border: `1px solid ${color.primary100}`,
  },
  SingleUserContainer: {
    position: "relative",
    width: "100%",
    marginBottom: "2px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"

  },
  actionBtns: {
    display: "grid", gridTemplateColumns: "15% 10%",
    justifyContent: "end",
    alignItems: "center",
    gridGap: "20px",
    marginTop: "20px"
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  whiteColor: {
    color: "white"
  },
  status: {
    width: "100%",
    marginTop: "20px"
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    color: `${color.primary100}`,
    marginBottom: theme.spacing(0)
  },
  uploadBtn: {
    backgroundColor: `${color.primary100}`,
    color: 'white',
    padding: "9px",
    margin: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(5),
  },
  profileUpload: {
    backgroundColor: `${color.primary100}`,
    color: 'white',
    padding: "9px",
    // margin: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    // marginLeft: theme.spacing(0),
  },
  cancelBtn: {
    backgroundColor: `${color.red}`,
    color: 'white',
    padding: "9px",
    margin: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  path: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "flex-end"
  },
  header2: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.path}`
  },
  header3: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    color: `${color.primary100}`
  },
  input2: {
    paddingBottom: theme.spacing(2)
  },
  buttons: {
    color: 'white',
    padding: "9px",
    margin: theme.spacing(0),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
  sizeAvatar: {
    height: theme.spacing(30),
    width: theme.spacing(30),
    fontSize: "6em"
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
    justifyContent: "center",
    alignItems: "center",

  },
  top: {
    marginBottom: theme.spacing(2),
  },
  middle: {
    marginBottom: theme.spacing(3),
  },
  importbtn: {

    display: "flex",
    textTransform: "uppercase",
    color: `${color.primary100}`,
    marginBottom: theme.spacing(2),
    '&:hover': {
      background: "#edf4fc",
    },
    marginTop: 0
  },
  select: {
    paddingRight: "25px",
    paddingLeft: "23px",
    marginTop: "4px",
    marginBottom: "-3px",
  },
  whiteColor: {
    color: "white"
  },
  btn3: {
    height: theme.spacing(3.4),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  largeAvatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    fontSize: '6em'
  },
  progress: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    position: 'absolute'
  },
})
)
