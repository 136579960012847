import axios from 'axios';
import Cookie from 'universal-cookie'
import { types } from "../types";
import API from "../../../utils/axios/axios";
import { authRedirect } from "../../../utils/redirects";

const cookies = new Cookie()

const token = cookies.get('x-auth-token');

export const singleRole = (id) => dispatch => {
  dispatch({
    type: types.GET_PERMISSIONS_PENDING
  })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/permissions/${id}`,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.GET_ROLE_PERMISSIONS,
        role: res.data.role,
        message: res.data.message,
      })
    })
    .catch((err) => {
      dispatch({
        type: types.GET_PERMISSIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}

export const changePermission = (property, value) => dispatch => {
  return dispatch({
    type: types.CHANGE_PERMISSIONS,
    property: property,
    value: value
  })
}

export const changeUnallowedPermission = (property, value) => dispatch => {
  return dispatch({
    type: types.CHANGE_UNALLOWED_PERMISSIONS,
    property: property,
    value: value
  })
}

export const selectAllUnAllowed = (value) => dispatch => {
  return dispatch({
    type: types.SELECT_ALL_UNALLOWED_PERMISSIONS,
    value: value
  })
}

export const selectAllAllowed = (value) => dispatch => {
  return dispatch({
    type: types.SELECT_ALL_ALLOWED_PERMISSIONS,
    value: value
  })
}

export const clearSnackBar = () => dispatch => {
  return dispatch({
    type: types.CLEAR_SNACKBAR
  })
}
export const updatePermission = (id, payload) => dispatch => {
  dispatch({
    type: types.GET_PERMISSIONS_PENDING
  })
  return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/permissions/${id}`,
    payload,
    {
      headers: {
        "x-auth-token": token
      }
    })
    .then(res => {
      dispatch({
        type: types.UPDATE_PERMISSIONS,
        role: res.data.newPermission,
        message: res.data.message
      })
    })
    .catch((err) => {
      // console.log("The error while assigning permissions is:", err)
      dispatch({
        type: types.GET_PERMISSIONS_ERROR,
        error: err,
        message: err.response ? err.response.data.error : err.message
      })
    })
}