import { makeStyles, useTheme } from '@material-ui/core/styles';
import color from '../colors/index'

export const useStyles = makeStyles((theme) => ({
    element: {
        // padding: theme.spacing(6, 1),
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    header: {
        fontWeight: "bold",
        color: `${color.primary100}`,
        marginTop:theme.spacing(7),
        fontWeight:"bold",
        color:`${color.primary100}`
    },
    progress: {
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 3, 1),
    },
    contain:{
        position: "relative",
        width: "100%",
        marginBottom:"2px",
        borderRadius: "3px"
    },
    title:{
        fontWeight:"bold",
        alignContent:"center"
      },
      container:{
        position: "relative",
        width: "100%",
        border:`solid 1px ${color.border}`,
        marginBottom:"2px",
        borderRadius: "3px"
      },
      btnstly:{
        height:theme.spacing(4),
        backgroundColor:`${color.primary100}`,
        marginBottom:theme.spacing(2),
    },
    root: {
        '& .super-app-theme--header': {
          fontWeight:"bolder",
        },
      },
      spinner:{
        display: "flex",
        justifyContent: "center",
        marginTop:theme.spacing(10)
      },
      label:{
        color:`${color.primary100}`,
        fontWeight:"bold",
        display:"inline",
        marginRight:theme.spacing(0.5)
      },
      table:{
        marginTop:theme.spacing(4),
    },
    select:{
      paddingRight: "25px",
      paddingLeft: "23px",
      marginTop: "4px",
      marginBottom: "-3px",
    },

    down:{
        minWidth: 100,
        height:12
      },
      search:{
        float:"right",
        height:"10px",
        margin:theme.spacing(1),
        marginRight:"0px",
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(0)
      },
      container:{
        position: "relative",
        width: "100%",
        border:`solid 1px ${color.border}`,
        marginBottom:"2px",
        borderRadius: "3px"
      },
      typeContents: {
        display: "grid", 
        gridTemplateColumns: "30% 69%",
        gridGap: "1%"
      },
      tableHeading: {
        backgroundColor: color.primary100,
        color: '#fff',
        fontWeight: 700,
        fontSize: '1em'
    }
}))
