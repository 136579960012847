import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    routes: null,
    route: null,
    pending: false,
    delete: false,
    upload: false,
    snackBarMessage: {
        open: false,
        severity: '',
        message: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ROUTE_PENDING:
            return {
                ...state,
                pending: true
            }
        case types.GET_ROUTES_SUCCESS:
            return {
                ...state,
                routes: action.sites,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };
        case types.GET_ROUTE_SUCCESS:
            return {
                ...state,
                route: action.route,
                message: action.message,
                pending: false,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            };

        case types.CREATE_ROUTE:
            return {
                ...state,
                route: action.route,
                message: action.message,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.UPLOAD_ROUTES:
            return {
                ...state,
                message: action.message,
                pending: false,
                upload: state.upload ? false : true,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.DELETE_ROUTE:
            return {
                ...state,
                message: action.message,
                pending: false,
                delete: state.delete ? false : true,
                // snackBarMessage: {
                //     open: true,
                //     severity: 'success',
                //     message: action.message
                // }
            }
        case types.UPDATE_ROUTE:
            return {
                ...state,
                message: action.message,
                route: action.site,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'success',
                    message: action.message
                }
            }
        case types.ROUTE_ERROR:
            return {
                ...state,
                message: action.error,
                error: action.error,
                pending: false,
                snackBarMessage: {
                    open: true,
                    severity: 'error',
                    message: action.message
                }
            };
        case types.CLEAR_SNACKBAR:
            return {
                ...state,
                snackBarMessage: {
                    open: false,
                    severity: '',
                    message: null
                }
            }
        default:
            return state;
    }
};
