const colors = {
    primary100: '#19459D',
    path: '#B8C3D8',
    border: '#B8C3D8',
    red: '#EB5757',
    path: '#B8C3D8',
    permission: '#F0F3F4',
    blue12: "587ECA",
    secondary: "#19459D",
    black: "#000",
    success: "#388e3c",
    yellow: "#f5be33",
    mix: "#003a51",
}

export default colors;