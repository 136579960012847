import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
} from '@material-ui/core';

const AddModel = props => {
    const {
        children,
        isModelOpen,
        closeAddModel,
        handleCreate
    } = props

    return (
        <div>
            <Dialog
                open={isModelOpen}
                onClose={closeAddModel}
                maxWidth='sm'
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                {children}
                <DialogActions>
                    <Button
                        onClick={handleCreate}
                        color="primary"
                        variant='contained'>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddModel
