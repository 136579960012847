import { DEVICES_ERROR, DEVICES_PENDING, UPDATE_DEVICE } from "./type";
import http from "../../../utils/axios/axios";

const auth = localStorage.getItem("x-auth-token");
export const updateDevice = (id, updated) => (dispatch) => {
  // console.log("The id and updated data on a device are:", id, updated)
  dispatch({ type: DEVICES_PENDING });
  return http
    .patch(`/api/devices/${id}`, updated, {
      headers: {
        "x-auth-token": auth,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_DEVICE,
        payload: res.data,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: DEVICES_ERROR,
        payload: err.response ? err.response.data.error : "Error occured",
      });
    });
};
