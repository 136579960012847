import React from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from '../../../templates/TopPartOfPage'
import { useStyles } from '../submitIncident';
import { formatToTextfieldDate } from '../../../../utils/formatDates';
import { createSuperagentRequest } from '../../../../redux/actions/superagent_requests/superagentRequests';
import IncNavLinks from '../IncNavLinks';

const SuperagentRefund = props => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { tag } = props

    const requestState = useSelector(state => state.superRequests)


    const initialValues = {
        agentnames: null,
        agentusername: null,
        agentId: null,
        date: formatToTextfieldDate(new Date(Date.now())),
        amount: null,
        version: null,
        description: null
    }
    const validationSchema = yup.object({
        agentnames: tag === 'loan' ? yup.string().nullable() : yup.string("Agent name must be a string")
            .required('Agentname is required'),
        agentusername: tag === 'loan' ? yup.string().nullable() : yup.string()
            .required('Agent username is required'),
        agentId: yup.string().nullable(),
        date: yup.date()
            .required('Date is required'),
        amount: yup.string()
            .required('Amount is required'),
        version: yup.string()
            .required('Version is required'),
        description: yup.string()
            .required('Reason is required')
    })
    return (
        <div>
            <PageHeader
                props={props}
                name={tag === 'loan' ? 'Request a Loan' : 'Submit Refund Request'}
            />
            <React.Fragment>
                {requestState.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    : <>
                        <IncNavLinks
                            hideRequestLoan={tag === "loan"}
                            hideRequestRefund={tag === "refund"}
                            hideSubmitIncident={false}
                        />
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnBlur={false}
                            validateOnChange
                            onSubmit={values => {
                                // console.log("The values we have are:", values)
                                dispatch(createSuperagentRequest({ ...values, tag }, props))
                            }}
                        >
                            {({ values, errors, touched, setFieldValue }) => {
                                // console.log("The values on the other hand are:", values)
                                // console.log("We have the following errors;", errors)
                                return (
                                    <Form>
                                        {tag !== "loan" && <>
                                            <Field
                                                autoFocus
                                                variant="outlined"
                                                size='small'
                                                margin="dense"
                                                id="agentnames"
                                                label="Agent names"
                                                type="text"
                                                fullWidth
                                                name='agentnames'
                                                className={classes.bottomMargin}
                                                as={TextField}
                                                error={touched.agentnames && errors.agentnames}
                                                helperText={touched.agentnames && errors.agentnames}
                                            />
                                            <Field
                                                variant="outlined"
                                                size='small'
                                                margin="dense"
                                                id="name"
                                                label="Agent username"
                                                type="text"
                                                name='agentusername'
                                                as={TextField}
                                                className={classes.bottomMargin}
                                                error={touched.agentusername && errors.agentusername}
                                                helperText={touched.agentusername && errors.agentusername} fullWidth
                                            />
                                            <Field
                                                variant="outlined"
                                                size='small'
                                                margin="dense"
                                                id="name"
                                                name='agentId'
                                                label="Agent Id"
                                                type="text"
                                                as={TextField}
                                                className={classes.bottomMargin}
                                                error={touched.agentId && errors.agentId}
                                                helperText={touched.agentId && errors.agentId} fullWidth
                                            />
                                        </>
                                        }
                                        <Field
                                            variant="outlined"
                                            size='small'
                                            margin="dense"
                                            id="name"
                                            label="Amount"
                                            type="text"
                                            name='amount'
                                            as={TextField}
                                            className={classes.bottomMargin}
                                            error={touched.amount && errors.amount}
                                            helperText={touched.amount && errors.amount}
                                            fullWidth
                                        />
                                        <Field
                                            variant="outlined"
                                            size='small'
                                            margin="dense"
                                            id="name"
                                            label="Version"
                                            name="version"
                                            type="text"
                                            as={TextField}
                                            className={classes.bottomMargin}
                                            error={touched.version && errors.version}
                                            helperText={touched.version && errors.version}
                                            fullWidth
                                        />
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            size='small'
                                            name='date'
                                            id="datetime-local"
                                            fullWidth
                                            label="Date"
                                            type="datetime-local"
                                            className={classes.bottomMargin}
                                            error={touched.date && errors.date}
                                            helperText={touched.date && errors.date}
                                        />
                                        <Field
                                            variant="outlined"
                                            size='small'
                                            margin="dense"
                                            id="name"
                                            label="Reason"
                                            type="text"
                                            fullWidth
                                            name='description'
                                            className={classes.bottomMargin}
                                            as={TextField}
                                            error={touched.description && errors.description}
                                            helperText={touched.description && errors.description}
                                        />
                                        <Button
                                            type={"submit"}
                                            variant='contained'
                                            color='primary'
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </>}
            </React.Fragment>
        </div >
    )
}

export default SuperagentRefund