import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    Typography,
    makeStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    InputLabel,
    MenuItem,
    CircularProgress,
    Slide,
    Snackbar,
} from '@material-ui/core';
import {
    Visibility,
    Edit,
    Delete
} from '@material-ui/icons/';
import {
    Districts,
    Provinces,
    Sectors,
    Cells
} from 'rwanda'
// import Select from 'react-select'
// import { Select } from '@dccs/react-searchable-select-mui'
import Autocomplete from '@mui/material/Autocomplete';

import {
    getSites,
    clearSnackBar,
    deleteSite,
    createSite,
    updateSite,
    uploadSites
} from '../../redux/actions/sites/sites';
import {
    getProjects
} from "../../redux/actions/projects/projectsAction"
import {
    getLocations,
} from "../../redux/actions/locations/locationActions"
import color from "../colors";
import DisplayAll from '../templates/DisplayAll'
import DeleteModel from '../templates/DeleteModel';
import EditModel from '../templates/EditModel';
import { deleteBus } from '../../redux/actions/buses/buses';
// import provinces from 'rwanda/data/provinces';

const useStyles = makeStyles((theme) => ({
    element: {
        flexGrow: 1,
        ...theme.mixins.toolbar,
    },
    progress: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        position: 'absolute'
    },
    header: {
        marginTop: theme.spacing(7),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    header2: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.path}`
    },
    header3: {
        marginTop: theme.spacing(0),
        fontWeight: "bold",
        color: `${color.primary100}`
    },
    path: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end"
    },
    selectlabel: {
        marginTop: theme.spacing(2)
    }
}))


const Sites = (props) => {
    let history = useHistory()
    const sitesState = useSelector(state => state.sites)
    const locations = useSelector(state => state.locations.locations)
    const projects = useSelector(state => state.projects.projects)

    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [siteToDelete, setSiteToDelete] = useState(null)
    const [siteName, setSiteName] = useState(null)
    const [siteNameToEdit, setSiteNameToEdit] = useState(null)
    const [siteDistrict, setSiteDistrict] = useState(null)
    const [siteSector, setSiteSector] = useState(null)
    const [siteCell, setSiteCell] = useState(null)
    const [siteProvince, setSiteProvince] = useState(null)

    const [name, setName] = useState(null)
    const [siteEditingData, setSiteEditingData] = useState([])
    const [selectedProvince, setSelectedProvince] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [selectedSector, setSelectedSector] = useState(null)
    const [selectedCell, setSelectedCell] = useState(null)
    const [editProject, setEditProject] = useState(null)

    const [selectedProjects, setSelectedProjects] = useState([])

    const classes = useStyles();
    const dispatch = useDispatch()

    const provices = Provinces()
    const districts = Districts(selectedProvince)
    const sectors = Sectors(selectedProvince, selectedDistrict)
    const cells = Cells(selectedProvince, selectedDistrict, selectedSector)

    const editingDistricts = Districts(siteProvince)
    const editingSectors = Sectors(siteProvince, siteDistrict)
    const editingCells = Cells(siteProvince, siteDistrict, siteSector)

    useEffect(() => {
        dispatch(getSites())
        dispatch(getProjects())
        dispatch(getLocations())
    }, [sitesState.delete, sitesState.site, sitesState.upload])

    const handleOpenDeleteModel = (e) => {
        setOpenDelete(true)
        const element = e.currentTarget.id.split(",")
        setSiteToDelete(element[0])
    }
    const handleCloseDeleteModel = () => setOpenDelete(false)
    const handleDelete = (e) => {
        dispatch(deleteSite(siteToDelete))
        setOpenDelete(false)
    }

    const handleOpenEditModel = (currentData) => {
        setSiteEditingData(currentData)
        setOpenEdit(true)
    }
    // console.log("The site we are about to edit is here:", siteEditingData)
    const handleCloseEditModel = () => setOpenEdit(false)

    const handleName = (e) => setSiteName(e.target.value)
    const handleEditingName = (e) => setSiteNameToEdit(e.target.value)
    const handleEditingDistrict = (e) => setSiteDistrict(e.target.value)
    const handleEditingSector = e => setSiteSector(e.target.value)
    const handleEditingCell = e => setSiteCell(e.target.value)

    const handleEditSite = () => {
        dispatch(updateSite(siteEditingData.id, {
            name: siteNameToEdit,
            province: siteProvince,
            district: siteDistrict,
            sector: siteSector,
            cell: siteCell
        }))
        handleCloseEditModel()
    }
    const handleCreateSite = () => {
        let projectIds = []
        selectedProjects.map(oneProject => projectIds.push(oneProject.id))
        dispatch(createSite({
            name: siteName,
            projectIds,
            province: selectedProvince,
            district: selectedDistrict,
            sector: selectedSector,
            cell: selectedCell
        }))
        setSiteName(null)
        setSelectedProvince(null)
        setSelectedDistrict(null)
        setSelectedSector(null)
        setSelectedCell(null)
        setSelectedProjects([])
    }

    const projectOptions = projects ? projects.map(project => {
        return {
            title: project.name,
            id: project.id
        }
    }) : []


    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'name',
            title: 'Name',
        },
        {
            field: 'siteProject',
            title: 'Project',
        },
        {
            field: 'district',
            title: 'District',

        },
        {
            field: 'sector',
            title: 'Sector',
        },
        {
            field: 'action',
            title: 'Action',
            render: rowData => {
                return (
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        wrap='nowrap'
                    >
                        <Grid
                            item
                        >
                            <Button
                                onClick={() => history.push(`/dashboard/sites/${rowData.id}`)}
                                size='small'
                            >
                                <Visibility
                                    fontSize='small'
                                    color="primary"
                                />
                            </Button>
                        </Grid>
                        <Grid
                            item
                        >
                            <Button
                                size='small'
                                onClick={() => handleOpenEditModel(rowData)}
                                id={`${rowData.id}:${rowData.name}:${rowData.siteProject}:${rowData.siteLocation}`}
                            >
                                <Edit
                                    fontSize='small'
                                    sx={{ fontSize: "10px" }}
                                />
                            </Button>
                        </Grid>
                        <Grid
                            item
                        >
                            <Button
                                onClick={handleOpenDeleteModel}
                                id={`${rowData.id},${rowData.name}`}
                                size='small'
                            >
                                <Delete
                                    color="secondary"
                                    fontSize='small'
                                />
                            </Button>


                        </Grid>
                    </Grid>
                )
            }
        },
    ];
    let rows
    const sites = sitesState.sites
    if (sites) {
        sites.map(site => site.newId = (sites.indexOf(site)) + 1)
    }
    sites ? rows = sites : rows = []

    rows.forEach((row, index) => {
        row.newId = index + 1
        let names = []
        row.projects.map(project => {
            names.push(project.name)
        })
        row.siteProject = names.join(" | ")
    })
    const multipleDeleteHandler = (ids) => {
        ids.selectionModel.map(id => dispatch(deleteSite(id)))
    }
    return (
        <div className={classes.element}>

            {sitesState.pending ?
                <div className={classes.progress}>
                    <CircularProgress />
                </div> :
                <DisplayAll
                    cTitle={'Available Sites'}
                    addBtnTitle={'Add New Site'}
                    rows={rows}
                    columns={columns}
                    cleaner={clearSnackBar}
                    reducer={sitesState}
                    pending={false}
                    uploader={uploadSites}
                    handleCreate={handleCreateSite}
                    multipleDeleteHandler={multipleDeleteHandler}
                    usemTable
                >
                    <DialogTitle id="form-dialog-title">Create New Site</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Site Name"
                            type="text"
                            onChange={handleName}
                            fullWidth
                        />
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={projectOptions}
                            disableCloseOnSelect
                            value={selectedProjects}
                            onChange={(event, newValue) => {
                                setSelectedProjects(newValue);
                            }}
                            getOptionLabel={(option) => option.title}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        {option.title}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Projects" placeholder="Choose projects" />
                            )}
                        />

                        <Autocomplete
                            id="combo-box-demo"
                            options={provices}
                            value={selectedProvince}
                            onChange={(event, newValue) => {
                                setSelectedProvince(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Province"
                            />}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={districts}
                            value={selectedDistrict}
                            disabled={!selectedProvince}
                            onChange={(event, newValue) => {
                                setSelectedDistrict(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="District"
                            />}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={sectors}
                            disabled={!selectedDistrict}
                            value={selectedSector}
                            onChange={(event, newValue) => {
                                setSelectedSector(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Sector"
                            />}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={cells}
                            value={selectedCell}
                            disabled={!selectedSector}
                            onChange={(event, newValue) => {
                                setSelectedCell(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Cell"
                            />}
                        />

                    </DialogContent>
                </DisplayAll>
            }
            <DeleteModel
                title={'Delete Site'}
                description={`Are you sure you want to delete this site?`}
                isDeleteModelOpen={openDelete}
                closeDialog={handleCloseDeleteModel}
                handleDelete={handleDelete}
            />
            <EditModel
                title={'Edit Site'}
                closeEditDialog={handleCloseEditModel}
                isEditModelOpen={openEdit}
                classes={classes}
                handleEdit={handleEditSite}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        defaultValue={siteEditingData.name}
                        label="Site Name"
                        type="text"
                        onChange={handleEditingName}
                        fullWidth
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={provices}
                        value={siteEditingData.province}
                        onChange={(event, newValue) => {
                            setSiteProvince(newValue)
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Province"
                        />}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={editingDistricts ? editingDistricts : []}
                        disabled={!siteProvince}
                        value={siteEditingData.district}
                        onChange={(event, newValue) => {
                            setSiteDistrict(newValue)
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="District"
                        />}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={editingSectors ? editingSectors : []}
                        disabled={!siteDistrict}
                        value={siteEditingData.sector}
                        onChange={(event, newValue) => {
                            setSiteSector(newValue)
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Sector"
                        />}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={editingCells ? editingCells : []}
                        value={siteEditingData.cell}
                        disabled={!siteSector}
                        onChange={(event, newValue) => {
                            setSiteCell(newValue)
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Cell"
                        />}
                    />

                    <Autocomplete
                        multiple
                        disabled
                        id="checkboxes-tags-demo"
                        options={projectOptions}
                        disableCloseOnSelect
                        value={siteEditingData.projects}
                        onChange={(event, newValue) => {
                            setSelectedProjects(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Projects" placeholder="Choose projects" />
                        )}
                    />
                </DialogContent>
            </EditModel>

        </div>
    )
}

export default Sites
