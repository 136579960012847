import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Typography,
    TableCell,
    TableFooter,
    TableRow,
    TextField,
    Button
} from "@material-ui/core";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MaterialTable, { MTableToolbar, MTableBody, MTableAction } from "material-table";
import Autocomplete from '@mui/material/Autocomplete';
import DateFnsUtils from '@date-io/date-fns'
import { getBusMakes } from '../../redux/actions/buses/busMakes'
import {
    Visibility,
    ImportExport,
    GetApp,
    Cancel,
    Check,
} from '@material-ui/icons/'
import VisibilityIcon from '@material-ui/icons/Visibility';

import { formatter } from '../../utils/currencyFormater'
import {
    getPendingRefunds,
    getRefundedRefunds,
    getRefunds,
    clearSnackBar
} from '../../redux/actions/refunds/refunds';
import {
    getIncidentsManager,
    getIncidentsAdmin,
} from "../../redux/actions/incidents/incidentActions"
import { checkLogin } from "../../redux/actions/auth/login"
import { getUsersAction } from "../../redux/actions/usersAction"
import { getBuses } from "../../redux/actions/buses/buses"
// import colors from '../../colors';

import {
    getInstitutions,
    getInstitution
} from "../../redux/actions/institutions/institutionsActions"
import {
    getProjects,
    getProject
} from "../../redux/actions/projects/projectsAction";
import { getDamages } from '../../redux/actions/damages/damages'
import { getCategories } from '../../redux/actions/categories/categoriesActions'
import { updateIncident, rejectIncident } from '../../redux/actions/incidents/incidentActions'
import DisplayAll from '../templates/DisplayAll'
import Header from '../templates/TopPartOfPage'

import _ from 'lodash'
import { getReports } from '../../redux/actions/reports/reports';
import { getRoutes } from '../../redux/actions/routes/routes'
import { getDevices } from '../../redux/actions/devices/getDevices'
import { getDeviceMakes } from '../../redux/actions/devices/deviceMakes';
import { getDeviceTypes } from '../../redux/actions/devices/deviceTypes';



const Refunds = props => {
    const dispatch = useDispatch();
    const refunds = useSelector(state => state.refunds)
    const reports = useSelector(state => state.reports)

    // const classes = useStyles();
    const projects = useSelector(state => state.projects)
    const buses = useSelector(state => state.buses)
    const institutions = useSelector(state => state.institutions)
    const damages = useSelector(state => state.damages)
    const categories = useSelector(state => state.categories)
    const routes = useSelector(state => state.routes)
    const devices = useSelector(state => state.devices)


    // console.log("The devices are:", devices)

    const priorities = useSelector(state => state.priorities)
    const uploadimage = useSelector(state => state.uploadimage)
    const users = useSelector(state => state.users)

    const incidents = useSelector(state => state.incidents)
    const auth = useSelector(state => state.login)
    const allOption = { name: "All", id: "all" }


    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const [viewOption, setViewOption] = useState("Pending")
    const [selectedStartDate, handleStartDateChange] = useState(yesterday);
    const [selectedEndDate, handleEndDateChange] = useState(today);
    const [selectedCategory, setSelectedCategory] = useState(allOption)
    const [selectedIssue, setSelectedIssue] = useState(allOption)
    const [selectedProject, setSelectedProject] = useState(allOption)
    const [selectedInstitution, setSelectedInstitution] = useState(allOption)
    const [selectedRoute, setSelectedRoute] = useState(allOption)
    const [selectedBus, setSelectedBus] = useState(allOption)
    const [selectedBusMake, setSelectedBusMake] = useState(allOption)
    const [selectedDeviceMake, setSelectedDeviceMake] = useState(allOption)
    const [selectedDeviceType, setSelectedDeviceType] = useState(allOption)


    // const [filteredIssues, setFilteredIssues] = useState([])

    const filterData = () => {
        const filters = {
            categoryId: selectedCategory.id,
            damageId: selectedIssue.id,
            projectId: selectedProject.id,
            institutionId: selectedInstitution.id,
            routeId: selectedRoute.id,
            busId: selectedBus.id,

        }
        const secondaryFilters = {
            busMakeId: selectedBusMake.id,
            deviceMakeId: selectedDeviceMake.id,
            deviceTypeId: selectedDeviceType.id
        }
        Object.keys(filters).forEach(key => {
            if (filters[key] === 'all') delete filters[key];
        });
        Object.keys(secondaryFilters).forEach(key => {
            if (secondaryFilters[key] === 'all') delete secondaryFilters[key];
        });
        dispatch(getReports(filters, secondaryFilters))
        // console.log("The stringified filters are: from componet", filters)
    }

    useEffect(() => {
        dispatch(checkLogin());
    }, [])
    // useEffect(() => {
    //     if (auth.user) {
    //         if (auth.user.role.Permissions.manageIncident) {
    //             dispatch(getIncidentsAdmin(props.match.params.id));
    //         } else if (auth.user.role.Permissions.institutionManager) {
    //             dispatch(getIncidentsManager(props.match.params.id));
    //         }
    //     }
    // }, [auth.user])

    // useEffect(() => {
    //     dispatch(getPendingRefunds())
    //     dispatch(getRefundedRefunds())
    // }, [refunds.pendingRefunds, refunds.changed])

    useEffect(() => {
        dispatch(getProjects())
        dispatch(getInstitutions())
        dispatch(getDamages())
        dispatch(getCategories())
        dispatch(getUsersAction());
        dispatch(getBusMakes())
        dispatch(getRoutes())
        dispatch(getBuses())
        dispatch(getDevices())
        dispatch(getDeviceMakes())
        dispatch(getDeviceTypes())
    }, [])
    let rows
    reports.reports ? rows = reports.reports : rows = []
    const filteredRows = rows.filter(row => {
        return ((new Date(row.createdAt).getTime() > new Date(selectedStartDate).getTime()) && (new Date(row.createdAt).getTime() < new Date(selectedEndDate).getTime()))
    })
    filteredRows.forEach((row, index) => {
        row.newId = index + 1
        row.superAgentUser = row.incidentAgent ? row.incidentAgent.superAgentUser.name : "No Superagent"
        row.damageName = row.damage ? row.damage.name : "No issue"
        row.projectName = row.project ? row.project.name : "No project"
        row.agentName = row.incidentAgent ? row.incidentAgent.name : "No Agent"
        row.institutionName = row.institution.name
        row.routeName = row.route ? row.route.name : "Unavailable"
        row.issueName = row.damage ? row.damage.name : "Unavailable"
    })

    const columns = [
        {
            field: 'newId',
            title: 'ID',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF',
                width: "5%"
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            field: 'ticketId',
            title: 'TicketId',
        }, {
            field: 'institutionName',
            title: 'Institution',
        },
        {
            field: 'location',
            title: 'Location',
        },
        {
            field: 'routeName',
            title: 'Route',
        },
        {
            field: 'issueName',
            title: 'Issue',
        },

        {
            field: 'description',
            title: 'Description',
            cellStyle: {
                width: "25%"
            },

        }
    ];

    const projectOptions = projects.projects ? projects.projects.map(project => {
        return {
            name: project.name,
            id: project.id
        }
    }) : []
    const institutionOptions = institutions.institutions ? institutions.institutions.map(institution => {
        return {
            name: institution.name,
            id: institution.id
        }
    }) : []
    const categoryOptions = categories.categories ? categories.categories.map(category => {
        return {
            name: category.name,
            id: category.id
        }
    }) : []
    const deviceMakeOptions = devices.deviceMakes ? devices.deviceMakes.map(device => {
        return {
            name: device.name,
            id: device.id
        }
    }) : []

    const deviceTypeOptions = devices.deviceTypes ? devices.deviceTypes.map(device => {
        return {
            name: device.name,
            id: device.id
        }
    }) : []

    const damageOptions = damages.damages ? damages.damages.map(damage => {
        return {
            name: damage.name,
            id: damage.id
        }
    }) : []

    const routeOptions = routes.routes ? routes.routes.map(route => {
        return {
            name: route.name,
            id: route.id
        }
    }) : []
    const busOptions = buses.buses ? buses.buses.map(bus => {
        return {
            name: bus.plateNumber,
            id: bus.id
        }
    }) : []


    const busMakeOptions = buses.busMakes ? buses.busMakes.map(bus => {
        return {
            name: bus.name,
            id: bus.id
        }
    }) : []

    return (
        <div>
            <Header
                name="Reports"
                props={props}
            />
            <Grid
                container
                alignItems="flex-start"
                spacing={2}
                style={{
                    marginBottom: "1em",
                }}
            >

                <Grid
                    item
                    lg={4}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justify='space-between'
                        >
                            <Grid
                                item
                                lg={6}
                            >
                                <DateTimePicker
                                    label="Start Date"
                                    disableFuture
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                            >
                                <DateTimePicker
                                    autoOk
                                    ampm={false}
                                    disableFuture
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    label="End Date"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        </Grid>



                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...categoryOptions]}
                        value={selectedCategory}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setSelectedCategory(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Category"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...damageOptions]}
                        value={selectedIssue}
                        onChange={(event, newValue) => {
                            setSelectedIssue(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Issue"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...projectOptions]}
                        value={selectedProject}
                        onChange={(event, newValue) => {
                            setSelectedProject(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Project"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...institutionOptions]}
                        value={selectedInstitution}
                        onChange={(event, newValue) => {
                            setSelectedInstitution(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Institution"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...routeOptions]}
                        value={selectedRoute}
                        onChange={(event, newValue) => {
                            setSelectedRoute(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Route"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...busMakeOptions]}
                        value={selectedBusMake}
                        onChange={(event, newValue) => {
                            setSelectedBusMake(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Bus Make"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...busOptions]}
                        value={selectedBus}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setSelectedBus(newValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Bus"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...deviceTypeOptions]}
                        value={selectedDeviceType}
                        onChange={(event, newValue) => {
                            setSelectedDeviceType(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Device Type"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={6}
                >
                    <Autocomplete
                        id="free-solo-demo"
                        options={[allOption, ...deviceMakeOptions]}
                        value={selectedDeviceMake}
                        onChange={(event, newValue) => {
                            setSelectedDeviceMake(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Device Make"
                            style={{
                                padding: "0.26em 0"
                            }}
                        />
                        }

                    />

                </Grid>

            </Grid>
            <Button
                variant='contained'
                color='primary'
                onClick={filterData}
                style={{
                    width: '100%',
                    marginBottom: '2em'
                }}
            >
                Filter
            </Button>
            {/* <Grid
                container
                alignItems="center"
                style={{
                    // backgroundColor: "coral",
                    marginBottom: '1em',
                    display: 'flex',
                    justifyContent: 'space-between'

                }}
            >
                <Typography>Total Filtered Incidents</Typography>
                <Typography>{filteredRows.length}</Typography>
            </Grid> */}

            {viewOption == "Pending" ?
                <MaterialTable
                    columns={columns}
                    data={filteredRows}
                    title={`Result: ${filteredRows.length} ${filteredRows.length <= 1 ? "incident" : "incidents"} `}
                    options={{
                        exportButton: true,
                        actionsColumnIndex: -1,
                        padding: "dense",
                        paging: true,
                        pageSize: 15,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [15, 30, 50, 100],
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: {
                            whiteSpace: 'nowrap',
                            fontSize: 13,
                            fontFamily: "Poppins', sans-serif !important"
                        }
                    }}
                    actions={[
                        {
                            icon: () => <Visibility color="primary" />,
                            tooltip: "View",
                            onClick: (event, rowData) => props.history.push(`/dashboard/incident/${rowData.id}`)
                        }

                    ]}
                    components={{
                        Toolbar: MTableToolbar

                    }}
                /> :
                <MaterialTable
                    columns={columns}
                    data={filteredRows}
                    title="Refunded"
                    options={{
                        exportButton: true,
                        actionsColumnIndex: -1,
                        padding: "dense",
                        paging: true,
                        pageSize: 10,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [6, 12, 20, 50],
                        rowStyle: {
                            whiteSpace: 'nowrap',
                            fontSize: 13,
                            fontFamily: "Poppins', sans-serif !important"
                        }
                    }}
                    components={{
                        Toolbar: MTableToolbar
                    }}
                />
            }


        </div>
    )
}

export default Refunds
