export const GET_DEVICES = 'GET_DEVICES'
export const GET_ONE_DEVICE = 'GET_ONE_DEVICE'
export const DEVICES_ERROR = 'DEVICES_ERROR'
export const DEVICES_PENDING = 'DEVICES_PENDING'
export const CREATE_DEVICE = 'CREATE_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const DELETE_DEVICE = 'DELETE_DEVICE'
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR"
export const UPLOAD_DEVICES = "UPLOAD_DEVICES"

export const GET_DEVICE_TYPES = "GET_DEVICE_TYPES"
export const GET_ONE_TYPE = "GET_ONE_TYPE"
export const CREATE_TYPE = "CREATE_TYPE"
export const DELETE_TYPE = "DELETE_TYPE"
export const UPDATE_TYPE = "UPDATE_TYPE"

export const GET_DEVICE_MAKES = "GET_DEVICE_MAKES"
export const GET_ONE_MAKE = "GET_ONE_MAKE"
export const CREATE_MAKE = "CREATE_MAKE"
export const DELETE_MAKE = "DELETE_MAKE"
export const UPDATE_MAKE = "UPDATE_MAKE"

export const GET_DEVICE_REPLACEMENTS = "GET_DEVICE_REPLACEMENTS"