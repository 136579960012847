import React, { useEffect } from "react";
import {
    Grid,
    makeStyles,
    CircularProgress,
    Tabs,
    Tab,
    Paper,
    Typography,
    Box
} from "@material-ui/core"
import CustomMessage from "../templates/CustomMessages";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux"
import { getUserIncidents, clearSnackBar } from "../../redux/actions/incidents/incidentActions"
import { getInventoryRequests, getUserRequests } from "../../redux/actions/stock/stock";
import RequestCard from './cards/InventroyRequest'
import Top from "../templates/TopPartOfPageWithId"
import NoData from "../../assets/svgs/NoData";
import color from "../colors"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    tabs: {
        backgroundColor: `${color.primary100}`,
        color: "white"
    },
    tab: {
        color: "white",
    },
    tabPanel: {
        color: "white"
    },
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    contain: {
        position: "relative",
        width: "100%",
        marginBottom: "2px",
        borderRadius: "3px"
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            className={useStyles.tabPanel}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}
function InventoryRequests(props) {

    const inventoryRequests = useSelector(state => state.stock)

    // console.log("The inventory requests are:", inventoryRequests)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInventoryRequests(props.match.params.id))
        dispatch(getUserRequests(props.match.params.id))
    }, [])

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const classes = useStyles();
    return (
        <div className={classes.contain}>
            <Top props={props} name="Inventory Requests" />
            <React.Fragment>
                {inventoryRequests.pending ?
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                    :
                    (
                        inventoryRequests.userRequests ?

                            <Paper className={classes.root}>
                                <CustomMessage reducer={inventoryRequests} cleaner={clearSnackBar} />
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="white"
                                    centered
                                    scrollButtons="auto"
                                    className={classes.tabs}
                                >
                                    <Tab value={0} label="Pending" className={classes.tab}  {...a11yProps(0)} />
                                    <Tab value={1} label="Accepted" className={classes.tab} {...a11yProps(1)} />
                                    <Tab value={2} label="Rejected" className={classes.tab} {...a11yProps(2)} />
                                    <Tab value={4} label="Cancelled" className={classes.tab} {...a11yProps(4)} />
                                    <Tab value={3} label="Resolved" className={classes.tab} {...a11yProps(3)} />
                                </Tabs>
                                <TabPanel value={value} index={0} >
                                    <Grid container item spacing={3}>
                                        {inventoryRequests.userRequests.map((request, index) => (
                                            request.status.title === "pending" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={`${classes.insideGrid} ${classes.separate}`}>
                                                    <RequestCard request={request} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid container item spacing={3}>
                                        {inventoryRequests.userRequests.map((request, index) => (
                                            request.status.title === "accepted" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={`${classes.insideGrid} ${classes.separate}`}>
                                                    <RequestCard request={request} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Grid container item spacing={3}>
                                        {inventoryRequests.userRequests.map((request, index) => (
                                            request.status.title === "cancelled" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={`${classes.insideGrid} ${classes.separate}`}>
                                                    <RequestCard request={request} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Grid container item spacing={3}>
                                        {inventoryRequests.userRequests.map((request, index) => (
                                            request.status.title === "rejected" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={`${classes.insideGrid} ${classes.separate}`}>
                                                    <RequestCard request={request} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Grid container item spacing={3}>
                                        {inventoryRequests.userRequests.map((request, index) => (
                                            request.status.title === "resolved" ?
                                                <Grid item xs={8} sm={4} md={3} key={index} className={`${classes.insideGrid} ${classes.separate}`}>
                                                    <RequestCard request={request} props={props} />
                                                </Grid>
                                                : null
                                        ))}
                                    </Grid>
                                </TabPanel>
                            </Paper>
                            :
                            (
                                inventoryRequests.error ?
                                    <div>
                                        {inventoryRequests.snackBarMessage.message}
                                    </div>
                                    :
                                    <div>
                                        <NoData />
                                    </div>

                            )
                    )

                }
            </React.Fragment>
        </div>
    )
}


export default InventoryRequests;