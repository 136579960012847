import React, { useState } from 'react'
import { TextField, Typography, CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import { connect } from 'react-redux'
import { LockOpen, MailOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Redirect } from 'react-router-dom'
import color from '../colors'
import { loginRequest } from '../../redux/actions/auth/login';
import '../landing/landingstyle.scss';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width: "100%"
  },
}));

function LoginForm(props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { loading, error } = props;
  const classes = useStyles();
  const history = useHistory()


  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password
    }
    props.loginRequest(data, history)
    setEmail('')
    setPassword('')

  }
  return (
    <>
      {loading && <div className='progress'><CircularProgress /></div>}
      {!loading &&
        <div className="login-holder">
          <div className="login-form">
            {error &&
              <div className="error-holder"><Typography className="error">{error}</Typography></div>
            }
            <Typography variant="p" style={{ fontSize: 30, color: "#19459D" }}>Welcome back!</Typography><br></br>
            <div>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Password"
                type="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpen />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <br></br>
            <div style={{ display: "grid", gridTemplateColumns: "30% 70%", alignItems: "center" }}>
              <button variant="contained" color="primary" onClick={handleLogin} className="button-login">Login</button>
              <a href="#" style={{ textAlign: "end" }} className="forgot-password">Forgot password?</a>
            </div>
          </div>
        </div>}
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.login.isLoading,
  error: state.login.error
})

export default connect(mapStateToProps, { loginRequest })(LoginForm);